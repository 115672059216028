import React, { Component, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Container, Button, Box, Avatar, Typography, TextField, Grid, Link, Paper, CircularProgress } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { resetPassword } from '../../services/http.service';
import { ErrorAlertTopCenter } from '../../common/snackbars';
import UnAuthLayout from '../../layouts/unauth';

const validationSchema = Yup.object({   
    password: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long"),
    confirmPassword: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long")
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    
});



const ResetPassword = () => {	

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { userId } = useParams();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsSubmitting(true);
            console.log(`submit values - ${JSON.stringify(values)}`);
            setErrorMsg(null);
            resetPassword(userId, values).then(resp => {
                setIsSubmitting(false);
                navigate('/login');
            }).catch(err => {
                setIsSubmitting(false);
                setErrorMsg(err);
            });
        },
    });

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            <Container component="main" maxWidth="sm">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockResetIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Reset Password
                        </Typography>
                        <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSubmitting &&
                                    <CircularProgress />
                                }
                                {!isSubmitting &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 4, mb: 2 }}
                                    >
                                        Submit
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>        
    );

        
    // return (
    //     <Row className="h-100 justify-content-center align-items-center register-bg">
    //         <div id="register-bg-overlay"> </div>
    //         <Col lg={4} id="register-container" className="shadow-lg rounded">
    //             <div className="text-center text-muted mb-5">
    //                 <h4>Reset Password</h4>
    //             </div>
    //             <Formik
    //                 validationSchema={schema}
    //                 onSubmit={( values, { setSubmitting }) => {
    //                     console.log(`submit values - ${JSON.stringify(values)}`);
    //                     setShowErrorAlert(false);
    //                     setErrorMsg(null);
    //                     resetPassword(userId, values).then(resp => {
    //                         setShowSuccessAlert(true);
    //                         setShowErrorAlert(false);                                
    //                     }).catch(err => {
    //                         setSubmitting(false);
    //                         setShowErrorAlert(true);
    //                         setErrorMsg(err);
    //                     });  
    //                 }}
    //                 initialValues={{
    //                     password: '',
    //                     confirmPassword: '',
    //                 }}
    //                 >
    //                     {({
    //                         handleSubmit,
    //                         handleChange,
    //                         handleBlur,
    //                         values,
    //                         touched,
    //                         isValid,
    //                         errors,
    //                         isSubmitting,
    //                     }) => (
    //                         <Form noValidate onSubmit={handleSubmit}>
    //                             <Form.Group>
    //                                 <Form.Label>New Password</Form.Label>
    //                                 <Form.Control
    //                                     type="password"
    //                                     placeholder="Enter new password"
    //                                     name="password"
    //                                     value={values.password}
    //                                     onChange={handleChange}
    //                                     isInvalid={!!errors.password} />
    //                                 <Form.Control.Feedback type="invalid">
    //                                     {errors.password}
    //                                 </Form.Control.Feedback>
    //                             </Form.Group>
    //                             <Form.Group>
    //                                 <Form.Label>Confirm New Password</Form.Label>
    //                                 <Form.Control
    //                                     type="password"
    //                                     placeholder="Enter confirm new password"
    //                                     name="confirmPassword"
    //                                     value={values.confirmPassword}
    //                                     onChange={handleChange}
    //                                     isInvalid={!!errors.confirmPassword} />
    //                                 <Form.Control.Feedback type="invalid">
    //                                     {errors.confirmPassword}
    //                                 </Form.Control.Feedback>
    //                             </Form.Group>
    //                             <div className="d-flex align-items-center justify-content-left">
    //                                 <Button className="btn-general" type="submit">Reset</Button>                            
    //                                 {isSubmitting &&                         
    //                                     <Spinner animation="border" variant="primary" />
    //                                 }
    //                                 {showSuccessAlert &&
    //                                     <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false) } 
    //                                         dismissible>
    //                                         Password changed Successfully <a href="/login">Login</a>
    //                                     </Alert>
    //                                 }
    //                                 {errorMsg &&
    //                                     <Alert className="ml-3 mb-0" variant="danger" onClose={() => setShowSuccessAlert(false) } 
    //                                         dismissible>
    //                                         {errorMsg}
    //                                     </Alert>
    //                                 }
    //                             </div>
    //                         </Form>
    //                     )}
    //                 </Formik>
    //         </Col>
    //     </Row>
    // );
}

export default ResetPassword;



// class ResetPassword extends Component {	

// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             showSuccessAlert: false,
//             errorMsg: null,
// 		};
//     }

//     render() {

//         const { showSuccessAlert, errorMsg } = this.state;
        
//         return (
//             <Row className="h-100 justify-content-center align-items-center register-bg">
//                 <div id="register-bg-overlay"> </div>
//                 <Col lg={4} id="register-container" className="shadow-lg rounded">
//                     <div className="text-center text-muted mb-5">
//                         <h4>Reset Password</h4>
//                     </div>
//                     <Formik
//                         validationSchema={schema}
//                         onSubmit={( values, { setSubmitting }) => {
//                             console.log(`submit values - ${JSON.stringify(values)}`);
//                             this.setState({showErrorAlert: false});
//                             this.setState({errorMsg: null})
//                             resetPassword(this.props.match.params.id, values).then(resp => {
//                                 setSubmitting(false);
//                                 this.setState({showSuccessAlert: true});
//                                 this.setState({showErrorAlert: false});                                
//                             }).catch(err => {
//                                 setSubmitting(false);
//                                 this.setState({showErrorAlert: true});
//                                 this.setState({errorMsg: err});
//                             });  
//                         }}
//                         initialValues={{
//                             password: '',
//                             confirmPassword: '',
//                         }}
//                         >
//                             {({
//                                 handleSubmit,
//                                 handleChange,
//                                 handleBlur,
//                                 values,
//                                 touched,
//                                 isValid,
//                                 errors,
//                                 isSubmitting,
//                             }) => (
//                                 <Form noValidate onSubmit={handleSubmit}>
//                                     <Form.Group>
//                                         <Form.Label>New Password</Form.Label>
//                                         <Form.Control
//                                             type="password"
//                                             placeholder="Enter new password"
//                                             name="password"
//                                             value={values.password}
//                                             onChange={handleChange}
//                                             isInvalid={!!errors.password} />
//                                         <Form.Control.Feedback type="invalid">
//                                             {errors.password}
//                                         </Form.Control.Feedback>
//                                     </Form.Group>
//                                     <Form.Group>
//                                         <Form.Label>Confirm New Password</Form.Label>
//                                         <Form.Control
//                                             type="password"
//                                             placeholder="Enter confirm new password"
//                                             name="confirmPassword"
//                                             value={values.confirmPassword}
//                                             onChange={handleChange}
//                                             isInvalid={!!errors.confirmPassword} />
//                                         <Form.Control.Feedback type="invalid">
//                                             {errors.confirmPassword}
//                                         </Form.Control.Feedback>
//                                     </Form.Group>
//                                     <div className="d-flex align-items-center justify-content-left">
//                                         <Button className="btn-general" type="submit">Reset</Button>                            
//                                         {isSubmitting &&                         
//                                             <Spinner animation="border" variant="primary" />
//                                         }
//                                         {showSuccessAlert &&
//                                             <Alert className="ml-3 mb-0" variant="success" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                                 dismissible>
//                                                 Password changed Successfully <a href="/login">Login</a>
//                                             </Alert>
//                                         }
//                                         {errorMsg &&
//                                             <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                                 dismissible>
//                                                 {this.state.errorMsg}
//                                             </Alert>
//                                         }
//                                     </div>
//                                 </Form>
//                             )}
//                         </Formik>
//                 </Col>
//             </Row>
//         )
//     }
// }

// export default ResetPassword;
