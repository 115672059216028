import { MODAL_OPEN, MODAL_CLOSE} from '../actions/action-types';


const modals = (state = {}, action) => {
    console.log("Modals reducer :: action type " + action.type + " -- payload : " + JSON.stringify(action.payload));
    switch (action.type) {
        case MODAL_OPEN:
            return {
                ...state,                
                modalType: action.payload.modalType,
                isOpen: action.payload.isOpen,
                content: action.payload.content,
                okCallback: action.okCallback,
                cancelCallback: action.cancelCallback
            }
        case MODAL_CLOSE:
            return {
                ...state,
                modalType: action.payload.modalType,
                isOpen: action.payload.isOpen
            }
        default:
            return state
    }
}

export default modals