
import io from 'socket.io-client';

import { URL } from './constants';


let locationSocket = null;
let chatSocket = null;
let notificationSocket = null;

let notificationUserInfo = null;
let locationUserInfo = null;
let chatUserInfo = null;

export const Events = {
    MY_INFO: "MyInfo",
    START_TRIP: "StartTrip",
    NOTIFICATION: 'Notification',
    UPDATE_NOTIFICATION_READ: "UpdateNotificationRead",
    NOTIFY_CURRENT_LOCATION: 'NotifyCurrentLocation',
    CHAT_MESSAGE: 'ChatMessage',
    CHAT_MESSAGE_NOTIFICATION: 'ChatMessageNotification',
    UPDATE_CHAT_MESSAGE_READ: 'UpdateChatMessageRead'
};

/**
 * Connect to Sokcet and retry on fail
 */
export const connectLocationSocket = (info) => {
    return new Promise((resolve, reject) => {
        console.log('is socket connected - ' + locationSocket);        
        if (locationSocket && locationSocket.connected) {
            console.log('Location Socket already connected. Returnning');
            resolve(locationSocket);
        }
        else {
            locationUserInfo = info;
            console.log('Location Socket not connected, connecting now..');
            locationSocket = io.connect(URL.SOCKET_IO_URL + '/location', {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax : 5000,
                reconnectionAttempts: Infinity,
            });
            locationSocket.on('connect', () => {
                console.log('Location Socket connected - ' + locationSocket);
                locationSocket.emit(Events.MY_INFO, locationUserInfo); // When retry also also send locationUserInfo
                resolve(locationSocket);
            });
            locationSocket.on('connect_error', (error) => {
                console.log('Socket connect_error - ' + error);
                reject(error);
            });
            locationSocket.on('connect_timeout', (timeout) => {
                console.log('Location Socket connect timeout - ' + timeout);
                reject(timeout);
            });
            locationSocket.on('error', (error) => {
                console.log('Location Socket connect error - ' + error);
                reject(error);
            });
            locationSocket.on('disconnect', (reason) => {
                console.log('Location Socket disconnect - ' + reason);
                reject(reason);
            });
            locationSocket.on('reconnect', (attemptNumber) => {
                console.log('Location Socket reconnect - ' + attemptNumber);
                reject(attemptNumber);
            });
            locationSocket.on('reconnect_attempt', (attemptNumber) => {
                console.log('Location Socket reconnect_attempt - ' + attemptNumber);
                reject(attemptNumber);
            });
            locationSocket.on('reconnecting', (attemptNumber) => {
                console.log('Location Socket reconnecting - ' + attemptNumber);
                reject(attemptNumber);
            });
            locationSocket.on('reconnect_error', (error) => {
                console.log('Location Socket reconnect_error - ' + error);
                reject(error);
            });
            locationSocket.on('reconnect_failed', (error) => {
                console.log('Location Socket reconnect_failed - ' + error);
                reject();
            });
        }
    });
};

/**
 * Connect to Chat Sokcet and retry on fail
 */
export const connectChatSocket = (info) => {
    return new Promise((resolve, reject) => {
        console.log('Chat Socket socket connected - ' + chatSocket);        
        if (chatSocket && chatSocket.connected) {
            console.log('Chat Socket already connected. Returnning');
            resolve(chatSocket);
        }
        else {
            chatUserInfo = info;
            console.log('Chat Socket not connected, connecting now..');
            chatSocket = io.connect(`${URL.SOCKET_IO_URL}/chat`, {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax : 5000,
                reconnectionAttempts: Infinity,
            });
            chatSocket.on('connect', () => {
                console.log('Chat Socket connected - ' + chatSocket);
                chatSocket.emit(Events.MY_INFO, chatUserInfo); // When retry also also send chatUserInfo
                resolve(chatSocket);
            });
            chatSocket.on('connect_error', (error) => {
                console.log('Chat Socket connect_error - ' + error);
                reject(error);
            });
            chatSocket.on('connect_timeout', (timeout) => {
                console.log('Chat Socket connect timeout - ' + timeout);
                reject(timeout);
            });
            chatSocket.on('error', (error) => {
                console.log('Chat Socket connect error - ' + error);
                reject(error);
            });
            chatSocket.on('disconnect', (reason) => {
                console.log('Chat Socket disconnect - ' + reason);
                reject(reason);
            });
            chatSocket.on('reconnect', (attemptNumber) => {
                console.log('Chat Socket reconnect - ' + attemptNumber);
                reject(attemptNumber);
            });
            chatSocket.on('reconnect_attempt', (attemptNumber) => {
                console.log('Chat Socket reconnect_attempt - ' + attemptNumber);
                reject(attemptNumber);
            });
            chatSocket.on('reconnecting', (attemptNumber) => {
                console.log('Chat Socket reconnecting - ' + attemptNumber);
                reject(attemptNumber);
            });
            chatSocket.on('reconnect_error', (error) => {
                console.log('Chat Socket reconnect_error - ' + error);
                reject(error);
            });
            chatSocket.on('reconnect_failed', (error) => {
                console.log('Chat Socket reconnect_failed - ' + error);
                reject();
            });
        }
    });
};

/**
 * Connect to Notification Sokcet and retry on fail
 */
export const connectNotificationSocket = (info) => {
    return new Promise((resolve, reject) => {
        console.log('Notification Socket socket connected - ' + notificationSocket);        
        if (notificationSocket && notificationSocket.connected) {
            console.log('Chst Socket already connected. Returnning');
            resolve(notificationSocket);
        }
        else {
            notificationUserInfo = info;
            console.log('Socket not connected, connecting now..');
            notificationSocket = io.connect(`${URL.SOCKET_IO_URL}/notification`, {
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax : 5000,
                reconnectionAttempts: Infinity,
            });
            notificationSocket.on('connect', () => {
                console.log('Chat Socket connected - ' + notificationSocket);
                notificationSocket.emit(Events.MY_INFO, notificationUserInfo); // When retry also also send userInfo
                resolve(notificationSocket);
            });
            notificationSocket.on('connect_error', (error) => {
                console.log('Notification Socket connect_error - ' + error);
                reject(error);
            });
            notificationSocket.on('connect_timeout', (timeout) => {
                console.log('Notification Socket connect timeout - ' + timeout);
                reject(timeout);
            });
            notificationSocket.on('error', (error) => {
                console.log('Notification Socket connect error - ' + error);
                reject(error);
            });
            notificationSocket.on('disconnect', (reason) => {
                console.log('Notification Socket disconnect - ' + reason);
                reject(reason);
            });
            notificationSocket.on('reconnect', (attemptNumber) => {
                console.log('Notification Socket reconnect - ' + attemptNumber);
                reject(attemptNumber);
            });
            notificationSocket.on('reconnect_attempt', (attemptNumber) => {
                console.log('Notification Socket reconnect_attempt - ' + attemptNumber);
                reject(attemptNumber);
            });
            notificationSocket.on('reconnecting', (attemptNumber) => {
                console.log('Notification Socket reconnecting - ' + attemptNumber);
                reject(attemptNumber);
            });
            notificationSocket.on('reconnect_error', (error) => {
                console.log('Notification Socket reconnect_error - ' + error);
                reject(error);
            });
            notificationSocket.on('reconnect_failed', (error) => {
                console.log('Notification Socket reconnect_failed - ' + error);
                reject();
            });
        }
    });
};


// // Connect to Basic Socket
// export const connectSocket = async () => {
//     return new Promise((resolve, reject) => {
//         console.log(`Connect Socket`);
//         if (socket == null || !socket.connected) {
//             console.log(`Socket IO is not connected, connecting now`);
//             socket = io.connect(URL.SOCKET_IO_URL);
//             socket.on('connect', () => {
//                 console.log(`SocketIO connected successfully - Returning now`);
//                 resolve(socket);
//             });
//         } else {
//             console.log(`SocketIO already connected - Returning Socket`);
//             resolve(socket);
//         }
//     });
// };

// // Connect to Chat Socket
// export const connectChatSocket = async () => {
//     return new Promise((resolve, reject) => {
//         console.log('Connect Chat Socket');
//         if (chatSocket == null || !chatSocket.connected) {
//             console.log('Chat Socket IO is not connected, connecting now');
//             chatSocket = io.connect(`${URL.SOCKET_IO_URL}/chat`);
//             chatSocket.on('connect', () => {
//                 console.log('Chat SocketIO connected successfully - Returning now');
//                 resolve(chatSocket);
//             });
//         } else {
//             console.log('SocketIO already connected - Returning Socket');
//             resolve(chatSocket);
//         }
//     });
// };

// // Connect to Notification Socket
// export const connectNotificationSocket = async () => {
//     return new Promise((resolve, reject) => {
//         console.log('Connect Notifcation Socket');
//         if (notificationSocket == null || !notificationSocket.connected) {
//             console.log('Notification Socket IO is not connected, connecting now');
//             notificationSocket = io.connect(`${URL.SOCKET_IO_URL}/notification`);
//             notificationSocket.on('connect', () => {
//                 console.log('Notification SocketIO connected successfully - Returning now');
//                 resolve(notificationSocket);
//             });
//         } else {
//             console.log('Notification SocketIO already connected - Returning Socket');
//             resolve(notificationSocket);
//         }
//     });
// };

// // Disconnect Basic Socket
// export const disconnectSocket = async () => {
//     if (socket != null || socket.connected) {
//         socket.disconnect();
//     }
// };

// // Disconnect Chat Socket
// export const disconnectChatSocket = async () => {
//     if (chatSocket != null || chatSocket.connected) {
//         chatSocket.disconnect();
//     }
// };

// // Disconnect Notification Socket
// export const disconnectNotificationSocket = async () => {
//     if (notificationSocket != null || notificationSocket.connected) {
//         notificationSocket.disconnect();
//     }
// };

export const disconnectLocationSocket = async () => {
    if (locationSocket != null || locationSocket.connected) {
        locationSocket.disconnect();
    }
};

export const disconnectChatSocket = async () => {
    if (chatSocket != null || chatSocket.connected) {
        chatSocket.disconnect();
    }
};

export const disconnectNotificationSocket = async () => {
    if (notificationSocket != null || notificationSocket.connected) {
        notificationSocket.disconnect();
    }
};


