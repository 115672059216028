import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap'; // To make work raw bootstrap functions


// import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { composeWithDevTools } from 'redux-devtools-extension';
// date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import rootReducer from './reducers'


// const allStoreEnhancers = compose (
// 	applyMiddleware(thunk),
// 	window.devToolsExtension && window.devToolsExtension()   // Remove it to reslove TypeError: Cannot read property 'apply' of undefined reactjs
// );

const allStoreEnhancers = compose (      
	applyMiddleware(thunk),
);

// use applyMiddleware to add the thunk middleware to the store
const store = createStore(rootReducer, allStoreEnhancers);

ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App/>
            </LocalizationProvider>
        </BrowserRouter>
    </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
