import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import Register from '../components/unauth/register';
import Login from '../components/unauth/login';
import AdminLogin from '../components/unauth/admin-login';
import School from '../school/school';
import RegisterOTPVerification from '../components/unauth/otp-verification';
import ForgotPassword from '../components/unauth/forgot-password';
import ForgotPasswordOTPVerification from '../components/unauth/forgot-password-otp-verification';
import ResetPassword from '../components/unauth/reset-password';
import { isAuthenticated, getUser } from '../services/auth.service';
import ModalComponent from '../modals';
import Profile from '../school/profile/profile';
import { Role } from '../utils/constants';

import Settings from '../components/auth/settings/settings';
import SettingProfile from '../components/auth/settings/profile/profile';
import SettingProfileEdit from '../components/auth/settings/profile/edit';
import SettingProfilePhoto from '../components/auth/settings/profile/photo';
import SettingProfileChangePassword from '../components/auth/settings/profile/change-password'

import SchoolList from '../components/auth/school/list';
import NewSchool from '../components/auth/school/new';
import SchoolLogo from '../components/auth/school/school-logo';

import SchoolAccessDenied from '../school-access-denied';

import User from '../components/auth/admin/user/user';
import OTPVerification from '../components/auth/admin/user/otp-verification';

import UserStudent from '../components/auth/admin/user/student';
import UserStudentList from '../components/auth/admin/user/student-list';
import UserStudentNew from '../components/auth/admin/user/student-new';
import ProfilePhoto from '../components/auth/admin/user/profile-photo';

import UserDriver from '../components/auth/admin/user/driver';
import UserDriverList from '../components/auth/admin/user/driver-list';
import UserDriverNew from '../components/auth/admin/user/driver-new';

// import UserAdmin from '../components/auth/admin/user/admin';
// import UserAdminList from '../components/auth/admin/user/admin-list';
// import UserAdminNew from '../components/auth/admin/user/admin-new';
import AdminList from '../components/auth/admin/list';
import AdminNew from '../components/auth/admin/new';
import AdminVerify from '../components/auth/admin/verify';
import AdminPhoto from '../components/auth/admin/photo';

import Section from '../components/auth/admin/section/section';
import NewSection from '../components/auth/admin/section/new';
import SectionList from '../components/auth/admin/section/list';

import Class from '../components/auth/admin/class/class';
import NewClass from '../components/auth/admin/class/new';
import ClassList from '../components/auth/admin/class/list';

import Student from '../components/auth/admin/student/student';
import NewStudent from '../components/auth/admin/student/new';
import StudentList from '../components/auth/admin/student/list';
import AddStudent from '../components/auth/admin/student/add';

import Driver from '../components/auth/admin/driver/driver';
import NewDriver from '../components/auth/admin/driver/new';
import DriverList from '../components/auth/admin/driver/list';
import DriverPhoto from '../components/auth/admin/driver/photo';
import DriverVerify from '../components/auth/admin/driver/verify';

import Bus from '../components/auth/admin/bus/bus';
import NewBus from '../components/auth/admin/bus/new';
import BusList from '../components/auth/admin/bus/list';

import BusRoute from '../components/auth/admin/route/route';
import NewRoute from '../components/auth/admin/route/new';
import RouteList from '../components/auth/admin/route/list';

import Trip from '../components/auth/admin/trip/trip';
import TripMetadata from '../components/auth/admin/trip/metadata';
import TripMetadataNew from '../components/auth/admin/trip/metadata-new';
import TripMetadataList from '../components/auth/admin/trip/metadata-list';
import TodayTripList from '../components/auth/admin/trip/today-list';
import EditTrip from '../components/auth/admin/trip/edit';
import RunningTripMapView from '../components/auth/admin/trip/running-trip-map-view';
import HistoryList from '../components/auth/admin/trip/history-list';
import TripDetailView from '../components/auth/admin/trip/trip-detail-view';

// Layouts
import UnAuthLayout from '../layouts/unauth';
import SchoolLayout from '../layouts/auth/school/index';
import SchoolAdminLayout from '../layouts/auth/school/admin';
import SettingsLayout from '../layouts/auth/settings/index';

const AppRoute = () => {
    return (
        <Routes>
            {/* v6 way */}
            <Route exact path="/" element={<PublicRoute><UnAuthLayout /></PublicRoute>}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route exact path='admin-login' element={<AdminLogin />} />
                <Route exact path='otp-verification/:id' element={<RegisterOTPVerification />} />
                <Route exact path='forgot-password' element={<ForgotPassword />} />
                <Route exact path='reset-password/:userId' element={<ResetPassword />} />
                <Route exact path='forgot-password/otp-verification/:userId' element={<ForgotPasswordOTPVerification />} />
            </Route>
            
            <Route path="/school" element={<PrivateRoute><SchoolLayout /></PrivateRoute>}>
                <Route path="list" element={<SchoolList />} />
                <Route path="new" element={<NewSchool />} />
                <Route path="edit/:id" element={<NewSchool />} />
                <Route path=":id/logo" element={<SchoolLogo />} />
                <Route path="access-denied" element={<SchoolAccessDenied />} />
            </Route>  

            <Route path="/school/:id/admin" element={<PrivateRoute><SchoolAdminLayout /></PrivateRoute>}>
                {/* <Route path=":id/admin" element={<Admin />}> */}
                    <Route path="list" element={<AdminList />} />
                    <Route path="new" element={<AdminNew />} />
                    <Route path="edit/:adminId" element={<AdminNew />} />
                    <Route exact path=":adminId/verify" element={<AdminVerify />} />
                    <Route exact path=":adminId/photo" element={<AdminPhoto />} />
                    <Route path="user" element={<User />}>
                        <Route path="student" element={<UserStudent />}>
                            <Route path="list" element={<UserStudentList />} />
                            <Route path="new" element={<UserStudentNew />} />
                            <Route path="edit/:userId" element={<UserStudentNew />} />
                            <Route path=":userId/profile-photo" element={<ProfilePhoto />} />
                        </Route>
                        <Route path="driver" element={<UserDriver />}>
                            <Route path="list" element={<UserDriverList />} />
                            <Route path="new" element={<UserDriverNew />} />
                            <Route path="edit/:userId" element={<UserDriverNew />} />
                            <Route path=":userId/profile-photo" element={<ProfilePhoto />} />
                            <Route path="otp-verification" element={<OTPVerification />} />
                        </Route>
                        {/* <Route path="admin" element={<UserAdmin />}>
                            <Route path="list" element={<UserAdminList />} />
                            <Route path="new" element={<UserAdminNew />} />
                            <Route path="edit/:userId" element={<UserAdminNew />} />
                            <Route path=":userId/profile-photo" element={<ProfilePhoto />} />
                            <Route path="otp-verification" element={<OTPVerification />} />
                        </Route> */}
                    </Route>

                    <Route path="section" element={<Section />}>
                        <Route exact path="edit/:sectionId" element={<NewSection />} />
                        <Route exact path="list" element={<SectionList />} />
                        <Route exact path="new" element={<NewSection />} />
                    </Route>

                    <Route path="class" exact element={<Class />}>
                        <Route exact path="edit/:classId" element={<NewClass />} />
                        <Route exact path="list" element={<ClassList />} />
                        <Route exact path="new" element={<NewClass />} />
                    </Route>

                    <Route path="student" element={<Student />}>
                        <Route exact path="edit/:studentId" element={<NewStudent />} />
                        <Route exact path="list" element={<StudentList />} />
                        <Route exact path="new" element={<NewStudent />} />
                        <Route exact path="add" element={<AddStudent />} />
                    </Route>

                    <Route path="driver" element={<Driver />}>
                        <Route exact path="edit/:driverId" element={<NewDriver />} />
                        <Route exact path="list" element={<DriverList />} />
                        <Route exact path="new" element={<NewDriver />} />
                        <Route exact path=":driverId/photo" element={<DriverPhoto />} />
                        <Route exact path=":driverId/verify" element={<DriverVerify />} />
                    </Route>

                    <Route path="bus" element={<Bus />}>
                        <Route exact path="edit/:busId" element={<NewBus />} />
                        <Route exact path="list" element={<BusList />} />
                        <Route exact path="new" element={<NewBus />} />
                    </Route>

                    <Route path="route" element={<BusRoute />}>
                        <Route exact path="edit/:routeId" element={<NewRoute />} />
                        <Route exact path="list" element={<RouteList />} />
                        <Route exact path="new" element={<NewRoute />} />
                    </Route>

                    <Route path="trip" element={<Trip />}>
                        <Route path=":tripId/running/map-view" element={<RunningTripMapView />} />
                        <Route path="edit/:tripId" element={<EditTrip />} />
                        <Route path="today/list" element={<TodayTripList />} />
                        <Route path="history/list" element={<HistoryList />} />
                        <Route path=":tripId/detail-view" element={<TripDetailView />} />
                        <Route path="metadata" element={<TripMetadata />}>
                            <Route path="new" element={<TripMetadataNew />} />
                            <Route path="edit/:tripMetaDataId" element={<TripMetadataNew />} />
                            <Route path="list" element={<TripMetadataList />} />
                        </Route>
                    </Route>
                {/* </Route> */}
            </Route>
            
            
            {/* <Route path="/school/*" element={<PrivateRoute><School /></PrivateRoute>} /> */}
            
            <Route path="/settings" element={<PrivateRoute><SettingsLayout /></PrivateRoute>}>
                <Route path="profile" element={<SettingProfile />}>
                    <Route exact path="edit" element={<SettingProfileEdit />} />
                    <Route exact path="photo" element={<SettingProfilePhoto />} />
                    <Route exact path="change-password" element={<SettingProfileChangePassword />} />
                </Route>
            </Route>
        </Routes>
    );
}

// v6 private route
function PrivateRoute({ children }) {
    let isAuth = isAuthenticated();
    return isAuth ? children : <Navigate replace to="/login" />;
}

// v6 public route
function PublicRoute({ children }) {
    const location = useLocation();
    const authenticated = isAuthenticated();
    console.log(`isAuthenticated - ${authenticated}`);
    const user = getUser();
    console.log("children ", children);
    if (authenticated) {
        let url = '/school/list';
        console.log(`auth user - ${JSON.stringify(user)}`);
        // Check if user is admin - then Navigate to accordingly
        const roleOwner = user.roles.find(item => item.name === Role.SCHOOL_OWNER);
        console.log(`User Owner role - ${JSON.stringify(roleOwner)}`);
        const roleAdmin = user.roles.find(item => item.name === Role.ADMIN);
        console.log(`User Admin role - ${JSON.stringify(roleAdmin)}`);
        if (roleOwner) {
            url = '/school/list';
        } else if (roleAdmin) {
            url = `/school/${user.school.id}/admin/trip/today/list`;
        }
        return <Navigate replace to={url} />;
    } else {
        console.log("location.pathname ", location.pathname);        
        if (location.pathname === "/") {
            return <Navigate replace to={"/login"} />;
        }
        return children;
    }
    //return !authenticated ? children : <Navigate replace to={url} />;
}

export default AppRoute;