import React, { Component, useState } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom";

import { CustomModal } from '../../../../common/modals';
import { ModalType, formatDateTimeRev, convertRouteType, TripStatus } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';


const ListTab = ({ trips, baseUrl, onTripDelete }) => {

    //const [trips, setTrips] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    const navigate = useNavigate();

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        })
        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false
        })
        onTripDelete(data.id);
    }

    return (
        <>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                    <TableCell>Ref No.</TableCell>
                                    <TableCell>Route No.</TableCell>
                                     <TableCell>Type</TableCell>
                                     <TableCell>Expected Start Time</TableCell>
                                     <TableCell>Actual Start Time</TableCell>
                                     <TableCell>Bus</TableCell>
                                     <TableCell>Driver</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trips.map((item, index) => (
                                    <TableRow
                                        key={item.name}
                                    >
                                        <TableCell align="left">{index + 1}</TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.ref_no}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.route.number}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {convertRouteType(item.route.type.name)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {formatDateTimeRev(item.expected_start_time)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {formatDateTimeRev(item.actual_start_time)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.bus ? item.bus.number : ''}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.driver ? item.driver.user.name : ''}
                                        </TableCell>
                                        <TableCell align="right">
                                            {(item.status === TripStatus.ON_RUN || item.status == TripStatus.FINISHED) &&
                                                <VertActionMenu
                                                    onView={() => navigate(`${baseUrl}/trip/${item.id}/running/map-view`)}
                                                />
                                            }
                                            {item.status == TripStatus.NEW &&
                                                <VertActionMenu
                                                    onView={() => navigate(`${baseUrl}/trip/${item.id}/detail-view`)}
                                                    onEdit={() => navigate(`${baseUrl + "/trip/edit/" + item.id}`)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            }
                                            {item.status == TripStatus.ABANDONED &&
                                                <VertActionMenu
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            }
                                            
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => { onDelete(data) }}
                onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
            >
            </CustomModal>
        </>
    );


    // return (
    //     <React.Fragment>
            
    //         <table className="table">
    //             <thead>
    //                 <tr className="row">
    //                     <th className="col-lg-1">Ref No.</th>
    //                     <th className="col-lg-1">Type</th>
    //                     <th className="col-lg-2">Expected Start Time</th>
    //                     <th className="col-lg-2">Actual Start Time</th>                            
    //                     <th className="col-lg-2">Bus</th>
    //                     <th className="col-lg-2">Driver</th>
    //                     <th className="col-lg-2">Action</th>
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {trips.map((item, index) => (
    //                     <tr key={index} className="row">
    //                         <td className="col-lg-1">{item.ref_no}</td>
    //                         <td className="col-lg-1">{convertRouteType(item.route.type.name)}</td>
    //                         <td className="col-lg-2">{formatDateTimeRev(item.expected_start_time)}</td>
    //                         <td className="col-lg-2">{formatDateTimeRev(item.actual_start_time)}</td>                                
    //                         <td className="col-lg-2">{item.bus ? item.bus.number : ''}</td>
    //                         <td className="col-lg-2">{item.driver ? item.driver.user.name : ''}</td>                                
    //                         <td className="col-lg-2">
    //                         <a href={`${baseUrl}/trip/${item.id}/running/map-view`}>
    //                                 <i className="fas fa-eye fa-lg text-success mr-3"></i>
    //                             </a>
    //                             <a href={baseUrl + "/trip/edit/" + item.id}>
    //                                 <i className="fas fa-edit fa-lg text-success mr-1"></i>
    //                             </a>
    //                             <Button variant="link" onClick={() => handleDelete(item.id)}>
    //                                 <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                             </Button>
    //                         </td>
    //                     </tr>
    //                 ))}
    //             </tbody>
    //         </table>
    //     </React.Fragment>
    // );
    
}
export default ListTab;



// class ListTab extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             trips: [],
//             isLoading: false,
//             delete: {
//                 show: false,
//                 data: {}
//             }
// 		};
//     }

//     // Handle Delete modal   
//     handleDelete = (id) => {
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));
//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of School
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));
//         // Show spinner
//         this.setState({isLoading: true});
        
//     }

//     render() {

//         const { trips } = this.props;

// 		return (
//             <React.Fragment>
                
//                 <table className="table">
//                     <thead>
//                         <tr className="row">
//                             <th className="col-lg-1">Ref No.</th>
//                             <th className="col-lg-1">Type</th>
//                             <th className="col-lg-2">Expected Start Time</th>
//                             <th className="col-lg-2">Actual Start Time</th>                            
//                             <th className="col-lg-2">Bus</th>
//                             <th className="col-lg-2">Driver</th>
//                             <th className="col-lg-2">Action</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {trips.map((item, index) => (
//                             <tr key={index} className="row">
//                                 <td className="col-lg-1">{item.ref_no}</td>
//                                 <td className="col-lg-1">{convertRouteType(item.route.type.name)}</td>
//                                 <td className="col-lg-2">{formatDateTimeRev(item.expected_start_time)}</td>
//                                 <td className="col-lg-2">{formatDateTimeRev(item.actual_start_time)}</td>                                
//                                 <td className="col-lg-2">{item.bus ? item.bus.number : ''}</td>
//                                 <td className="col-lg-2">{item.driver ? item.driver.user.name : ''}</td>                                
//                                 <td className="col-lg-2">
//                                 <a href={`${this.props.baseUrl}/trip/${item.id}/running/map-view`}>
//                                         <i className="fas fa-eye fa-lg text-success mr-3"></i>
//                                     </a>
//                                     <a href={this.props.baseUrl + "/trip/edit/" + item.id}>
//                                         <i className="fas fa-edit fa-lg text-success mr-1"></i>
//                                     </a>
//                                     <Button variant="link" onClick={() => this.handleDelete(item.id)}>
//                                         <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                     </Button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </React.Fragment>
//         )
//     }
// }
// export default ListTab;