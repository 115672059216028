import { LEFT_NAV_VIEW, EXTRAS, SCHOOL } from './action-types'

export const leftNav = (data) => ({
    type: LEFT_NAV_VIEW,
    payload: data
})

export const extras = (data) => ({
    type: EXTRAS,
    payload: data
})

export const school = (data) => ({
    type: SCHOOL,
    payload: data
})
