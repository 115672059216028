import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, useMediaQuery, Popper, Paper, ClickAwayListener, Grid, Typography, Stack,
            Divider, CardActions, Button, List, ListItem, ListItemText, Badge,
            ListItemAvatar } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import ChatIcon from '@mui/icons-material/Chat';

import Transitions from '../../../../../common/extended/transitions';
import MainCard from '../../../../../common/cards/main-card';
import { getTripRefsByIds } from '../../../../../services/http.service';
import { receiveChatMessageNotificationsObservable } from '../../../../../services/observable.service';
import Loader from '../../../../../common/loader';


// Chat Notification popup
const ChatNotification = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [notifications, setNotifications] = useState({
        unReadTotalCount: 0,
        notifications: [],
    });

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [trips, setTrips] = useState([]);

    const schoolId = useSelector(state => state.extras.schoolId);

    console.log("header schoolId - ", schoolId);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
     const anchorRef = useRef(null);

     const handleToggle = () => {
         setOpen((prevOpen) => !prevOpen);
     };
 
     const handleClose = (event) => {
         if (anchorRef.current && anchorRef.current.contains(event.target)) {
             return;
         }
         setOpen(false);
     };
 
     const prevOpen = useRef(open);
     useEffect(() => {
         if (prevOpen.current === true && open === false) {
             anchorRef.current.focus();
         }
         prevOpen.current = open;
         onShowNotificationPopup(open);
     }, [open]);

     // On notification popup open
    const onShowNotificationPopup = (isOpen) => {
        console.log("chat notification open status - ", open);
        // setNotifications({
        //     ...notifications,
        //     notifications: [],
        // });
        if (isOpen) {
            // Load trips reference from server
            const newNotifications = notifications.notifications;
            let tripIds = newNotifications.map(item => item.tripId);
            console.log(`trip Ids after extraction - ${JSON.stringify(tripIds)}`);
            tripIds = Array.from(new Set(tripIds))
            console.log(`trip Ids unique - ${JSON.stringify(tripIds)}`);
            if (!tripIds || !tripIds.length) {
                return;
            }

            setIsLoading(true);
            getTripRefsByIds(tripIds).then(resp => {
                setIsLoading(false);
                setTrips(resp.data.data);
            }).catch(err => {
                console.log(err);
                setIsLoading(false);
            })
        }
    }

    useEffect(() => {
        // Receive Chat Message Notification
        const receiveChatMessageNotificationsObservableRef = receiveChatMessageNotificationsObservable()
            .subscribe(value => {
                console.log(`chat message notification total count in header - ${JSON.stringify(value.totalCount)}`);
                //if (value.totalCount > 0) {  No need to check else it will not update when total count is zero
                setNotifications({
                    ...notifications,
                    unReadTotalCount: value.totalCount,
                    notifications: value.notifications,
                });
            });
        // cleanup - unsubscribe before component unmount
        return () => {
            receiveChatMessageNotificationsObservableRef.unsubscribe();
        }

    }, []);

    return (
        <>
            <Box
                sx={{
                    mx: 1
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Badge badgeContent={notifications.unReadTotalCount} color="error">
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&[aria-controls="menu-list-grow"],&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            color="inherit"
                        >
                            <ChatIcon stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </Badge>
                </ButtonBase>
            </Box>

            {/* Notification popup dropdown */}
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Grid container direction="column" spacing={2} sx={{ pt: 2, px: 2 }}>
                                        <Grid item xs={12}>
                                            {isLoading &&
                                                <Loader />
                                            }
                                            <Stack spacing={2}>
                                                <Typography variant="subtitle1">All chats</Typography>
                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', maxHeight: 400, overflow: 'auto' }}>
                                                    {trips.map((item, index) => (
                                                        <ListItem alignItems="flex-start">
                                                            <ListItemAvatar>
                                                                <Avatar>
                                                                    <GroupIcon />
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={<a href={`/school/${schoolId}/admin/trip/${item.id}/running/map-view`}>#{item.ref_no}</a>}
                                                            >
                                                            </ListItemText>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                        <Button size="small" disableElevation>
                                            Load more
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
}

export default ChatNotification;