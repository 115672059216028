import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Typography, Chip, Grid, TextField,
    Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { getUser, changePasswordOtp, changePassword } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

const validationSchema = Yup.object({  
    oldPassword: Yup.string().required("Old password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be less than 32 chars long"), 
    password: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be less than 32 chars long"),
    confirmPassword: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be less than 32 chars long")
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    otp: Yup.string().required("OTP is required."),
    
});

const SettingProfileChangePassword = () =>  {
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState({});
    // Otp
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState(null);
    // Saving
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    // Get OTP for changing password
    const getOtp = () => {
        setIsOtpSent(false);
        setIsOtpLoading(true);
        setOtpErrorMsg(null);
        changePasswordOtp().then(resp => {
            setIsOtpSent(true);
            setIsOtpLoading(false);
            setOtpErrorMsg(null);
        }).catch(err => {
            console.log(err);
            setIsOtpSent(false);
            setIsOtpLoading(false);
            setOtpErrorMsg(err);
        })
    }

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: '',
            otp: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsLoading(false);
            setIsOtpLoading(false);
            setOtpErrorMsg(null);
            setIsSaving(true);
            setShowSuccessAlert(false);
            setErrorMsg(null);

            const data = {
                oldPassword: values.oldPassword,
                password: values.password,
                otp: values.otp,
            }
            changePassword(data).then(resp => {
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
            }).catch(err => {
                setIsSaving(false);
                setShowSuccessAlert(false);
                setErrorMsg(err);
            })
        }
    });

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"School saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
             <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                    width: '100%',
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Change password
                    </Typography>
                </Box>
                <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 5, width: "100%" }}>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={10} sm={5}>
                            <TextField
                                required
                                fullWidth
                                name="oldPassword"
                                label="Old Password"
                                type="password"
                                id="oldPassword"
                                autoComplete="new-password"
                                value={formik.values.oldPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={10} sm={5}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={10} sm={5}>
                            <TextField
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                autoComplete="new-password"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={10} sm={3}>
                            <TextField
                                required
                                fullWidth
                                name="otp"
                                label="Otp"
                                type="number"
                                id="otp"
                                autoComplete="otp"
                                value={formik.values.otp}
                                onChange={formik.handleChange}
                                error={formik.touched.otp && Boolean(formik.errors.otp)}
                                helperText={formik.touched.otp && formik.errors.otp}
                            />
                        </Grid>
                        <Grid item xs={10} sm={5} sx={{ display: 'flex', alignItems: 'center' }}>
                            <LoadingButton
                                variant="outlined"
                                onClick={getOtp}
                                loading={isOtpLoading}
                            >
                                Get OTP
                            </LoadingButton>
                            {isOtpSent &&
                                 <Typography component="h6" sx={{ ml: 1 }}>
                                    Otp sent on your registered mobile number
                                 </Typography>
                            }
                        </Grid>
                    </Grid>
                    {/* Show only when otp has been sent */}
                    {isOtpSent &&
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 4, mb: 2 }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Box>

        </>
    )

}

export default SettingProfileChangePassword;

