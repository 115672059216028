import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import CustomPagination from '../../../../utils/pagination';

import { fetchSchoolUsers, fetchSchool as fetchSchoolHttp } from '../../../../services/http.service';
import SearchBox from '../../../../common/search-box';
import { Role } from '../../../../utils/constants';

const AddStudent = () => {
    const [schoolUsers, setSchoolUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    let [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    let studentSearchKey = null;

    const navigate = useNavigate();

    useEffect(() => {
        fetchStudentUsers();
    }, []);

    // Fetch school users
    const fetchStudentUsers = () => {
        setSchoolUsers([]);        
        setIsLoading(true);
        const filter = {
            'searchKey': studentSearchKey
        }
        fetchSchoolUsers(Role.STUDENT, filter, page, limit).then(resp => {
            const respData = resp.data.data;
            setSchoolUsers(respData.school_users);
            setTotalCount(respData.total_count);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        });
    }

    // on search stduents
    const onStudentSearch = (val) => {
        console.log(`on student search ${val}`);
        studentSearchKey = val;
        page = 0;
        setPage(0);
        fetchStudentUsers();
    }

    // ****************************** Pagination ******************************
    const onFirst = () => {
        console.log(`onFirst`);
        page = 0;
        setPage(page);
        fetchStudentUsers();  
    }

    const onPrevious = () => {
        console.log(`onPrevious`);
        if (page > 0) {
            page -= 1;
            setPage(page);
            fetchStudentUsers();
        }
    }

    const onNext = () => {
        console.log(`onNext`);
        console.log(`cal count - ${(page * limit)}`)
        // check if records on next page is less than the total record count i.e (page + 1)
        if (((page + 1) * limit) < totalCount) {
            page += 1;
            setPage(page);
            fetchStudentUsers();
        }
    }

    const onLast = () => {
        console.log(`onLast`);
        // Calculate last page number
        // const { page, totalCount, limit } = this.state;
        const newPage = Math.floor(totalCount/limit);
        console.log(`new page - ${newPage}`);  
        page = newPage;
        setPage(page);   
        fetchStudentUsers();  
    }

    // ************************** End Pagination ******************************

    return (
        <div className="p-3">
            <div className="d-flex align-items-center justify-content-between">
                <p className="text">Search student to add</p>
                <SearchBox onChangeText={onStudentSearch} />
            </div>
            <div>
                {isLoading &&                   
                    <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
                }
                {schoolUsers.length === 0 &&                        
                    <h4 className="text-muted text-center mb-3">Oops..No students found!!</h4>                           
                }
                {schoolUsers.length > 0 &&
                    <div>
                        <table className="table" ble striped bordered hover>
                            <thead className="container">
                                <tr className="row">
                                    <th className="col-lg-11">Name</th>
                                    <th className="col-lg-1"></th>
                                </tr>
                            </thead>
                            <tbody className="container">
                                {schoolUsers.map((item, index) => (
                                    <tr key={index} className="row">
                                        <td className="col-lg-11">{item.user.name}</td>
                                        <td className="col-lg-1 d-flex justify-content-between">
                                            <button className="btn btn-primary btn-sm"
                                                onClick={() => {
                                                    navigate("../edit/" + item.user.id)
                                                }}
                                            >Add</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* Custom Pagination rendering */}
                        <div className="mt-3">
                            <CustomPagination 
                                currentPage={page+1}
                                totalCount={totalCount}
                                limit={limit}
                                onFirst={onFirst}
                                onPrevious={onPrevious}
                                onNext={onNext}
                                onLast={onLast}
                            />
                        </div>
                    </div>
                } 
            </div>

        </div>
    );
}

export default AddStudent;