import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, FormHelperText, FormLabel } from '@mui/material';

const TimeSelector = ({ name, label, values, setFieldValue, setNameFieldValue, setFieldTouched, validate }) => {
  const { hours, minutes, period } = values || {};
  const isError = validate && !hours || !minutes || !period;

  const handleChange = (field) => (event) => {
    if (setNameFieldValue) {
        setNameFieldValue(`${name}.${field}`, event.target.value);
    }
    if (setFieldValue) {
        setFieldValue(`${field}`, event.target.value);
    }
  };

  const handleBlur = () => {
    if (setFieldTouched) {
        setFieldTouched(name, true);
    }
  };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {label &&
                <FormLabel component="legend">{label}</FormLabel>
            }
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <FormControl variant="outlined" error={isError} sx={{ flex: 1 }}>
                    <InputLabel>Hours</InputLabel>
                    <Select
                        value={hours || ''}
                        onChange={handleChange('hours')}
                        onBlur={handleBlur}
                        label="Hours"
                    >
                        {Array.from({ length: 12 }, (_, i) => (
                            <MenuItem key={i + 1} value={String(i + 1).padStart(2, '0')}>
                                {String(i + 1).padStart(2, '0')}
                            </MenuItem>
                        ))}
                    </Select>
                    {isError && !hours && <FormHelperText>Hours are required</FormHelperText>}
                </FormControl>
                <FormControl variant="outlined" error={isError} sx={{ flex: 1 }}>
                    <InputLabel>Minutes</InputLabel>
                    <Select
                        value={minutes || ''}
                        onChange={handleChange('minutes')}
                        onBlur={handleBlur}
                        label="Minutes"
                    >
                        {Array.from({ length: 60 }, (_, i) => (
                            <MenuItem key={i} value={String(i).padStart(2, '0')}>
                                {String(i).padStart(2, '0')}
                            </MenuItem>
                        ))}
                    </Select>
                    {isError && !minutes && <FormHelperText>Minutes are required</FormHelperText>}
                </FormControl>
                <FormControl variant="outlined" error={isError} sx={{ flex: 1 }}>
                    <InputLabel>AM/PM</InputLabel>
                    <Select
                        value={period || ''}
                        onChange={handleChange('period')}
                        onBlur={handleBlur}
                        label="AM/PM"
                    >
                        <MenuItem value="AM">AM</MenuItem>
                        <MenuItem value="PM">PM</MenuItem>
                    </Select>
                    {isError && !period && <FormHelperText>AM/PM is required</FormHelperText>}
                </FormControl>
            </Box>
        </Box>
    );
};

export default TimeSelector;
