import React from 'react';
import { Box } from '@mui/material';

import AppLayout from './index';
import { Outlet } from "react-router-dom";

const UnAuthLayout = () => {
    return (
        <Box
            sx={{
                backgroundColor: 'primary.main',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
            }}
        >
            <Outlet />
        </Box>
    );
}

export default UnAuthLayout;