import React, { Component, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import GoogleMapReact from 'google-map-react';

import { URL, GOOGLE_MAP_API_KEY } from '../../utils/constants';
import { reverseGeocode } from '../../services/http.service';


const GoogleMap = (props, ref) => {
    
    const [initialCenter, setInitialCenter] = useState({ lat: 28.4650447, lng: 77.5084151 });
    const [center, setCenter] = useState({ lat: 28.4650447, lng: 77.5084151 });
    const [zoom, setZoom] = useState(14);
    const [markers, setMarkers] = useState([]);
    const [infoWindow, setInfoWindow] = useState(null);
    const [polyline, setPolyline] = useState(null);
    const [currentLocationMarker, setCurrentLocationMarker] = useState(null);


    const mapRef = useRef();
    const mapsRef = useRef();
    const infoWindowRef = useRef();
    const currentLocationMarkerRef = useRef();

    const onMapReady = (map, maps) => {
        console.log('on map ready')
        props.onMapReady(map, maps);
        mapRef.current = map;
        mapsRef.current = maps;
        // send back to parent
        if (props.onMap) {
            props.onMap(map);
        }
        if (props.onMaps) {
            props.onMaps(maps);
        }
        const { students } = props;
        if (students) {
            drawOnMap(students);        
        }
    };

    // function calls from parent ---------------
    useImperativeHandle(ref, () => ({
        drawOnMap(students) {
            drawOnMap(students);
        },
        showStudentOnMap(student) {
            showStudentOnMap(student);
        },
        updateDriverCurrentLocation(currentLocation) {
            updateDriverCurrentLocation(currentLocation);
        },
        showLocationOnMap(location) {
            showLocationOnMap(location);
        }
    }), []);
    // End ---------------------------------------

    // Update current location of driver on map
    const updateDriverCurrentLocation = (currentLocation) => {
        // check if Google map is laoaded
        if (!mapsRef.current) {
            setTimeout(updateDriverCurrentLocation, 100, currentLocation);
            return;
        }
        console.log(`Updating current location on Google map - ${JSON.stringify(currentLocation)}`);
        // Rmove current marker if exists        
        if (currentLocationMarkerRef.current) {
            currentLocationMarkerRef.current.setMap(null);
            currentLocationMarkerRef.current = null;
        }
        // Add latest
        let latLng = {
            lat: parseFloat(currentLocation.latitude),
            lng: parseFloat(currentLocation.longitude)
        };
        console.log(`updating current location ${JSON.stringify(latLng)}`);
        
        let marker = new mapsRef.current.Marker({
            draggable: false,
            position: latLng,
            map: mapRef.current,
            //icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png"
            icon: `${URL.BASE_URL}/icons/icon_bus.png`
            //icon: "http://13.234.3.238:5000/icons/icon_bus.png"
        });
        currentLocationMarkerRef.current = marker;
    }

    // Draw on Map -  students should pe passed as parent as it would also pass from parent component
    
    const drawOnMap = (students) => {
        console.log(`Draw map - ${JSON.stringify(students)}`);
        const { school } = props;
        // let { map, polyline, markers } = this.state;
        let latlngs = [];
        let bounds = new mapsRef.current.LatLngBounds();

        // Return when school donot have location
        if (!(school.latitude && school.latitude)) {
            return
        }

        // School latlong
        let schoolLatLng = {
            lat: school.latitude,
            lng: school.longitude
        }

         // Add schoo marker
         const marker = new mapsRef.current.Marker({
            draggable: true,
            position: schoolLatLng,
            map: mapRef.current,
            title: school
        });
        marker.addListener('click', () => {
            showSchoolOnMap(school, marker);
        });

        latlngs.push(schoolLatLng);
        bounds.extend(schoolLatLng);

        // Remove all markers    
        markers.forEach(marker => {
            marker.setMap(null);
        })
        //markers = [];
        setMarkers([]);
        console.log(`students to draw map - ${JSON.stringify(students)}`);
        // Iterate over each route student and draw path on map
        for (let i = 0; i < students.length; i++) {
            const student = students[i];
            // Coninue to next student if student has not location
            if (!(student.latitude && student.longitude)) {
                continue;
            }
            let latLng = {
                lat: student.latitude,
                lng: student.longitude
            };
            let marker = new mapsRef.current.Marker({
                draggable: false,
                position: latLng,
                map: mapRef.current,
                icon: "http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_purple.png",
                title: student.name
            });
            marker.addListener('click', () => {
                showStudentOnMap(student, marker);
            });
            // Store all markers
            markers.push(marker);           

            bounds.extend(latLng);
            latlngs.push(latLng);
        };
        // Fit/Zoom all the markers
        mapRef.current.fitBounds(bounds);

        setMarkers(markers);

         // Draw routes on Map
         var origin = latlngs[0];
         var dest = latlngs[latlngs.length - 1];
 
         var wayPoints = [];
         if (latlngs.length > 1) {
             latlngs.forEach(latlng => {
                 wayPoints.push({
                     location: latlng.lat + ', ' + latlng.lng,
                     stopover: true
                 });
             });
         }
         console.log("Way points :: " + JSON.stringify(wayPoints));
         var request = {
             origin: origin,
             destination: dest,
             travelMode: 'DRIVING',
             waypoints: wayPoints
         };
 
         // Remove previous polyline
         if (polyline != null) {
             polyline.setMap(null);
             setPolyline(null);
         }
         var directionsService = new mapsRef.current.DirectionsService();
         directionsService.route(request, (result, status) => {
             if (status === 'OK') {
                 var path = [];
                 result.routes[0].overview_path.forEach(point => {
                     path.push({ lat: point.lat(), lng: point.lng() });
                 });
                 // Draw Polyline of route	        
                 const tripPolyline = new mapsRef.current.Polyline({
                     path: path,
                     geodesic: true,
                     strokeColor: '#3365b7',
                     strokeOpacity: 1.0,
                     strokeWeight: 3
                 });
                 tripPolyline.setMap(mapRef.current);
                 setPolyline(tripPolyline);
             }
         });
    }
   

    // Draw bus travelled map
    // drawBusTravelledMap = (locations) => {
        
    // }

    // Show InfoWindow of student on Map
    const showStudentOnMap = (student) => {
        console.log("View student location : " + JSON.stringify(student));
        // const { google } = this.props;
        // const { map } = this.state;
        // let { infoWindow } = this.state;

        // Remove previous infowindow
        if (infoWindow != null) {
            infoWindow.close();
            setInfoWindow(infoWindow);
        }
        const studentInfoWindow = new mapsRef.current.InfoWindow({
            content: `<h6>${student.name}</h6><small className="text-muted">${student.location}</small>`
        });
        studentInfoWindow.setPosition({ lat: student.latitude, lng: student.longitude });
        studentInfoWindow.open(mapRef.current);
        setInfoWindow(studentInfoWindow);
    };

    // Show InfoWindow of location on Map
    const showLocationOnMap = (location) => {
        console.log("View location : " + JSON.stringify(location));

        // Prepare data
        const data = {
            latitude: location.latitude,
            longitude: location.longitude,
            content: location.location
        }

        // Get location address from google API if its not given
        if (location.location) {
            data.content = `<h6>Address</h6><small className="text-muted">${location.location}</small>`
            showInfoWindow(data);

            // --- Hitting google api will cost extra - show the location from param
            // data.content = '<div class="d-flex justify-content-center"><div class="spinner-border spinner-border-sm" role="status"><span class="sr-only"></span></div></div>';            
            // showInfoWindow(data);
            // getGeoAddress(location).then(resp => {
            //     data.content = `<h6>Address</h6><small className="text-muted">${resp}</small>`
            //     showInfoWindow(data);
            // }).catch(err => {
            //     console.log(`Error in geocoding - ${JSON.stringify(err)}`);
            // });
        }
        else {
            data.content = "Loading..";
            showInfoWindow(data);
            getGeoAddress(location).then(resp => {
                data.content = `<h6>Address</h6><small className="text-muted">${resp}</small>`
                showInfoWindow(data);
            }).catch(err => {
                console.log(`Error in geocoding - ${JSON.stringify(err)}`);
            });
        } 

    };

    // Show Infomap
    const showInfoWindow = (data) => {
        // const { google } = this.props;
        // const { map } = this.state;
        // let { infoWindow } = this.state;

        // Remove previous infowindow
        if (infoWindowRef.current != null) {
            infoWindowRef.current.close();
            infoWindowRef.current = null;
        }

        // Add new Infowindow
        const newInfoWindow = new mapsRef.current.InfoWindow({
            content: data.content
        });
        newInfoWindow.setPosition({ lat: data.latitude, lng: data.longitude });
        newInfoWindow.open(mapRef.current);
        infoWindowRef.current = newInfoWindow;
    }

    // Show InfoWindow of school on Map
    const showSchoolOnMap = (school, marker) => {
        console.log("View school location : " + JSON.stringify(school));
        // const { google } = this.props;
        // const { map } = this.state;
        // let { infoWindow } = this.state;

        // Remove previous infowindow
        if (infoWindow != null) {
            infoWindow.close();
            setInfoWindow(null);
        }
        infoWindow = new mapsRef.current.InfoWindow({
            content: `<h6>${school.name}</h6><small className="text-muted">${school.address}</small>`
        });
        infoWindow.setPosition({ lat: school.latitude, lng: school.longitude });
        infoWindow.open(mapRef.current, marker);
        setInfoWindow(infoWindow);
    };

    // Get Address from Geocode
    const getGeoAddress = (currentLocation) => {
        if (!(currentLocation && currentLocation.latitude && currentLocation.longitude)) {
            return;
        }
        return new Promise((resolve, reject) => {
            reverseGeocode(currentLocation.latitude, currentLocation.longitude).then(resp => {
                if (resp.data.results && resp.data.results.length > 0) {
                    const address = resp.data.results[0].formatted_address;
                    console.log(`Geocode resp - ${JSON.stringify(address)}`);
                    return resolve(address);
                }
                return reject('Address Not found');
            }).catch(err => {
                console.log(err);
                return reject(err);
            });
        });
    }

    
    return (
        <div className="route-map-container">
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'], }}
                defaultCenter={center}
                defaultZoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => onMapReady(map, maps)}
            ></GoogleMapReact>


            {/* <Map google={this.props.google}
                initialCenter={this.state.initialCenter}
                center={this.state.center}
                zoom={this.state.zoom}
                onReady={this.onMapReady}>
            </Map> */}
        </div>
    );
    
}

export default forwardRef(GoogleMap); // 'forwardRef' enables call function from parent



// class GoogleMap extends Component {
//     constructor(props) {
// 		super(props)
// 		this.state = {
//             initialCenter: { lat: 40.854885, lng: -88.081807 },
//             center: { lat: 40.854885, lng: -88.081807 },
//             zoom: 14,
//             map: null,
//             markers: [],
//             infoWindow: null,
//             polyline: null,
//             currentLocationMarker: null
//         }
//     }

//     onMapReady = (mapProps, map) => {
//         console.log('on map ready')
//         this.setState({ map });
//         const { students } = this.props;
//         if (students) {
//             this.drawOnMap(students);        
//         }
//     };

//     // Update current location of driver on map
//     updateDriverCurrentLocation = (currentLocation) => {
//         console.log(`Updating current location on Google map - ${JSON.stringify(currentLocation)}`);
//         const { google } = this.props;
//         const { map } = this.state;
//         // Rmove current marker if exists        
//         if (this.state.currentLocationMarker) {
//             this.state.currentLocationMarker.setMap(null);
//             this.setState({ currentLocationMarker: null })
//         }
//         // Add latest
//         let latLng = {
//             lat: parseFloat(currentLocation.latitude),
//             lng: parseFloat(currentLocation.longitude)
//         };
//         console.log(`updating current location ${JSON.stringify(latLng)}`);
        
//         let marker = new google.maps.Marker({
//             draggable: false,
//             position: latLng,
//             map: map,
//             //icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png"
//             icon: `${URL.BASE_URL}/icons/icon_bus.png`
//             //icon: "http://13.234.3.238:5000/icons/icon_bus.png"
//         });
//         this.setState({ currentLocationMarker: marker });
//     }

//     // Draw on Map -  students should pe passed as parent as it would also pass from parent component
//     drawOnMap = (students) => {
//         console.log(`Draw map - ${JSON.stringify(students)}`);
//         const { google, school } = this.props;
//         let { map, polyline, markers } = this.state;
//         let latlngs = [];
//         let bounds = new google.maps.LatLngBounds();

//         // School latlong
//         let schoolLatLng = {
//             lat: school.latitude,
//             lng: school.longitude
//         }

//          // Add schoo marker
//          const marker = new google.maps.Marker({
//             draggable: true,
//             position: schoolLatLng,
//             map: map,
//             title: school
//         });
//         marker.addListener('click', () => {
//             this.showSchoolOnMap(school, marker);
//         });

//         latlngs.push(schoolLatLng);
//         bounds.extend(schoolLatLng);

//         // Remove all markers    
//         markers.forEach(marker => {
//             marker.setMap(null);
//         })
//         markers = [];
//         console.log(`students to draw map - ${JSON.stringify(students)}`);
//         // Iterate over each route student and draw path on map
//         students.forEach(student => {
//             let latLng = {
//                 lat: student.latitude,
//                 lng: student.longitude
//             };
//             let marker = new google.maps.Marker({
//                 draggable: false,
//                 position: latLng,
//                 map: map,
//                 icon: "http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_purple.png",
//                 title: student.name
//             });
//             marker.addListener('click', () => {
//                 this.showStudentOnMap(student, marker);
//             });
//             // Store all markers
//             markers.push(marker);           

//             bounds.extend(latLng);
//             latlngs.push(latLng);
//         });
//         // Fit/Zoom all the markers
//         map.fitBounds(bounds);

//         this.setState({ markers });

//          // Draw routes on Map
//          var origin = latlngs[0];
//          var dest = latlngs[latlngs.length - 1];
 
//          var wayPoints = [];
//          if (latlngs.length > 1) {
//              latlngs.forEach(latlng => {
//                  wayPoints.push({
//                      location: latlng.lat + ', ' + latlng.lng,
//                      stopover: true
//                  });
//              });
//          }
//          console.log("Way points :: " + JSON.stringify(wayPoints));
//          var request = {
//              origin: origin,
//              destination: dest,
//              travelMode: 'DRIVING',
//              waypoints: wayPoints
//          };
 
//          // Remove previous polyline
//          if (polyline != null) {
//              polyline.setMap(null);
//              polyline = null;
//          }
//          var directionsService = new google.maps.DirectionsService();
//          directionsService.route(request, (result, status) => {
//              if (status === 'OK') {
//                  var path = [];
//                  result.routes[0].overview_path.forEach(point => {
//                      path.push({ lat: point.lat(), lng: point.lng() });
//                  });
//                  // Draw Polyline of route	        
//                  polyline = new google.maps.Polyline({
//                      path: path,
//                      geodesic: true,
//                      strokeColor: '#3365b7',
//                      strokeOpacity: 1.0,
//                      strokeWeight: 3
//                  });
//                  polyline.setMap(map);
//                  this.setState({ polyline });
//              }
//          });
//     }

//     // Draw bus travelled map
//     drawBusTravelledMap = (locations) => {
        
//     }

//     // Show InfoWindow of student on Map
//     showStudentOnMap = (student) => {
//         console.log("View student location : " + JSON.stringify(student));
//         const { google } = this.props;
//         const { map } = this.state;
//         let { infoWindow } = this.state;

//         // Remove previous infowindow
//         if (infoWindow != null) {
//             infoWindow.close();
//             this.setState({ infoWindow: null });
//         }
//         infoWindow = new google.maps.InfoWindow({
//             content: `<h6>${student.name}</h6><small className="text-muted">${student.location}</small>`
//         });
//         infoWindow.setPosition({ lat: student.latitude, lng: student.longitude });
//         infoWindow.open(map);
//         this.setState({ infoWindow });
//     };

//     // Show InfoWindow of location on Map
//     showLocationOnMap = (location) => {
//         console.log("View location : " + JSON.stringify(location));

//         // Prepare data
//         const data = {
//             latitude: location.latitude,
//             longitude: location.longitude,
//             content: ''
//         }

//         // Get location address from google API if its not given
//         if (!location.location) {
//             data.content = '<div class="d-flex justify-content-center"><div class="spinner-border spinner-border-sm" role="status"><span class="sr-only"></span></div></div>';            
//             this.showInfoWindow(data);
//             this.getGeoAddress(location).then(resp => {
//                 data.content = `<h6>Address</h6><small className="text-muted">${resp}</small>`
//                 this.showInfoWindow(data);
//             }).catch(err => {
//                 console.log(`Error in geocoding - ${JSON.stringify(err)}`);
//             });
//         }
//         else {

//         } 

//     };

//     // Show Infomap
//     showInfoWindow = (data) => {
//         const { google } = this.props;
//         const { map } = this.state;
//         let { infoWindow } = this.state;

//         // Remove previous infowindow
//         if (infoWindow != null) {
//             infoWindow.close();
//             this.setState({ infoWindow: null });
//         }

//         // Add new Infowindow
//         infoWindow = new google.maps.InfoWindow({
//             content: data.content
//         });
//         infoWindow.setPosition({ lat: data.latitude, lng: data.longitude });
//         infoWindow.open(map);
//         this.setState({ infoWindow });
//     }

//     // Show InfoWindow of school on Map
//     showSchoolOnMap = (school, marker) => {
//         console.log("View school location : " + JSON.stringify(school));
//         const { google } = this.props;
//         const { map } = this.state;
//         let { infoWindow } = this.state;

//         // Remove previous infowindow
//         if (infoWindow != null) {
//             infoWindow.close();
//             this.setState({ infoWindow: null });
//         }
//         infoWindow = new google.maps.InfoWindow({
//             content: `<h6>${school.name}</h6><small className="text-muted">${school.address}</small>`
//         });
//         infoWindow.setPosition({ lat: school.latitude, lng: school.longitude });
//         infoWindow.open(map, marker);
//         this.setState({ infoWindow });
//     };

//     // Get Address from Geocode
//     getGeoAddress = (currentLocation) => {
//         if (!(currentLocation && currentLocation.latitude && currentLocation.longitude)) {
//             return;
//         }
//         return new Promise((resolve, reject) => {
//             reverseGeocode(currentLocation.latitude, currentLocation.longitude).then(resp => {
//                 if (resp.data.results && resp.data.results.length > 0) {
//                     const address = resp.data.results[0].formatted_address;
//                     console.log(`Geocode resp - ${JSON.stringify(address)}`);
//                     return resolve(address);
//                 }
//                 return reject('Address Not found');
//             }).catch(err => {
//                 console.log(err);
//                 return reject(err);
//             });
//         });
//     }

//     render() {
//         return (
//             <div className="route-map-container">
//                 <Map google={this.props.google}
//                     initialCenter={this.state.initialCenter}
//                     center={this.state.center}
//                     zoom={this.state.zoom}
//                     onReady={this.onMapReady}>
//                 </Map>
//             </div>
//         )
//     }
// }

// export default GoogleMap