import React, { Component, useState, useEffect } from 'react';
import { Container, Button, Box, Avatar, Typography, TextField, Grid, Link, Paper, CircularProgress } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from 'react-router-dom';
import { ErrorAlertTopCenter } from '../../common/snackbars';

import { forgotPassword } from '../../services/http.service';
import UnAuthLayout from '../../layouts/unauth';


const ForgotPassword = () => {	

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const navigate = useNavigate();


    /**
     * Handle submit
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        const mobile = data.get('mobile');

        setIsSubmitting(true);
        setShowSuccessAlert(false);
        setErrorMsg(null);
        forgotPassword(mobile).then(resp => {
            console.log(`Response: ${JSON.stringify(resp.data)}`);
            setIsSubmitting(false);
            if (resp.data.data) {
                navigate('/forgot-password/otp-verification/' + resp.data.data.user_id);
            }
        }).catch(err => {
            setIsSubmitting(false);
            setShowSuccessAlert(false);
            setErrorMsg(err);
        });
    }

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
             <Container component="main" maxWidth="xs">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockResetIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Forgot password
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, px: 2, width: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="mobile"
                                label="Mobile/Email"
                                name="mobile"
                                autoComplete="mobile"
                                autoFocus
                            />

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSubmitting &&
                                    <CircularProgress />
                                }
                                {!isSubmitting &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Submit
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>            
        </>
    );

        
    // return (

    //     <div id="layoutAuthentication" class="bg-primary">
    //         <div id="layoutAuthentication_content">
    //             <main>
    //                 <div class="container">
    //                     <div class="row justify-content-center">
    //                         <div class="col-lg-5">
    //                             <div class="card shadow-lg border-0 rounded-lg mt-5">
    //                                 <div class="card-header"><h3 class="text-center font-weight-light my-4">Forgot Password</h3></div>
    //                                 <div class="card-body">
    //                                     <Form onSubmit={handleSubmit}>
    //                                         <Form.Group>
    //                                             <Form.Label class="small mb-1">Enter Mobile/Email</Form.Label>
    //                                             <Form.Control
    //                                                 type="text"
    //                                                 placeholder="Enter mobile or email"
    //                                                 name="mobile" />
    //                                         </Form.Group>
    //                                         <div className="d-flex flex-column align-items-center justify-content-center">
    //                                             <Button className="btn btn-primary btn-user btn-block btn-user" type="submit">Submit</Button>                            
    //                                             {isSubmitting &&                         
    //                                                 <Spinner className="mt-3" animation="border" variant="primary" />
    //                                             }
    //                                             {showSuccessAlert &&
    //                                                 <Alert className="ml-3 mb-0 mt-3" variant="success" onClose={() => setShowSuccessAlert(false) } 
    //                                                     dismissible>
    //                                                     Registered Successfully
    //                                                 </Alert>
    //                                             }
    //                                             {errorMsg &&
    //                                                 <Alert className="ml-3 mb-0 mt-3" variant="danger" onClose={() => setShowSuccessAlert(false) } 
    //                                                     dismissible>
    //                                                     {errorMsg}
    //                                                 </Alert>
    //                                             }
    //                                         </div>                                               
    //                                     </Form>
    //                                 </div>
    //                                 <div class="card-footer text-center">
    //                                     <div class="small d-flex align-items-center justify-content-center">
    //                                         <a href="/login">Login</a>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </main>
    //         </div>
    //     </div>  
    // );
}

export default ForgotPassword;


// class ForgotPassword extends Component {	

// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             isSubmitting: false,
//             showSuccessAlert: false,
//             errorMsg: null,
// 		};
//     }

//     /**
//      * Handle submit
//      */
//     handleSubmit = (e) => {
//         e.preventDefault();
//         e.stopPropagation();

//         const form = e.currentTarget;
//         const mobile = form[0].value

//         this.setState({ isSubmitting: true });
//         this.setState({ showSuccessAlert: false });
//         this.setState({ errorMsg: null });
//         forgotPassword(mobile).then(resp => {
//             console.log(`Response: ${JSON.stringify(resp.data)}`);
//             this.setState({ isSubmitting: false });
//             if (resp.data.data) {
//                 this.props.history.push('/forgot-password/otp-verification/' + resp.data.data.user_id);
//             }
//         }).catch(err => {
//             this.setState({ isSubmitting: false });
//             this.setState({ showSuccessAlert: false });
//             this.setState({ errorMsg: err });
//         });
//     }

//     render() {

//         const { isSubmitting, showSuccessAlert, errorMsg } = this.state;
        
//         return (

//             <div id="layoutAuthentication" class="bg-primary">
//                 <div id="layoutAuthentication_content">
//                     <main>
//                         <div class="container">
//                             <div class="row justify-content-center">
//                                 <div class="col-lg-5">
//                                     <div class="card shadow-lg border-0 rounded-lg mt-5">
//                                         <div class="card-header"><h3 class="text-center font-weight-light my-4">Forgot Password</h3></div>
//                                         <div class="card-body">
//                                             <Form onSubmit={(e) => this.handleSubmit(e)}>
//                                                 <Form.Group>
//                                                     <Form.Label class="small mb-1">Enter Mobile/Email</Form.Label>
//                                                     <Form.Control
//                                                         type="text"
//                                                         placeholder="Enter mobile or email"
//                                                         name="mobile" />
//                                                 </Form.Group>
//                                                 <div className="d-flex flex-column align-items-center justify-content-center">
//                                                     <Button className="btn btn-primary btn-user btn-block btn-user" type="submit">Submit</Button>                            
//                                                     {isSubmitting &&                         
//                                                         <Spinner className="mt-3" animation="border" variant="primary" />
//                                                     }
//                                                     {showSuccessAlert &&
//                                                         <Alert className="ml-3 mb-0 mt-3" variant="success" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                                             dismissible>
//                                                             Registered Successfully
//                                                         </Alert>
//                                                     }
//                                                     {errorMsg &&
//                                                         <Alert className="ml-3 mb-0 mt-3" variant="danger" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                                             dismissible>
//                                                             {this.state.errorMsg}
//                                                         </Alert>
//                                                     }
//                                                 </div>                                               
//                                             </Form>
//                                         </div>
//                                         <div class="card-footer text-center">
//                                             <div class="small d-flex align-items-center justify-content-center">
//                                                 <a href="/login">Login</a>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </main>
//                 </div>
//             </div>  
//         )
//     }
// }

// export default ForgotPassword;
