import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';

import RoutableMap from './routable-map';
import schoolBusIcon from '../../assets/images/school_bus_map_icon.png';

const LiveLocationMap = forwardRef(({ 
    locations,
    currentLocation,    
    originIcon,
    otherIcon,
}, ref) => {
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [currentLocationMarker, setCurrentLocationMarker] = useState(null);

    const routableMapRef = useRef(null);

    const handleMapReady = (map, maps) => {
        setMap(map);
        setMaps(maps);
    }


    // ************ External Calls ******************
    useImperativeHandle(ref, () => ({
        showInfoWindow
    }));

    const showInfoWindow = (location) => {
        routableMapRef.current.showInfoWindow(location);
    }

    // ************ End // External Calls ******************

    useEffect(() => {
        console.log("current location - ", currentLocation);
        if (currentLocationMarker) {
            currentLocationMarker.setMap(null);
            setCurrentLocationMarker(null);
        }
        // Add latest
        let latLng = {
            lat: parseFloat(currentLocation.latitude),
            lng: parseFloat(currentLocation.longitude)
        };
        console.log(`updating current location ${JSON.stringify(latLng)}`);
        if (maps) {
            let marker = new maps.Marker({
                draggable: false,
                position: latLng,
                map: map,
                icon: {
                    url: schoolBusIcon,
                    scaledSize: new maps.Size(30, 30), // Customize size here
                },
            });
            setCurrentLocationMarker(marker);
        }

    }, [currentLocation, maps, map]);
    
    return (
        <RoutableMap
            locations={locations}
            otherIcon={otherIcon}
            originIcon={originIcon}
            ref={routableMapRef}
            onMapReady={handleMapReady}
        />
    );
});

export default LiveLocationMap;