import React from 'react';
import { Outlet } from "react-router-dom";

const SettingProfile = () =>  {

    return (
        <>
            <Outlet />
        </>
    );

}

export default SettingProfile;