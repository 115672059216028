import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Col, Form, Button, Spinner, ButtonToolbar ,Alert, Row } from 'react-bootstrap';

import { saveOrUpdateSchoolUser,
    fetchSchoolUser as fetchSchoolUserHttp, getStudentByUserId } from '../../../../services/http.service';

const UserStudentNew = () => {
    const [validated, setValidated] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({});
    const [studentUniqueNumber, setStudentUniqueNumber] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [student, setStudent] = useState({});

    const roleName = 'STUDENT';

    const { userId } = useParams();
    const schoolId = useSelector(state => state.extras.schoolId);

    useEffect(() => {
        if (userId) {
            setIsUpdate(true);
            fetchStudentSchoolUser(userId);
        } 
    }, []);

    // Fetch student school user from server
    const fetchStudentSchoolUser = (userId) => {
        setIsLoading(true);
        getStudentByUserId(userId).then(resp => {
            setIsLoading(false);
            const student = resp.data.data;
            if (student) {
                setStudent(student);
                const user = {
                    id: student.user.id,
                    name: student.user.name,
                    isVerified: student.user.is_verified,
                }
                setUser(user);
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Fetch school user from server
    // const fetchSchoolUser = (roleName, userId) => {
    //     setIsLoading(true);
    //     fetchSchoolUserHttp(roleName, userId).then(resp => {
    //         setIsLoading(false);
    //         const schoolUser = resp.data.data;
    //         const user = {
    //             id: schoolUser.user.id,
    //             name: schoolUser.user.name,
    //             email: schoolUser.user.email,
    //             mobile: schoolUser.user.mobile,
    //             isVerified: schoolUser.user.is_verified,
    //             password: '******'  // Default password
    //         }
    //         setUser(user);
    //     }).catch(err => {
    //         console.log(err);
    //         setIsLoading(false);
    //     })
    // }


    // Handle Submit
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return false;
        }   
        setIsSaving(true); 
        user.name = form[0].value;
        console.log(`user - ${JSON.stringify(user)}`);
        user.roleName = roleName;
        user.schoolId = schoolId;
        setShowSuccessAlert(false);
        saveOrUpdateSchoolUser(user, isUpdate).then(resp => {
            setIsSaving(false); 
            setShowSuccessAlert(true);
            setErrorMsg(null);
            const respData = resp.data.data;
            setStudent({...student, id: respData.student_id});
            // when student is new then set unique number
            if (!isUpdate) {
                setStudentUniqueNumber(respData.student_unique_number);
            }
            // Set back user id - to make available profile photo link and other
            if (!user.id) { // If its not Edit user
                setUser({
                    ...user,
                    id: respData.user_id,
                });
            }
        }).catch(err => {
            console.log(err);
            setIsSaving(false); 
            setShowSuccessAlert(false);
            setErrorMsg(err);
        })
    }

    return (
        <React.Fragment>
             <div className="d-flex align-items-center justify-content-between mb-4">
                <div className="h5 mb-0 text-gray-800">{isUpdate ? 'Update student' : 'New student'}</div>
                <Link type="button" class="btn btn-outline-info btn-sm ml-3" to="../list">Show students</Link>
            </div> 
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit} className="mt-5"> 

                <Form.Group as={Row}>
                    <Form.Label column sm="2">Full Name</Form.Label>
                    <Col lg="6">
                        <Form.Control required type="text" placeholder="Enter full name"
                            value={user.name}
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    name: e.target.value,
                                });
                                // user.name = e.target.value;
                                // this.setState({user});
                            }}
                        />
                    </Col>
                </Form.Group>

                {studentUniqueNumber && 
                    <Row className="mb-3">
                        <Col sm="4">
                            Student Unique Number:
                        </Col>
                        <Col sm="4">
                            <div className="font-weight-bold">{studentUniqueNumber} </div>
                        </Col>
                    </Row>
                }

                <div className="d-flex align-items-center justify-content-center mt-5">
                    <Button className="btn-general" type="submit">Submit</Button>   
                    {isSaving &&                         
                        <Spinner animation="border" variant="primary" />
                    }
                    {showSuccessAlert &&
                        <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false) } 
                            dismissible>
                            Saved Successfully
                        </Alert>
                    }
                    {errorMsg &&
                        <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null) } 
                            dismissible>
                            {errorMsg}
                        </Alert>
                    }
                    {user && user.id &&
                        <React.Fragment>
                            <Link className="ml-5" to={`../../../student/edit/${student.id}`}>
                                Add detail
                            </Link>
                            <Link className="ml-5" to={`../${user.id}/profile-photo`}>
                                Profile Photo
                            </Link>
                        </React.Fragment>
                    } 

                </div>  

            </Form>    
        </React.Fragment>
    );
}
export default UserStudentNew;