import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import DraggableMap from './googlemap/draggable-map';
import { initializeAutocomplete } from '../utils/google-map-helper';
import { saveRouteStop } from '../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../common/snackbars';
import TimeSelector from './time-selector';
import { convert24HourTo12Hour } from '../utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    address: Yup.string().required("Address is required."),
});

const RouteStopDialog = ({ open, onClose, routeId, routeType, stopForEdit, onSubmit, onUpdate }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [center, setCenter] = useState({lat: 28.4650447, lat: 77.5084151});
    const [zoom, setZoom] = useState(15);
    const [address, setAddress] = useState('');
    const [stop, setStop] = useState({
        id: null,
        name: '',
        address: '',
        pickupTime: null,
        dropoffTime: null,
        lat: null,
        lng: null,
    });

    // Reset form when dialog is open
    useEffect(() => {
        if (open) {
          formik.resetForm();
          setStop(null); // Reset stop
        }
      }, [open]);

    useEffect(() => {
        if (stopForEdit) {
            const stopPickupTime = stopForEdit.pickupTime ? convert24HourTo12Hour(stopForEdit.pickupTime) : {
                hours: '01',
                minutes: '00',
                period: 'AM',
            };
            const stopDropoffTime = stopForEdit.dropoffTime ? convert24HourTo12Hour(stopForEdit.dropoffTime) : {
                hours: '01',
                minutes: '00',
                period: 'AM',
            };
            formik.setValues({
                name: stopForEdit.name,
                address: stopForEdit.location.location,
                stopPickupTime,
                stopDropoffTime,
                lat: stopForEdit.location.latitude,
                lng: stopForEdit.location.longitude,
            });
            setCenter({ lat: stopForEdit.location.latitude, lng: stopForEdit.location.longitude });
            setStop({
                id: stopForEdit.id,
                name: stopForEdit.name,
                address: stopForEdit.location.location,
                pickupTime: stopForEdit.pickupTime,
                dropoffTime: stopForEdit.dropoffTime,
                lat: stopForEdit.location.latitude,
                lng: stopForEdit.location.longitude,
            });
        }
    }, [stopForEdit]);


    const formik = useFormik({
        initialValues: {
            name: '',
            address: '',
            stopPickupTime: {
                hours: '01',
                minutes: '00',
                period: 'AM',
            },
            stopDropoffTime: {
                hours: '01',
                minutes: '00',
                period: 'AM',
            },
            lat: null,
            lng: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log("values ", values);
            console.log("stop ", stop);
            const { name, address, stopPickupTime, stopDropoffTime } = values;
            const routeStop = {
                ...stop,
                routeId,
                name,
                address,
                routeId,
                routeType,
                pickupTime: stopPickupTime,
                dropoffTime: stopDropoffTime,
            }
            setIsSaving(true);
            saveRouteStop(routeStop).then(resp => {
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
                if (stop.id) {
                    onUpdate({routeId, stopId: stop.id});
                    handleClose();
                } else {
                    onSubmit(resp.data.data);
                }
            }).catch(err => {
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
            });

        }
    });

    const handleClose = (event, reason) => {
        console.log("reason", reason);
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
        }
        onClose(false);
    };

    // ################ Google Map helpers ############################
    const handleMapReady = (map, maps) => {
        console.log('Map is ready', map, maps);
        const autocomplete = initializeAutocomplete('address', map, (location, address) => {
            setCenter(location);
            setZoom(15);
            formik.setFieldValue('address', address);
            setStop({...stop, lat: location.lat, lng: location.lng});
        });

        if (autocomplete) {
            console.log("Autocomplete initialized");
        }
    };

    const onLocation = (location) => {
        console.log("Location ", location);
        // setAddress(location.address);
        formik.setFieldValue('address', location.address);
        const newStop = {
            ...stop,
            lat: location.lat,
            lng: location.lng,
        };
        setStop(newStop);
    }


    // ################ Eng Google Map helpers ########################

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
                disableEscapeKeyDown={true}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
                            Add stop
                        </Typography>
                    </Toolbar>
                </AppBar>
                {errorMsg &&
                    <ErrorAlertTopCenter
                        errorMsg={errorMsg}
                        onClose={() => setErrorMsg(null)}
                    />
                }
                {showSuccessAlert &&
                    <SuccessAlertTopRight
                        successMsg={"Stop saved successfully!!"}
                        onClose={() => setShowSuccessAlert(false)}
                    />
                }
                <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="name"
                                label="Stop name"
                                value={formik.values.name}
                                onChange={(e) => {
                                    const val = e.target.value;
                                    // setSchool({ ...school, name: val });
                                    // schoolRef.current.name = val; // update it to be used in functions
                                    formik.handleChange(e);
                                }}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                id="address" // to be used for address suggestions
                                name="address"
                                label="Address"
                                value={formik.values.address}
                                onChange={(e) => {
                                    // setSchool({...school, address: e.target.value});
                                    formik.handleChange(e);
                                }}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                            <TimeSelector
                                name="stopPickupTime"
                                label="Choose stop pickup time"
                                values={formik.values.stopPickupTime}
                                setNameFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                validate={false} // Pass conditionally
                            />
                            <TimeSelector
                                name="stopDropoffTime"
                                label="Choose stop dropoff time"
                                values={formik.values.stopDropoffTime}
                                setNameFieldValue={formik.setFieldValue}
                                setFieldTouched={formik.setFieldTouched}
                                validate={false} // Pass conditionally
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        {/* {school.id ? 'Update':'Continue'} */}
                                        Submit
                                    </Button>
                                }
                            </Box>
                        </Box>
                        
                    </Grid>
                    <Grid sx={{ height: "500px" }} item xs={12} md={6}>
                        <DraggableMap
                            zoom={12}
                            center={center}
                            onMapReady={handleMapReady}
                            onLocation={onLocation}
                        > </DraggableMap>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default RouteStopDialog;