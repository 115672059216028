import React, { Component, useState, useEffect } from 'react';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { saveOrUpdateBus, getBus } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

import { useParams, useNavigate } from "react-router-dom";



const NewBus = () => {

    const [validated, setValidated] = useState(false);
    const [busLoading, setBusLoading] = useState(false);
    const [bus, setBus] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const { busId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (busId) {
            fetchBus(busId);
        }
    }, []);

     // Fetch bus detail for edit
    const fetchBus = (busId) => {
        setBusLoading(true);
        getBus(busId).then(resp => {
            console.log(`Fetch bus - ${JSON.stringify(resp)}`);
            setBus(resp.data.data);
            setBusDetail(resp.data.data);
            setBusLoading(false);
        }).catch(err => {
            console.log(err);
            setBusLoading(false);
        })
    }

    // Set Bus details into state
    const setBusDetail = (respBus) => {
        const newBus = {
            ...bus,
            id: respBus.id,
            brand: respBus.brand,
            manufacturer: respBus.manufacturer,
            model: respBus.model,
            number: respBus.number
        }
        setBus(newBus);
        formik.setValues({ ...newBus }); // get copy of school
        console.log(`Set bus detail - ${JSON.stringify(newBus)}`);
    }


    /**
     * Handle form submission
     */
    // Formik handle submit 
    const validationSchema = Yup.object({
        number: Yup.string().required("Name is required."),
        manufacturer: Yup.string().required("Manufacturer is required."),
        brand: Yup.string().required("Brand is required."),
        model: Yup.string().required("Model is required."),
    });
    const formik = useFormik({
        initialValues: {
            number: '',
            manufacturer: '',
            brand: '',
            model: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            const newBus = {
                ...bus,
                number: values.number,
                manufacturer: values.manufacturer,
                brand: values.brand,
                model: values.model,
            }
            setBus(newBus);
            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);

            saveOrUpdateBus(newBus).then(resp => {
                setIsSaving(false);
                setErrorMsg(null);
                setShowSuccessAlert(true);
            }).catch(err => {
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
                console.log(err);
            })
        }
    });


    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setValidated(true);
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         console.log(`not valid`);
    //         return
    //     } 
        
    //     setIsSaving(true);
    //     setErrorMsg(null);
    //     setShowSuccessAlert(false);

    //     setBus({
    //         ...bus,
    //         number: form[0].value,
    //         manufacturer: form[1].value,
    //         brand: form[2].value,
    //         model: form[3].value,
    //     });
    //     console.log(`Bus to save ${JSON.stringify(bus)}`);

    //     saveOrUpdateBus(bus).then(resp => {
    //         setIsSaving(false);
    //         setErrorMsg(null);
    //         setShowSuccessAlert(true);
    //     }).catch(err => {
    //         setIsSaving(false);
    //         setErrorMsg(err);
    //         setShowSuccessAlert(false);
    //         console.log(err);
    //     })
    // }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Bus saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)}
                />
            }
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography component="h2" variant="header">
                    {bus && bus.id ? "Update bus" : "Add new bus"}
                </Typography>
                <Chip
                    onClick={() => navigate(`../list`)}
                    icon={<FormatListBulletedIcon />}
                    label="Buses"
                    variant="outlined"
                    color="secondary"
                />
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="number"
                            label="Bus number"
                            value={formik.values.number}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.number)}
                            helperText={formik.touched.number && formik.errors.number}
                        />
                         <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="manufacturer"
                            label="Manufacturer"
                            value={formik.values.manufacturer}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.manufacturer)}
                            helperText={formik.touched.manufacturer && formik.errors.manufacturer}
                        />
                         <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="brand"
                            label="Brand"
                            value={formik.values.brand}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.brand)}
                            helperText={formik.touched.brand && formik.errors.brand}
                        />
                         <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="model"
                            label="Model"
                            value={formik.values.model}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.model)}
                            helperText={formik.touched.model && formik.errors.model}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {isSaving &&
                                <CircularProgress />
                            }
                            {!isSaving &&
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Submit
                                </Button>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
    
    // return (
    //     <React.Fragment> 
    //             <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //                 <div className="h5 mb-0 text-gray-800">{bus.number ? 'Update Bus' : 'New Bus'}</div>
    //             </div>
    //             <div className="card shadow h-100 my-2">
    //                 <div className="card-body">
    //                     <Form
    //                         noValidate
    //                         validated={validated}
    //                         onSubmit={handleSubmit}>
                                                            
    //                         <Form.Group>
    //                             <Form.Label>Number</Form.Label>
    //                             <Form.Control required type="text" placeholder="Enter Bus number"
    //                                 value={bus.number}
    //                                 onChange={(e) => {
    //                                     // const bus = this.state.bus;                                                           
    //                                     // bus.number = e.target.value;
    //                                     // this.setState({bus});
    //                                     setBus({
    //                                         ...bus,
    //                                         number: e.target.value,
    //                                     });
    //                                 }}
    //                             />
    //                             <Form.Control.Feedback type="invalid">
    //                                 Please enter bus number.
    //                             </Form.Control.Feedback>
    //                         </Form.Group> 
    //                         <Form.Group>
    //                             <Form.Label>Manufacturer</Form.Label>
    //                             <Form.Control required type="text" placeholder="Enter Manufacturer" 
    //                                 value={bus.manufacturer}
    //                                 onChange={(e) => {
    //                                     // const bus = this.state.bus;                                                           
    //                                     // bus.manufacturer = e.target.value;
    //                                     // this.setState({bus});
    //                                     setBus({
    //                                         ...bus,
    //                                         manufacturer: e.target.value,
    //                                     });
    //                                 }}
    //                             />
    //                             <Form.Control.Feedback type="invalid">
    //                                 Please enter manufacturer
    //                             </Form.Control.Feedback>
    //                         </Form.Group> 
    //                         <Form.Group>
    //                             <Form.Label>Brand</Form.Label>
    //                             <Form.Control required type="text" placeholder="Enter Brand" 
    //                                 value={bus.brand}
    //                                 onChange={(e) => {
    //                                     // const bus = this.state.bus;                                                           
    //                                     // bus.brand = e.target.value;
    //                                     // this.setState({bus});
    //                                     setBus({
    //                                         ...bus,
    //                                         brand: e.target.value,
    //                                     });
    //                                 }}
    //                             />
    //                             <Form.Control.Feedback type="invalid">
    //                                 Please enter brand.
    //                             </Form.Control.Feedback>
    //                         </Form.Group> 
    //                         <Form.Group>
    //                             <Form.Label>Model</Form.Label>
    //                             <Form.Control required type="text" placeholder="Enter Model" 
    //                                 value={bus.model}
    //                                 onChange={(e) => {
    //                                     // const bus = this.state.bus;                                                           
    //                                     // bus.model = e.target.value;
    //                                     // this.setState({bus});
    //                                     setBus({
    //                                         ...bus,
    //                                         model: e.target.value,
    //                                     });
    //                                 }}
    //                             />
    //                             <Form.Control.Feedback type="invalid">
    //                                 Please enter bus model.
    //                             </Form.Control.Feedback>
    //                         </Form.Group>                                 

    //                         <div className="d-flex align-items-center justify-content-left">
    //                             <Button className="btn-general" type="submit">Submit</Button>   
    //                             {isSaving &&                         
    //                                 <Spinner animation="border" variant="primary" />
    //                             }
    //                             {showSuccessAlert &&
    //                                 <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false) } 
    //                                     dismissible>
    //                                     Saved Successfully
    //                                 </Alert>
    //                             }
    //                             {errorMsg &&
    //                                 <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null) } 
    //                                     dismissible>
    //                                     {errorMsg}
    //                                 </Alert>
    //                             }
    //                         </div>  

    //                     </Form>
    //                 </div>
    //             </div>
    //     </React.Fragment> 
    // );
}

export default NewBus;



// class NewBus extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             validated: false,
//             busLoading: false,
//             bus: {},

//             isSaving: false,
//             errorMsg: null,
//             showSuccessAlert: false
// 		};
//     }

//     componentDidMount() {
//         const id = this.props.match.params.id;
//         if (id) {
//             this.fetchBus(id);
//         }
//     }

//      // Fetch bus detail for edit
//     fetchBus = (busId) => {
//         this.setState({busLoading: true});
//         getBus(busId).then(resp => {
//             console.log(`Fetch bus - ${JSON.stringify(resp)}`);
//             this.setState({bus: resp.data.data});
//             this.setBusDetail(this.state.bus);
//             this.setState({busLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({busLoading: false});
//         })
//     }

//     // Set Bus details into state
//     setBusDetail = (respBus) => {
//         this.setState(() => ({
//             bus: {
//                 ...this.state.bus,
//                 id: respBus.id,
//                 brand: respBus.brand,
//                 manufacturer: respBus.manufacturer,
//                 model: respBus.model,
//                 number: respBus.number
//             }
//         }));
//         console.log(`Set bus detail - ${JSON.stringify(this.state.bus)}`);
//     }


//     /**
//      * Handle form submission
//      */
//     handleSubmit = (event) => {
//         event.preventDefault();
//         event.stopPropagation();
//         this.setState({ validated: true });
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             console.log(`not valid`);
//             return
//         } 
//         const bus = this.state.bus;
//         this.setState({isSaving: true});
//         this.setState({errorMsg: null});
//         this.setState({showSuccessAlert: false});

//         bus.number = form[0].value;
//         bus.manufacturer = form[1].value;
//         bus.brand = form[2].value;
//         bus.model = form[3].value;

//         saveOrUpdateBus(bus).then(resp => {
//             this.setState({isSaving: false});
//             this.setState({errorMsg: null});
//             this.setState({showSuccessAlert: true});
//         }).catch(err => {
//             this.setState({isSaving: false});
//             this.setState({errorMsg: err});
//             this.setState({showSuccessAlert: false});
//             console.log(err);
//         })
//     }
    
//     render() {
//         const { validated, isSaving, errorMsg, showSuccessAlert } = this.state;
// 		return (
//             <React.Fragment> 
//                     <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                         <h1 className="h4 mb-0 text-gray-800">{this.state.bus.number ? 'Update Bus' : 'New Bus'}</h1>
//                     </div>
//                     <div className="card shadow h-100 my-2">
//                         <div className="card-body">
//                             <Form
//                                 noValidate
//                                 validated={validated}
//                                 onSubmit={e => this.handleSubmit(e)}>
                                                               
//                                 <Form.Group>
//                                     <Form.Label>Number</Form.Label>
//                                     <Form.Control required type="text" placeholder="Enter Bus number"
//                                         value={this.state.bus.number}
//                                         onChange={(e) => {
//                                             const bus = this.state.bus;                                                           
//                                             bus.number = e.target.value;
//                                             this.setState({bus});
//                                         }}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                         Please enter bus number.
//                                     </Form.Control.Feedback>
//                                 </Form.Group> 
//                                 <Form.Group>
//                                     <Form.Label>Manufacturer</Form.Label>
//                                     <Form.Control required type="text" placeholder="Enter Manufacturer" 
//                                         value={this.state.bus.manufacturer}
//                                         onChange={(e) => {
//                                             const bus = this.state.bus;                                                           
//                                             bus.manufacturer = e.target.value;
//                                             this.setState({bus});
//                                         }}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                         Please enter manufacturer
//                                     </Form.Control.Feedback>
//                                 </Form.Group> 
//                                 <Form.Group>
//                                     <Form.Label>Brand</Form.Label>
//                                     <Form.Control required type="text" placeholder="Enter Brand" 
//                                         value={this.state.bus.brand}
//                                         onChange={(e) => {
//                                             const bus = this.state.bus;                                                           
//                                             bus.brand = e.target.value;
//                                             this.setState({bus});
//                                         }}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                         Please enter brand.
//                                     </Form.Control.Feedback>
//                                 </Form.Group> 
//                                 <Form.Group>
//                                     <Form.Label>Model</Form.Label>
//                                     <Form.Control required type="text" placeholder="Enter Model" 
//                                         value={this.state.bus.model}
//                                         onChange={(e) => {
//                                             const bus = this.state.bus;                                                           
//                                             bus.model = e.target.value;
//                                             this.setState({bus});
//                                         }}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                         Please enter bus model.
//                                     </Form.Control.Feedback>
//                                 </Form.Group>                                 

//                                 <div className="d-flex align-items-center justify-content-left">
//                                     <Button className="btn-general" type="submit">Submit</Button>   
//                                     {isSaving &&                         
//                                         <Spinner animation="border" variant="primary" />
//                                     }
//                                     {showSuccessAlert &&
//                                         <Alert className="ml-3 mb-0" variant="success" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                             dismissible>
//                                             Saved Successfully
//                                         </Alert>
//                                     }
//                                     {errorMsg &&
//                                         <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({errorMsg: null}) } 
//                                             dismissible>
//                                             {this.state.errorMsg}
//                                         </Alert>
//                                     }
//                                 </div>  

//                             </Form>
//                         </div>
//                     </div>
//             </React.Fragment> 
//         )
//     }
// }

// export default NewBus;