import React from 'react';
import { Outlet, Link } from "react-router-dom";

const Student = () => {
    return (
        <>
            <Outlet />
        </>
    );
    // return (
    //     <React.Fragment>
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <h1 className="h4 mb-0 text-gray-800">Students</h1>
    //             <div class="mx-3">
    //                 <Link type="button" class="btn btn-info btn-sm ml-3" to="list">Show students</Link>
    //                 <Link type="button" class="btn btn-info btn-sm ml-3" to={"../user/student/new"}>Add new student</Link>
    //             </div>
    //             {/* <div class="mx-3">  ---- not required as of now as student directly edited
    //                 <Link type="button" class="btn btn-info btn-sm ml-3" to="add">Add student detail</Link>
    //             </div> */}
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 <Outlet />
    //             </div>
    //         </div>
    //     </React.Fragment>
    // );
}

export default Student;