import { useState, forwardRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import SchoolIcon from '@mui/icons-material/School';

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Typography, Divider, List,
    Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, useMediaQuery,
    Collapse } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import { IconDashboard, IconKey, IconTypography, IconPalette, IconShadow, IconWindmill, 
    IconBrandChrome, IconHelp, IconChevronDown, IconChevronUp } from '@tabler/icons';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { MENU_OPEN, SET_MENU } from '../../../actions/action-types';
import { LeftNavViews } from '../../../utils/constants';


const LeftNav = ({ drawerOpen, drawerToggle, window, menuItems }) => {

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    console.log('menu items -- ', menuItems);

    const drawer = (
        <>
          <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    <MenuList menuItems={menuItems} />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList menuItems={menuItems} />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    const drawerWidth = 260;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
        </Drawer>
    </Box>
    );
}

export default LeftNav;



// =================== Menu List Component ============================
const MenuList = ({ menuItems }) => {

    const schoolId = useSelector(state => state.extras.schoolId);
    const leftNav = useSelector(state => state.leftNav);
    console.log(`user role - ${JSON.stringify(leftNav.userRoles)}`);
    console.log(`nav view - ${leftNav.view}`);
    console.log('menuItems -- ', menuItems);

    const schools = {
        id: 'setting-schools',
        title: 'Schools setting',
        type: 'group',
        children: [
            {
                id: 'list',
                title: 'Your schools',
                type: 'item',
                url: 'list',
                icon: SchoolIcon,
                breadcrumbs: false
            },
        ]
    };
    const trips = {
        id: 'trips-routes',
        title: 'Trips and Route planner',
        type: 'group',
        children: [
            {
                id: 'trips',
                title: 'Trips',
                type: 'collapse',
                icon: SchoolIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'trip-list',
                        title: `Todays trip's`,
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/trip/today/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'trip-list',
                        title: `History`,
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/trip/history/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'trip-list',
                        title: `New metadata`,
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/trip/metadata/new"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'trip-list',
                        title: `List metadata`,
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/trip/metadata/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                ]
            },
            {
                id: 'route',
                title: 'Route Planner',
                type: 'collapse',
                icon: SchoolIcon,
                children: [
                    {
                        id: 'route-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/route/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'new-route',
                        title: 'Build route',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/route/new"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    }
                ]

            },
        ]
    };
    const manageSchools = {
        id: 'manage-schools',
        title: 'Manage school',
        type: 'group',

        children: [
            {
                id: 'section',
                title: 'Section',
                type: 'collapse',
                icon: SchoolIcon,
                children: [
                    {
                        id: 'section-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/section/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'section-new',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/section/new"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    }
                ]
            },
            {
                id: 'class',
                title: 'Class',
                type: 'collapse',
                icon: SchoolIcon,
                children: [
                    {
                        id: 'class-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/class/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'class',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/class/new"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    }
                ]
            },
            {
                id: 'student',
                title: 'Student',
                type: 'collapse',
                url: `${"/school/" + schoolId + "/admin/student/list"}`,
                icon: SchoolIcon,
                children: [
                    {
                        id: 'student-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/student/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'student-new',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/student/new"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    }
                ]
            },
        ]
    };

    const manageTransportation = {
        id: 'manage-transportation',
        title: 'Manage Transportation',
        type: 'group',
        children: [
            {
                id: 'driver',
                title: 'Driver',
                type: 'collapse',
                icon: SchoolIcon,
                children: [
                    {
                        id: 'driver-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/driver/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'driver-new',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/driver/new"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    }
                ]
            },
            {
                id: 'bus',
                title: 'Bus',
                type: 'collapse',
                icon: SchoolIcon,
                children: [
                    {
                        id: 'bus-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/bus/list"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'bus-new',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/bus/new"}`,
                        icon: SchoolIcon,
                        breadcrumbs: false,
                    }
                ]
            },
        ]
    };

    //let menuItems = [schools, trips, manageSchools, manageTransportation];

    const navItems = menuItems?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: '32px',
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1,
                            background: theme.palette.primary.light
                        }
                    }}
                >
                    {menus}
                </List>
            </Collapse>
        </>
    );
};

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${item.url}`} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
            selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

