import React, { Component, useState, useEffect, useRef } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, Chip, StepContent, Paper, Grid,
    TextField, Autocomplete, CircularProgress } from '@mui/material';
import { connect, useSelector } from 'react-redux'
import Autosuggest from 'react-autosuggest';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useParams, useNavigate } from "react-router-dom";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SettingsIcon from '@mui/icons-material/Settings';

import { getTrip, fetchSchool as fetchSchoolHttp, deleteTripStudent, getAvailableTripDrivers, getAvailableTripBuses,
    updateTripBus, updateTripDriver, searchStudent, updateTripStudents } 
    from '../../../../services/http.service';
import { convertFrequencyType, convertFrequency, formatDateTime, convertRouteType,
    convertTripStatus, ModalType, TripStatus } from '../../../../utils/constants';

import  GooogleMap  from '../../../../common/googlemap/google-map';
import { CustomModal } from '../../../../common/modals';
import { id } from 'date-fns/locale';
import Loader from '../../../../common/loader';

import UpdateTripStudents from './update-students';
import UpdateTripStops from './update-stops';
import ChangeTripBus from './change-bus';
import ChangeTripDriver from './change-driver';

const steps = [
    // {
    //     label: 'Students',
    //     description: `Search student from the dropdown and pont to exact student's location on google map on the right.`,
    // },
    {
        label: 'Trip stops',
        description: `Update stops on this trip.`,
    },
    {
        label: 'Trip students',
        description: `Update students on this trip.`,
    },
    {
        label: 'Change bus',
        description: `Change the available bus to the route.`,
    },
    {
        label: 'Change driver',
        description:`Change the available driver to the route`,
    },
];


const EditTrip = () => {

    const [activeStep, setActiveStep] = React.useState(0);




    const [isLoading, setIsLoading] = useState(false);
    const [trip, setTrip] = useState({
        id: null,
        refNo: '',
        students: [],
        route: {
            type: {}
        },
        bus: {},
        driver: {
            user: {}
        }
    });
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [alert, setAlert] = useState({
        show: false,
        body: '',
    });
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [searchedStudents, setSearchedStudents] = useState([]);
    const [school, setSchool] = useState(null);
    const [availableDrivers, setAvailableDrivers] = useState([]);
    const [availableBuses, setAvailableBuses] = useState([]);
    const [changeAssignBus, setChangeAssignBus] = useState(false);
    const [changeAssignDriver, setChangeAssignDriver] = useState(false);
    const [selectedBus, setSelectedBus] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [assignBusFormValidated, setAssignBusFormValidated] = useState(false);
    const [assignDriverFormValidated, setAssignDriverFormValidated] = useState(false);
    const [updateStudentHandle, setUpdateStudentHandle] = useState({
        isSaving: false,
        errorMsg: null,
        showSuccessAlert: false,
    });
    const [changeBusHandle, setChangeBusHandle] = useState({
        isSaving: false,
        errorMsg: null,
        showSuccessAlert: false,
    });
    const [changeDriverHandle, setChangeDriverHandle] = useState({
        isSaving: false,
        errorMsg: null,
        showSuccessAlert: false,
    });
    let [students, setStudents] = useState([]); // make it 'let' to be assinged later
    const [errorMsg, setErrorMsg] = useState(null);

    const mapRef = useRef();
    const mapsRef = useRef();
    const googleMapElement = useRef();
    const navigate = useNavigate();
	

    const { tripId } = useParams();
    const schoolId = useSelector(state => state.extras.schoolId);
    console.log(`schoolid ---- ${schoolId}`);

    useEffect(() => {
        fetchSchool();
        setTrip({
            ...trip,
            id: tripId,
        });
    }, []);

    // Fetch school details from server
    const fetchSchool = () => {
        fetchSchoolHttp(schoolId).then(resp => {
            const school = resp.data.school;
            setSchool(school);
            fetchTrip(tripId);
            // this.setState({ school }, () => {
            //     this.fetchTrip(this.state.trip.id);
            // });
            console.log(`loaded school - ${JSON.stringify(school)}`);
        }).catch(err => {
            console.log(err);
        })
    }

    // Fetch available drivers
    const fetchAvailableDrivers = () => {
        getAvailableTripDrivers(trip.route.ref_no).then(resp => {
            setAvailableDrivers(resp.data.data);
        }).catch(err => {
            console.log(err);
        });
    }

     // Fetch available buses
     const fetchAvailableBuses = () => {
        getAvailableTripBuses(trip.route.ref_no).then(resp => {
            setAvailableBuses(resp.data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    // Fetch trip
    const fetchTrip = (id) => {
        setIsLoading(true);
        getTrip(id).then(resp => {
            console.log(JSON.stringify(resp.data.data));
            const respTrip = resp.data.data;
            if (respTrip) {
                // Set route details
                // const tripStudents = respTrip.trip_students.map(item => {
                //     const student = buildTripStudent(item.student);
                //     student.order = item.order;
                //     return student;
                // })
                const trip = {
                    id: respTrip.id,
                    refNo: respTrip.ref_no,
                    route: respTrip.route,
                    students: respTrip.trip_students.map(item => {
                        return {
                            id: item.id,
                            isActive: item.is_active,
                            stop: item.stop,
                            student: {
                                id: item.student.id,
                                name: item.student.name,
                                rollNo: item.student.roll_no,
                                profilePhoto: item.student.profile_photo,
                                class: item.student.sclass,
                                section: item.student.section,
                            }
                        }
                    }),
                    stops: respTrip.trip_stops.map(item => {
                        return {
                            id: item.id,
                            routeId: item.route_id,
                            order: item.order,
                            isActive: item.is_active,
                            stop: {
                                id: item.stop.id,
                                name: item.stop.name,
                                pickupTime: item.stop.pickup_time,
                                dropoffTime: item.stop.dropoff_time,
                                location: item.stop.location,
                            }
                        }
                    }),
                    bus: respTrip.bus,
                    driver: respTrip.driver,
                    expectedStartTime: respTrip.expected_start_time,
                    expectedEndTime: respTrip.expected_end_time,
                    status: respTrip.status,
                };
                setTrip(trip); // spread to update ui
                //this.drawOnMap();                
                console.log(`Trip for edit - ${JSON.stringify(trip)}`);
            }

            setIsLoading(false);

            // Fetch available buses and drivers including assigned to this trip route
            fetchAvailableBuses();
            fetchAvailableDrivers();

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Build Trip student from student object
    const buildTripStudent = (student) => {
        let location = null;
        // const trip = this.state.trip;
        if (trip.route.type.name === 'DROPOFF') {
            location = student.dropoff;
        } else {
            location = student.pickup;
        }

        return {
            id: student.id,
            name: student.user.name,
            latitude: location.latitude,
            longitude: location.longitude,
            location: location.location,
            rollNo: student.roll_no,
            sclass: student.sclass,
            section: student.section
        }
    }


    /********** Start Student search Suggestions *****************/
    // // Auto Suggests 
    // const onChange = (event, { newValue }) => {
    //     console.log(`onChange() - value - ${newValue}`);
    //     setValue(newValue);
    // };
    // // Autosuggest will call this function every time you need to update suggestions.
    // // You already implemented this logic above, so just use it.
    // const onSuggestionsFetchRequested = ({ value }) => {
    //     console.log(`fetching suggestionf for value - ${value}`);
    //     if (value === undefined) {
    //         return;
    //     }
    //     // Type at least 3 chars
    //     value = value.trim();
    //     if (value.length < 3) {
    //         return;
    //     }

    //     // If previous request is not finished then dont start
    //     if (isLoading) {
    //         return;
    //     }

    //     setIsLoading(true);
    //     searchStudent(value)
    //         .then(resp => {
    //             //console.log("Search Students :: " + JSON.stringify(students));
    //             const students = resp.data.data;
    //             console.log("Search Students users :: " + JSON.stringify(students));

    //             // process suggestions
    //             const suggestions = students.map(student => {
    //                 return buildTripStudent(student);
    //             })

    //             setSuggestions(suggestions);
    //             setIsLoading(false);
    //         })
    //         .catch(error => {
    //             console.log("Error in Search student", error);
    //             setIsLoading(false);
    //         });
    // };

    // // Autosuggest will call this function every time you need to clear suggestions.
    // const onSuggestionsClearRequested = () => {
    //     setSuggestions([]);
    // };

    // // On selected of student
    // const onSuggestionSelected = (event, { suggestion }) => {
    //     console.log("Selected suggestion :: " + JSON.stringify(suggestion));
    //     // const trip = this.state.trip;
    //     // Check if student is already add to route
    //     const duplicate = trip.students.find(item => item.id === suggestion.id);
    //     if (duplicate) {
    //         return;
    //     }

    //     // Reset search value
    //     setValue('');
    //     trip.students.push(suggestion);
    //     // Add ordering
    //     trip.students = trip.students.map((item, index) => {
    //         item.order = index;
    //         return item;
    //     });
    //     setTrip({...trip});
    //     console.log(`ordering of students in state - ${JSON.stringify(trip.students)}`);
    //     drawOnMap(); // Draw map
    // };


    /********** End Student search Suggestions *****************/


    /********** Start Drag Drop *****************/

    // const onDragEnd = result => {
    //     const { destination, source, draggableId } = result;

    //     if (!destination) {
    //         return;
    //     }

    //     if (destination.index === source.index) { // user drop back item
    //         return;
    //     }

    //     // Reorder search students
    //     let tripStudents = [...trip.students]; // clone student array using spread

    //     // Get searched studend by draggble id
    //     const draggedStudent = tripStudents.find(item => item.id === draggableId);

    //     tripStudents.splice(source.index, 1); // Remove item at source
    //     tripStudents.splice(destination.index, 0, draggedStudent); // Insert dragged item at distination
    //     // Upate ordering of student
    //     console.log(`students after Drag end - ${JSON.stringify(tripStudents)}`);
    //     tripStudents = tripStudents.map((item, index) => {
    //         item.order = index;
    //         return item;
    //     })
    //     console.log(`students order after Drag end - ${JSON.stringify(tripStudents)}`);
    //     students = tripStudents;
    //     setStudents([...students]);

    //     // also update the trip students
    //     trip.students = tripStudents;
    //     setTrip({
    //         ...trip,
    //         students: tripStudents,
    //     });
    //     drawOnMap(); // Update route on map
    // }
    /********** End Drag Drop *****************/



    /********** Google Map methods *****************/

    // Show student info on Map - pass student data to GoogleMap Component
    // const showStudentOnMap = (student) => {
    //     googleMapElement.current.showStudentOnMap(student);
    // }

    // const drawOnMap = () => {
    //     googleMapElement.current.drawOnMap(students);
    // }

    /********** End Google Map methods *****************/
    

    // Delete student from route list
    const deleteStudent = (id) => {
        // Check for deletion
        if (!isTripEditable()) {
            return;
        }
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        // this.setState(() => ({
        //     delete: {
        //         ...this.state.delete,
        //         show: true,
        //         data: {
        //             id: id
        //         }
        //     }
        // }));
    }
    // On Delete of Trip student
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });

        // const trip = this.state.trip;
        trip.students = trip.students.filter(item => item.id !== data.id);
        setTrip({...trip});
        // drawOnMap();     
    }

    // Handle Assign Bus change submit
    const handleAssignBusSubmit = (event) => {        
        event.preventDefault();
        event.stopPropagation();
        // Check for submit
        if (!isTripEditable()) {
            return;
        }
        setAssignBusFormValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log(`not valid`);
            return
        }
        // const selectedBus = this.state.selectedBus;
        console.log(`selected bus - ${JSON.stringify(selectedBus)}`);

        if (!selectedBus) {
            return;
        }

        const data = {
            tripId: trip.id,
            busId: selectedBus.id
        }

        setChangeBusHandle({
            ...changeBusHandle,
            isSaving: true,
            showSuccessAlert: false,
            errorMsg: null,
        });
        updateTripBus(data).then(resp => {
            // Add update bus into Trip
            // const trip = this.state.trip;
            trip.bus = selectedBus;
            setTrip({...trip});
            setChangeBusHandle({
                ...changeBusHandle,
                isSaving: false,
                showSuccessAlert: true,
                errorMsg: null,
            });
        }).catch(err => {
            console.log(err);
            setChangeBusHandle({
                ...changeBusHandle,
                isSaving: false,
                showSuccessAlert: false,
                errorMsg: err,
            });
        })

    }

    // Handle Assign Bus change submit
    const handleAssignDriverSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        // Check for submit
        if (!isTripEditable()) {
            return;
        }
        setAssignDriverFormValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log(`not valid`);
            return
        }
        // const selectedDriver = this.state.selectedDriver;
        console.log(`selected driver - ${JSON.stringify(selectedDriver)}`);

        if (!selectedDriver) {
            return;
        }

        const data = {
            tripId: trip.id,
            driverId: selectedDriver.id
        }

        setChangeDriverHandle({
            ...changeDriverHandle,
            isSaving: true,
            showSuccessAlert: false,
            errorMsg: null,
        });

        updateTripDriver(data).then(resp => {
            // Add update bus into Trip
            // const trip = this.state.trip;
            trip.driver = selectedDriver;
            setTrip({ ...trip });

            setChangeDriverHandle({
                ...changeDriverHandle,
                isSaving: false,
                showSuccessAlert: true,
                errorMsg: null,
            });
        }).catch(err => {
            console.log(err);
            setChangeDriverHandle({
                ...changeDriverHandle,
                isSaving: false,
                showSuccessAlert: false,
                errorMsg: err,
            });
        })
    }

    // Update Students to the server
    const updateStudents = () => {
        console.log(`updating students to server`);

        // Check for submit
        if (!isTripEditable()) {
            return;
        }

        // Reset Flags
        setUpdateStudentHandle({
            ...updateStudentHandle,
            isSaving: false,
            showSuccessAlert: false,
            errorMsg: null,
        });

        // Build students order
        const students = [];
        trip.students.forEach((item, index) => {
            students.push({id: item.id, order: index});
        });

        const data = {
            tripId: trip.id,
            students: students
        }
        console.log(`Updating trips students - ${JSON.stringify(data)}`);
        updateTripStudents(data).then(resp => {
            setUpdateStudentHandle({
                ...updateStudentHandle,
                isSaving: false,
                showSuccessAlert: true,
                errorMsg: null,
            });
        }).catch(err => {
            console.log(err);
            setUpdateStudentHandle({
                ...updateStudentHandle,
                isSaving: false,
                showSuccessAlert: false,
                errorMsg: err,
            });
        })
    }

    // Check trip of action
    const isTripEditable = () => {
        // const { trip } = this.state;
        if (trip.status !== TripStatus.NEW) {
            setAlert({
                show: true,
                body: "Can not edit Trip, Trip is not New",
            });
            return false;
        }
        return true;
    }

    // Autosuggest will pass through all these props to the input.
    // const inputProps = {
    //     placeholder: 'Type a student name',
    //     value,
    //     onChange: onChange
    // };




    // ############ Stepper config ########################
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    // ############ End // Stepper config ########################

      // on update of trip from child stepper component
      const updateTrip = (trip) => {
        setTrip(trip);
    }





    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                }}
            > 
                { isLoading &&
                    <Loader />
                } 
                 <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Update trip
                    </Typography>
                    <Box>
                        <Chip
                            onClick={() => navigate(`../today/list`)}
                            sx={{ mx: 1 }}
                            icon={<FormatListBulletedIcon />}
                            label="Today's trips"
                            variant="outlined"
                            color="secondary"
                        />
                        <Chip
                            onClick={() => navigate(`../history/list`)}
                            sx={{ mx: 1 }}
                            icon={<FormatListBulletedIcon />}
                            label="History"
                            variant="outlined"
                            color="secondary"
                        />
                         <Chip
                            onClick={() => navigate(`../metadata/list`)}
                            sx={{ mx: 1 }}
                            icon={<SettingsIcon />}
                            label="Metadata"
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography component="h5" variant="h5">
                                    Trip Ref:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography component="h5" variant="h5">
                                    <strong>{trip.refNo}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography component="h5" variant="h5">
                                    Type:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography component="h5" variant="h5">
                                    <strong>{convertRouteType(trip.route.type.name)}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography component="h5" variant="h5">
                                    Expected Start Time:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography component="h5" variant="h5">
                                    <strong>{formatDateTime(trip.expectedStartTime)}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography component="h5" variant="h5">
                                    Expected End Time:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography component="h5" variant="h5">
                                    <strong>{formatDateTime(trip.expectedEndTime)}</strong>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: "100%", mt: 2 }}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                     {/* Update stops on trip with map */}
                                     {index === 0 &&
                                        <UpdateTripStops
                                            trip={trip}
                                            onNext={handleNext} 
                                            onSubmit={(trip) => updateTrip(trip)}
                                        />
                                    }
                                    {/* Update students on trip with map */}
                                    {index === 1 &&
                                        <UpdateTripStudents
                                            trip={trip}
                                            onNext={handleNext} 
                                            onSubmit={(trip) => updateTrip(trip)}
                                        />
                                    }
                                    {/* Change bus to the trip */}
                                    {index === 2 &&
                                        <ChangeTripBus
                                            trip={trip}
                                            availableBuses={availableBuses}
                                            onNext={handleNext} 
                                            onSubmit={(trip) => updateTrip(trip)}
                                            onBack={handleBack}
                                        />
                                    }
                                    {/* Change driver to the trip */}
                                    {index === 3 &&
                                        <ChangeTripDriver
                                            trip={trip}
                                            availableDrivers={availableDrivers}
                                            onSubmit={(trip) => updateTrip(trip)}
                                            onBack={handleBack}
                                        />
                                    }
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>


            </Box>
        </>
    );
}

export default EditTrip;