import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Box, Typography, Chip, Button, Card, CardMedia } from '@mui/material';

import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import Loader from '../../../../common/loader';
import CommonProfilePhoto from '../../../../common/profile-photo';
import { IMAGES_URL, ImageSourceType } from '../../../../utils/constants';
import { uploadStudentProfilePhoto } from '../../../../services/http.service';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const StudentPhoto = ({ student, type, onNext, onBack, onSubmit }) => {

    const [user, setUser] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);

    useEffect(() => {
        //fetchUser(userId);
    }, []);

    useEffect(() => {
        const url = student.profilePhoto ? `${IMAGES_URL.STUDENT}/${student.profilePhoto}` : `${IMAGES_URL.STUDENT}/default_profile.png`
        setProfilePhotoUrl(url);
    }, [student]);



    const upload = (image) => {
        console.log(`Uploading profile photo - ${JSON.stringify(image)}`);

        // Show error if image is not selected
        if (!image) {
            setIsSaving(false);
            setErrorMsg("Please select image to upload");
            setShowSuccessAlert(false);
            return;
        }
        // Reset flags
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        uploadStudentProfilePhoto(student.id, image).then(resp => {
            console.log(`Uploaded successfully`);
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const newStudent = {
                ...student,
                profilePhoto: resp.data.filename
            }
            setProfilePhotoUrl(`${IMAGES_URL.STUDENT}/${resp.data.filename}`);
            onSubmit(newStudent);
        }).catch(err => {
            console.error(err);
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    return (
        <>
            { errorMsg && 
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert && 
                <SuccessAlertTopRight
                    successMsg={'Uploaded successfully'}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
            <Box  
                sx={{ 
                    mt: 4, 
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "start",
                    alignItems: "start"
                }}
            >
                <CommonProfilePhoto
                    profilePhotoUrl={profilePhotoUrl}
                    altName={student.name}
                    onSubmit={upload}
                />

                <Box sx={{ display: 'flex', mt: 3, mb: 2  }}>
                    <Button
                        variant="outlined"
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={onBack}
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ ml: 3 }}
                        endIcon={<ArrowForwardIosIcon />}
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Box>
                
            </Box>
        </>
    );
}

export default StudentPhoto;