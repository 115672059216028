import { login as loginHttp, verifyOTP as  verifyOTPHttp,
        logout as logoutHttp } from './http.service';
import { OTPVerificationType, Role } from '../utils/constants';


/**
 * Login to server
 */
export function login(mobile, password) {
    return new Promise((resolve, reject) => {
        loginHttp(mobile, password).then(resp => {
            console.log(`login success - ` + JSON.stringify(resp));
            if (resp.data.user && resp.data.user.is_verified) {
                console.log('user is verified');
                saveAuth(resp.data);
            }
            resolve(resp);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Logout from server and local storage
 */
export function logout() {
    return new Promise((resolve, reject) => {
        logoutHttp().then(resp => {
            console.log(`logout success - ` + JSON.stringify(resp));
            removeAuth();
            resolve(resp);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Verify user OTP and saveAuth
 * @param  userId 
 * @param otp 
 */
export function verifyOTP(userId, otp) {
    return new Promise((resolve, reject) => {
        verifyOTPHttp(userId, otp, OTPVerificationType.REGISTER).then(resp => {
            saveAuth(resp.data);
            resolve(resp);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Save Auth detail
 * @param data 
 */
export function saveAuth(data) {
    console.log(`storing auth -- ${JSON.stringify(data)}`);
    localStorage.setItem('accessToken', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);
    localStorage.setItem('expiry', data.expires_in);
    localStorage.setItem('user', JSON.stringify(data.user));
}

/**
 * Remove auth details
 * 
 * @param data 
 */
export function removeAuth(data) {
    console.log('removing auth');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiry');
    localStorage.removeItem('user');
}

export function getAccessToken() {
    return localStorage.getItem('accessToken');
}

export function getRefreshToken() {
    return localStorage.getItem('refreshToken');
}

export function setAccessToken(accessToken) {
    return localStorage.setItem('accessToken', accessToken);
}

export function getUser() {
    let user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user);
    }
    return null;
}

// Update profile pic into storage
export function updateProfilePhoto(profilePhoto) {
    let userStr = localStorage.getItem('user');
    if (userStr) {
        let user = JSON.parse(userStr);
        user.profile_photo = profilePhoto;
        localStorage.setItem('user', JSON.stringify(user));
    }
}

export function isAuthenticated() {
    const accessToken = localStorage.getItem('accessToken');
    const user = localStorage.getItem('user');
    console.log(`isAuthenticated --- ${JSON.stringify(user)}`)
    if (accessToken && user) {
        return true;
    }
    return false;
}

export function isSchoolOwner() {
    const user = getUser();
    const roleOwner = user?.roles.find(item => item.name === Role.SCHOOL_OWNER);
    if (roleOwner) {
        return true;
    }
    return false;
}

