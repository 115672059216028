import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    IconButton, Divider } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { updateTripBus } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

const ChangeTripBus = ({ availableBuses, trip, onNext, onBack, onSubmit }) => {

    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [selectedBus, setSelectedBus] = useState(null);

    useEffect(() => {
        console.log("availableBuses - ", availableBuses);
        setSelectedBus(trip.bus);
    }, [trip]);

    // Save assinged bus
    const handleSubmit = () => {
        console.log("Selected Bus - ", selectedBus);
        if (!selectedBus) {
            return;
        }
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        const data = {
            tripId: trip.id,
            busId: selectedBus.id
        }

        updateTripBus(data).then(resp => { 
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const respData = resp.data.data;
            if (respData) {
                const newTrip = {
                    ...trip,
                    bus: newTrip
                }
                onSubmit(newTrip);
            }
        }).catch(err => {
            console.log(err);
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Bus saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)}
                />
            }

            <Box>
                <Box sx={{ mt: 1 }}>
                    <Autocomplete
                        id="available-buses"
                        options={availableBuses}
                        sx={{ width: '20rem' }}
                        value={selectedBus}
                        isOptionEqualToValue={(option, value) => {
                            if (value) {
                                return option.id === value.id;
                            }
                            return false;
                        }}
                        getOptionLabel={(option) => option.number}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSelectedBus(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Assign Bus" />}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}
            >
                {isSaving &&
                    <CircularProgress />
                }
                {!isSaving &&
                    <Box sx={{ my: 2 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mx: 2}}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ mx: 2 }}
                            startIcon={<ArrowBackIosNewIcon />}
                            onClick={onBack}
                        >
                            Back
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{ mx: 2 }}
                            startIcon={<ArrowForwardIosIcon />}
                            onClick={onNext}
                        >
                            Next
                        </Button>
                    </Box>
                }
            </Box>
        </>
    );

}

export default ChangeTripBus;