import React, { useState, useEffect } from 'react';

const SearchBox = ({onChangeText}) => {
    return (
        <div class="input-group col-md-6 my-2">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Type first 3 chars" aria-describedby="basic-addon1" 
                onChange={(e) => {
                    const val = e.target.value;
                    console.log(val);
                    // validate first 3 char or is blank then call
                    if (val.trim().length == 0 || val.trim().length > 2) {
                        onChangeText(e.target.value);
                    }
                }}
            />
        </div>
    );
}

export default SearchBox;