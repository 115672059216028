import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";

import { getRoutes, deleteRoute, getRouteByRefAndType, disableEnableRoute } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { RouteType, ModalType } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';



const RouteList = () => {

    const [routes, setRoutes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [disableJson, setDisableJson] = useState({
        show: false,
        data: {}
    });
    const [alert, setAlert] = useState({
        show: false,
        body: '',
    });

    const navigate = useNavigate();
    const baseUrl = useSelector(state => state.extras.baseUrl);

    useEffect(() => {
        fetchRoutes();
    }, []);

    // Fetch routes
    const fetchRoutes = () => {
        setIsLoading(true);
        getRoutes().then(resp => {
            setRoutes(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteRoute(data.id).then(resp => {
            setIsLoading(false);
            fetchRoutes();
        }).catch(err => {
            console.log(`Error in deleting route id - ${data.id} - ${err}`);
            setIsLoading(false); 
        })
    }

    // Handle Disable modal   
    const handleDisable = (id, isDisabled) => {
        setDisableJson({
            ...disableJson,
            show: true,
            data: {
                id: id,
                isDisabled: isDisabled
            }
        });
        console.log(`disable - ${JSON.stringify(disableJson)}`);
    }

    // On Disable of route
    const onDisable = (data) => {
        console.log('on disable data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDisableJson({
            ...disableJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        disableEnableRoute(data.id, data.isDisabled).then(resp => {
            setIsLoading(false);
            fetchRoutes();
        }).catch(err => {
            console.log(`Error in disabling/enabling route id - ${data.id} - ${err}`);
            setIsLoading(false); 
        })
    }

    // Fetch route by ref no and its type - to view in modal and then Navigate to edit route screen in case of editing 
    const fetchRouteByRefAndType = (refNo) => {
        getRouteByRefAndType(refNo, RouteType.DROPOFF).then(resp => {
            const dropoffRoute = resp.data.data;
            console.log(`Show dropoff route - ${JSON.stringify(dropoffRoute)}`);
            if (dropoffRoute && dropoffRoute.id) {
                navigate(`${baseUrl}/route/edit/${resp.data.data.id}`);
            }
            else {
                //alert("Drop Off route is not create yet.");
                setAlert({
                    ...alert,
                    show: true,
                    body: "Drop Off route is not create yet. Please edit route to create one",
                });
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Routes
                    </Typography>
                    <Chip
                        onClick={() => navigate(`../new`)}
                        icon={<AddCircleOutlineIcon />}
                        label="New route"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Ref No</TableCell>
                                        <TableCell>Route No</TableCell>
                                        <TableCell sx={{ width: '40%'}}>Driver</TableCell>
                                        <TableCell>Bus number</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {routes.map((item, index) => (
                                        <TableRow
                                            key={item.ref_no}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <Typography component="h5" variant='h5'>
                                                    {item.ref_no} 
                                                    {!item.is_active &&
                                                        <Chip
                                                            sx={{ ml: 1 }}
                                                            label="Disabled"
                                                            size="small" color="error" variant="outlined" />
                                                    }
                                                </Typography>
                                                <Typography component="h6" variant='caption'>
                                                    {item.route_students.length} Students
                                                </Typography>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.number}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.driver ? item.driver.user.name : ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.bus ? item.bus.number : ''}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onDropOff={() => fetchRouteByRefAndType(item.ref_no)}
                                                    onEdit={() => navigate(`${baseUrl + "/route/edit/" + item.id}`)}
                                                    onDisable={() => handleDisable(item.id, true)}
                                                    onEnable={() => handleDisable(item.id, false)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => { onDelete(data) }}
                onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
            ></CustomModal>
            {/* Disable modal */}
            <CustomModal
                show={disableJson.show}
                data={disableJson.data}
                type={ModalType.CONFIRM_DISABLE}
                onOkay={(data) => { onDisable(data) }}
                onHide={ () => {setDisableJson({...disableJson, show: false})} }
            ></CustomModal>
        </>
    );
  

    // return (
    //     <React.Fragment> 
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <h1 className="h4 mb-0 text-gray-800">Route List</h1>
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 {isLoading &&
    //                     <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />
    //                 }
    //                 <table className="table">
    //                     <thead>
    //                         <tr className="row">
    //                             <th className="col-lg-2">Ref No.</th>
    //                             <th className="col-lg-4">Driver</th>
    //                             <th className="col-lg-3">Bus Number</th>
    //                             <th className="col-lg-3">Action</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {routes.map((item, index) => (
    //                             <tr key={index} className="row">
    //                                 <td className="col-lg-2">{item.ref_no}</td>
    //                                 <td className="col-lg-4">{item.driver ? item.driver.user.name : ''}</td>
    //                                 <td className="col-lg-3">{item.bus ? item.bus.number : ''}</td>
    //                                 <td className="col-lg-3 d-flex justify-content-left">
    //                                     <Link style={{ 'width': 50 }} className="btn btn-link" to={baseUrl + "/route/edit/" + item.id}>
    //                                         <i className="fas fa-edit fa-lg text-primary mr-5"></i>
    //                                     </Link>
    //                                     <Button variant="link" onClick={() => fetchRouteByRefAndType(item.ref_no)}>
    //                                         Drop Off
    //                                 </Button>
    //                                     <Button variant="link" onClick={() => handleDelete(item.id)}>
    //                                         <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                                     </Button>
    //                                 </td>
    //                             </tr>
    //                         ))}
    //                     </tbody>
    //                 </table>
    //             </div>
    //         </div>

    //         {/* For delete confirmation */}
    //         {deleteJson.show &&
    //             <CustomModal 
    //                 show={deleteJson.show}
    //                 data={deleteJson.data}
    //                 type={ModalType.CONFIRM_DELETE}
    //                 onOkay={(data) => {
    //                     onDelete(deleteJson.data);
    //                     setDeleteJson({
    //                         ...deleteJson,
    //                         show: false,
    //                     });
    //                 }}
    //                 onHide={() => {
    //                     setDeleteJson({
    //                         ...deleteJson,
    //                         show: false,
    //                     });
    //                 }}
    //             >
    //             </CustomModal>
    //         }

    //             {/* For alert */}
    //             {alert.show &&
    //             <CustomModal 
    //                 show={alert.show}
    //                 type={ModalType.ALERT}
    //                 body={alert.body}
    //                 onHide={() => {
    //                     setAlert({
    //                         ...alert,
    //                         show: false,
    //                     });
    //                 }}
    //             >
    //             </CustomModal>
    //         }
    //     </React.Fragment> 
    // )
}

// const mapDispatchToProps = dispatch => { 
//     return {}
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
export default RouteList;




// class RouteList extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             routes: [],
//             isLoading: false,
//             delete: {
//                 show: false,
//                 data: {}
//             },
//             alert: {
//                 show: false,
//                 body: '',
//             }
// 		};
//     }

//     componentDidMount() {
//         this.fetchRoutes();
//     }

//     // Fetch routes
//     fetchRoutes = () => {
//         this.setState({isLoading: true});
//         getRoutes().then(resp => {
//             this.setState({routes: resp.data.data});
//             this.setState({isLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isLoading: false});
//         })
//     }

//     // Handle Delete modal   
//     handleDelete = (id) => {
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));
//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of School
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));
//         // Show spinner
//         this.setState({isLoading: true});
//         deleteRoute(data.id).then(resp => {
//             this.setState({isLoading: false});
//             this.fetchRoutes();
//         }).catch(err => {
//             this.setState({isLoading: false});          
//         })
//     }

//     // Fetch route by ref no and its type - to view in modal and then Navigate to edit route screen in case of editing 
//     fetchRouteByRefAndType = (refNo) => {
//         getRouteByRefAndType(refNo, RouteType.DROPOFF).then(resp => {
//             const dropoffRoute = resp.data.data;
//             console.log(`Show dropoff route - ${JSON.stringify(dropoffRoute)}`);
//             if (dropoffRoute && dropoffRoute.id) {
//                 this.props.history.push(`${this.props.baseUrl}/route/edit/${resp.data.data.id}`);
//             }
//             else {
//                 //alert("Drop Off route is not create yet.");
//                 this.setState(ps => ({
//                     alert: {
//                         ...ps.alert,
//                         show: true,
//                         body: "Drop Off route is not create yet. Please edit route to create one",
//                     }
//                 }))
//             }
//         }).catch(err => {
//             console.log(err);
//         })
//     }
    
//     render() {

//         const { routes, isLoading } = this.state;

//         console.log(`show alert modal --- ${this.state.alert.show}`);

// 		return (
//             <React.Fragment> 
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Route List</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         {isLoading &&
//                             <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />
//                         }
//                         <table className="table">
//                             <thead>
//                                 <tr className="row">
//                                     <th className="col-lg-2">Ref No.</th>
//                                     <th className="col-lg-4">Driver</th>
//                                     <th className="col-lg-3">Bus Number</th>
//                                     <th className="col-lg-3">Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {routes.map((item, index) => (
//                                     <tr key={index} className="row">
//                                         <td className="col-lg-2">{item.ref_no}</td>
//                                         <td className="col-lg-4">{item.driver ? item.driver.user.name : ''}</td>
//                                         <td className="col-lg-3">{item.bus ? item.bus.number : ''}</td>
//                                         <td className="col-lg-3 d-flex justify-content-left">
//                                             <Link style={{ 'width': 50 }} className="btn btn-link" to={this.props.baseUrl + "/route/edit/" + item.id}>
//                                                 <i className="fas fa-edit fa-lg text-primary mr-5"></i>
//                                             </Link>
//                                             <Button variant="link" onClick={() => this.fetchRouteByRefAndType(item.ref_no)}>
//                                                 Drop Off
//                                         </Button>
//                                             <Button variant="link" onClick={() => this.handleDelete(item.id)}>
//                                                 <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                             </Button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>

//                 {/* For delete confirmation */}
//                 {this.state.delete.show &&
//                     <CustomModal 
//                         show={this.state.delete.show}
//                         data={this.state.delete.data}
//                         type={ModalType.CONFIRM_DELETE}
//                         onOkay={(data) => {
//                             this.onDelete(this.state.delete.data);
//                             this.setState(ps => ({
//                                 delete: {
//                                     ...ps.delete,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                         onHide={() => {
//                             this.setState(ps => ({
//                                 delete: {
//                                     ...ps.delete,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                     >
//                     </CustomModal>
//                 }

//                  {/* For alert */}
//                  {this.state.alert.show &&
//                     <CustomModal 
//                         show={this.state.alert.show}
//                         type={ModalType.ALERT}
//                         body={this.state.alert.body}
//                         onHide={() => {
//                             this.setState(ps => ({
//                                 alert: {
//                                     ...ps.alert,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                     >
//                     </CustomModal>
//                 }
//             </React.Fragment> 
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {}
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(RouteList);