import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Chip, Box } from '@mui/material';

import CommonUserOtpVerification from '../../../common/user-otp-verification';
import { getAdmin } from '../../../services/http.service';

const AdminVerify = () => {

    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [admin, setAdmin] = useState(null);

    const { adminId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchAdmin(adminId);
    }, []);

    // Fetch admin detail for edit
    const fetchAdmin = (adminId) => {
        setIsLoading(true);
        getAdmin(adminId).then(resp => {
            console.log(`Fetch admin - ${JSON.stringify(resp)}`);
            setAdmin(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    return (
        <>
             <Chip 
                icon={<ArrowBackIosIcon />} 
                label="Back" 
                variant="outlined" 
                size="small"
                onClick={() => navigate(-1)}
            />
            { admin &&
                <Box sx={{ width: '50%'}}>
                    <CommonUserOtpVerification user={admin.user}  />
                </Box>
            }
        </>
    )
}

export default AdminVerify