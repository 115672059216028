import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Chip } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../common/snackbars';
import CommonProfilePhoto from '../../../common/profile-photo';
import { IMAGES_URL } from '../../../utils/constants';
import { uploadAdminProfilePhoto } from '../../../services/http.service';

import { getAdmin } from '../../../services/http.service';

const AdminPhoto = () => {

    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [admin, setAdmin] = useState(null);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
    
    const { adminId } = useParams();
    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAdmin(adminId);
    }, []);

    useEffect(() => {
        if (admin) {
            const url = admin.user?.profile_photo ? `${IMAGES_URL.USER}/${admin.user.profile_photo}` : `${IMAGES_URL.USER}/default_profile.png`
            setProfilePhotoUrl(url);
        }
    }, [admin]);

    // Fetch admin detail for edit
    const fetchAdmin = (adminId) => {
        setIsLoading(true);
        getAdmin(adminId).then(resp => {
            setIsLoading(false);
            setErrorMsg(null);
            console.log(`Fetch admin - ${JSON.stringify(resp)}`);
            setAdmin(resp.data.data);
            //setAdminDetail(resp.data.data);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
            setErrorMsg(err);
        })
    }

    // update photo
    const upload = (image) => {
        console.log(`Uploading profile photo - ${JSON.stringify(image)}`);

        // Show error if image is not selected
        if (!image) {
            setIsSaving(false);
            setErrorMsg("Please select image to upload");
            setShowSuccessAlert(false);
            return;
        }
        // Reset flags
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        uploadAdminProfilePhoto(admin.id, image).then(resp => {
            console.log(`Uploaded successfully`);
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const newAdmin = {
                ...admin,
                user: {
                    ...admin.user,
                    profile_photo: resp.data.filename
                }
            }
            console.log("new admin - ", newAdmin);
            //setProfilePhotoUrl(`${IMAGES_URL.USER}/${resp.data.filename}`);
            setAdmin(newAdmin);
        }).catch(err => {
            console.error(err);
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    return (
        <>
           { errorMsg && 
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert && 
                <SuccessAlertTopRight
                    successMsg={'Uploaded successfully'}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
            { isSaving && 
                <CircularProgress />
            }
            <Box  
                sx={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "start",
                    alignItems: "start"
                }}
            >
                <Chip 
                    icon={<ArrowBackIosIcon />} 
                    label="Back" 
                    variant="outlined" 
                    size="small"
                    onClick={() => navigate(-1)}
                    sx={{ mb: 2 }}
                />
                {admin &&
                    <CommonProfilePhoto
                        profilePhotoUrl={profilePhotoUrl}
                        altName={admin.name}
                        onSubmit={upload}
                    />
                }
            </Box>
        </>
    );
}


export default AdminPhoto;