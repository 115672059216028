import React from 'react';
import { Outlet, Link } from "react-router-dom";

const Class = () => {
    return (
        <>
            <Outlet />
        </>
    );
    // return (
    //     <React.Fragment>
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <div className="h4 mb-0 text-gray-800">School classes</div>
    //             <div class="mx-3">
    //                 <Link type="button" class="btn btn-info btn-sm ml-3" to="list">Classes</Link>
    //                 <Link type="button" class="btn btn-info btn-sm ml-3" to="new">New class</Link>
    //             </div>
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 <Outlet />
    //             </div>
    //         </div>
    //     </React.Fragment>
    // );
}

export default Class;