import React, { Component, useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ImageIcon from '@mui/icons-material/Image';
import RouteIcon from '@mui/icons-material/Route';
import HideSourceIcon from '@mui/icons-material/HideSource';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChatIcon from '@mui/icons-material/Chat';
import HikingIcon from '@mui/icons-material/Hiking';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';

export const VertActionMenu = ({ 
  onView, 
  onEdit, 
  onDelete,
  onVerify,
  onPhoto,
  onDropOff,
  onDisable,
  onEnable,
  isDisabled,
  onChat,
  onViewLocation,
  onGetOnBus,
  onGetOffBus,
  onAbsent,

  chatText,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleView = () => {
        handleClose();
        onView();
    }

    const handleEdit = () => {
        handleClose();
        onEdit();
    }

    const handleDelete = () => {
        handleClose();
        onDelete();
    }

    const handleVerify = () => {
      handleClose();
      onVerify();
    }

    const handlePhoto = () => {
      handleClose();
      onPhoto();
    }

    const handleDropOff = () => {
      handleClose();
      onDropOff();
    }

  const handleDisable = () => {
    handleClose();
    onDisable();
  }

  const handleEnable = () => {
    handleClose();
    onEnable();
  }

  const handleChat = () => {
    handleClose();
    onChat();
  }

  const handleViewLocation = () => {
    handleClose();
    onViewLocation();
  }

  const handleGetOnBus = () => {
    handleClose();
    onGetOnBus();
  }

  const handleGetOffBus = () => {
    handleClose();
    onGetOffBus();
  }

  const handleAbsent = () => {
    handleClose();
    onAbsent();
  }

  return (
    <>
      <IconButton aria-label="delete" color="primary"
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        { onView &&
          <MenuItem onClick={handleView}>
            <ListItemIcon><PreviewIcon color="secondary" /></ListItemIcon>
            <ListItemText>View</ListItemText>
          </MenuItem>
        }
        { onVerify &&
          <MenuItem onClick={handleVerify}>
            <ListItemIcon><HowToRegIcon color="primary" /></ListItemIcon>
            <ListItemText>Verify</ListItemText>
          </MenuItem>
        }
        { onPhoto &&
          <MenuItem onClick={handlePhoto}>
            <ListItemIcon><ImageIcon color="primary" /></ListItemIcon>
            <ListItemText>Photo</ListItemText>
          </MenuItem>
        }
        { onDropOff &&
          <MenuItem onClick={handleDropOff}>
            <ListItemIcon><RouteIcon color="primary" /></ListItemIcon>
            <ListItemText>Show Drop-off</ListItemText>
          </MenuItem>
        }
        { onEdit &&
          <MenuItem onClick={handleEdit}>
            <ListItemIcon><EditIcon color="primary" /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        }
        { !isDisabled && onDisable &&
          <MenuItem onClick={handleDisable}>
            <ListItemIcon><HideSourceIcon color="error" /></ListItemIcon>
            <ListItemText>Disable</ListItemText>
          </MenuItem>
        }
          { isDisabled && onEnable &&
          <MenuItem onClick={handleEnable}>
            <ListItemIcon><CheckCircleIcon color="success" /></ListItemIcon>
            <ListItemText>Enable</ListItemText>
          </MenuItem>
        }
        { onDelete &&
          <MenuItem onClick={handleDelete}>
            <ListItemIcon><DeleteIcon color="error" /></ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        }
        { onChat &&
          <MenuItem onClick={handleChat}>
            <ListItemIcon><ChatIcon color="success" /></ListItemIcon>
            <ListItemText>{chatText}</ListItemText>
          </MenuItem>
        }
        { onViewLocation &&
          <MenuItem onClick={handleViewLocation}>
            <ListItemIcon><LocationOnIcon /></ListItemIcon>
            <ListItemText>View location</ListItemText>
          </MenuItem>
        }
        { onGetOnBus &&
          <MenuItem onClick={handleGetOnBus}>
            <ListItemIcon><HikingIcon /></ListItemIcon>
            <ListItemText>Get on the bus</ListItemText>
          </MenuItem>
        }
        { onGetOffBus &&
          <MenuItem onClick={handleGetOffBus}>
            <ListItemIcon><DownhillSkiingIcon /></ListItemIcon>
            <ListItemText>Get off the bus</ListItemText>
          </MenuItem>
        }
        { onAbsent &&
          <MenuItem onClick={handleAbsent}>
            <ListItemIcon><DownhillSkiingIcon /></ListItemIcon>
            <ListItemText>Absent</ListItemText>
          </MenuItem>
        }
      </Menu>

    </>
  );
}