import React, { Component, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Box, Typography, Grid, Link as MuiLink, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link, useNavigate } from "react-router-dom";

import { CustomModal } from '../../../common/modals';
import Loader from '../../../common/loader';
import { schools, deleteSchool } from '../../../services/http.service' 
import { convertRole } from '../../../utils/constants';
import { openModal } from '../../../actions/modals';
import { ModalTypes, ModalType, SCHOOL } from '../../../utils/constants';
import { ErrorAlertTopRight } from '../../../common/snackbars';
import MainCard from '../../../common/cards/main-card';


const SchoolList = () => {

    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [schoolUsers, setSchoolUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showContent, setShowContent] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // run on compnonent init
    useEffect(() => {
        // remove school id stored in the cache
        SCHOOL.id = null;
        setShowContent(false);
        fetchSchools();
    }, []); // runs once similar to componentDidMount

    // Handle Delete modal   
    const handleDelete = (id) => {
        console.log('handle delete - ' + id);
        setDeleteJson({
            ...deleteJson, 
            show: true,
            data: {
                id: id
            }
        });

        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson, 
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteSchool(data.id).then(resp => {
            setIsLoading(false);
            fetchSchools();
        }).catch(err => {
            setIsLoading(false);
            //this.props.onError(err); // TODO convert to v6
            dispatch(openModal(ModalTypes.ERROR, err, null, null));
            //console.log(err);
        })
    }

    // Fetch Schools
    const fetchSchools = () => {
        setIsLoading(true);
        schools().then(resp => {
            console.log(resp);
            setSchoolUsers(resp.data.school_users);
            setIsLoading(false);
            setShowContent(true);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
            setErrorMsg(err);
        })
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                {isLoading &&
                    <Loader />
                }
                {errorMsg &&
                    <ErrorAlertTopRight 
                        errorMsg={errorMsg}
                        onClose={() => setErrorMsg(null)} 
                    />
                }
                { showContent &&
                    <SchoolListContent 
                        schoolUsers={schoolUsers} 
                        navigate={navigate} 
                        handleDelete={handleDelete} 
                    />
                }
                
            </Box>
            {/* Delete modal */}
            <CustomModal 
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => {onDelete(data)}}
                onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
            >
            </CustomModal>
        </>
    );
}

export default (SchoolList);

const SchoolListContent = ({ schoolUsers, navigate, handleDelete }) => {
    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography component="h2" variant="header">
                    Your Schools
                </Typography>
                <Chip
                    onClick={() => navigate(`/school/new`)}
                    icon={<AddCircleOutlineIcon />}
                    label="Create new"
                    variant="outlined"
                    color="secondary"
                />
            </Box>
            {(!schoolUsers || schoolUsers.length === 0) &&
                <Box sx={{
                    mt: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                }}>
                    <Typography component="h2" variant="info" color="secondary">
                        You have no schools created yet. Please <a href="/school/new">create</a>.
                    </Typography>
                </Box>
            }
            <Box sx={{ mt: 2, width: "100%" }}>
                {(schoolUsers && schoolUsers.length > 0) &&
                    <>
                        {schoolUsers.map((item, index) => (
                            <MainCard sx={{ my: 3 }}>
                                <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Link to={"/school/" + item.school.id + "/admin/trip/today/list"}>
                                                <Typography component="h4" variant="h4" color="secondary">
                                                    {item.school.name}
                                                </Typography>
                                            </Link>
                                            <Typography component="span" variant="subtitle2">
                                                {item.school.address}
                                            </Typography>
                                            <Typography component="span" variant="subtitle2">
                                                ID number:
                                                <Typography component="span" variant="subtitle2" sx={{ ml: 1, fontWeight: 'bold' }}>
                                                    {item.school.unique_number}
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item justifyContent="end">
                                        <MuiLink component="button" underline="none" onClick={() => navigate("/school/edit/" + item.school.id)}>
                                            <EditIcon sx={{ color: 'primary.main' }} />
                                        </MuiLink>
                                        <MuiLink component="button" underline="none" onClick={() => handleDelete(item.school.id)}>
                                            <DeleteIcon sx={{ ml: 3, color: 'error.light' }} />
                                        </MuiLink>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        ))}
                    </>
                }
            </Box>
        </>
    );
}

    // return (   
    //     <React.Fragment>
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <h1 className="h4 mb-0 text-gray-800">Schools</h1>
    //         </div>
    //         {isLoading &&
    //             <Row>
    //                 <Col className="d-flex justify-content-center">
    //                     <Spinner animation="border" variant="primary" />
    //                 </Col>
    //             </Row>
    //         }
    //         {errorMsg &&
    //             <div className="d-flex align-items-center justify-content-center mb-3">
    //                 <Alert className="ml-3 mb-0" variant="danger">
    //                     {errorMsg}
    //                 </Alert>
    //             </div>
    //         }

    //         {(!schoolUsers || schoolUsers.length === 0) &&
    //             <React.Fragment>
    //                 <div className="d-flex align-items-center justify-content-center mb-3">
    //                     <h5>You have no schools created yet. Please <a href="/school/new">create</a>. </h5>
    //                 </div>
    //             </React.Fragment>
    //         }

    //         {(schoolUsers && schoolUsers.length > 0) &&
    //             <React.Fragment>
    //                 {schoolUsers.map((item, index) => (
    //                     <div class="card border-left-primary shadow h-100 py-2 my-2">
    //                         <div class="card-body">
    //                             <div class="row no-gutters align-items-center">
    //                                 <div class="col mr-2">
    //                                     <div class="font-weight-bold text-primary text-uppercase mb-1">{item.school.name}</div>
    //                                     <div class="small mb-0 text-gray-800">{item.school.address}</div>
    //                                     <div className="d-flex">
    //                                         <div class="small mb-0 text-gray-800">ID Number:</div>
    //                                         <div class="ml-2 small mb-0 text-gray-800 font-weight-bold">{item.school.unique_number}</div>
    //                                     </div>
                                        
    //                                 </div>
    //                                 <div className="col-auto row">                                         
    //                                     <Link className="col-md-4 d-flex justify-content-end my-2" to={"/school/" + item.school.id + "/admin/trip/today/list"} data-toggle="tooltip" title="<h6>edit</h6>" data-html="true" data-placement="top">
    //                                         <i className="fas fa-eye fa-lg text-primary"></i>
    //                                     </Link>
    //                                     <Link className="col-md-4 d-flex justify-content-end my-2" to={"/school/edit/" + item.school.id} data-toggle="tooltip" title="<h6>edit</h6>" data-html="true" data-placement="top">
    //                                         <i className="fas fa-edit fa-lg text-primary"></i>
    //                                     </Link>                                    
    //                                     <a className="col-md-4 d-flex justify-content-end my-2" onClick={() => handleDelete(item.school.id)} href="#" data-toggle="tooltip" title="<h6>delete</h6>" data-html="true" data-placement="top">
    //                                         <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                                     </a>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 ))}
    //             </React.Fragment>
    //         }
            

    //         {/* Delete modal */}
    //         <CustomModal 
    //             show={deleteJson.show}
    //             data={deleteJson.data}
    //             type={ModalType.CONFIRM_DELETE}
    //             onOkay={(data) => {onDelete(data)}}
    //             onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
    //         >
    //         </CustomModal>
    //     </React.Fragment>
    // )


// const mapDispatchToProps = dispatch => { 
//     return {
//         onError: (err) => {
//             console.log("onError..");
//             dispatch(openModal(ModalTypes.ERROR, err, null, null));
//         }
//     }
// }

// const mapStateToProps = state => {
//     return {
        
//     }
// }

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(SchoolList);



// class SchoolList extends Component {
//     constructor(props) {
// 		super(props)
// 		this.state = {
//             delete: {
//                 show: false,
//                 data: {}
//             },
//             schoolUsers: null,
//             isLoading: false,
//             errorMsg: null,
//         };
//     }

//     // On mount of component
//     componentDidMount() {
//         this.fetchSchools();
//     }

//     // Handle Delete modal   
//     handleDelete = (id) => {
//         console.log('handle delete - ' + id);
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));

//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of School
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));
//         // Show spinner
//         this.setState({isLoading: true});
//         deleteSchool(data.id).then(resp => {
//             this.setState({isLoading: false});
//             this.fetchSchools();
//         }).catch(err => {
//             this.setState({isLoading: false});
//             this.props.onError(err);
//             //console.log(err);
//         })
//     }

//     // Fetch Schools
//     fetchSchools = () => {
//         this.setState({isLoading: true});
//         schools().then(resp => {
//             console.log(resp);
//             this.setState({schoolUsers: resp.data.school_users});
//             this.setState({isLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isLoading: false});
//             this.setState({errorMsg: err});
//         })
//     }

//     render() {

//         const { schoolUsers, isLoading, errorMsg } = this.state;

//         return (   
//             <React.Fragment>
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Schools</h1>
//                 </div>
//                 {isLoading &&
//                     <Row>
//                         <Col className="d-flex justify-content-center">
//                             <Spinner animation="border" variant="primary" />
//                         </Col>
//                     </Row>
//                 }
//                 {errorMsg &&
//                     <div className="d-flex align-items-center justify-content-center mb-3">
//                         <Alert className="ml-3 mb-0" variant="danger">
//                             {this.state.errorMsg}
//                         </Alert>
//                     </div>
//                 }

//                 {(!schoolUsers || schoolUsers.length === 0) &&
//                     <React.Fragment>
//                         <div className="d-flex align-items-center justify-content-center mb-3">
//                             <h5>You have no schools created yet. Please <a href="/school/new">create</a>. </h5>
//                         </div>
//                     </React.Fragment>
//                 }

//                 {(schoolUsers && schoolUsers.length > 0) &&
//                     <React.Fragment>
//                         {/* <Container fluid="true" className="bg-primary">
//                             <Row className="py-3 mb-4 task-border align-items-center">                    
//                                 <Col lg="4" className="d-flex justify-content-left text-white font-weight-bold">
//                                     Name
//                                 </Col>
//                                 <Col lg="6" className="d-flex justify-content-left text-white font-weight-bold">
//                                     Address
//                                 </Col>
//                                 <Col lg="2" className="d-flex justify-content-center text-white font-weight-bold">
//                                 </Col>
//                             </Row>                    
//                         </Container> */}
//                         {schoolUsers.map((item, index) => (
//                             // <Container fluid="true" key={index} className="bg-white">
//                             //     <Row className="py-3 mb-4 task-border align-items-center">                    
//                             //         <Col lg="4" className="d-flex justify-content-left text-wrap">
//                             //             {item.school.name}
//                             //         </Col>
//                             //         <Col lg="6" className="d-flex justify-content-left text-wrap">
//                             //             {item.school.address}
//                             //         </Col>
//                             //         {/* <Col lg="2" className="d-flex justify-content-left">
//                             //             {convertRole(item.role.name)}
//                             //         </Col> */}
//                             //         <Col lg="2" className="d-flex justify-content-between p-4">
//                             //             <Link to={"/school/" + item.school.id + "/admin/user/new"} data-toggle="tooltip" title="<h6>edit</h6>" data-html="true" data-placement="top">
//                             //                 <i className="fas fa-eye fa-lg text-success mr-2"></i></Link>
//                             //             <Link to={"/school/edit/" + item.school.id} data-toggle="tooltip" title="<h6>edit</h6>" data-html="true" data-placement="top"><i className="fas fa-edit fa-lg text-success mr-2"></i></Link>                                    
//                             //             <a onClick={() => this.handleDelete(item.school.id)} href="#" data-toggle="tooltip" title="<h6>delete</h6>" data-html="true" data-placement="top"><i className="fas fa-trash-alt fa-lg text-danger"></i></a>
//                             //         </Col>
//                             //     </Row>                    
//                             // </Container>
//                             <div class="card border-left-primary shadow h-100 py-2 my-2">
//                                 <div class="card-body">
//                                     <div class="row no-gutters align-items-center">
//                                         <div class="col mr-2">
//                                             <div class="font-weight-bold text-primary text-uppercase mb-1">{item.school.name}</div>
//                                             <div class="small mb-0 text-gray-800">{item.school.address}</div>
//                                         </div>
//                                         <div className="col-auto row">                                         
//                                             <Link className="col-md-4 d-flex justify-content-end my-2" to={"/school/" + item.school.id + "/admin/trip/today/list"} data-toggle="tooltip" title="<h6>edit</h6>" data-html="true" data-placement="top">
//                                                 <i className="fas fa-eye fa-lg text-primary"></i>
//                                             </Link>
//                                             <Link className="col-md-4 d-flex justify-content-end my-2" to={"/school/edit/" + item.school.id} data-toggle="tooltip" title="<h6>edit</h6>" data-html="true" data-placement="top">
//                                                 <i className="fas fa-edit fa-lg text-primary"></i>
//                                             </Link>                                    
//                                             <a className="col-md-4 d-flex justify-content-end my-2" onClick={() => this.handleDelete(item.school.id)} href="#" data-toggle="tooltip" title="<h6>delete</h6>" data-html="true" data-placement="top">
//                                                 <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                             </a>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </React.Fragment>
//                 }
                

//                 {/* Delete modal */}
//                 <CustomModal 
//                     show={this.state.delete.show}
//                     data={this.state.delete.data}
//                     onOkay={(data) => {this.onDelete(data)}}>
//                 </CustomModal>
//             </React.Fragment>
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {
//         onError: (err) => {
//             console.log("onError..");
//             dispatch(openModal(ModalTypes.ERROR, err, null, null));
//         }
//     }
// }

// const mapStateToProps = state => {
//     return {
        
//     }
// }

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(SchoolList);