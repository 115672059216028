import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Chip, Button, Grid, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { IMAGES_URL, ImageSourceType } from '../utils/constants';

const Input = styled('input')({
    display: 'none',
});

const CommonProfilePhoto = ({ profilePhotoUrl, altName, onSubmit, onError }) => {
    const [src, setSrc] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState({
        url: null,
        type: null,
    });
    const [crop, setCrop] = useState({
        unit: "%",
        width: 50,
        height: 50,
        aspect: 16 / 16             
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [image, setImage] = useState(null);

    const imageRef = useRef();
    let fileUrl = null;

    // init with remote photo on first load
    useEffect(() => {
        setProfilePhoto({
            ...profilePhoto,
            url: profilePhotoUrl,
            type: ImageSourceType.REMOTE,
        });
        // reset on update pf profile photo url
        setImage(null);
        setCroppedImageUrl(null);
        setSrc(null);
    }, [profilePhotoUrl]);

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                console.log(`Selected file Src - ${reader.result}`);
                setSrc(reader.result);
            });            
            reader.readAsDataURL(e.target.files[0]);
            // Do not show image - already loaded from server if user wants to select new one
            setProfilePhoto({
                ...profilePhoto,
                url: null,
                type: ImageSourceType.LOCAL,
            });
        }
    };
    // If you setState the crop in here you should return false.
    const onImageLoaded = image => {
        console.log(`onImageLoaded - image - ${image}`);
        imageRef.current = image;
    };

    const onCropComplete = crop => {
        console.log(`onCropComplete - crop - ${JSON.stringify(crop)}`);
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        console.log(`onCropChange`);
        // You could also use percentCrop:
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef.current,
                crop,
                "newFile.jpeg"
            );
            console.log(`Cropped image url - ${croppedImageUrl}`);
            setCroppedImageUrl(croppedImageUrl);
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                console.log(`setting file image to upload - ${JSON.stringify(blob)}`);
                setImage(blob);
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, "image/jpeg");
        });
    }

    // Handle Cancel
    const handleCancel = () => {
        setProfilePhoto({
            ...profilePhoto,
            url: profilePhotoUrl,
            type: profilePhotoUrl ? ImageSourceType.REMOTE : null,
        });
        setImage(null);
        setCroppedImageUrl(null);
        setSrc(null);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(`Uploading profile photo - ${JSON.stringify(image)}`);
        onSubmit(image);
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate>
                <label htmlFor="contained-button-file">
                    <Input 
                        accept="image/*" 
                        id="contained-button-file" 
                        multiple type="file" 
                        onChange={onSelectFile}
                        onClick={(event)=> { 
                            event.target.value = null
                        }}
                    />
                    <Button variant="outlined" component="span">
                        Choose photo
                    </Button>
                </label>
                <Grid container spacing={2} sx={{ mt: 3, }}>
                    <Grid item xs={12} md={6}>
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={onImageLoaded}
                                onComplete={onCropComplete}
                                onChange={onCropChange}
                                style={{ maxWidth: "100%", height: "100%" }}
                            />
                        )}
                    </Grid>
                    {croppedImageUrl && (
                        <Grid item xs={12} md={6}>
                            <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
                        </Grid>
                    )}
                </Grid>
                <Box sx={{ mt: 5, }}>
                    {profilePhoto.type === ImageSourceType.LOCAL &&
                        <Box sx={{ display: 'flex' }}>
                            <Button variant="contained" type="submit" sx={{ mt: 2 }}>Upload</Button>
                            <Button variant="outlined" sx={{ mt: 2, ml: 3 }} onClick={handleCancel}>Cancel</Button>
                        </Box>
                    }
                </Box>
                {profilePhoto.type === ImageSourceType.REMOTE &&
                    <Box sx={{ mt: 3 }}>
                        <CardMedia
                            component="img"
                            image={profilePhoto.url}
                            alt={altName}
                        />
                    </Box>
                } 
            </Box>
        </>
    );
}

export default CommonProfilePhoto;