import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const AlertSnackbar = ({ errorMsg, successMsg, onClose }) => {
    let content = "<></>";
    if (errorMsg) {
        content = <ErrorAlertTopCenter errorMsg={errorMsg} onClose={onClose} />;
    } else if (successMsg) {
        content = <SuccessAlertTopRight successMsg={successMsg} onClose={onClose} />;
    }
    return (content);
}

export const ErrorAlertTopCenter = ({ errorMsg, onClose }) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={errorMsg}
            autoHideDuration={6000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
                {errorMsg}
            </Alert>
        </Snackbar>
    );
}

export const ErrorAlertTopRight = ({ errorMsg, onClose }) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={errorMsg}
            autoHideDuration={6000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
                {errorMsg}
            </Alert>
        </Snackbar>
    );
}

export const SuccessAlertTopRight = ({ successMsg, onClose }) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={successMsg}
            autoHideDuration={6000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
                {successMsg}
            </Alert>
        </Snackbar>
    );
}