import React, { Component, useState, useEffect, useRef } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Accordion, AccordionSummary, AccordionDetails, TextField, Paper, Grid, TableBody, IconButton, 
    FormControl, InputLabel, Select, MenuItem, Tabs, TableFooter, TablePagination,
    Tab } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux'
import HistoryIcon from '@mui/icons-material/History';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { getRoutesWithDistinctRef, getHistoryTrips, getBuses, getDrivers } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';

import { formatDateTime, convertTripStatus, TripStatus, convertRouteType } from '../../../../utils/constants';
import ListTab from './list-tab';
import CustomPagination from '../../../../utils/pagination';
import Loader from '../../../../common/loader';
import CustomPaginationActions from '../../../../utils/pagination';
import { VertActionMenu } from '../../../../common/action-menu';


const HistoryList = () => {

    const [trips, setTrips] = useState([]);
    const [buses, setBuses] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [filter, setFilter] = useState({
        date: null,
        routeRef: null,
        tripStatus: null,
        busId: null,
        driverId: null,
    });
    const [routes, setRoutes] = useState([]);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    let [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const didMount = useRef(true);

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();


    useEffect(() => {
        fetchRoutes(); 
        fetchBuses();
        fetchDrivers();
        showTrips();
    }, []);

    // Fetch Routes with only Ref and type
    const fetchRoutes = () => {
        getRoutesWithDistinctRef().then(resp => {
            const respRoutes = resp.data.data;
            const routes = respRoutes;
            setRoutes(routes);            
        }).catch(err => {
            console.log(err);
        })
    }

    // Fetch all buses of school
    const fetchBuses = () => {
        getBuses().then(resp => {
            setBuses(resp.data.data);
        }).catch(err => {
            console.log(err);
        })
    }

    // Fetch all drivers of school
    const fetchDrivers = () => {
        getDrivers().then(resp => {
            setDrivers(resp.data.data);
        }).catch(err => {
            console.log(err);
        })
    }

    // Fetch filters based on filter criteria
    const showTrips = () => {
        console.log(`show trips filter - ${JSON.stringify(filter)}`);
        setIsLoading(true);
        setErrorMsg(null);
        getHistoryTrips(filter, page, rowsPerPage).then(resp => {
            const respData = resp.data.data;
            setIsLoading(false);
            setErrorMsg(null);
            setTrips(respData.trips);
            setTotalCount(respData.total_count);
        }).catch(err => {
            setIsLoading(false);
            setErrorMsg(err);
        })
    }

    // ******* Pagination methods *****************


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        // avoid first render
        if (didMount.current) {
            didMount.current = false;
            return;
        }
        showTrips();
    }, [page, rowsPerPage]);


    // const onFirst = () => {
    //     console.log(`onFirst`);
    //     page = 0;
    //     setPage(page);
    //     showTrips();   
    // }

    // const onPrevious = () => {
    //     console.log(`onPrevious`);
    //     if (page > 0) {
    //         page -= 1;
    //         setPage(page);
    //         showTrips();
    //     }
    // }

    // const onNext = () => {
    //     console.log(`onNext`);
    //     console.log(`cal count - ${(page * limit)}`)
    //     // check if records on next page is less than the total record count i.e (page + 1)
    //     if (((page + 1) * limit) < totalCount) {
    //         page += 1;
    //         setPage(page);
    //         showTrips();
    //     }
    // }

    // const onLast = () => {
    //     console.log(`onLast`);
    //     // Calculate last page number
    //     // const { page, totalCount, limit } = this.state;
    //     const newPage = Math.floor(totalCount/limit);
    //     console.log(`new page - ${newPage}`);  
    //     page = newPage;
    //     setPage(page);   
    //     showTrips();   
    // }
    // ******* End Pagination *****************


    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Trip History
                    </Typography>
                    <Box>
                        <Chip
                            onClick={() => navigate(`../todays/list`)}
                            sx={{ mr: 3 }}
                            icon={<HistoryIcon />}
                            label="Todays History"
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>
                <Accordion sx={{ mt: 2, width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Filter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <MobileDatePicker
                                    label="Date"
                                    value={filter.date}
                                    inputFormat="MM/dd/yyyy"
                                    onChange={(date) => {
                                        setFilter({
                                            ...filter,
                                            date: date,
                                        });
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="route-select">Route number/ref</InputLabel>
                                    <Select
                                        labelId="route-select"
                                        id="route-select"
                                        label="Route ref/number"
                                        onChange={e => {
                                            const refNo = e.target.value;
                                            if (refNo) {
                                                setFilter({
                                                    ...filter,
                                                    routeRef: refNo
                                                });
                                            }
                                        }}
                                        value={filter.routeRef}
                                    >
                                        {routes.map((item, index) => (
                                            <MenuItem key={index} value={item.ref_no}>{item.number} ({item.ref_no})</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="status-select">Trip Status</InputLabel>
                                    <Select
                                        labelId="status-select"
                                        id="status-select"
                                        label="Trip Status"
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (value) {
                                                setFilter({
                                                    ...filter,
                                                    tripStatus: value
                                                });
                                            }
                                        }}
                                        value={filter.tripStatus}
                                    >
                                        {[TripStatus.NEW, TripStatus.FINISHED, TripStatus.ABANDONED].map((item, index) => (
                                            <MenuItem key={index} value={item}>{convertTripStatus(item)}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="bus-select">Trip Bus</InputLabel>
                                    <Select
                                        labelId="bus-select"
                                        id="bus-select"
                                        label="Trip Bus"
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (value) {
                                                setFilter({
                                                    ...filter,
                                                    busId: value
                                                });
                                            }
                                        }}
                                        value={filter.busId}
                                    >
                                        {buses.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.number}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="driver-select">Bus Driver</InputLabel>
                                    <Select
                                        labelId="driver-select"
                                        id="driver-select"
                                        label="Bus Driver"
                                        onChange={e => {
                                            const value = e.target.value;
                                            if (value) {
                                                setFilter({
                                                    ...filter,
                                                    driverId: value
                                                });
                                            }
                                        }}
                                        value={filter.driverId}
                                    >
                                        {drivers.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.user.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {!isLoading &&
                                <Button
                                    type="button"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() => {
                                        page = 0;
                                        setPage(page);
                                        showTrips();
                                    }}
                                >
                                    Filter trips
                                </Button>
                            }
                        </Box>
                    </AccordionDetails>
                </Accordion>
                { isLoading &&
                    <Loader />
                }
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Ref No.</TableCell>
                                        <TableCell>Route No.</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Expected Start Time</TableCell>
                                        <TableCell>Actual Start Time</TableCell>
                                        <TableCell>Bus</TableCell>
                                        <TableCell>Driver</TableCell>
                                        {filter.tripStatus === TripStatus.NEW &&
                                            <TableCell align="right">Action</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trips.map((item, index) => (
                                        <TableRow
                                            key={item.name}
                                        >
                                            <TableCell align="left">{page * rowsPerPage + (index + 1)}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.ref_no}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.route.number}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {convertRouteType(item.route.type.name)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {formatDateTime(item.expected_start_time)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {formatDateTime(item.actual_start_time)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.bus ? item.bus.number : ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.driver ? item.driver.user.name : ''}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onView={() => navigate(`${baseUrl}/trip/${item.id}/detail-view`)}
                                                />
                                            </TableCell>
                                            
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={3}
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={CustomPaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </>
    );

   
    // return (
    //     <React.Fragment>
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <h1 className="h4 mb-0 text-gray-800">History List</h1>
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-header">Filter</div>
    //             <div className="card-body">
    //                 <Form>
    //                     <Row>
    //                         <Col lg="3">
    //                             <Form.Group>
    //                                 <Form.Label>Date</Form.Label>
    //                                 <div >
    //                                     <DatePicker className="form-control"
    //                                         selected={filter.date}
    //                                         onChange={(date) => {
    //                                             setFilter({
    //                                                 ...filter,
    //                                                 date: date,
    //                                             });
    //                                         }}
    //                                         dateFormat="MMMM d, yyyy"
    //                                         placeholderText="Select Date"
    //                                     />
    //                                 </div>
    //                             </Form.Group>
    //                         </Col>
    //                         <Col lg="3">
    //                             <Form.Group column lg="3">
    //                                 <Form.Label>Route</Form.Label>
    //                                 <Form.Control as="select" onChange={(e) => {
    //                                     const value = e.target.value;
    //                                     console.log(`Select route - ${value}`);
    //                                     let refNo = "";
    //                                     if (value && value.trim().length > 0) {
    //                                         const route = JSON.parse(value);
    //                                         refNo = route.ref_no;
    //                                     }
    //                                     setFilter({
    //                                         ...filter,
    //                                         routeRef: refNo
    //                                     });
    //                                 }}>
    //                                     <option value="">Select</option>
    //                                     {routes.map((item, index) => (
    //                                         <option key={index} value={JSON.stringify(item)}
    //                                             selected={false}>{item.ref_no}</option>
    //                                     ))}
    //                                 </Form.Control>
    //                             </Form.Group>
    //                         </Col>
    //                         <Col lg="3">
    //                             <Form.Group column lg="3">
    //                                 <Form.Label>Trip Status</Form.Label>
    //                                 <Form.Control as="select" onChange={(e) => {
    //                                     const value = e.target.value;
    //                                     console.log(`Select Trip status - ${value}`);
    //                                     setFilter({
    //                                         ...filter,
    //                                         tripStatus: value,
    //                                     });
    //                                 }}>
    //                                     <option value="">Select</option>
    //                                     {[TripStatus.NEW, TripStatus.FINISHED, TripStatus.ABANDONED].map((item, index) => (
    //                                         <option key={index} value={item}
    //                                             selected={false}>{convertTripStatus(item)}</option>
    //                                     ))}
    //                                 </Form.Control>
    //                             </Form.Group>
    //                         </Col>
    //                     </Row>
    //                     <Row>
    //                         <Col lg="3">
    //                             <Form.Group column lg="3">
    //                                 <Form.Label>Bus Number</Form.Label>
    //                                 <Form.Control as="select" onChange={(e) => {
    //                                     const bus = JSON.parse(e.target.value);
    //                                     setFilter({
    //                                         ...filter,
    //                                         busId: bus.id,
    //                                     });
    //                                 }}>
    //                                     <option value="">Select</option>
    //                                     {buses.map((item, index) => (
    //                                         <option key={index} value={JSON.stringify(item)}
    //                                             selected={false}>{item.number}</option>
    //                                     ))}
    //                                 </Form.Control>
    //                             </Form.Group>
    //                         </Col>
    //                         <Col lg="3">
    //                             <Form.Group column lg="3">
    //                                 <Form.Label>Driver</Form.Label>
    //                                 <Form.Control as="select" onChange={(e) => {
    //                                     const driver = JSON.parse(e.target.value);
    //                                     setFilter({
    //                                         ...filter,
    //                                         driverId: driver.id,
    //                                     });
    //                                 }}>
    //                                     <option value="">Select</option>
    //                                     {drivers.map((item, index) => (
    //                                         <option key={index} value={JSON.stringify(item)}
    //                                             selected={false}>{item.user.name}</option>
    //                                     ))}
    //                                 </Form.Control>
    //                             </Form.Group>
    //                         </Col>
    //                     </Row>
    //                 </Form>
    //                 <div>
    //                     <Button onClick={() => {
    //                         page = 0;
    //                         setPage(page);
    //                         showTrips();
    //                     }}>Filter Trips</Button>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 {isLoading &&
    //                     <div className="d-flex justify-content-center align-items-center">
    //                         <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />
    //                     </div>
    //                 }
    //                 <table className="table">
    //                     <thead>
    //                         <tr className="row">
    //                             <th className="col-lg-1">Ref No.</th>
    //                             <th className="col-lg-1">Type</th>
    //                             <th className="col-lg-2">Expected Start Time</th>
    //                             <th className="col-lg-2">Actual Start Time</th>
    //                             <th className="col-lg-2">Bus</th>
    //                             <th className="col-lg-2">Driver</th>
    //                             <th className="col-lg-2">Action</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {trips.map((item, index) => (
    //                             <tr key={index} className="row">
    //                                 <td className="col-lg-1">
    //                                     <Link to={`${baseUrl}/trip/${item.id}/detail-view`}>{item.ref_no}</Link>
    //                                 </td>
    //                                 <td className="col-lg-1">{convertRouteType(item.route.type.name)}</td>
    //                                 <td className="col-lg-2">{formatDateTime(item.expected_start_time)}</td>
    //                                 <td className="col-lg-2">{formatDateTime(item.actual_start_time)}</td>
    //                                 <td className="col-lg-2">{item.bus.number}</td>
    //                                 <td className="col-lg-2">{item.driver.user.name}</td>
    //                                 <td className="col-lg-2 d-flex justify-content-center align-items-center">
    //                                     {item.status === TripStatus.NEW &&
    //                                         <Link to={baseUrl + "/trip/edit/" + item.id}>
    //                                             <i className="fas fa-edit fa-lg text-primary mr-1"></i>
    //                                         </Link>
    //                                     }
    //                                     {/* <Button variant="link" onClick={() => handleDelete(item.id)}>
    //                                         <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                                     </Button> */}
    //                                 </td>
    //                             </tr>
    //                         ))}
    //                     </tbody>
    //                 </table>
    //                 {/* Custom Pagination rendering */}
    //                 <CustomPagination 
    //                     currentPage={page+1}
    //                     totalCount={totalCount}
    //                     limit={limit}
    //                     onFirst={onFirst}
    //                     onPrevious={onPrevious}
    //                     onNext={onNext}
    //                     onLast={onLast}
    //                 />
    //             </div>
    //         </div>
    //     </React.Fragment>
    // );
}

export default HistoryList;



// class HistoryList extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             trips: [],  
//             buses: [],
//             drivers: [],         
//             filter: {
//                 date: null,
//                 routeRef: null,
//                 tripStatus: null,
//                 busId: null,
//                 driverId: null,
//             },
//             routes: [],
//             delete: {
//                 show: false,
//                 data: {}
//             },

//             isLoading: false,
//             errorMsg: null,

//             // Trips pagination
//             page: 0,
//             limit: 10,
//             totalCount: 0,
// 		};
//     }

//     componentDidMount() {
//         this.fetchRoutes(); 
//         this.fetchBuses();
//         this.fetchDrivers();
//     }

//     // Fetch Routes with only Ref and type
//     fetchRoutes = () => {
//         getRoutesWithDistinctRef().then(resp => {
//             const respRoutes = resp.data.data;
//             const routes = respRoutes;
//             this.setState({ routes });            
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Fetch all buses of school
//     fetchBuses = () => {
//         getBuses().then(resp => {
//             this.setState({ buses: resp.data.data });
//         }).catch(err => {

//         })
//     }

//     // Fetch all drivers of school
//     fetchDrivers = () => {
//         getDrivers().then(resp => {
//             this.setState({ drivers: resp.data.data });
//         }).catch(err => {

//         })
//     }

//     // Fetch filters based on filter criteria
//     showTrips = () => {
//         const { page, limit } = this.state;
//         console.log(`show trips filter - ${JSON.stringify(this.state.filter)}`)
//         this.setState({ isLoading: true, errorMsg: null});
//         getHistoryTrips(this.state.filter, page, limit).then(resp => {
//             const respData = resp.data.data;
//             this.setState({ isLoading: false, errorMsg: null});
//             this.setState({ trips: respData.trips, totalCount: respData.total_count });
//         }).catch(err => {
//             this.setState({ isLoading: false, errorMsg: err});
//         })
//     }

//     // ******* Pagination methods *****************

//     onFirst = () => {
//         console.log(`onFirst`);
//         this.setState({ page: 0 }, () => {
//             this.showTrips();
//         });        
//     }

//     onPrevious = () => {
//         console.log(`onPrevious`);
//         if (this.state.page > 0) {
//             this.setState(ps => ({
//                 page: ps.page - 1
//             }), () => {
//                 this.showTrips();
//             } );
//         }
//     }

//     onNext = () => {
//         console.log(`onNext`);
//         console.log(`cal count - ${(this.state.page * this.state.limit)}`)
//         if ((this.state.page * this.state.limit) < this.state.totalCount) {
//             this.setState(ps => ({
//                 page: ps.page + 1
//             }), () => {
//                 this.showTrips();
//             } );
//         }
//     }

//     onLast = () => {
//         console.log(`onLast`);
//         // Calculate last page number
//         const { page, totalCount, limit } = this.state;
//         const newPage = Math.floor(totalCount/limit);
//         console.log(`new page - ${newPage}`);        
//         this.setState(ps => ({
//             page: newPage
//         }), () => {
//             this.showTrips();
//         } );        
//     }
//     // ******* End Pagination *****************

//     render() {
//         const { isLoading, filter, routes, buses, drivers, trips, page, limit, totalCount } = this.state;
//         console.log(`Total count - ${totalCount}`);
//         return (
//             <React.Fragment>
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">History List</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-header">Filter</div>
//                     <div className="card-body">
//                         <Form>
//                             <Row>
//                                 <Col lg="3">
//                                     <Form.Group>
//                                         <Form.Label>Date</Form.Label>
//                                         <div >
//                                             <DatePicker className="form-control"
//                                                 selected={filter.date}
//                                                 onChange={(date) => {
//                                                     this.setState(ps => ({
//                                                         filter: {
//                                                             ...ps.filter,
//                                                             date: date
//                                                         }
//                                                     }));
//                                                 }}
//                                                 dateFormat="MMMM d, yyyy"
//                                                 placeholderText="Select Date"
//                                             />
//                                         </div>
//                                     </Form.Group>
//                                 </Col>
//                                 <Col lg="3">
//                                     <Form.Group column lg="3">
//                                         <Form.Label>Route</Form.Label>
//                                         <Form.Control as="select" onChange={(e) => {
//                                             const value = e.target.value;
//                                             console.log(`Select route - ${value}`);
//                                             let refNo = "";
//                                             if (value && value.trim().length > 0) {
//                                                 const route = JSON.parse(value);
//                                                 refNo = route.ref_no;
//                                             }
                                                                                        
//                                             this.setState(ps => ({
//                                                 filter: {
//                                                     ...ps.filter,
//                                                     routeRef: refNo
//                                                 }
//                                             }));
//                                         }}>
//                                             <option value="">Select</option>
//                                             {routes.map((item, index) => (
//                                                 <option key={index} value={JSON.stringify(item)}
//                                                     selected={false}>{item.ref_no}</option>
//                                             ))}
//                                         </Form.Control>
//                                     </Form.Group>
//                                 </Col>
//                                 <Col lg="3">
//                                     <Form.Group column lg="3">
//                                         <Form.Label>Trip Status</Form.Label>
//                                         <Form.Control as="select" onChange={(e) => {
//                                             const value = e.target.value;
//                                             console.log(`Select Trip status - ${value}`);
//                                             this.setState(ps => ({
//                                                 filter: {
//                                                     ...ps.filter,
//                                                     tripStatus: value
//                                                 }
//                                             }));
//                                         }}>
//                                             <option value="">Select</option>
//                                             {[TripStatus.NEW, TripStatus.FINISHED, TripStatus.ABANDONED].map((item, index) => (
//                                                 <option key={index} value={item}
//                                                     selected={false}>{convertTripStatus(item)}</option>
//                                             ))}
//                                         </Form.Control>
//                                     </Form.Group>
//                                 </Col>
//                             </Row>
//                             <Row>
//                                 <Col lg="3">
//                                     <Form.Group column lg="3">
//                                         <Form.Label>Bus Number</Form.Label>
//                                         <Form.Control as="select" onChange={(e) => {
//                                             const bus = JSON.parse(e.target.value);
//                                             this.setState(ps => ({
//                                                 filter: {
//                                                     ...ps.filter,
//                                                     busId: bus.id
//                                                 }
//                                             }));
//                                         }}>
//                                             <option value="">Select</option>
//                                             {buses.map((item, index) => (
//                                                 <option key={index} value={JSON.stringify(item)}
//                                                     selected={false}>{item.number}</option>
//                                             ))}
//                                         </Form.Control>
//                                     </Form.Group>
//                                 </Col>
//                                 <Col lg="3">
//                                     <Form.Group column lg="3">
//                                         <Form.Label>Driver</Form.Label>
//                                         <Form.Control as="select" onChange={(e) => {
//                                             const driver = JSON.parse(e.target.value);
//                                             this.setState(ps => ({
//                                                 filter: {
//                                                     ...ps.filter,
//                                                     driverId: driver.id
//                                                 }
//                                             }));
//                                         }}>
//                                             <option value="">Select</option>
//                                             {drivers.map((item, index) => (
//                                                 <option key={index} value={JSON.stringify(item)}
//                                                     selected={false}>{item.user.name}</option>
//                                             ))}
//                                         </Form.Control>
//                                     </Form.Group>
//                                 </Col>
//                             </Row>
//                         </Form>
//                         <div>
//                             <Button onClick={() => this.showTrips()}>Filter Trips</Button>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         {isLoading &&
//                             <div className="d-flex justify-content-center align-items-center">
//                                 <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />
//                             </div>
//                         }
//                         <table className="table">
//                             <thead>
//                                 <tr className="row">
//                                     <th className="col-lg-1">Ref No.</th>
//                                     <th className="col-lg-1">Type</th>
//                                     <th className="col-lg-2">Expected Start Time</th>
//                                     <th className="col-lg-2">Actual Start Time</th>
//                                     <th className="col-lg-2">Bus</th>
//                                     <th className="col-lg-2">Driver</th>
//                                     <th className="col-lg-2">Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {trips.map((item, index) => (
//                                     <tr key={index} className="row">
//                                         <td className="col-lg-1">
//                                             <Link to={`${this.props.baseUrl}/trip/${item.id}/detail-view`}>{item.ref_no}</Link>
//                                         </td>
//                                         <td className="col-lg-1">{convertRouteType(item.route.type.name)}</td>
//                                         <td className="col-lg-2">{formatDateTime(item.expected_start_time)}</td>
//                                         <td className="col-lg-2">{formatDateTime(item.actual_start_time)}</td>
//                                         <td className="col-lg-2">{item.bus.number}</td>
//                                         <td className="col-lg-2">{item.driver.user.name}</td>
//                                         <td className="col-lg-2 d-flex justify-content-center align-items-center">
//                                             {item.status === TripStatus.NEW &&
//                                                 <Link to={this.props.baseUrl + "/trip/edit/" + item.id}>
//                                                     <i className="fas fa-edit fa-lg text-primary mr-1"></i>
//                                                 </Link>
//                                             }
//                                             <Button variant="link" onClick={() => this.handleDelete(item.id)}>
//                                                 <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                             </Button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                         {/* Custom Pagination rendering */}
//                         <CustomPagination 
//                             currentPage={page+1}
//                             totalCount={totalCount}
//                             limit={limit}
//                             onFirst={() => this.onFirst()}
//                             onPrevious={() => this.onPrevious()}
//                             onNext={() => this.onNext()}
//                             onLast={() => this.onLast()}
//                         />
//                     </div>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {}
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(HistoryList);