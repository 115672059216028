
import React, { Component } from 'react';
import { Row, Col, Navbar, Nav, Container, Media, DropdownButton, Dropdown, Modal, Button, Spinner, ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Switch, Link, match } from "react-router-dom";
import { withRouter } from 'react-router';

import { logout } from '../services/auth.service'; 
import { getTripRefsByIds, getNotifications } from '../services/http.service';
import { Role, LeftNavViews, NotificationType, formatDateTimeRev } from '../utils/constants';
import { IMAGES_URL } from '../utils/constants';
import { receiveChatMessageNotificationsObservable, receiveNotificationsObservable,
        updateNotificationReadObservable } from '../services/observable.service';

import '../styles/school.css';
import logo from '../assets/images/logo-sbt.png'

class SchoolNav extends Component {
    constructor(props) {
		super(props)
		this.state = {
            chatNotifications: {
                unReadTotalCount: 0,
                notifications: [],
            },
            notifications: {
                unReadTotalCount: 0,
                notifications: [],
            },
            trips: [],            

            chatNotificationModal: {
                show: false,
                isLoading: false,
            },
            notificationModal: {
                show: false,
                isLoading: false,
                page: 0,
                limit: 10,
            }
		};
    }

    logout = () => {
        console.log('loggin out..');
        logout().then(resp => {
            this.props.history.push('/login');
        }).catch(err => {
            console.log(err);
        })
    }

    // Show chat notification modal
    showChatNotificationModal = () => {
        this.setState(ps => ({
            chatNotificationModal: {
                ...ps.chatNotificationModal,
                show: true,
            }
        }));
    }

    // Show notification modal
    showNotificationModal = () => {
        this.setState(ps => ({
            notificationModal: {
                ...ps.notificationModal,
                show: true,
            }
        }));
    }

    // On chat notification modal show callback
    onShowChatNotificationModal = () => {
        console.log('chat notification modal opened');
        // Load trips reference from server
        const notifications = this.state.chatNotifications.notifications;
        let tripIds = notifications.map(item => item.tripId);
        console.log(`trip Ids after extraction - ${JSON.stringify(tripIds)}`);
        tripIds = Array.from(new Set(tripIds))
        console.log(`trip Ids unique - ${JSON.stringify(tripIds)}`);

        // Reset flags
        this.setState(ps => ({
            chatNotificationModal: {
                ...ps.chatNotificationModal,
                isLoading: true,
            }
        }));
        getTripRefsByIds(tripIds).then(resp => {
            this.setState(ps => ({
                chatNotificationModal: {
                    ...ps.chatNotificationModal,
                    isLoading: false,
                }
            }));
            this.setState({ trips: resp.data.data });
        }).catch(err => {
            console.log(err);
            this.setState(ps => ({
                chatNotificationModal: {
                    ...ps.chatNotificationModal,
                    isLoading: false,
                }
            }));
        })
    }


    // Hide chat notification modal
    hideChatNotificationModal = () => {
        console.log('chat notification modal hide');
        this.setState(ps => ({
            chatNotificationModal: {
                ...ps.chatNotificationModal,
                show: false,
            }
        }));
    }

    // On notification modal show callback
    onShowNotificationModal = () => {
        console.log('notification modal opened'); 
        // Reset states
        this.setState(ps => ({
            notificationModal: {
                ...ps.notificationModal,
                page: 0,
            },
            notifications: {
                ...ps.notifications,
                notifications: [],
            }
        }), () => {
            this.updateNotificationRead();
            this.fetchNotifications();
        });        
    }

    // Fetch Notifications
    fetchNotifications = () => {
        // Reset flags
        this.setState(ps => ({
            notificationModal: {
                ...ps.notificationModal,
                isLoading: true,
            }
        }));
        const { notificationModal } = this.state;
        getNotifications(notificationModal.page, notificationModal.limit).then(resp => {
            this.setState(ps => ({
                notificationModal: {
                    ...ps.notificationModal,
                    isLoading: false,
                },
                notifications: {
                    ...ps.notifications,
                    notifications: [...ps.notifications.notifications, ...resp.data.data],
                }
            }));

        }).catch(err => {
            console.log(`Error in getting notifications - ${JSON.stringify(err)}`);
            this.setState(ps => ({
                notificationModal: {
                    ...ps.notificationModal,
                    isLoading: false,
                }
            }));
        });
    }

    // Hide notification modal
    hideNotificationModal = () => {
        console.log('notification modal hide');
        this.setState(ps => ({
            notificationModal: {
                ...ps.notificationModal,
                show: false,
            }
        }));
    }

    // On Load More of Notifications
    onLoadMoreNotifications = () => {
        // Increment page and call api
        this.setState(ps => ({
            notificationModal: {
                ...ps.notificationModal,
                page: ps.notificationModal.page + 1
            }
        }), () => {
            this.fetchNotifications();
        });
    }

    // Update notification read flag
    updateNotificationRead = () => {
        // Reset unread count
        this.setState(ps => ({
            notifications: {
                ...ps.notifications,
                unReadTotalCount: 0,
            }
        }));
        updateNotificationReadObservable().next();
    }

    // After component mounted
    componentDidMount() {
        // Receive Chat Message Notification
        this.receiveChatMessageNotificationsObservable = receiveChatMessageNotificationsObservable()
            .subscribe(value => {
                console.log(`chat message notification total count in header - ${JSON.stringify(value.totalCount)}`);
                //if (value.totalCount > 0) {  No need to check else it will not update when total count is zero
                    this.setState(ps => ({
                        chatNotifications: {
                            ...ps.chatNotifications,
                            unReadTotalCount: value.totalCount,
                            notifications: value.notifications,
                        }
                    }));
                //}
            });

        // Receive Notifications
        this.receiveNotificationsObservable = receiveNotificationsObservable()
            .subscribe(value => {
                console.log(`Notification received - ${JSON.stringify(value)}`);
                this.setState(ps => ({
                    notifications: {
                        ...ps.notifications,
                        unReadTotalCount: value.unReadCount,
                    }
                }));
            });
    }

    componentWillUnmount() {
        this.receiveChatMessageNotificationsObservable.unsubscribe();
        this.receiveNotificationsObservable.unsubscribe();
    }    

    render() { 

        const { chatNotifications, notifications, chatNotificationModal, notificationModal, trips } = this.state;

        console.log(`Notifications - ${JSON.stringify(notifications)}`);

        console.log(`School in Nav - ${JSON.stringify(this.props.school)}`);

        return (
            <React.Fragment>
            <Navbar bg="light" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-auto" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Container fluid="true">
                        {/* sidebar */}
                        <Row>
                            <div className="col-xl-2 col-lg-3 col-md-4 sidebar fixed-top">
                                <a href="http://app.schoolbustracer.com" className="navbar-brand text-white d-block mx-auto text-center py-3 mb-4 bottom-border">
                                    <div>
                                        <img
                                            className="align-self-center mr-3 left-nav-sapi-logo"
                                            src={logo}
                                            alt="Logo" />
                                    </div>
                                </a>
                                <div className="d-flex align-items-center justify-content-left"> 
                                
                                    {this.props.leftNav.user.profile_photo &&                                   
                                        <img src={`${IMAGES_URL.USER}/${this.props.leftNav.user.profile_photo}`} alt={this.props.leftNav.user.name} width="60" className="rounded-circle mr-3" />
                                    }
                                    {!this.props.leftNav.user.profile_photo &&                                   
                                        <img src={`${IMAGES_URL.USER}/default_profile.jpeg`} alt={this.props.leftNav.user.name} width="60" className="rounded-circle mr-3" />
                                    }
                                    <h5><a href={`/profile`} className="text-white">{this.props.leftNav.user.name}</a></h5>
                                </div>                                    
                                
                                <Nav className="mr-auto flex-column mt-4">
                                    {/* Show for School Owner only */}
                                    {this.props.leftNav.userRoles && this.props.leftNav.userRoles.indexOf(Role.SCHOOL_OWNER) > -1 &&
                                        <DropdownButton variant="secondary"  className="left-nav-item mb-3" id="dropdown-basic-button"
                                            title={<span><i className="fas fa-school fa-lg"></i> School</span>}>                                            
                                            <Link className="dropdown-item" to="list">List</Link>
                                            <Link className="dropdown-item" to="new">New</Link> 
                                        </DropdownButton>
                                    }

                                    {/* Profile Navs */}
                                    {this.props.leftNav.view === LeftNavViews.PROFILE &&
                                        <React.Fragment>
                                            <DropdownButton variant="secondary" className="left-nav-item" id="dropdown-basic-button"
                                                title={<span><i className="fas fa-user fa-lg"></i>Profile Settings</span>}>
                                                <Link className="dropdown-item" to="/profile/edit">Edit</Link>
                                                <Link className="dropdown-item" to="/profile/photo">Profile photo</Link>
                                                <Link className="dropdown-item" to="/profile/change-password">Change Password</Link>
                                            </DropdownButton>
                                        </React.Fragment>
                                    }

                                    {/* School level menus */}
                                    {this.props.leftNav.view === LeftNavViews.SCHOOL_ADMIN &&
                                        <React.Fragment>
                                            <DropdownButton variant="secondary" className="left-nav-item" id="dropdown-basic-button"
                                            title={<span><i className="fas fa-road fa-lg"></i>Trip</span>}>                                                                                               
                                                <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/trip/today/list"}>Today's Trips</Link>
                                                <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/trip/history/list"}>History</Link>                                              
                                                <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/trip/metadata/new"}>New Metadata</Link>                                                                                                
                                                <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/trip/metadata/list"}>List Metadata</Link>                                                
                                            </DropdownButton>
                                            <DropdownButton variant="secondary" className="left-nav-item mt-3" id="dropdown-basic-button"
                                                title={<span><i className="fas fa-route fa-lg"></i>Route</span>}>                                                                                                
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/route/list"}>List</Link>                                                
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/route/new"}>New</Link>
                                            </DropdownButton> 
                                            <DropdownButton variant="secondary" className="left-nav-item mt-3"
                                                title={<span><i className="fa fa-users fa-lg"></i>User</span>}>  
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/user/list"}>List</Link>
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/user/new"}>New</Link>
                                            </DropdownButton>
                                            <DropdownButton variant="secondary" className="left-nav-item mt-3" id="dropdown-basic-button"
                                                title={<span><i className="fa fa-grip-vertical fa-lg"></i>Section</span>}>
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/section/list"}>List</Link>
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/section/new"}>New</Link>                                                
                                            </DropdownButton>
                                            <DropdownButton variant="secondary" className="left-nav-item mt-3" id="dropdown-basic-button"
                                                title={<span><i className="fa fa-layer-group fa-lg"></i>Class</span>}>
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/class/list"}>List</Link>
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/class/new"}>New</Link>                                                
                                            </DropdownButton>
                                            <DropdownButton variant="secondary" className="left-nav-item mt-3" id="dropdown-basic-button"
                                                title={<span><i className="fas fa-user-graduate fa-lg"></i>Student</span>}>                                                                                                
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/student/list"}>List</Link>                                                                                                
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/student/new"}>New</Link>                                                
                                            </DropdownButton>
                                            <DropdownButton variant="secondary" className="left-nav-item mt-3" id="dropdown-basic-button"
                                                title={<span><i className="fa fa-user-tie fa-lg"></i>Driver</span>}>                                                                                                
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/driver/list"}>List</Link>                                                                                                
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/driver/new"}>New</Link>                                                
                                            </DropdownButton>
                                            <DropdownButton variant="secondary" className="left-nav-item mt-3" id="dropdown-basic-button"
                                                title={<span><i className="fa fa-bus fa-lg"></i>Bus</span>}>                                                                                                
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/bus/list"}>List</Link>                                                                                                
                                                    <Link className="dropdown-item" to={"/school/" + this.props.schoolId + "/admin/bus/new"}>New</Link>                                                
                                            </DropdownButton>                                                                                   
                                        </React.Fragment>
                                    }
                                    
                                </Nav>
                            </div>

                            {/* top-nav */}
                            <div className="col-xl-10 col-lg-9 col-md-8 ml-auto bg-dark fixed-top py-2 top-navbar">
                                <div className="row align-items-center">
                                    <div className="col-md-9">
                                        <h4 className="text-light text-uppercase mb-0">{this.props.school.name}</h4>
                                    </div>
                  
                                    <div className="col-md-3">
                                        <ul className="navbar-nav">
                                            <li className="nav-item icon-parent">
                                                <Button variant="link" className={chatNotifications.unReadTotalCount > 0 ? "nav-link icon-bullet":"nav-link"}
                                                    onClick={() => this.showChatNotificationModal()}
                                                >
                                                    <i className="fas fa-comments text-muted fa-lg"></i>
                                                </Button>
                                            </li>
                                            <li className="nav-item icon-parent">
                                                <Button variant="link" className="nav-link"
                                                    onClick={() => this.showNotificationModal()}
                                                >
                                                    <i className="fas fa-bell text-muted fa-lg"></i>

                                                    {notifications.unReadTotalCount > 0 &&
                                                        <div className="notification-badge">
                                                            {notifications.unReadTotalCount}
                                                        </div>
                                                    }
                                                </Button>
                                            </li>
                                            <li className="nav-item ml-md-auto" onClick={() => this.logout()}><a href="#" className="nav-link" data-toggle="modal" data-target="#sign-out" alt="Logout"><i className="fas fa-sign-out-alt text-danger fa-lg"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* end of top-nav */}

                        </Row>
                        {/* end of sidebar */}
                    </Container>
                </Navbar.Collapse>
            </Navbar>

            
                {/* Chat Message Notification Modal */}
                
                <Modal show={chatNotificationModal.show} 
                    onHide={() => this.hideChatNotificationModal()}
                    onShow={() => this.onShowChatNotificationModal()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Chat Notifications - Trip Refs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex notification-body"> 
                        {chatNotificationModal.isLoading &&                       
                            <Spinner animation="border" variant="primary" />
                        }
                        <ListGroup variant="flush">
                            {trips.map((item, index) => (
                                <ListGroup.Item><a href={`/school/${this.props.schoolId}/admin/trip/${item.id}/running/map-view`}>#{item.ref_no}</a></ListGroup.Item>
                            ))}
                        </ListGroup>
                </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            
                        </Container>
                    </Modal.Footer>
                </Modal>

                {/* Notification Modal */}
                
                <Modal 
                    size="lg"
                    show={notificationModal.show} 
                    onHide={() => this.hideNotificationModal()}
                    onShow={() => this.onShowNotificationModal()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Notifications</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="notification-body"> 
                        {notificationModal.isLoading &&                       
                            <Spinner animation="border" variant="primary" />
                        }
                        <ListGroup variant="flush">
                            {notifications.notifications.map((item, index) => (
                                <ListGroup.Item>
                                    <NotificationItemContent notification={item}/>
                                </ListGroup.Item>                                
                            ))}
                            <ListGroup.Item className="d-flex justify-content-center">
                                <Button variant="info" onClick={this.onLoadMoreNotifications}>Load More</Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Modal.Body>
                </Modal>
                

            </React.Fragment>
        )
    }
}

// Build Notification content based on NotifucationType
const NotificationItemContent = (props) => {
    const notification = props.notification;
    if (notification.type === NotificationType.START_TRIP) {
        console.log('Build StartTrip notification content');
        return (
            <div className="d-flex justify-content-between">
                <a href={`/school/${notification.school_id}/admin/trip/${notification.notification_data.tripId}/running/map-view`}>{notification.text}</a>
                <div className="text-muted">
                    {formatDateTimeRev(notification.created_at)}
                </div>
            </div>
        )
    }
    else if (notification.type === NotificationType.STUDENT_PICK_UP || notification.type === NotificationType.STUDENT_DROP_OFF) {
        return (
            <div className="d-flex justify-content-between">
                <a href={`/school/${notification.school_id}/admin/trip/${notification.notification_data.tripId}/running/map-view`}>{notification.text}</a>
                <div className="text-muted">
                    {formatDateTimeRev(notification.created_at)}
                </div>
            </div>
        )
    }
    else {
        return <div className="d-flex justify-content-between"></div>
    }
}

const mapStateToProps = state => {
    console.log("LeftNav in school nav :: " + JSON.stringify(state.leftNav))
    return {
        schoolId: state.extras.schoolId,
        baseUrl: state.extras.baseUrl,
        leftNav: state.leftNav,
        school: state.school,
    }
}

// export default connect(mapStateToProps)(withRouter(SchoolNav));
export default connect(mapStateToProps)(SchoolNav); // TODO user useNavigate hook to replace withRouter later