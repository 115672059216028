import { EXTRAS } from '../actions/action-types'

const extras = (state = {}, action) => {
  console.log("Extras reducer :: action type " + action.type + " -- payload : " + JSON.stringify(action.payload));
  switch (action.type) {
    case EXTRAS:
      return action.payload
    default:
      return state
  }
}

  export default extras