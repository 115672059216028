import React, { useState, useEffect, useRef } from 'react';

import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Chip,
    Collapse,
    Box,
    Grid,
    Typography,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const TripStudentsTable = ({
    tripStudents,
}) => {
    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Stop</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tripStudents && tripStudents.map((row, index) => (
                                <Row
                                    key={row.id}
                                    row={row}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

const Row = ({ row, onDisable, onDelete, onEdit }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ width: '10px' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.student.name}
                    {!row.isActive && <Chip sx={{ ml: 1 }} label="Disabled" color="error" variant="outlined" />}
                </TableCell>
                <TableCell>
                    {row.stop ? row.stop.name : 'N/A'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Typography component='div' variant='h5'>
                                        Roll No.
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {row.student.rollNo}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Typography component='div' variant='h5'>
                                        Class
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {row.student.class.name}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Typography component='div' variant='h5'>
                                        Section
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    {row.student.section.name}
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default TripStudentsTable;

