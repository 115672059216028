import { MODAL_OPEN, MODAL_CLOSE} from './action-types';

export const openModal = (modalType, content, okCallback, cancelCallback) => ({
    type: MODAL_OPEN,
    payload: {
        isOpen: true,
        modalType: modalType,
        content: content
    },
    okCallback: okCallback,
    cancelCallback: cancelCallback
})

export const closeModal = (modalType) => ({
    type: MODAL_CLOSE,
    payload: {
        isOpen: false,
        modalType: modalType
    }
})