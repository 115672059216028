import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
    Button } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ImageIcon from '@mui/icons-material/Image';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { fetchSchoolUsers, getDriverByUserId, saveOrUpdateDriver, getDriver } from '../../../../services/http.service';
import { Role } from '../../../../utils/constants';



const NewDriver = () => {

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [driver, setDriver] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	
    const { driverId } = useParams();
    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        if (driverId) {
            fetchDriver(driverId);
        }
    }, []);

    // Fetch driver detail for edit
    const fetchDriver = (driverId) => {
        setIsLoading(true);
        getDriver(driverId).then(resp => {
            console.log(`Fetch driver - ${JSON.stringify(resp)}`);
            setDriver(resp.data.data);
            setDriverDetail(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Set Driver details into state
    const setDriverDetail = (respDriver) => {
        // set formik field
        formik.setFieldValue("name", respDriver.user.name);
        formik.setFieldValue("mobile", respDriver.user.mobile);
        formik.setFieldValue("email", respDriver.user.email);
        formik.setFieldValue("password", "******"); // 6 digit default password on edit of driver
        formik.setFieldValue("licenseNumber", respDriver.license_number);

        setDriver({
            ...driver,
            id: respDriver.id,
            user: respDriver.user,
            licenseNumber: respDriver.license_number
        });
        
        console.log(`Set driver detail - ${JSON.stringify(driver)}`);
    }

    /**
     * Handle form submission
     */
     const validationSchema = Yup.object({
        name: Yup.string().required("Name is required."),
        mobile: Yup.string().required("Mobile is required."),
        email: Yup.string().email("Invalid email."),
        licenseNumber: Yup.string().required("License number is required."),
        password: Yup.string().required("Password is required.")
            .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long"),
        });
    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            email: '',
            password: '',
            licenseNumber: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            console.log("saving driver - ", values);
            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);
            const newDriver = {
                ...driver,
                name: values.name,
                mobile: values.mobile,
                email: values.email,
                password: values.password,
                licenseNumber: values.licenseNumber,
            }
            
            console.log(`Saving driver - ${JSON.stringify(newDriver)}`);
            saveOrUpdateDriver(newDriver).then(resp => {
                setIsSaving(false);
                setErrorMsg(null);
                setShowSuccessAlert(true);
            }).catch(err => {
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
                console.log(err);
            });
        },
        onReset: (values, { resetForm }) => resetForm(),
    });



    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setValidated(true);
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         console.log(`not valid`);
    //         return
    //     } 
    //     // const driver = this.state.driver;
    //     setIsSaving(true);
    //     setErrorMsg(null);
    //     setShowSuccessAlert(false);
    //     setDriver({
    //         ...driver,
    //         licenseNumber: form[0].value
    //     });
    //     // driver.licenseNumber = form[0].value;
    //     console.log(`Saving driver - ${JSON.stringify(driver)}`);
    //     saveOrUpdateDriver(driver).then(resp => {
    //         setIsSaving(false);
    //         setErrorMsg(null);
    //         setShowSuccessAlert(true);
    //     }).catch(err => {
    //         setIsSaving(false);
    //         setErrorMsg(err);
    //         setShowSuccessAlert(false);
    //         console.log(err);
    //     })
    // }

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Driver saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                    width: '100%',
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        { driver && driver.id ? "Update driver" : "Add new driver"}
                    </Typography>
                    { isLoading &&
                        <CircularProgress />
                    }
                    <Box>
                        { driver.user &&
                            <>
                                <Chip
                                    onClick={() => navigate(`${baseUrl}/driver/${driverId}/verify`)}
                                    icon={<HowToRegIcon />}
                                    label="Verify"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ mx: 2 }}
                                />
                                <Chip
                                    onClick={() => navigate(`${baseUrl}/driver/${driverId}/photo`)}
                                    icon={<ImageIcon />}
                                    label="Photo"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ mx: 2 }}
                                />
                            </>
                        }
                        <Chip
                            onClick={() => navigate(`../list`)}
                            icon={<FormatListBulletedIcon />}
                            label="Drivers"
                            variant="outlined"
                            color="secondary"
                            sx={{ mx: 2 }}
                        />
                    </Box>
                </Box>

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="name"
                                label="Driver name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="mobile"
                                label="Mobile"
                                value={formik.values.mobile}
                                onChange={formik.handleChange}
                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                helperText={formik.touched.mobile && formik.errors.mobile}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="password"
                                label="Password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                               <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="licenseNumber"
                                label="License number"
                                value={formik.values.licenseNumber}
                                onChange={formik.handleChange}
                                error={formik.touched.licenseNumber && Boolean(formik.errors.licenseNumber)}
                                helperText={formik.touched.licenseNumber && formik.errors.licenseNumber}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        {driver && driver.id ? 'Update' : 'Save'}
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
    
   
    // return (
    //     <React.Fragment> 
    //             <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //                 <div className="h5 mb-0 text-gray-800">{driver.user.id ? 'Update Driver' : 'New Driver'}</div>
    //             </div>
    //             <Form>                        
    //                 <Form.Group as={Row}>
    //                     <Form.Label column sm={2}>Select Driver
    //                         {driverLoading &&
    //                             <Spinner className="ml-2" animation="border" size="sm" variant="primary" /> 
    //                         }
    //                     </Form.Label>
    //                     <Col sm={4}>
    //                         <Form.Control as="select" onChange={selectDriver}>
    //                             <option value="">Select</option>
    //                             {drivers.map((item, index) => ( 
    //                                 <option key={index} value={JSON.stringify(item)}
    //                                 selected={driver.user.id === item.user.id ? true : false}>{item.user.name}</option>
    //                             ))}                                                                      
    //                         </Form.Control>
    //                     </Col>
    //                 </Form.Group>                         
    //             </Form> 
                                    
    //             {driver.user.id && 
    //                 <div className="card shadow h-100 my-2">
    //                     <div className="card-body">
    //                         <Form
    //                             noValidate
    //                             validated={validated}
    //                             onSubmit={handleSubmit}>
                                                            
    //                             <Form.Group as={Row}>
    //                                 <Form.Label column sm="2">License</Form.Label>
    //                                 <Col sm="4">
    //                                     <Form.Control required type="text" placeholder="Enter Lisence number" 
    //                                         value={driver.licenseNumber}
    //                                         onChange={(e) => {
    //                                             // const driver = this.state.driver;                                                           
    //                                             // driver.licenseNumber = e.target.value;
    //                                             // this.setState({driver});
    //                                             setDriver({
    //                                                 ...driver,
    //                                                 licenseNumber: e.target.value
    //                                             });
    //                                         }}
    //                                     />
    //                                     <Form.Control.Feedback type="invalid">
    //                                         Please enter driver valid License Number.
    //                                     </Form.Control.Feedback>
    //                                 </Col>
    //                             </Form.Group>                                 

    //                             <div className="d-flex align-items-center justify-content-left">
    //                                 <Button className="btn btn-primary" type="submit">Submit</Button>   
    //                                 {isSaving &&                         
    //                                     <Spinner animation="border" variant="primary" />
    //                                 }
    //                                 {showSuccessAlert &&
    //                                     <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false) } 
    //                                         dismissible>
    //                                         Saved Successfully
    //                                     </Alert>
    //                                 }
    //                                 {errorMsg &&
    //                                     <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null) } 
    //                                         dismissible>
    //                                         {this.state.errorMsg}
    //                                     </Alert>
    //                                 }
    //                             </div> 

    //                         </Form>
    //                     </div>
    //                 </div>
    //             }
    //     </React.Fragment> 
    // );
}

export default NewDriver;


// class NewDriver extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             validated: false,
//             driverLoading: false,
//             drivers: [],
//             driver: {
//                 user: {}
//             },

//             isSaving: false,
//             errorMsg: null,
//             showSuccessAlert: false
// 		};
//     }

//     componentDidMount() {
//         this.fetchDrivers();
//         const id = this.props.match.params.id;
//         if (id) {
//             this.fetchDriver(id);
//         }
//     }

//     // Fetch drivers
//     fetchDrivers = () => {
//         console.log('fetching students');
//         this.setState({driverLoading: true});
//         fetchSchoolUsers(Role.DRIVER).then(resp => {
//             this.setState({driverLoading: false});
//             const schoolUsers = resp.data.data;
//             const drivers = schoolUsers.map(item => {
//                 return {
//                     user: {
//                         id: item.user.id,
//                         name: item.user.name
//                     }                
//                 }
//             })
//             this.setState({drivers});
//             this.setState({driverLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({driverLoading: false});
//         })
//     }

//     // Fetch driver detail for edit
//     fetchDriver = (driverId) => {
//         this.setState({driverLoading: true});
//         getDriver(driverId).then(resp => {
//             console.log(`Fetch driver - ${JSON.stringify(resp)}`);
//             this.setState({driver: resp.data.data});
//             this.setDriverDetail(this.state.driver);
//             this.setState({driverLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({driverLoading: false});
//         })
//     }

//     // Select a driver
//     selectDriver = (e) => {
//         // Reset flags
//         this.setState({
//             isSaving: false,
//             errorMsg: null,
//             showSuccessAlert: false
//         });        

//         let selectedDriver = e.target.value;
//         if (selectedDriver === '') {
//             return;
//         }

//         selectedDriver = JSON.parse(selectedDriver);
//         console.log(`selected driver - ${JSON.stringify(selectedDriver)}`);
//         this.setState(() => ({
//             driver: {
//                 ...this.state.driver,
//                 id: null,
//                 user: {
//                     id: selectedDriver.user.id,
//                     name: selectedDriver.user.name
//                 },
//                 licenseNumber: '',
//             }
//         }));

//         // Fetch driver details from server
//         this.setState({driverLoading: true});
//         getDriverByUserId(selectedDriver.user.id).then(resp => {
//             this.setState({driverLoading: false});
//             const respDriver = resp.data.data; 
//             console.log(`Driver respons - ${JSON.stringify(respDriver)}`);
//             if (!respDriver.id) { // If driver not found
//                 return;
//             } 

//             // Set driver detail
//             this.setDriverDetail(respDriver); 
            
//         }).catch(err => {
//             console.log(err);
//             this.setState({driverLoading: false});
//         })
//     }

//     // Set Driver details into state
//     setDriverDetail = (respDriver) => {
//         this.setState(() => ({
//             driver: {
//                 ...this.state.driver,
//                 id: respDriver.id,
//                 user: respDriver.user,
//                 licenseNumber: respDriver.license_number
//             }
//         }));
//         console.log(`Set driver detail - ${JSON.stringify(this.state.driver)}`);
//     }

//     /**
//      * Handle form submission
//      */
//     handleSubmit = (event) => {
//         event.preventDefault();
//         event.stopPropagation();
//         this.setState({ validated: true });
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             console.log(`not valid`);
//             return
//         } 
//         const driver = this.state.driver;
//         this.setState({isSaving: true});
//         this.setState({errorMsg: null});
//         this.setState({showSuccessAlert: false});
//         driver.licenseNumber = form[0].value;
//         console.log(`Saving driver - ${JSON.stringify(driver)}`);
//         saveOrUpdateDriver(driver).then(resp => {
//             this.setState({isSaving: false});
//             this.setState({errorMsg: null});
//             this.setState({showSuccessAlert: true});
//         }).catch(err => {
//             this.setState({isSaving: false});
//             this.setState({errorMsg: err});
//             this.setState({showSuccessAlert: false});
//             console.log(err);
//         })
//     }
    
//     render() {
//         const { validated, driverLoading, drivers, driver, isSaving, errorMsg, showSuccessAlert } = this.state;
// 		return (
//             <React.Fragment> 
//                     <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                         <h1 className="h4 mb-0 text-gray-800">{driver.user.id ? 'Update Driver' : 'New Driver'}</h1>
//                     </div>
//                     <Form>                        
//                         <Form.Group as={Row}>
//                             <Form.Label column sm={2}>Select Driver
//                                 {driverLoading &&
//                                     <Spinner className="ml-2" animation="border" size="sm" variant="primary" /> 
//                                 }
//                             </Form.Label>
//                             <Col sm={4}>
//                                 <Form.Control as="select" onChange={(e) => {this.selectDriver(e)}}>
//                                     <option value="">Select</option>
//                                     {drivers.map((item, index) => ( 
//                                         <option key={index} value={JSON.stringify(item)}
//                                         selected={driver.user.id === item.user.id ? true : false}>{item.user.name}</option>
//                                     ))}                                                                      
//                                 </Form.Control>
//                             </Col>
//                         </Form.Group>                         
//                     </Form> 
                                        
//                     {driver.user.id && 
//                         <div className="card shadow h-100 my-2">
//                             <div className="card-body">
//                                 <Form
//                                     noValidate
//                                     validated={validated}
//                                     onSubmit={e => this.handleSubmit(e)}>
                                                                
//                                     <Form.Group as={Row}>
//                                         <Form.Label column sm="2">License</Form.Label>
//                                         <Col sm="4">
//                                             <Form.Control required type="text" placeholder="Enter Lisence number" 
//                                                 value={this.state.driver.licenseNumber}
//                                                 onChange={(e) => {
//                                                     const driver = this.state.driver;                                                           
//                                                     driver.licenseNumber = e.target.value;
//                                                     this.setState({driver});
//                                                 }}
//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please enter driver valid License Number.
//                                             </Form.Control.Feedback>
//                                         </Col>
//                                     </Form.Group>                                 

//                                     <div className="d-flex align-items-center justify-content-left">
//                                         <Button className="btn btn-primary" type="submit">Submit</Button>   
//                                         {isSaving &&                         
//                                             <Spinner animation="border" variant="primary" />
//                                         }
//                                         {showSuccessAlert &&
//                                             <Alert className="ml-3 mb-0" variant="success" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                                 dismissible>
//                                                 Saved Successfully
//                                             </Alert>
//                                         }
//                                         {errorMsg &&
//                                             <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({errorMsg: null}) } 
//                                                 dismissible>
//                                                 {this.state.errorMsg}
//                                             </Alert>
//                                         }
//                                     </div> 

//                                 </Form>
//                             </div>
//                         </div>
//                     }
//             </React.Fragment> 
//         )
//     }
// }

// export default NewDriver;