import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress } from '@mui/material';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Role, DEFAULT_MAP_LOCATION, GOOGLE_MAP_API_KEY, convertMetersToKMsText, convertSecsToHms } from '../../../../utils/constants';
import { createMapOptions } from '../../../../utils/google-map-helper';
import { saveOrUpdateStudentPickup, saveOrUpdateStudentDropoff, getStudent, 
    fetchSchool as fetchSchoolHttp } from '../../../../services/http.service';

import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';


const validationSchema = Yup.object({
    location: Yup.string().required("Student location is required."),
});

const StudentLocation = ({ student, type, onNext, onBack, onSubmit }) => {
    const [initialCenter, setInitialCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
    const [pickupCenter, setPickupCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
    const [dropoffCenter, setDropoffCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
    const [zoom, setZoom] = useState(14);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    //const [location, setLocation] = useState(null);
    const [school, setSchool] = useState(null);

    const mapRef = useRef();
    const mapsRef = useRef();
    const locationRef = useRef();
    const elementLocationRef = useRef();

    const markerRef = useRef();
    const schoolId = useSelector(state => state.extras.schoolId);

    useEffect(() => {
        fetchSchool();
    }, []);

    useEffect(() => {
        const location = student[type];
        locationRef.current = student[type];
        formik.setFieldValue('location', location.location);
    }, [student]);


    // fetch school from server
    const fetchSchool = () => {
        fetchSchoolHttp(schoolId).then(resp => {
            const respSchool = resp.data.school;
            console.log(`loaded school - ${JSON.stringify(respSchool)}`);
            //school = respSchool; // assigned to state school as state not changed previous school object
            setSchool(respSchool);
            setInitialCenter({lat: respSchool.latitude, lng: respSchool.longitude});
        }).catch(err => {
            console.log(err);
        })
    }

    // ************** Google Map Helper *****************/



    const onMapReady = (map, maps) => {
        console.log("On Map Ready ...");
        mapRef.current = map;
        mapsRef.current = maps;
        updateMarker(map, maps);
        setSearchOnMap(map, maps); 
    }

    const updateMarker = (map, maps) => {
        console.log(`student location - ${type}`);
        let markerPosition = initialCenter;
        const location = locationRef.current;
        if (location) {
            markerPosition = {lat: location.latitude, lng: location.longitude};
        }
        // remove previous marker
        let prevMarker = markerRef;
        if (prevMarker.current) {
            prevMarker.current.setMap(null);
        }
        const defaultMarker = new maps.Marker({
            draggable: true,
            position: markerPosition,
            map: map,
            title: 'Student Address'
        });
        map.setCenter(markerPosition); // center map
        prevMarker.current = defaultMarker;
        setOnMarkerDrag(map, maps);
        getStudentDistanceAndDuration(maps);
    }

    // Set drag for marker
    const setOnMarkerDrag = (map, maps) => {
        const geocoder = new maps.Geocoder();
        maps.event.addListener(markerRef.current, 'dragend', (marker) => {
            console.log("Marker drag new Location :: " + JSON.stringify(marker.latLng));
            geocoder.geocode({ 'latLng': marker.latLng }, (results, status) => {
                if (status === maps.GeocoderStatus.OK) {
                    console.log("Marker drag Geocode Address :: " + results[0].formatted_address);
                    formik.setFieldValue('location', results[0].formatted_address);
                    locationRef.current.location = results[0].formatted_address;
                    locationRef.current.latitude = marker.latLng.lat();
                    locationRef.current.longitude = marker.latLng.lng();
                    console.log(locationRef.current);
                    // spread student object to take effect on ui
                    // setStudent({ ...student });
                    getStudentDistanceAndDuration(maps);
                }
            });
        });
    }

    // Set search on map
    const setSearchOnMap = (map, maps) => {
        //const autocomplete = new maps.places.Autocomplete(document.getElementById('location'));
        const autocomplete = new maps.places.Autocomplete(elementLocationRef.current);
        autocomplete.bindTo('bounds', map);
        autocomplete.addListener('place_changed', () => {
            var place = autocomplete.getPlace();
        
            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);  // Why 17? Because it looks good.
            }
            // Set address into student
            formik.setFieldValue('location', place.formatted_address);
            locationRef.current.location = place.formatted_address;
            locationRef.current.latitude = place.geometry.location.lat();
            locationRef.current.longitude = place.geometry.location.lng();
            
            // Update marker
            updateMarker(map, maps);
        });
    }

    const getStudentDistanceAndDuration = (maps) => {

    }

    // ************** End // Google Map Helper *****************/

    // handle submit
    const formik = useFormik({
        initialValues: {
            location: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            const location = locationRef.current;
            const newStudent = {
                ...student,
                [type]: {
                    ...student[type],
                    location: location.location,
                    latitude: location.latitude,
                    longitude: location.longitude
                }
            }
            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);
            if (type === 'pickup') {
                saveOrUpdateStudentPickup(newStudent).then(resp => {
                    // Also add same dropoff as pickup by default for new one
                    if (!newStudent.dropoff.latitude || !newStudent.dropoff.longitude) {
                        const updatedStudent = {
                            ...newStudent,
                            dropoff: {
                                location: location.location,
                                latitude: location.latitude,
                                longitude: location.longitude
                            }
                        }
                        resetSuccessFlags(updatedStudent);
                    } else {
                        resetSuccessFlags(newStudent);
                    }
                }).catch(err => {
                    resetErrorFlags(err);
                });
            }
            else if (type === 'dropoff') {
                saveOrUpdateStudentDropoff(newStudent).then(resp => {
                    resetSuccessFlags(newStudent);
                }).catch(err => {
                    resetErrorFlags(err);
                });
            }
        }
    });

    // Set dropoff same as pickup address
    const setSameAsPickup = () => {
        if (student.pickup) {
            locationRef.current.location = student.pickup.location;
            locationRef.current.latitude = student.pickup.latitude;
            locationRef.current.longitude = student.pickup.longitude;
            formik.setFieldValue('location', student.pickup.location);
            updateMarker(mapRef.current, mapsRef.current);
        }
    }

    // Reset success flags
    const resetSuccessFlags = (newStudent) => {
        console.log("resetSuccessFlags", newStudent);
        onSubmit(newStudent); // send student back to parent component
        setIsSaving(false);
        setErrorMsg(null);
        setShowSuccessAlert(true);
    }

    // Reset error flags
    const resetErrorFlags = (err) => {
        setIsSaving(false);
        setErrorMsg(err);
        setShowSuccessAlert(false);
        console.log(err);
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"School saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)}
                />
            }

            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            inputRef={elementLocationRef}
                            //id="location" // to be used for address suggestions
                            name="location"
                            label="Choose location"
                            value={formik.values.location}
                            onChange={(e) => {
                                formik.handleChange(e);
                            }}
                            error={formik.touched.location && Boolean(formik.errors.location)}
                            helperText={formik.touched.location && formik.errors.location}
                        />
                        {type == 'dropoff' &&
                            <Box>
                                <Button size="small"
                                    onClick={setSameAsPickup}
                                >Same as pickup</Button>
                            </Box>
                        }
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                            }}
                        >
                            {isSaving &&
                                <CircularProgress />
                            }
                            {!isSaving &&
                                <>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Save
                                    </Button>
                                </>
                            }
                            <Button
                                variant="outlined"
                                sx={{ mt: 3, mb: 2, ml: 3 }}
                                startIcon={<ArrowBackIosNewIcon />}
                                onClick={onBack}
                            >
                                Back
                            </Button>
                            {type && type !== 'dropoff' &&
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 3, mb: 2, ml: 3 }}
                                    endIcon={<ArrowForwardIosIcon />}
                                    onClick={onNext}
                                >
                                    Next
                                </Button>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ height: "500px", mt: 1 }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'], }}
                            defaultCenter={pickupCenter}
                            defaultZoom={zoom}
                            options={createMapOptions}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => onMapReady(map, maps)}
                        ></GoogleMapReact>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default StudentLocation; 