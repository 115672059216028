import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Box, Typography, Chip, Button, Card, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';


import { uploadSchoolLogo, fetchSchool, deleteSchoolLogo } from '../../../services/http.service';
import { IMAGES_URL, ImageSourceType } from '../../../utils/constants';
import { CustomModal } from '../../../common/modals';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalType } from '../../../utils/constants';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../common/snackbars';
import Loader from '../../../common/loader';

const Input = styled('input')({
    display: 'none',
});

const SchoolLogo = () => {

    const [validated, setValidated] = useState(false);
    const [school, setSchool] = useState({});
    const [schoolLogo, setSchoolLogo] = useState({
                                                    url: null,
                                                    type: null,
                                                });
    const [selectedFile, setSelectedFile] = useState(null);
    const [deleteJson, setDeleteJson] = useState({
                                                    show: false,
                                                    data: {}
                                                });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState(null);
    
    const { id } = useParams();

    const navigate = useNavigate();

     // run on compnonent init
     useEffect(() => {
        if (id) {
            setSchool({
                ...school,
                id,
            });
            // load school
            loadSchool(id);
        } 
    }, []); // runs once similar to componentDidMount


    // Load school
    const loadSchool = (id) => {
        if (!id) {
            return;
        }
        fetchSchool(id).then(resp => {
            const school = resp.data.school;
            if (!school) {
                return;
            }
            setSchool(school);
            // update logo url if exists
            if (school.logo && school.logo.trim().length > 0) {  
                setSchoolLogo({
                    ...schoolLogo,
                    url: `${IMAGES_URL.SCHOOL}/${school.logo}`,
                    type: ImageSourceType.REMOTE,
                });  
            }
        }).catch(err => {
            console.log(err);
        });
    }

    // Select logo
    const selectLogo = (file) => {
        console.log(`Selected logo - ${URL.createObjectURL(file)}`);
        setSchoolLogo({
            ...schoolLogo,
            url: URL.createObjectURL(file),
            type: ImageSourceType.LOCAL,
        });
        setSelectedFile(file);
    }

    // Handle Cancel
    const handleCancel = () => {   
        setSchoolLogo({
            ...schoolLogo,
            url: school.logo && school.logo.trim().length > 0 ? `${IMAGES_URL.SCHOOL}/${school.logo}` : null,
            type: school.logo && school.logo.trim().length > 0 ?  ImageSourceType.REMOTE : null,
        });   
        setSelectedFile(null); 
    }

    // Submit to server
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log(`not valid`);
            return
        }
        console.log(`Submitting to the server`); 

        // Reset flags
        setIsSubmitting(true);
        setIsError(false);
        setMessage(null);
        

        uploadSchoolLogo(school.id, selectedFile).then(resp => {
            setSchoolLogo({
                ...schoolLogo,
                url: `${IMAGES_URL.SCHOOL}/${resp.data.filename}`,
                type: ImageSourceType.REMOTE,
            });
            setIsSubmitting(false);
            setIsError(false);
            setMessage("Uploaded successfully");

            // update states and logo
            // this.setState(ps => ({
            //     schoolLogo: {
            //         ...ps.schoolLogo,
            //         url: `${IMAGES_URL.SCHOOL}/${resp.data.filename}`,
            //         type: ImageSourceType.REMOTE,
            //     },
            //     isSubmitting: false,
            //     isError: false,
            //     message: "Uploaded successfully",
            // }));    
        }).catch(err => {
            setIsSubmitting(false);
            setIsError(true);
            setMessage(err);
        })
    }


    // Handle Delete modal   
    const handleDelete = (id) => {
        console.log('handle delete - ' + id);
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        // this.setState(() => ({
        //     delete: {
        //         ...this.state.delete,
        //         show: true,
        //         data: {
        //             id: id
        //         }
        //     }
        // }));

        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School logo
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // this.setState(() => ({
        //     delete: {
        //         ...this.state.delete,
        //         show: false
        //     }
        // }));

        // Reset flags
        setIsDeleting(true);
        setIsError(false);
        setMessage(null);

        // this.setState({ 
        //     isSubmitting: true,
        //     isError: false,
        //     message: null,
        // })

        deleteSchoolLogo(school.id).then(resp => {
            // update flags
            setSchoolLogo({
                ...schoolLogo,
                url: null,
                type: null,
            });
            // also delete from school object its uploaded remotely
            setSchool({...school, logo: null});
            setIsDeleting(false);
            setIsError(false);
            setMessage("Deleted successfully");
            // this.setState(ps => ({
            //     schoolLogo: {
            //         ...ps.schoolLogo,
            //         url: null,
            //         type: null,
            //     },
            //     isSubmitting: false,
            //     isError: false,
            //     message: "Deleted successfully",
            // }));
        }).catch(err => {
            setIsDeleting(false);
            setIsError(true);
            setMessage(err);
            // this.setState({ 
            //     isSubmitting: false,
            //     isError: true,
            //     message: err,
            // })
        })
    }

    return (
        <>
            { isError && message && 
                <ErrorAlertTopCenter 
                    errorMsg={message}
                    onClose={() => setMessage(null)} 
                />
            }
            { message && 
                <SuccessAlertTopRight
                    successMsg={message}
                    onClose={() => setMessage(null)} 
                />
            }

            <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Chip 
                    icon={<ArrowBackIosIcon />} 
                    label="Back" 
                    sx={{ mr: 3 }}
                    onClick={() => navigate(-1)}
                />
                <Typography component="h2" variant="header">
                    Upload School Logo
                </Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} noValidate 
                sx={{ 
                    mt: 4, 
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <label htmlFor="contained-button-file">
                    <Input 
                        accept="image/*" 
                        id="contained-button-file" 
                        multiple type="file" 
                        onChange={(e) => {
                            selectLogo(e.target.files[0]);
                        }}
                    />
                    <Button variant="outlined" component="span">
                        Choose photo
                    </Button>
                </label>
                <Box sx={{ mt: 5,  }}>
                    { isSubmitting &&                         
                        <Loader />
                    }
                     { !isSubmitting && schoolLogo.type === ImageSourceType.LOCAL &&
                        <Button variant="contained" type="submit" sx={{ mt: 2 }}>Upload</Button>
                    }
                </Box>
                <Box sx={{ mt: 3 }}>
                    {schoolLogo.url &&
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                component="img"
                                image={schoolLogo.url}
                                alt={school.name}
                            />
                        </Card>
                    }
                </Box>
                <Box sx={{ mt: 3 }}>                              
                    {schoolLogo.type === ImageSourceType.REMOTE && 
                        <>  
                            <Chip 
                                icon={<DeleteIcon />} 
                                label="Delete" 
                                color="error"
                                sx={{ mr: 3 }}
                                onClick={() => handleDelete(school.id)}
                            />                              
                            {/* <Button variant="contained" onClick={() => handleDelete(school.id)}>Delete</Button>    */}
                            {isDeleting &&                         
                                <Loader />
                            }      
                        </>                                  
                    } 
                    {schoolLogo.type === ImageSourceType.LOCAL &&    
                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>                                  
                    }    
                </Box>
            </Box>

            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => { onDelete(data) }}
                onHide={() => { setDeleteJson({ ...deleteJson, show: false }) }}
            >
            </CustomModal>
        </>
    );

    

    // return (
    //     <React.Fragment>
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <h1 className="h4 mb-0 text-gray-800">Upload School Logo</h1>
    //         </div>
    //         <div className="row">
    //             <div className="col-12">
    //                 <div class="card shadow h-100 py-2 my-2">
    //                     <div class="card-body">
    //                         <button className="btn btn-light mb-4" onClick={() => navigate(-1)}>
    //                             <i class="fas fa-chevron-left"></i>
    //                         </button> 

    //                         {message &&
    //                             <Alert className="mb-3" variant={isError ? "danger":"success"} onClose={() => setMessage(null) } 
    //                                 dismissible>
    //                                 {message}
    //                             </Alert>
    //                         }
    //                         <Form
    //                             noValidate
    //                             validated={validated}
    //                             onSubmit={handleSubmit}>
    //                                 <div className="row">
    //                                     <div className="col-md-8">
    //                                         <Form.Group>
    //                                             <Form.Label>Select Logo</Form.Label>
    //                                             <Form.Control required type="file" placeholder="Logo"
    //                                                 onChange={(e) => {
    //                                                     selectLogo(e.target.files[0]);
    //                                                     //this.setState({ selectedFile: e.target.files[0]});
    //                                                 }}
    //                                             />
    //                                             <Form.Control.Feedback type="invalid">
    //                                                 Please select Logo.
    //                                             </Form.Control.Feedback>
    //                                         </Form.Group>
    //                                     </div>
    //                                     <div className="col-md-2">
    //                                         {isSubmitting &&                         
    //                                             <Spinner animation="border" variant="primary" />
    //                                         }
    //                                     </div>
    //                                     {schoolLogo.type === ImageSourceType.LOCAL &&
    //                                         <div className="col-md-2">
    //                                             <Button className="btn btn-primary" type="submit">Upload</Button> 
    //                                         </div> 
    //                                     }
    //                                 </div>
    //                         </Form>
    //                         {schoolLogo.url &&
    //                             <div>
    //                                 <img src={schoolLogo.url} alt={school.name}></img>
    //                             </div> 
    //                         }
    //                         <div className="d-flex align-items-center justify-content-left mt-5">                                    
    //                             {schoolLogo.type === ImageSourceType.REMOTE && 
    //                                 <React.Fragment>                               
    //                                     <Button size="sm" variant="danger" onClick={() => handleDelete(school.id)}>Delete</Button>   
    //                                     {isSubmitting &&                         
    //                                         <Spinner animation="border" variant="primary" />
    //                                     }      
    //                                 </React.Fragment>                                  
    //                             } 
    //                             {schoolLogo.type === ImageSourceType.LOCAL &&                                    
    //                                 <Button size="sm" variant="light" onClick={handleCancel}>Cancel</Button>                                  
    //                             }
    //                         </div> 
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>


    //         {/* Delete modal */}
    //         <CustomModal 
    //             show={deleteJson.show}
    //             data={deleteJson.data}
    //             type={ModalType.CONFIRM_DELETE}
    //             onOkay={(data) => {onDelete(data)}}
    //             onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
    //         >
    //         </CustomModal>
    //     </React.Fragment>
    // );
}

export default (SchoolLogo)



// class SchoolLogo extends Component {
//     constructor(props) {
// 		super(props)
// 		this.state = {
//             validated: false,
//             school: {},
//             schoolLogo: {
//                 url: null,
//                 type: null,
//             },
//             selectedFile: null,

//             delete: {
//                 show: false,
//                 data: {}
//             },

//             isSubmitting: false,
//             isError: false,
//             message: null,
//         }
//     }

//     componentDidMount() {
//         const id = this.props.match.params.id;
//         if (id) {
//             this.setState((prevState) => ({
//                 school: {
//                     ...prevState.school,
//                     id: id
//                 }
//             }))
//             // load school
//             this.loadSchool(id);
//         } 

//     }

//     // Load school
//     loadSchool = (id) => {
//         if (!id) {
//             return;
//         }
//         fetchSchool(id).then(resp => {
//             const school = resp.data.school;
//             if (!school) {
//                 return;
//             }
//             this.setState({ school });
//             // update logo url if exists
//             if (school.logo && school.logo.trim().length > 0) {                
//                 this.setState(ps => ({
//                     schoolLogo: {
//                         ...ps.schoolLogo,
//                         url: `${IMAGES_URL.SCHOOL}/${school.logo}`,
//                         type: ImageSourceType.REMOTE,
//                     }
//                 }))
//             }
//         }).catch(err => {
//             console.log(err);
//         });
//     }

//     // Select logo
//     selectLogo = (file) => {
//         console.log(`Selected logo - ${URL.createObjectURL(file)}`);
//         this.setState(ps => ({
//             schoolLogo: {
//                 ...ps.schoolLogo,
//                 url: URL.createObjectURL(file),
//                 type: ImageSourceType.LOCAL,
//             },
//             selectedFile: file,
//         }))
//     }

//     // Handle Cancel
//     handleCancel = () => {
//         const { school } = this.state;               
//         this.setState(ps => ({
//             schoolLogo: {
//                 ...ps.schoolLogo,
//                 url: school.logo && school.logo.trim().length > 0 ? `${IMAGES_URL.SCHOOL}/${school.logo}` : null,
//                 type: school.logo && school.logo.trim().length > 0 ?  ImageSourceType.REMOTE : null,
//             },
//             selectedFile: null,
//         }));       
//     }

//     // Submit to server
//     handleSubmit = (event) => {
//         event.preventDefault();
//         event.stopPropagation();
//         this.setState({ validated: true });
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             console.log(`not valid`);
//             return
//         }
//         console.log(`Submitting to the server`); 

//         // Reset flags
//         this.setState({ 
//             isSubmitting: true,
//             isError: false,
//             message: null,
//         })

//         uploadSchoolLogo(this.state.school.id, this.state.selectedFile).then(resp => {
//             // update states and logo
//             this.setState(ps => ({
//                 schoolLogo: {
//                     ...ps.schoolLogo,
//                     url: `${IMAGES_URL.SCHOOL}/${resp.data.filename}`,
//                     type: ImageSourceType.REMOTE,
//                 },
//                 isSubmitting: false,
//                 isError: false,
//                 message: "Uploaded successfully",
//             }));        
//             // this.setState({ 
//             //     isSubmitting: false,
//             //     isError: false,
//             //     message: "Uploaded successfully",
//             // })

//             // this.setState((prevState) => ({
//             //     school: {
//             //         ...prevState.school,
//             //         logo: resp.data.filename
//             //     }
//             // }))
//         }).catch(err => {
//             this.setState({ 
//                 isSubmitting: false,
//                 isError: true,
//                 message: err,
//             })
//         })
//     }


//     // Handle Delete modal   
//     handleDelete = (id) => {
//         console.log('handle delete - ' + id);
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));

//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of School logo
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));

//         // Reset flags
//         this.setState({ 
//             isSubmitting: true,
//             isError: false,
//             message: null,
//         })

//         deleteSchoolLogo(this.state.school.id).then(resp => {
//             // update flags
//             this.setState(ps => ({
//                 schoolLogo: {
//                     ...ps.schoolLogo,
//                     url: null,
//                     type: null,
//                 },
//                 isSubmitting: false,
//                 isError: false,
//                 message: "Deleted successfully",
//             })); 

//             // this.setState({ 
//             //     isSubmitting: false,
//             //     isError: false,
//             //     message: "Deleted successfully",
//             // })
//             // this.setState((prevState) => ({
//             //     school: {
//             //         ...prevState.school,
//             //         logo: null
//             //     }
//             // }))
//         }).catch(err => {
//             this.setState({ 
//                 isSubmitting: false,
//                 isError: true,
//                 message: err,
//             })
//         })
//     }

//     render() {

//         const { school, schoolLogo, validated, isSubmitting, isError, message } = this.state;

//         //console.log(`image urls - ${IMAGES_URL.SCHOOL}/${school.logo}`);

//         //const logo_url = `${IMAGES_URL.SCHOOL}/${school.logo}`;

//         console.log(`Selected logo - ${schoolLogo.url}`);

//         return (
//             <React.Fragment>
//                 {/* <Col xl={{ span: 8, offset: 2 }}>
//                     <h3 className="text-muted text-center mb-3">Upload School Logo</h3>                    
//                     <div className="card rounded">
//                         <div className="card-body">
//                             {message &&
//                                 <Alert className="mb-3" variant={isError ? "danger":"success"} onClose={() => this.setState({message: null}) } 
//                                     dismissible>
//                                     {this.state.message}
//                                 </Alert>
//                             }
//                             <Form
//                                 noValidate
//                                 validated={validated}
//                                 onSubmit={e => this.handleSubmit(e)}>
//                                     <Form.Group>
//                                     <Form.Label>Select Logo</Form.Label>
//                                     <Form.Control required type="file" placeholder="Logo"
//                                         onChange={(e) => {
//                                             this.setState({ selectedFile: e.target.files[0]});
//                                         }}
//                                     />
//                                     <Form.Control.Feedback type="invalid">
//                                         Please select Logo.
//                                     </Form.Control.Feedback>
//                                 </Form.Group>
//                                 <div className="d-flex align-items-center justify-content-left">
//                                     <Button className="btn-general mb-3" type="submit">Upload</Button>   
//                                     {isSubmitting &&                         
//                                         <Spinner animation="border" variant="primary" />
//                                     }                                    
//                                 </div> 
//                             </Form>
//                             {school.logo &&
//                                 <div>
//                                     <img src={logo_url} alt={school.name}></img>
//                                 </div> 
//                             }
//                             {school.logo &&
//                                 <div className="d-flex align-items-center justify-content-left mt-3">
//                                     <Button size="sm" variant="danger" onClick={() => this.handleDelete(school.id)}>Delete</Button>   
//                                     {isSubmitting &&                         
//                                         <Spinner animation="border" variant="primary" />
//                                     }
//                                 </div> 
//                             } 

//                         </div>
//                     </div>
//                 </Col> */}
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Upload School Logo</h1>
//                 </div>
//                 <div className="row">
//                     <div className="col-12">
//                         <div class="card shadow h-100 py-2 my-2">
//                             <div class="card-body">
//                                 <button className="btn btn-light mb-4" onClick={() => this.props.history.goBack()}>
//                                     <i class="fas fa-chevron-left"></i>
//                                 </button> 

//                                 {message &&
//                                     <Alert className="mb-3" variant={isError ? "danger":"success"} onClose={() => this.setState({message: null}) } 
//                                         dismissible>
//                                         {this.state.message}
//                                     </Alert>
//                                 }
//                                 <Form
//                                     noValidate
//                                     validated={validated}
//                                     onSubmit={e => this.handleSubmit(e)}>
//                                         <div className="row">
//                                             <div className="col-md-8">
//                                                 <Form.Group>
//                                                     <Form.Label>Select Logo</Form.Label>
//                                                     <Form.Control required type="file" placeholder="Logo"
//                                                         onChange={(e) => {
//                                                             this.selectLogo(e.target.files[0]);
//                                                             //this.setState({ selectedFile: e.target.files[0]});
//                                                         }}
//                                                     />
//                                                     <Form.Control.Feedback type="invalid">
//                                                         Please select Logo.
//                                                     </Form.Control.Feedback>
//                                                 </Form.Group>
//                                             </div>
//                                             <div className="col-md-2">
//                                                 {isSubmitting &&                         
//                                                     <Spinner animation="border" variant="primary" />
//                                                 }
//                                             </div>
//                                             {schoolLogo.type === ImageSourceType.LOCAL &&
//                                                 <div className="col-md-2">
//                                                     <Button className="btn btn-primary" type="submit">Upload</Button> 
//                                                 </div> 
//                                             }
//                                         </div>
//                                 </Form>
//                                 {schoolLogo.url &&
//                                     <div>
//                                         <img src={schoolLogo.url} alt={school.name}></img>
//                                     </div> 
//                                 }
//                                 <div className="d-flex align-items-center justify-content-left mt-5">                                    
//                                     {schoolLogo.type === ImageSourceType.REMOTE && 
//                                         <React.Fragment>                               
//                                             <Button size="sm" variant="danger" onClick={() => this.handleDelete(school.id)}>Delete</Button>   
//                                             {isSubmitting &&                         
//                                                 <Spinner animation="border" variant="primary" />
//                                             }      
//                                         </React.Fragment>                                  
//                                     } 
//                                     {schoolLogo.type === ImageSourceType.LOCAL &&                                    
//                                         <Button size="sm" variant="light" onClick={() => this.handleCancel()}>Cancel</Button>                                  
//                                     }
//                                 </div> 
//                             </div>
//                         </div>
//                     </div>
//                 </div>


//                 {/* Delete modal */}
//                 <CustomModal 
//                     show={this.state.delete.show}
//                     data={this.state.delete.data}
//                     onOkay={(data) => {this.onDelete(data)}}>
//                 </CustomModal>
//             </React.Fragment>
//         )
//     }
// }

// export default connect()(SchoolLogo)