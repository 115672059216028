import React, { Component, useState } from 'react';
//import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Box, Avatar, Typography, TextField, FormControlLabel, 
    Checkbox, Grid, Paper, Snackbar, Alert, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "react-router-dom";

import { login, getAccessToken } from '../../services/auth.service';
import { ErrorAlertTopCenter } from '../../common/snackbars';

const Login = (props) => {

    const [validated, setValidated] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');

    let navigate = useNavigate();

    const handleSubmit = (event) => {
        console.log('handle submit');
        event.preventDefault();
        setValidated(true);
        //const form = event.currentTarget;
        const data = new FormData(event.currentTarget);

        const mobile = data.get('mobile');
        const password = data.get('password');

        setIsSaving(true);
        login(mobile, password).then(resp => {
            setErrorMsg(null);
            setIsSaving(false);
            console.log('in login auth - ' + getAccessToken());
            if (resp.data.user && !resp.data.user.is_verified) {
                navigate('/otp-verification/' + resp.data.user.id);
            } else {
                navigate('/school/list');
            }
        }).catch(err => {
            console.log(err);
            setErrorMsg(err);
            setIsSaving(false);
        })
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            <Container component="main" maxWidth="xs">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign In
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Mobile/Email"
                                name="mobile"
                                autoComplete="mobile"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign In
                                    </Button>
                                }
                            </Box>
                            <Grid container>
                                <Grid item xs>
                                    <Link to="/forgot-password" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/register" variant="body2">
                                        Don't have an account? Sign up
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box>
                                <Link to="/admin-login" variant="body2">
                                    Admin Login?
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );
    

    // return (
    //     <div id="layoutAuthentication" class="bg-primary">
    //         <div id="layoutAuthentication_content">
    //             <main>
    //                 <div class="container">
    //                     <div class="row justify-content-center">
    //                         <div class="col-lg-5">
    //                             <div class="card shadow-lg border-0 rounded-lg mt-5">
    //                                 <div class="card-header"><h3 class="text-center font-weight-light my-4">Login</h3></div>
    //                                 <div class="card-body">
    //                                     { errorMsg &&
    //                                         <Alert variant="danger" onClose={() => setErrorMsg(null)} dismissible>
    //                                             <strong>{errorMsg}</strong>
    //                                         </Alert>
    //                                     }
    //                                     <form onSubmit={handleSubmit}>
    //                                         <div class="form-group">
    //                                             <label class="small mb-1" for="inputEmailAddress">Mobile/Email</label>
    //                                             <input class="form-control form-control-user py-4" required type="text" placeholder="Enter mobile/email" />
    //                                         </div>
    //                                         <div class="form-group">
    //                                             <label class="small mb-1" for="inputPassword">Password</label>
    //                                             <input class="form-control form-control-user py-4" id="inputPassword" required type="password" placeholder="Enter password" />
    //                                         </div>
    //                                         {/* <div class="form-group">
    //                                             <div class="custom-control custom-checkbox"><input class="custom-control-input" id="rememberPasswordCheck" type="checkbox" /><label class="custom-control-label" for="rememberPasswordCheck">Remember password</label></div>
    //                                         </div> */}
    //                                         <div class="form-group d-flex flex-column align-items-center justify-content-center mt-4 mb-0"> 
    //                                             <Button className="btn btn-primary btn-user btn-block btn-user" type="submit">Login</Button>                                                  
    //                                             {isSaving &&                          
    //                                                 <Spinner className="mt-3" animation="border" variant="primary" />
    //                                             }                                                    
    //                                         </div>
    //                                     </form>
    //                                 </div>
    //                                 <div class="card-footer text-center">
    //                                     <div class="small d-flex flex-column align-items-center justify-content-between">
    //                                         <a href="/register">Need an account? Sign up!</a>
    //                                         <a href="/admin-login">Admin Login</a>
    //                                         <a href="/forgot-password">Forgot Password?</a>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </main>
    //         </div>
    //     </div>
    // );
}

export default Login;


