import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';
import { Box, Typography } from '@mui/material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import RouteIcon from '@mui/icons-material/Route';
import ListIcon from '@mui/icons-material/List';
import HistoryIcon from '@mui/icons-material/History';
import LaunchIcon from '@mui/icons-material/Launch';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AddCardIcon from '@mui/icons-material/AddCard';
import GroupsIcon from '@mui/icons-material/Groups';
import TableViewIcon from '@mui/icons-material/TableView';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import AccessibleIcon from '@mui/icons-material/Accessible';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../../services/auth.service';
import { Role, LeftNavViews, SCHOOL, SocketEvents } from '../../../utils/constants';
import { fetchSchool } from '../../../services/http.service';
import { Events, connectLocationSocket, connectChatSocket, connectNotificationSocket,
    disconnectLocationSocket, disconnectChatSocket, disconnectNotificationSocket } from '../../../utils/socketio';
import { currentLocationObservable, receiveChatMessageObservable, 
sendChatMessageObservable, receiveChatMessageNotificationsObservable,
updateChatMessageReadObservable, receiveNotificationsObservable,
updateNotificationReadObservable } from '../../../services/observable.service';
import { leftNav, extras, school } from '../../../actions/index'

import AuthLayout from '../index';


const SchoolAdminLayout = () =>  {
    const [isSchoolValid, setIsSchoolValid] = useState(true);
    const dispatch = useDispatch();
    const params = useParams();
    const { id } = params;
    const schoolId = parseInt(id)
    const adminBaseUrl = `/school/${id}/admin`; // TODO this hack later need to pull it dynmic
    

    //dispatch(leftNavView(LeftNavViews.ADMIN, match.url));
    const user = getUser();
    console.log(`Admin ----- user roles -- ${JSON.stringify(user)}`)
    dispatch(leftNav({
        view: LeftNavViews.SCHOOL_ADMIN,
        userRoles: getUser().roles.map(role => role.name),
        baseUrl: adminBaseUrl,
        user: user,
    }));
   
    
    SCHOOL.id = schoolId; // Store into cache

    dispatch(extras({
        schoolId: schoolId,
        baseUrl: adminBaseUrl
    }));

    // run on compnonent init
    useEffect(() => {
        onLoadScreen();
    }, []); // runs once similar to componentDidMount

    // run on load of admin component
    const onLoadScreen = () => {
        setIsSchoolValid(true);
        console.log(`admin onLoadScreen - `);
        // Get school from server
        fetchSchool(schoolId).then(resp => {
            console.log(`School in admin - ${JSON.stringify(resp.data.school)}`);
            dispatch(school(resp.data.school));
        }).catch(err => {
            console.error(err);
            // Invalidate school if its not active or any other error for now
            setIsSchoolValid(false);
        });



        console.log(`Logged in User - ${JSON.stringify(user)}`);
    const info = { schoolId: schoolId, name: user.name, userId: user.id, role: Role.ADMIN };

    // ************ Socket IO ****************************

    // ####### Location SocketIO
    connectLocationSocket(info).then(socket => {
        console.log(`Got Location Socket - ${socket}`);
        socket.on(Events.NOTIFY_CURRENT_LOCATION, (data) => {
            console.log(`On NotifyCurrentLocation - ${JSON.stringify(data)}`);
            currentLocationObservable().next(data);
        })
    })

    
    // ####### Chat Socket

    connectChatSocket(info).then(socket => {
        console.log(`Got Chat Socket - ${socket}`);
        // Receive chat message
        socket.on(Events.CHAT_MESSAGE, (message) => {
            console.log(`Received chat message - ${JSON.stringify(message)}`);
            receiveChatMessageObservable().next(message);
        });
        // Ask for Chat Message notification of unread count
        socket.emit(Events.CHAT_MESSAGE_NOTIFICATION, { schoolId: schoolId, userId: user.id });

        // Receive Chat message Notififications of unread count and broadcast
        socket.on(Events.CHAT_MESSAGE_NOTIFICATION, (notification) => {
            console.log(`Received chat message notification - ${JSON.stringify(notification)}`);
            receiveChatMessageNotificationsObservable().next(notification);
        });
    });
    // Send Chat message to Socket
    console.log(`Admin called sendChatMessageObservable  - `);
    sendChatMessageObservable()
        .subscribe(value => {
            console.log(`Send Chat message obsevrvable - ${JSON.stringify(value)}`)            
            connectChatSocket(info).then(socket => {
                console.log(`Got Chat Socket to send message - ${socket}`);
                socket.emit(Events.CHAT_MESSAGE, value);
            });
        });

    // Send notification to update chat message to read for this driver user
    updateChatMessageReadObservable()
        .subscribe(value => {
            console.log(`Update chat message to read obsevrvable - ${JSON.stringify(value)}`);
            connectChatSocket(info).then(socket => {
                console.log(`Got Chat Socket to update message unread - ${socket}`);
                socket.emit(Events.UPDATE_CHAT_MESSAGE_READ, value);
            });
        });

    // ####### Notification Socket
    
    // Receive Notification from Socket
    connectNotificationSocket(info).then(socket => {
        console.log(`Got Notification socket - ${socket}`);
        // Ask for new notifications
        socket.emit(SocketEvents.NOTIFICATION, { schoolId: schoolId, to: {role: Role.ADMIN} });

        // Receive notification
        socket.on(SocketEvents.NOTIFICATION, (notification) => {
            console.log(`Notification data - ${JSON.stringify(notification)}`);
            receiveNotificationsObservable().next(notification);
        })
    });
    // Update notification read flag
    updateNotificationReadObservable()
    .subscribe(value => {
        console.log(`Got Update notification read obsevrvable`)
        connectNotificationSocket(info).then(socket => {
            console.log(`Update Notification read socket - ${socket}`);
            socket.emit(SocketEvents.UPDATE_NOTIFICATION_READ, { schoolId: schoolId , to: {role: Role.ADMIN} });
        });        
    });

    // ****** Socket IO *******************
    }


    const schools = {
        id: 'setting-schools',
        title: 'Schools setting',
        type: 'group',
        children: [
            {
                id: 'list',
                title: 'Your schools',
                type: 'item',
                url: '/school/list',
                icon: SchoolIcon,
                breadcrumbs: false
            },
            {
                id: 'school-admin',
                title: 'Admins',
                type: 'item',
                url: 'list',
                icon: SupervisorAccountIcon,
                breadcrumbs: false
            },
        ]
    };

    const trips = {
        id: 'trips-routes',
        title: 'Trips and Route planner',
        type: 'group',
        children: [
            {
                id: 'trips',
                title: 'Trips',
                type: 'collapse',
                icon: DirectionsBusIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'todays-trips',
                        title: `Todays trip's`,
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/trip/today/list"}`,
                        icon: CalendarTodayIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'trip-history',
                        title: `History`,
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/trip/history/list"}`,
                        icon: HistoryIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'trip-metadata',
                        title: `New metadata`,
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/trip/metadata/new"}`,
                        icon: SettingsApplicationsIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'trip-metadata-list',
                        title: `List metadata`,
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/trip/metadata/list"}`,
                        icon: ListIcon,
                        breadcrumbs: false,
                    },
                ]
            },
            {
                id: 'route',
                title: 'Route Planner',
                type: 'collapse',
                icon: RouteIcon,
                children: [
                    {
                        id: 'route-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/route/list"}`,
                        icon: ListIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'new-route',
                        title: 'Build route',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/route/new"}`,
                        icon: LaunchIcon,
                        breadcrumbs: false,
                    }
                ]

            },
        ]
    };
    const manageSchools = {
        id: 'manage-schools',
        title: 'Manage school',
        type: 'group',

        children: [
            {
                id: 'section',
                title: 'Section',
                type: 'collapse',
                icon: WorkspacesIcon,
                children: [
                    {
                        id: 'section-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/section/list"}`,
                        icon: ListIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'section-new',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/section/new"}`,
                        icon: AddCardIcon,
                        breadcrumbs: false,
                    }
                ]
            },
            {
                id: 'class',
                title: 'Class',
                type: 'collapse',
                icon: TableViewIcon,
                children: [
                    {
                        id: 'class-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/class/list"}`,
                        icon: ListIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'class',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/class/new"}`,
                        icon: AddCardIcon,
                        breadcrumbs: false,
                    }
                ]
            },
            {
                id: 'student',
                title: 'Student',
                type: 'collapse',
                url: `${"/school/" + schoolId + "/admin/student/list"}`,
                icon: GroupsIcon,
                children: [
                    {
                        id: 'student-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/student/list"}`,
                        icon: ListIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'student-new',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/student/new"}`,
                        icon: AddCardIcon,
                        breadcrumbs: false,
                    }
                ]
            },
        ]
    };

    const manageTransportation = {
        id: 'manage-transportation',
        title: 'Manage Transportation',
        type: 'group',
        children: [
            {
                id: 'driver',
                title: 'Driver',
                type: 'collapse',
                icon: AccessibleIcon,
                children: [
                    {
                        id: 'driver-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/driver/list"}`,
                        icon: ListIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'driver-new',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/driver/new"}`,
                        icon: AddCardIcon,
                        breadcrumbs: false,
                    }
                ]
            },
            {
                id: 'bus',
                title: 'Bus',
                type: 'collapse',
                icon: DirectionsBusFilledIcon,
                children: [
                    {
                        id: 'bus-list',
                        title: 'List',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/bus/list"}`,
                        icon: ListIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'bus-new',
                        title: 'New',
                        type: 'item',
                        url: `${"/school/" + schoolId + "/admin/bus/new"}`,
                        icon: AddCardIcon,
                        breadcrumbs: false,
                    }
                ]
            },
        ]
    };

    const menuItems = [trips, manageSchools, manageTransportation];
    const isSchoolOwner = user.roles.find(item => item.name == Role.SCHOOL_OWNER);
    if (isSchoolOwner) {
        menuItems.unshift(schools);
    }
    //const menuItems = [schools, trips, manageSchools, manageTransportation];

    return (
        <AuthLayout menuItems={menuItems}>
            { isSchoolValid 
                ? <Outlet />
                : <InvalidSchool />
            }
        </AuthLayout>
    );
};

const InvalidSchool = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="center"
            sx={{ mt: 5 }}
        >
            <Typography component="h4" variant="h4" color="error" sx={{mb: 2}}>
                <p>Please contact SchoolBusTracer (by SAPI Technologies) to activate your school.</p>
            </Typography>
            <Typography component="h5" variant="h5" color="error">
                Email - contact@sapitechnologies.com<br />
                Mobile - 9818924602
            </Typography>
        </Box>
    )
}


  
export default SchoolAdminLayout;