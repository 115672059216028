import { SCHOOL } from '../actions/action-types'

const school = (state = {}, action) => {
    console.log("School reducer :: action type " + action.type + " -- payload : " + JSON.stringify(action.payload));
    switch (action.type) {
      case SCHOOL:
          return action.payload   
      default:
        return state
      }
  }

  export default school