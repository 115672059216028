export const getDistanceAndDuration = (maps, origins, destinations) => {
    return new Promise((resolve, reject) => {
        const service = new maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: origins,
                destinations: destinations,
                travelMode: 'DRIVING',
            }, (response, status) => {
                if (status === 'OK') {
                    let distance = 0;
                    let duration = 0;
                    response.rows[0].elements.forEach((item, index) => {
                        if (item.status == 'OK') {
                            distance += item.distance.value;
                            duration += item.duration.value;
                        }
                    });
                    console.log(`Total Distance - ${distance} -- Total Duration - ${duration}`);
                    const result = {
                        "distance": distance,
                        "duration": duration
                    }
                    resolve(result);
                }
                else {
                    reject("An error ocurred");
                }
            }
        );
    }); 
}

export const drawRoute = (map, maps, latLngs) => {
    console.log("Util - drawRoute - latLngs", latLngs);
    return new Promise((resolve, reject) => {
        var origin = latLngs[0];
        var dest = latLngs[latLngs.length - 1];

        var wayPoints = [];
        if (latLngs.length > 1) {
            latLngs.forEach(latlng => {
                wayPoints.push({
                    location: latlng.lat + ', ' + latlng.lng,
                    stopover: true
                });
            });
        }
        console.log("Way points :: " + JSON.stringify(wayPoints));
        var request = {
            origin: origin,
            destination: dest,
            travelMode: 'DRIVING',
            waypoints: wayPoints
        };
        var directionsService = new maps.DirectionsService();
        directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                var path = [];
                result.routes[0].overview_path.forEach(point => {
                    path.push({ lat: point.lat(), lng: point.lng() });
                });
                // Draw Polyline of route	        
                const tripPolyline = new maps.Polyline({
                    path: path,
                    geodesic: true,
                    strokeColor: '#3365b7',
                    strokeOpacity: 1.0,
                    strokeWeight: 3
                });
                tripPolyline.setMap(map);
            }
        });
    }); 

}

export const createMapOptions = (maps) => {
    return {
        zoomControlOptions: {
          position: maps.ControlPosition.RIGHT_CENTER,
          style: maps.ZoomControlStyle.SMALL
        },
        mapTypeControlOptions: {
          position: maps.ControlPosition.TOP_RIGHT
        },
        mapTypeControl: true
      };
}

export const initializeAutocomplete = (inputId, map, onPlaceChanged) => {
    const autocompleteInput = document.getElementById(inputId);
    if (autocompleteInput) {
        const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput);
        autocomplete.bindTo('bounds', map);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const { lat, lng } = place.geometry.location;
                onPlaceChanged({ lat: lat(), lng: lng() }, place.formatted_address);
            }
        });
        return autocomplete;
    }
    return null;
};