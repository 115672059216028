import React, { Component, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Button, Box, Avatar, Typography, TextField, Grid, Link, Paper, CircularProgress } from '@mui/material';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../common/snackbars';

import { resendOTP, verifyOTP, getOtpExpiryTime } from '../../services/http.service';
import { OTPVerificationType, convertSecsToHms } from '../../utils/constants';
import UnAuthLayout from '../../layouts/unauth';



const ForgotPasswordOTPVerification = () => {

    const [validated, setValidated] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);
    const [otpExpiryTime, setOtpExpiryTime] = useState('');

    const { userId } = useParams();
    const navigate = useNavigate();

    // Get Otp expiry time
    const fetchOtpExpiryTime = () => {
        getOtpExpiryTime().then(resp => {
            let seconds = parseInt(resp.data);  
            setOtpExpiryTime(convertSecsToHms(seconds) );        

            // TODO - will into count down timer later
            // let timer = setInterval(() => {
            //     seconds = seconds - 1;
            //     console.log(`remaining - ${seconds}`);
            //     this.setState({ otpExpiryTime: convertSecsToHms(seconds) });
            //     if (seconds === 0) {
            //         clearInterval(timer);
            //     }
            // }, 1000);

        }).catch(err => {
            console.error(err);
        });
    }


    // Handle Submit
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaving(true);
        const data = new FormData(event.currentTarget);
        const otp = data.get('otp');
        // const userId = this.props.match.params.id;
        console.log(`OTP - ${otp} -- userid - ${userId}`);
        verifyOTP(userId, otp, OTPVerificationType.FORGOT_PASSWORD).then(resp => {
            setIsSaving(false);
            setErrorMsg(null);
            if (resp.data.data) {
                navigate(`/reset-password/${resp.data.data.user_id}`);
            }
        }).catch(err => {
            setIsSaving(false);
            setErrorMsg(err);
            console.log(err);
        })
    }

    // Resend OTP code
    const resendOtpCode = () => {
        // const userId = this.props.match.params.id;
        setIsResending(true);
        setResendSuccess(false);
        resendOTP(userId, OTPVerificationType.FORGOT_PASSWORD).then(resp => {
            setIsResending(false);
            setResendSuccess(true);
            setErrorMsg(null);            
        }).catch(err => {
            setIsResending(false);
            setErrorMsg(err);  
            console.log(err);
        })
    }

    // run on compnonent init
    useEffect(() => {
        fetchOtpExpiryTime();
    }, []); // runs once similar to componentDidMount

    return (
       <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { resendSuccess &&
                <SuccessAlertTopRight 
                    successMsg={"Otp sent successfully"}
                    onClose={() => setResendSuccess(false)} 
                />
            }

            <Container component="main" maxWidth="xs">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            OTP verification
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="otp"
                                label="Enter Otp"
                                name="otp"
                                autoComplete="otp"
                                autoFocus
                            /> 
                            <Typography variant="body2">OTP expires in {otpExpiryTime}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 3
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                       Submit
                                    </Button>
                                    <Button
                                        onClick={resendOtpCode}
                                        fullWidth
                                        variant="outlined"
                                        sx={{ ml: 5 }}
                                    >
                                        Resend
                                    </Button>
                                    </>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );


    // return (
    //     <div id="layoutAuthentication" class="bg-primary">
    //         <div id="layoutAuthentication_content">
    //             <main>
    //                 <div class="container">
    //                     <div class="row justify-content-center">
    //                         <div class="col-lg-5">
    //                             <div class="card shadow-lg border-0 rounded-lg mt-5">
    //                                 <div class="card-header"><h3 class="text-center font-weight-light my-4">OTP Verification</h3></div>
    //                                 <div class="card-body">
    //                                 <Form
    //                                     noValidate
    //                                     validated={validated}
    //                                     onSubmit={handleSubmit}>
                                                                    
    //                                     <Form.Group>
    //                                         <Form.Control type="number" required placeholder="Enter OTP" />
    //                                         <Form.Control.Feedback type="invalid">
    //                                             Enter valid OTP
    //                                         </Form.Control.Feedback>
    //                                     </Form.Group>                   

    //                                     <div className="d-flex align-items-center justify-content-left">   
    //                                         {isSaving &&                          
    //                                             <Button variant="primary" disabled>
    //                                                 <Spinner as="span" animation="grow" size="sm"
    //                                                 role="status"
    //                                                 aria-hidden="true"
    //                                                 />
    //                                                 Saving....
    //                                             </Button>
    //                                         }
    //                                         {!isSaving && 
    //                                             <Button type="submit" variant="primary">
    //                                                 Send
    //                                             </Button>
    //                                         }

    //                                         {isResending &&                          
    //                                             <Button className="ml-2" variant="default" disabled>
    //                                                 <Spinner as="span" animation="grow" size="sm"
    //                                                 role="status"
    //                                                 aria-hidden="true"
    //                                                 />
    //                                                 Sending....
    //                                             </Button>
    //                                         }
    //                                         {!isResending && 
    //                                             <Button className="ml-2" type="submit" onClick={resendOtpCode} variant="default">
    //                                                 Resend
    //                                             </Button>
    //                                         }

    //                                         {errorMsg &&
    //                                             <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null) } 
    //                                                 dismissible>
    //                                                 {errorMsg}
    //                                             </Alert>
    //                                         }
    //                                         {resendSuccess &&
    //                                             <Alert className="ml-3 mb-0" variant="success" 
    //                                                 onClose={() => setResendSuccess(false) } 
    //                                                 dismissible>
    //                                                 Send successfully
    //                                             </Alert>
    //                                         }
    //                                     </div>  

    //                                 </Form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </main>
    //         </div>
    //     </div>

    // );
}

export default ForgotPasswordOTPVerification;



// class ForgotPasswordOTPVerification extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             validated: false,
//             isSaving: false,
//             isResending: false,
//             errorMsg: null,
//             resendSuccess: false
// 		};
//     }

//     // Handle Submit
//     handleSubmit(event) {
//         this.setState({ validated: true });
//         event.preventDefault();
//         event.stopPropagation();
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             console.log(`not valid`);
//             return
//         }
//         this.setState({isSaving: true});
//         const otp = form[0].value;
//         const userId = this.props.match.params.id;
//         console.log(`OTP - ${otp} -- userid - ${userId}`);
//         verifyOTP(this.props.match.params.id, otp, OTPVerificationType.FORGOT_PASSWORD).then(resp => {
//             this.setState({isSaving: false});
//             this.setState({errorMsg: null});
//             if (resp.data.data) {
//                 this.props.history.push(`/reset-password/${resp.data.data.user_id}`);
//             }
//         }).catch(err => {
//             this.setState({isSaving: false});
//             this.setState({errorMsg: err});
//             console.log(err);
//         })
//     }

//     // Resend OTP code
//     resendOtpCode() {
//         const userId = this.props.match.params.id;
//         this.setState({isResending: true});
//         this.setState({resendSuccess: false})
//         resendOTP(userId, OTPVerificationType.FORGOT_PASSWORD).then(resp => {
//             this.setState({isResending: false});
//             this.setState({resendSuccess: true})
//             this.setState({errorMsg: null});            
//         }).catch(err => {
//             this.setState({isResending: false});
//             this.setState({errorMsg: err});
//             console.log(err);
//         })
//     }


//     //const id = this.props.match.params.id;
//     render() {
//         const { validated, isSaving, isResending, resendSuccess, errorMsg } = this.state;
// 		return (
//             <div id="layoutAuthentication" class="bg-primary">
//                 <div id="layoutAuthentication_content">
//                     <main>
//                         <div class="container">
//                             <div class="row justify-content-center">
//                                 <div class="col-lg-5">
//                                     <div class="card shadow-lg border-0 rounded-lg mt-5">
//                                         <div class="card-header"><h3 class="text-center font-weight-light my-4">OTP Verification</h3></div>
//                                         <div class="card-body">
//                                         <Form
//                                          noValidate
//                                          validated={validated}
//                                          onSubmit={e => this.handleSubmit(e)}>
                                                                        
//                                          <Form.Group>
//                                              <Form.Control type="number" required placeholder="Enter OTP" />
//                                              <Form.Control.Feedback type="invalid">
//                                                  Enter valid OTP
//                                              </Form.Control.Feedback>
//                                          </Form.Group>                   

//                                          <div className="d-flex align-items-center justify-content-left">   
//                                              {isSaving &&                          
//                                                  <Button variant="primary" disabled>
//                                                      <Spinner as="span" animation="grow" size="sm"
//                                                      role="status"
//                                                      aria-hidden="true"
//                                                      />
//                                                      Saving....
//                                                  </Button>
//                                              }
//                                              {!isSaving && 
//                                                   <Button type="submit" variant="primary">
//                                                      Send
//                                                  </Button>
//                                              }

//                                              {isResending &&                          
//                                                  <Button className="ml-2" variant="default" disabled>
//                                                      <Spinner as="span" animation="grow" size="sm"
//                                                      role="status"
//                                                      aria-hidden="true"
//                                                      />
//                                                      Sending....
//                                                  </Button>
//                                              }
//                                              {!isResending && 
//                                                   <Button className="ml-2" type="submit" onClick={() => this.resendOtpCode()} variant="default">
//                                                      Resend
//                                                  </Button>
//                                              }

//                                              {errorMsg &&
//                                                  <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({errorMsg: null}) } 
//                                                      dismissible>
//                                                      {this.state.errorMsg}
//                                                  </Alert>
//                                              }
//                                              {resendSuccess &&
//                                                  <Alert className="ml-3 mb-0" variant="success" 
//                                                      onClose={() => this.setState({resendSuccess: false}) } 
//                                                      dismissible>
//                                                      Send successfully
//                                                  </Alert>
//                                              }
//                                          </div>  

//                                      </Form>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </main>
//                 </div>
//             </div>

//         )
//     }
// }

// export default ForgotPasswordOTPVerification;