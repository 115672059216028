import { Outlet } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';

import AuthLayout from '../index';

const SchoolLayout = () =>  {

    const schools = {
        id: 'setting-schools',
        title: 'Schools setting',
        type: 'group',
        children: [
            {
                id: 'list',
                title: 'Your schools',
                type: 'item',
                url: 'list',
                icon: SchoolIcon,
                breadcrumbs: false
            },
        ]
    };

    const menuItems = [schools];

    return (
        <AuthLayout menuItems={menuItems}>
            <Outlet />
        </AuthLayout>
    );
};
  
export default SchoolLayout;