import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Chip, Grid, TextField,
    Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ImageIcon from '@mui/icons-material/Image';
import { editUserProfile, getUserProfile } from '../../../../services/http.service';
import { useNavigate } from "react-router-dom";
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

// Yup from validation
const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    email: Yup.string().required("Email is required.").email("Invalid email.")
});

const SettingProfileEdit = () =>  {

    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        fetchUser();
    }, []);

    // Fetch User
    const fetchUser = () => {
        setIsLoading(true);
        getUserProfile().then(resp => {
            setIsLoading(false);
            const data = resp.data.data;
            if (data) {
                formik.setValues({ ...data }); // get copy of data
            }
        }).catch(err => {
            setIsLoading(false);
            setErrorMsg(err);
        });
    }

    /**
     * Handle form submission
     */
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // Reset flags
            setIsSaving(true);
            setShowSuccessAlert(false);
            setErrorMsg(null);
            // Prepare data
            const data = {
                name: values.name,
                email: values.email,
            }
            editUserProfile(data).then(resp => {
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
            }).catch(err => {
                console.log(err);
                setIsSaving(false);
                setShowSuccessAlert(false);
                setErrorMsg(err);
            })
        }
    });



    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setValidated(true);
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         console.log(`not valid`);
    //         return
    //     } 

    //     // Reset flags
    //     setIsSaving(true);
    //     setShowSuccessAlert(false);
    //     setErrorMsg(null);
    //     // Prepare data
    //     const data = {
    //         name: form[0].value,
    //         email: form[1].value,
    //     }
    //     editUserProfile(data).then(resp => {
    //         setIsSaving(false);
    //         setShowSuccessAlert(true);
    //         setErrorMsg(null);
    //     }).catch(err => {
    //         console.log(err);
    //         setIsSaving(false);
    //         setShowSuccessAlert(false);
    //         setErrorMsg(err);
    //     })

    // }

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"School saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                    width: '100%',
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Edit profile
                    </Typography>
                    { isLoading &&
                        <CircularProgress />
                    }
                    <Box>
                        <Chip
                            onClick={() => navigate(`../photo`)}
                            icon={<ImageIcon />}
                            label="Photo"
                            variant="outlined"
                            color="secondary"
                            sx={{ mx: 2 }}
                        />
                    </Box>
                </Box>
                <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, width: "100%" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="name"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                autoFocus
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Update
                                    </Button>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );

}

export default SettingProfileEdit;