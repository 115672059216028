import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getRouteByRefAndType } from '../services/http.service';
import Loader from './loader';
import StudentLocationMap from './googlemap/student-location-map';
import { buildRouteStudent, IMAGES_URL } from '../utils/constants';
import  GoogleMap  from './googlemap/google-map';


const RouteDetailDialog = ({ open, onClose, routeRef, routeType }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [route, setRoute] = useState(null);

    const school = useSelector(state => state.school);

    // Child component Refs
    const googleMapElement = useRef();  

    useEffect(() => {
        if (open) {
            fetchRouteByRefAndType(routeRef, routeType);
        }
    }, [open]);

    useEffect(() => {
        
    }, [route]);

    // Fetch route
    const fetchRouteByRefAndType = (routeId) => {
        setIsLoading(true);
        // reset
        setRoute(null);
        getRouteByRefAndType(routeRef, routeType).then(resp => {
            const respRoute = resp.data.data;
            console.log("response route - ", respRoute);
            setIsLoading(false);
            if (respRoute) {
                const newRoute = {
                    id: respRoute.id,
                    refNo: respRoute.ref_no,
                    type: respRoute.type.name,
                    students: respRoute.route_students.map(routeStudent => 
                        buildRouteStudent(respRoute.type.name, routeStudent.student)),
                    bus: respRoute.bus,
                    driver: respRoute.driver
                }
                setRoute(newRoute);
            }
        }).catch(err => {
            console.log(`Error in fetching route :: ${routeId} :: ${JSON.stringify(err)}`);
            setIsLoading(false);
        });
    }

    // Build route student from student object




    const handleClickOpen = () => {
       
    };

    const handleClose = () => {
        onClose(false);
    };

    console.log('route - ', route);

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            size="large"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                { isLoading &&
                    <Box sx={{ my: 2 }}><Loader /></Box>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mt: 1, p: 2 }}
                >
                    <Grid item xs={12} md={5} >
                        <List>
                            {route?.students.map((item, index) => (
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar alt={item.name} src={item.profilePhoto ? `${IMAGES_URL.STUDENT}/${item.profilePhoto}` : `${IMAGES_URL.STUDENT}/default_profile.png`} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.name}
                                        secondary={
                                            <>
                                                <Typography variant="subtitle2" component="span" sx={{ '& > span': { mr: 1 } }}>
                                                    <span><strong>Roll No: </strong> {item.rollNo},</span>
                                                    <span><strong>Class: </strong> {item.sclass && item.sclass.name}</span>
                                                    <span><strong>Section: </strong> {item.section && item.section.name}</span>
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid sx={{ height: "500px" }} item xs={12} md={7}>
                        { route?.students.length && !isLoading && // wait for loading of route to finish
                            <GoogleMap ref={googleMapElement}
                                students={route?.students}
                                school={school}
                            >
                            </GoogleMap>
                        }
                    </Grid>
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mt: 1, p: 2 }}
                >
                    <Grid item xs={12} md={5} >
                        <Typography component="h4" variant="h4">
                            Bus:
                        </Typography>
                        <Typography component="h6" variant="h6">
                            {route?.bus?.number}, {route?.bus?.brand}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} >
                    <Typography component="h4" variant="h4">
                            Driver:
                        </Typography>
                        <Typography component="h6" variant="h6">
                            {route?.driver?.user?.name}, {route?.driver?.user?.mobile}, {route?.driver?.user?.email}
                        </Typography>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default RouteDetailDialog;