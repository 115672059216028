import React, { useState, useEffect, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    Grid,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Box,
    Button,
    Typography,
    Collapse,
    Paper,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { changeRouteStopOrder } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import { VertActionMenu } from '../../../../common/action-menu';
import { convertTimeToAMPM } from '../../../../utils/constants';


const RouteStopsTable = ({ 
    routeId, 
    routeStops, 
    handleStopOrderChange, 
    onDelete, 
    onDisable, 
    onEdit,
    onViewLocationOnMap,
}) => {
    const [isChangeOrderDialogOpen, setChangeOrderDialogOpen] = useState(false);
    const [selectedRouteStop, setSelectedRouteStop] = useState(null);
    

    const openChangeOrderDialog = (data) => {
        setChangeOrderDialogOpen(true);
        setSelectedRouteStop(data);
    }

    const onStopOrderChange = (data) => {
        handleStopOrderChange(data);
    }

    return (
        <>  
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Order</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {routeStops.map((row, index) => (
                                <Row
                                    key={row.id}
                                    row={row}
                                    openChangeOrderDialog={openChangeOrderDialog}
                                    onDelete={onDelete}
                                    onDisable={onDisable}
                                    onEdit={onEdit}
                                    onViewLocationOnMap={onViewLocationOnMap}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {selectedRouteStop && (
                <ChangeOrderDialog
                    open={isChangeOrderDialogOpen}
                    onClose={() => {
                        setChangeOrderDialogOpen(false)
                    }}
                    routeId={routeId}
                    stopName={selectedRouteStop.stop.name}
                    stopOrder={selectedRouteStop.order}
                    stopId={selectedRouteStop.stop.id}
                    handleSubmit={onStopOrderChange}
                    maxOrder={routeStops.length}
                />
            )}
        </>
    )
}

const Row = ({ row, openChangeOrderDialog, onDisable, onDelete, onEdit, onViewLocationOnMap }) => {

    const [open, setOpen] = React.useState(false);

    console.log("row -- ", JSON.stringify(row));

    return (
        <>
            <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ width: '10px'}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.stop.name}
                    {!row.isActive && <Chip sx={{ ml: 1 }} label="Disabled" color="error" variant="outlined" />}
                </TableCell>
                <TableCell>
                    <Chip
                        label={row.order ?? "Add order"}
                        color={row.order ? 'primary' : 'default'}
                        onClick={() => openChangeOrderDialog(row)}
                    />
                </TableCell>
                <TableCell align="right">
                    <VertActionMenu
                        onEdit={() => onEdit(row)}
                        onDisable={() => onDisable(row, true)}
                        onEnable={() => onDisable(row, false)}
                        onDelete={() => onDelete(row)}
                        isDisabled={row.isActive ? false : true}
                        onViewLocation={() => onViewLocationOnMap(row)}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Location
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {row.stop.location.location}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Pickup Time
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {convertTimeToAMPM(row.stop.pickupTime)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Dropoff Time
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {convertTimeToAMPM(row.stop.dropoffTime)}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Latitude
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {row.stop.location.latitude}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography component='div' variant='h5'>
                                        Longitude
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {row.stop.location.longitude}
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const ChangeOrderDialog = ({ open, onClose, stopName, stopOrder, routeId, stopId, maxOrder, handleSubmit }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const formik = useFormik({
        initialValues: {
            order: stopOrder || '',
        },
        validationSchema: Yup.object({
            order: Yup.number()
                .required('Required')
                .positive('Must be positive')
                .integer('Must be an integer'),
        }),
        onSubmit: (values) => {
            // Handle form submission
            console.log('Form Data:', values);
            const data = {
                stopId,
                order: values.order
            }
            console.log("data ", data);
            setIsSaving(true);
            changeRouteStopOrder(routeId, stopId, values.order).then(resp => {
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
                handleClose();
                handleSubmit(resp.data.data);
            }).catch(err => {
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
            });
        },
    });

    useEffect(() => {
        if (open) {
            formik.resetForm();
            formik.setFieldValue('order', stopOrder);
        }
    }, [open]);

    const handleClose = () => {
        onClose()
    }
    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Stop saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)}
                />
            }
            
            <Dialog
                fullWidth={false}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <Typography
                        variant="h5" // Use h6 variant for a headline
                        component="div"
                        sx={{ fontWeight: 'bold', mb: 2 }} // Bold and margin bottom for spacing
                    >
                        Change stop order
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change the order of the stop - {stopName}
                    </DialogContentText>
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        noValidate
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Center items horizontally
                            justifyContent: 'center', // Center items vertically
                            mt: 1, // Optional: Set a minimum width for the form
                        }}
                    >
                        <TextField
                            label="Stop order"
                            name="order"
                            type="number"
                            value={formik.values.order}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.order && Boolean(formik.errors.order)}
                            helperText={formik.touched.order && formik.errors.order}
                            margin="normal"
                            variant="outlined"
                            sx={{ width: '150px' }} // Set the width of the number field
                            InputProps={{ inputProps: { min: 1, max: maxOrder } }} // Set the minimum value to 1
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2
                            }}
                        >
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                            <Button onClick={handleClose} sx={{ ml: 2 }}>Close</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default RouteStopsTable;



