import React, { useState, useEffect, useRef } from 'react';

import {
    Button,
    Chip,
    Box,
    Paper,
    Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { AlertSnackbar } from '../../../../common/snackbars';
import RouteStudentDialog from '../../../../common/route-student-dialog';
import RouteStudentsTable from './route-students-table';
import { deleteRouteStudent, enableDisableRouteStudent } from '../../../../services/http.service';
import { ModalType } from '../../../../utils/constants';
import { CustomModal } from '../../../../common/modals';

const AddStudents = ({ route, onNext, onBack, onSubmit, onUpdateRoute }) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [isRouteStudentDialogOpen, setRouteStudentDialogOpen] = useState(false);
    const [routeStudentForEdit, setRouteStudentForEdit] = useState(null);
    const [routeStops, setRouteStops] = useState([]);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [disableJson, setDisableJson] = useState({
        show: false,
        data: {}
    });

    const openRouteStudentDialog = () => {
        setRouteStudentForEdit(null); // set to null for new 
        setRouteStudentDialogOpen(true);
    }

    const closeRouteStudentDialog = () => {
        setRouteStudentDialogOpen(false);
    }

    const onSaveRouteStudent = (data) => {
        console.log("onSaveRouteStudent ", data);
        // Set route it if it's new route
        const updatedRoute = {
            ...route,
            id: data.route_id
        }
        onSubmit(updatedRoute);
    }

    const onUpdateRouteStudent = (data) => {
    }

    useEffect(() => {
        if (route && route.stops) {
            const activeStops = route.stops.filter(item => item.isActive);
            setRouteStops(activeStops);
        }
    }, [route]);

    // ################### Route student table methods ######################

    // Handle delete of route student
    const handleDelete = (routeStudent) => {
        console.log("handleDelete ", routeStudent);
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                routeId: route.id,
                studentId: routeStudent.student.id,
            }
        });
    }
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false
        });
        deleteRouteStudent(data.routeId, data.studentId).then(resp => {
            setSuccessMsg("Deleted successfully");
            setErrorMsg(null);
            onSubmit(route); // Trigger fetch route to refresh
        }).catch(err => {
            setErrorMsg(err);
            setSuccessMsg(null);
        });
    }


    // Handle disable of route stop
    const handleDisable = (routeStudent, isDisabled) => {
        console.log("handleDisable ", routeStudent);
        setDisableJson({
            ...disableJson,
            show: true,
            data: {
                routeId: route.id,
                studentId: routeStudent.student.id,
                isDisabled: isDisabled
            }
        });
    }
    const onDisable = (data) => {
        console.log('on disable data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDisableJson({
            ...disableJson,
            show: false,
        });
        enableDisableRouteStudent(data.routeId, data.studentId, data.isDisabled).then(resp => {
            setSuccessMsg(`${data.isDisabled ? 'Disabled':'Enabled'} successfully`);
            setErrorMsg(null);
            onSubmit(route); // Trigger fetch route to refresh
        }).catch(err => {
            setErrorMsg(err);
            setSuccessMsg(null);
        })
    }

    const handleEdit = (routeStudent) => {
        console.log("handleEdit ", routeStudent);
        setRouteStudentForEdit(routeStudent);
        setRouteStudentDialogOpen(true);
    }

    // ################### End Route student table methods ##################


    return (
        <>
            {errorMsg || successMsg &&
                <AlertSnackbar 
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    onClose={() => {
                        setErrorMsg(null);
                        setSuccessMsg(null);
                    }}
                />
            }
            {/* Show route student dialog */}
            <RouteStudentDialog
                open={isRouteStudentDialogOpen}
                onClose={closeRouteStudentDialog}
                routeId={route.id}
                routeType={route.type}
                routeStops={routeStops}
                onSubmit={onSaveRouteStudent}
                onUpdate={onUpdateRouteStudent}
                routeStudentForEdit={routeStudentForEdit}
            />
            <RouteStudentsTable
                routeId={route.id}
                routeStudents={route.students}
                onDelete={handleDelete}
                onDisable={handleDisable}
                onEdit={handleEdit}
            />
            {/* Add new student */}
            <Chip
                onClick={openRouteStudentDialog}
                icon={<AddCircleIcon />}
                label="Add Student"
                variant="outlined"
                color="secondary"
                sx={{ mt: 1 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ my: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={onBack}
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowForwardIosIcon />}
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Box>
            </Box>

            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => { onDelete(data) }}
                onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
            />
            {/* Disable modal */}
            <CustomModal
                show={disableJson.show}
                data={disableJson.data}
                type={ModalType.CONFIRM_DISABLE}
                onOkay={(data) => { onDisable(data) }}
                onHide={ () => {setDisableJson({...disableJson, show: false})} }
            />
        </>
    );
}

export default AddStudents;