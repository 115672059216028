import React, { Component, useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { green, blue } from '@mui/material/colors';

import { receiveChatMessageObservable, sendChatMessageObservable } from '../services/observable.service';
import { getUser } from '../services/auth.service';
import { Role, formatDateTimeRev } from '../utils/constants';
import { getChatWithAdmin } from '../services/http.service';
import Loader from '../common/loader';

import '../styles/chat-modal.css';
import { Typography } from '@mui/material';



const ChatModal = (props, ref) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadMoreChat, setIsLoadMoreChat] = useState(true);
    const [show, setShow] = useState(false);
    let [data, setData] = useState(null);
    const [message, setMessage] = useState('');
    let [chatMessages, setChatMessages] = useState([]); // 'let' to be set from other place
    let [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [errorMsg, setErrorMsg] = useState(null);

    const chatMessagesRef = useRef(chatMessages);

    // hook equivelanvt to --- componentWillReceiveProps
    useEffect( () => {
        console.log(`useEffect receive props show - ${props.show}`);
        setShow(props.show);
    }, [props.show]);

    // componentWillReceiveProps(nextProps) {
    //     // You don't have to do this check first, but it can help prevent an unneeded render
    //     if (nextProps.show !== this.state.show) {
    //         this.setState({show: nextProps.show});
    //         //this.setState({data: nextProps.data})
    //     }       
    // }

    useEffect(() => {
        // Recive chat message from Admin/Driver
        const receiveChatMessageObservableRef = receiveChatMessageObservable()
        .subscribe(message => {
            console.log(`Chat message obsevrvable - ${JSON.stringify(message)}`)
            updateChatMessages(message);
        });

        // component will unmount
        return () => {
            //receiveChatMessageObservable().unsubscribe();
            receiveChatMessageObservableRef.unsubscribe();
            sendChatMessageObservable().unsubscribe();
        }
    }, []);

    // componentDidMount() {  
    //     // Recive chat message from Admin/Driver
    //     receiveChatMessageObservable()
    //     .subscribe(message => {
    //         console.log(`Chat message obsevrvable - ${JSON.stringify(message)}`)
    //         this.updateChatMessages(message);
    //     });
    // }



    // function calls from parent ---------------
    useImperativeHandle(ref, () => ({
        loadChats(data) {
            loadChats(data);
        }
    }), []);
    // End ---------------------------------------


    // Load chats when modal opens up
    const loadChats = (newData) => {
        console.log(`Loading chat for ${JSON.stringify(newData)}`);
        console.log(`Load more data flag is - ${isLoadMoreChat}`);        
        // Set state and load messages
        data = newData;
        setData(newData);

        // reset values
        // clear previous chat messages;
        chatMessages = [];
        chatMessagesRef.current = [];
        setIsLoadMoreChat(true);
        setPage(0);
        setChatMessages([]);
        fetchChatMessages();

        // this.setState({ data: data, page: 0, chatMessages: [] }, () => {
        //     // Load latest chats from server for Driver/Parent
        //     this.fetchChatMessages();
        // });
    } 

    // Load more chats
    const loadMoreChats = () => {
        if (!isLoadMoreChat) {
            return;
        }
        // First increment the page count then fetch chat messages in callback function
        page += 1; // increment original
        setPage(page);
        fetchChatMessages();
        // this.setState(ps => ({
        //     page: ps.page + 1
        // }), () => {
        //     this.fetchChatMessages();
        // })
    }


    // Fetch chat messages from server -- TODO Send tripId in fetch chat message
    const fetchChatMessages = () => {
        // const { data, page, limit } = this.state;
        console.log(`fetchChatMessages - data - ${JSON.stringify(data)}`);
        setIsLoading(true);
        setErrorMsg(null);
        getChatWithAdmin(data.userId, data.tripId, data.role, page, limit).then(resp => {   
            setIsLoading(false);
            setErrorMsg(null);
            const respChats = resp.data.data;
            console.log(`Admin Chat from server - ${JSON.stringify(respChats)}`);
            if (!respChats || respChats.length === 0) {
                console.log(`No more chat `);
                // No More chat
                setIsLoadMoreChat(false);
            }
            else {
                buildAndUpdateChatMessages(resp.data.data);
            }
            
        }).catch(err => {
            setIsLoading(false);
            setErrorMsg(err);
        });
    }

    // Build and update chat messages
    const buildAndUpdateChatMessages = (messages) => {
        //console.log(`Building chat messages - ${JSON.stringify(messages)}`);
        // const chatMessages = [...this.state.chatMessages, ...messages];
        // const updatedChatMessages = [...chatMessages, ...messages];
        // chatMessages = updatedChatMessages; // reset to original
        // sortChatsByCreatedDateAsc(updatedChatMessages);
        // setChatMessages(updatedChatMessages);
        const updatedChatMessages = [...chatMessagesRef.current, ...messages];
        sortChatsByCreatedDateAsc(updatedChatMessages);
        chatMessagesRef.current = updatedChatMessages; // reset to original
        setChatMessages(updatedChatMessages);
    }

    // Send chat Message
    const sendChatMessage = () => {
        if (!message || message.trim().length == 0) {
            return;
        }
        // const { data } = this.state;
        const sender = {
            schoolId: data.schoolId, 
            tripId: data.tripId, 
            name: getUser().name, 
            userId: getUser().id, 
            role: Role.ADMIN
        };
        const receiver = {
            schoolId: data.schoolId, 
            tripId: data.tripId, 
            name: data.name, 
            userId: data.userId, 
            role: data.role // Later - will change based on chat window - DRIVER/PARENT
        };
        var chatMessage = {
            schoolId: data.schoolId, 
            tripId: data.tripId,
            sender: sender, 
            receiver: receiver, 
            message, // message from state
            sentTime: new Date().toString(),
        };
        console.log(`Sending chat message - ${JSON.stringify(chatMessage)}`);
        sendChatMessageObservable().next(chatMessage);
        updateChatMessages(chatMessage);
        // Clear input after sending
        setMessage('');
    }

    // Update Chat messages
    const updateChatMessages = (chatMessage) => {
        // Add to list
        // this.setState({ chatMessages: [...this.state.chatMessages, chatMessage] });
        console.log(`---- chatmessages before - ${JSON.stringify(chatMessagesRef.current)}`);
        const updatedChatMessages = [...chatMessagesRef.current, chatMessage]; 
        chatMessagesRef.current = updatedChatMessages; // rest to original 
        //chatMessages.push(chatMessage);
        console.log(`---- chatmessages after - ${JSON.stringify(updatedChatMessages)}`);
        setChatMessages(updatedChatMessages);
        //setChatMessages([...chatMessages]);
    }


    // Unsubscribe observables
    // componentWillUnmount() {
    //     receiveChatMessageObservable().unsubscribe();
    //     sendChatMessageObservable().unsubscribe();
    // }

    // On hide - TODO also send back hide event to parent so that parent can update show flag.
    const onHide = () => {
        setShow(false);
        // Reset 
        setMessage('');  
        setChatMessages([]);
        props.onHide();
    }

    const sortChatsByCreatedDateAsc = (chats) => {
        chats.sort((chat1, chat2) => {           
            let date1 = new Date(chat1.sentTime);
            let date2 = new Date(chat2.sentTime);
            console.log(`Sorting chat dates - date - ${date1} - date2 - ${date2}`);
            if (date1 < date2) { return -1; }
            if (date1 > date2) { return 1; }
            return 0;
        });
    }

    return (
        <>
             <Dialog open={show} onClose={onHide} fullWidth={true} scroll="paper">
                <DialogTitle>
                    <Typography variant='h5'>
                        Chat with {data ? data.name : ''}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => props.onHide()}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {/* Load more button */}
                        <Button variant="text" onClick={loadMoreChats}>Load more</Button>
                        {/* show loading first */}
                        {isLoading &&
                            <Loader />
                        }
                        {chatMessages.map((item, index) => (
                            <Box sx={{ 
                                    alignSelf: item.sender.userId === getUser().id ? 'flex-end' : 'flex-start',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: item.sender.userId === getUser().id ? 'flex-end' : 'flex-start',
                                    mb: 1,
                                    backgroundColor: item.sender.userId === getUser().id ? green[200] : blue[200],
                                    p: 1,
                                    borderRadius: '16px'
                                }}
                            >
                                <Typography variant='caption'>
                                    { formatDateTimeRev(item.sentTime) }
                                </Typography>
                                
                                <Typography variant='h5'>
                                    { item.message }
                                </Typography>
                            </Box>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <TextField variant="outlined" multiline  sx={{ width: '100%' }} 
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                    />
                    {/* <Button variant="text" onClick={onHide}>Close</Button> */}
                    <IconButton aria-label="send" onClick={sendChatMessage}>
                        <SendIcon />
                    </IconButton>
                </DialogActions>
             </Dialog>
        </>
    );


    // return (   
    //     <React.Fragment>
    //         <Modal show={show} size="lg" onHide={onHide}>
    //             <Modal.Header closeButton>
    //                 <Modal.Title>{data ? data.name : ''}</Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body className="d-flex flex-column chat-body">
    //                 {/* Load more button */}
    //                 <Button variant="link" onClick={loadMoreChats}>Load More</Button>

    //                 {/* show loading first */}
    //                 {isLoading &&
    //                     <div className="d-flex justify-content-center align-items">
    //                         <Spinner animation="border" />
    //                     </div>
    //                 }
    //                 {chatMessages.map((item, index) => (
    //                     <div key={item.sentTime} className={(item.sender.userId === getUser().id ? "align-self-end" : "align-self-start") + " my-3 mx-3 "}>
                            
    //                         <div className="font-italic text-muted font-weight-normal">{ formatDateTimeRev(item.sentTime) }</div>
    //                         <div className={(item.sender.userId === getUser().id ? "chat-box-right" : "chat-box-left")}>
    //                             { item.message }
    //                         </div>
                            
    //                     </div>
    //                 ))}
    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Container>
    //                 <Form>
    //                     <Form.Group as={Row}>
    //                         <Col lg={11}>
    //                             <Form.Control type="text" placeholder="Type your message.." 
    //                                 value={message}
    //                                 onChange={(e) => {
    //                                     setMessage(e.target.value);
    //                                 }}>
    //                             </Form.Control>
    //                         </Col>
    //                         <Col lg={1}>
    //                             <Button variant="link" onClick={sendChatMessage}>
    //                                 <i class="fas fa-2x fa-chevron-circle-right"></i>
    //                             </Button>
    //                         </Col>
    //                     </Form.Group>
    //                 </Form>   
    //                 </Container>                     
    //             </Modal.Footer>
    //         </Modal>
    //     </React.Fragment>
    // );
}

export default forwardRef(ChatModal); // 'forwardRef' enables call function from parent



// export default class ChatModal extends Component {
	
// 	constructor(props) {
//         super(props)
//         this.state = {
//             isLoading: false,
//             isLoadMoreChat: true,
//             show: false,
//             data: null,
//             message: '',
//             chatMessages: [],

//             // Load more
//             page: 0,
//             limit: 10,

//             // Error
//             errorMsg: null,
//         }
//     } 

//     componentWillReceiveProps(nextProps) {
//         // You don't have to do this check first, but it can help prevent an unneeded render
//         if (nextProps.show !== this.state.show) {
//             this.setState({show: nextProps.show});
//             //this.setState({data: nextProps.data})
//         }       
//     }

//     componentDidMount() {  
//         // Recive chat message from Admin/Driver
//         receiveChatMessageObservable()
//         .subscribe(message => {
//             console.log(`Chat message obsevrvable - ${JSON.stringify(message)}`)
//             this.updateChatMessages(message);
//         });
//     }

//     // Load chats when modal opens up
//     loadChats = (data) => {
//         console.log(`Loading chat for ${JSON.stringify(data)}`);
//         console.log(`Load more data flag is - ${this.state.isLoadMoreChat}`);        
//         // Set state and load messages
//         this.setState({ data: data, page: 0, chatMessages: [] }, () => {
//             // Load latest chats from server for Driver/Parent
//             this.fetchChatMessages();
//         });
//     } 

//     // Load more chats
//     loadMoreChats = () => {
//         if (!this.state.isLoadMoreChat) {
//             return;
//         }
//         // First increment the page count then fetch chat messages in callback function
//         this.setState(ps => ({
//             page: ps.page + 1
//         }), () => {
//             this.fetchChatMessages();
//         })
//     }


//     // Fetch chat messages from server -- TODO Send tripId in fetch chat message
//     fetchChatMessages = () => {
//         const { data, page, limit } = this.state;
//         console.log(`fetchChatMessages - data - ${JSON.stringify(data)}`);
//         this.setState({ isLoading: true, errorMsg: null });
//         getChatWithAdmin(data.userId, data.tripId, data.role, page, limit).then(resp => {            
//             this.setState({ isLoading: false, errorMsg: null });
//             const respChats = resp.data.data;
//             console.log(`Admin Chat from server - ${JSON.stringify(respChats)}`);
//             if (!respChats || respChats.length === 0) {
//                 console.log(`No more chat `);
//                 // No More chat
//                 this.setState({ isLoadMoreChat: false });
//             }
//             else {
//                 this.buildAndUpdateChatMessages(resp.data.data);
//             }
            
//         }).catch(err => {
//             this.setState({ isLoading: false, errorMsg: err });
//         });
//     }

//     // Build and update chat messages
//     buildAndUpdateChatMessages = (messages) => {
//         //console.log(`Building chat messages - ${JSON.stringify(messages)}`);
//         const chatMessages = [...this.state.chatMessages, ...messages];
//         this.sortChatsByCreatedDateAsc(chatMessages);
//         this.setState({ chatMessages: chatMessages });
//     }

//     // Send chat Message
//     sendChatMessage = (message) => {
//         const { data } = this.state;
//         const sender = {
//             schoolId: data.schoolId, 
//             tripId: data.tripId, 
//             name: getUser().name, 
//             userId: getUser().id, 
//             role: Role.ADMIN
//         };
//         const receiver = {
//             schoolId: data.schoolId, 
//             tripId: data.tripId, 
//             name: data.name, 
//             userId: data.userId, 
//             role: data.role // Later - will change based on chat window - DRIVER/PARENT
//         };
//         var chatMessage = {
//             schoolId: data.schoolId, 
//             tripId: data.tripId,
//             sender: sender, 
//             receiver: receiver, 
//             message: this.state.message,
//             sentTime: new Date().toString(),
//         };
//         console.log(`Sending chat message - ${JSON.stringify(chatMessage)}`);
//         sendChatMessageObservable().next(chatMessage);
//         this.updateChatMessages(chatMessage);
//         // Clear input after sending
//         this.setState({ message: '' });
//     }

//     // Update Chat messages
//     updateChatMessages = (chatMessage) => {
//         // Add to list
//         this.setState({ chatMessages: [...this.state.chatMessages, chatMessage] });
//     }


//     // Unsubscribe observables
//     componentWillUnmount() {
//         receiveChatMessageObservable().unsubscribe();
//         sendChatMessageObservable().unsubscribe();
//     }

//     // On hide - TODO also send back hide event to parent so that parent can update show flag.
//     onHide = () => {
//         this.setState({show: false});
//         // Reset 
//         this.setState({ message: '' });  
//         this.setState({ chatMessages: [] });
//         this.props.onHide();
//     }

//     sortChatsByCreatedDateAsc = (chats) => {
//         chats.sort((chat1, chat2) => {           
//             let date1 = new Date(chat1.sentTime);
//             let date2 = new Date(chat2.sentTime);
//             console.log(`Sorting chat dates - date - ${date1} - date2 - ${date2}`);
//             if (date1 < date2) { return -1; }
//             if (date1 > date2) { return 1; }
//             return 0;
//         });
//     }

//     render() {  

//         const { isLoading, data, chatMessages } = this.state;

//         return (   
//             <React.Fragment>
//                 <Modal show={this.state.show} size="lg" onHide={() => {this.onHide()}}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>{data ? data.name : ''}</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body className="d-flex flex-column chat-body">
//                         {/* Load more button */}
//                         <Button variant="link" onClick={() => this.loadMoreChats()}>Load More</Button>

//                         {/* show loading first */}
//                         {isLoading &&
//                             <div className="d-flex justify-content-center align-items">
//                                 <Spinner animation="border" />
//                             </div>
//                         }
//                         {chatMessages.map((item, index) => (
//                             <div className={(item.sender.userId === getUser().id ? "align-self-end" : "align-self-start") + " my-3 mx-3 "}>
                                
//                                 <div className="font-italic text-muted font-weight-normal">{ formatDateTimeRev(item.sentTime) }</div>
//                                 <div className={(item.sender.userId === getUser().id ? "chat-box-right" : "chat-box-left")}>
//                                     { item.message }
//                                 </div>
                                
//                             </div>
//                         ))}
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Container>
//                         <Form>
//                             <Form.Group as={Row}>
//                                 <Col lg={11}>
//                                     <Form.Control type="text" placeholder="Type your message.." 
//                                         value={this.state.message}
//                                         onChange={(e) => {
//                                             this.setState({ message: e.target.value });
//                                         }}>
//                                     </Form.Control>
//                                 </Col>
//                                 <Col lg={1}>
//                                     <Button variant="link" onClick={() => this.sendChatMessage()}>
//                                         <i class="fas fa-2x fa-chevron-circle-right"></i>
//                                     </Button>
//                                 </Col>
//                             </Form.Group>
//                         </Form>   
//                         </Container>                     
//                     </Modal.Footer>
//                 </Modal>
//             </React.Fragment>
//         )  
//     }  
// }

