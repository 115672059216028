import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, TextField, Button, Chip } from '@mui/material';
import { schoolUserGetOtp, schoolUserVerifyOtp } from '../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../common/snackbars';


const CommonUserOtpVerification = ({ user }) => {

    const [mobile, setMobile] = useState(null);

    // Otp
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState(null);
    // Submit
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    

    useEffect(() => {
        setMobile(user.mobile);
    }, [user])

    // Get OTP for verification
    const getOtp = () => {
        // const { mobile } = this.state;
        setIsOtpSent(false);
        setIsOtpLoading(true);
        setOtpErrorMsg(null);
        setSuccessMsg(null);
        
        if (!mobile || mobile.trim().length === 0) {
            setIsOtpSent(false);
            setIsOtpLoading(false);
            setOtpErrorMsg('Mobile number is not entered.');
            return;
        }        
        schoolUserGetOtp(mobile).then(resp => {
            console.log(`Get Otp success - ${resp}`);
            setIsOtpSent(true);
            setIsOtpLoading(false);
            setOtpErrorMsg(null);
            setSuccessMsg("Otp sent successfully!");
        }).catch(err => {
            console.log(err);
            setIsOtpSent(false);
            setIsOtpLoading(false);
            setOtpErrorMsg(err);
            setSuccessMsg(null);
        })
    }

    // Verify OTP
    const verifyOtp = () => {
        // First reset all flags include get otp
        setIsSaving(true);
        setSuccessMsg(null);
        setErrorMsg(null);
        const data = {
            mobile: mobile,
		    otp: otp,
        }
        schoolUserVerifyOtp(data).then(resp => {
            setIsSaving(false);
            setSuccessMsg("Otp verified successfully");
            setErrorMsg(null);
        }).catch(err => {
            setIsSaving(false);
            setSuccessMsg(null);
            setErrorMsg(err);
        });
    }

    return (
        <>
            {isOtpLoading &&
                <CircularProgress />
            }

            { successMsg &&
                <SuccessAlertTopRight
                    successMsg={successMsg}
                    onClose={() => setSuccessMsg(null)} 
                />
            }

            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }

            <Box component="form" sx={{ mt: 3 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    required
                    name="name"
                    label="Mobile"
                    disabled={true}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                />
                {isOtpSent &&
                    <TextField
                        margin="normal"
                        required
                        name="otp"
                        label="Otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                    />
                }
                <Box sx={{ my: 3, justifyContent: 'center', display: 'flex' }}>
                    { !isOtpSent &&
                        <Button
                            type="button"
                            variant="contained"
                            onClick={getOtp}
                        >
                            Continue
                        </Button>
                    }
                    { isOtpSent &&
                        <>
                            <Button
                                type="button"
                                variant="contained"
                                onClick={verifyOtp}
                                sx={{ mr: 5 }}
                            >
                                Submit
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={getOtp}
                            >
                                Resend
                            </Button>
                        </>
                    }
                </Box>
            </Box>
        </>
    )
}

export default CommonUserOtpVerification;