import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography } from '@mui/material';
import { IconMenu2 } from '@tabler/icons';

import Logo from '../../../../assets/images/logo-sbt.png';

// Parts
import NotificationSection from './notification/index';
import ProfileSection from './profile/index';


const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const schoolId = useSelector(state => state.extras.schoolId);
    const school = useSelector(state => state.school);
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <ButtonBase disableRipple component={Link} to={''}>
                        <img src={Logo} height="60px" />
                    </ButtonBase>
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>
            <Box
                sx={{ ml: 3 }}
            >
                <SchoolInfo />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {/* notification & profile */}
            {schoolId &&
                <NotificationSection />
            }
            <ProfileSection />
        </>
    );
}

export default Header;


const SchoolInfo = () => {
    const school = useSelector(state => state.school);
    return (
        <Box>
            {school && 
                <Typography variant="h4" component="h4">
                    {school.name}
                </Typography>
            }
            {school && school.unique_number &&
                <Typography variant="h5" component="h5">
                    Unique number: {school.unique_number}
                </Typography>
            }
        </Box>
    )
}
