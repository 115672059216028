import { Subject } from 'rxjs';

const currentLocationSubject = new Subject();
const sendChatMessageSubject = new Subject();
const receiveChatMessageSubject = new Subject();
const receiveChatMessageNotificationsSubject = new Subject();
const receiveNotificationsSubject = new Subject();
const updateChatMessageReadSubject = new Subject();
const updateNotificationReadSubject = new Subject();

export function currentLocationObservable() {
    return currentLocationSubject;
}

export function sendChatMessageObservable() {
    return sendChatMessageSubject;
}

export function receiveChatMessageObservable() {
    return receiveChatMessageSubject;
}

export function receiveChatMessageNotificationsObservable() {
    return receiveChatMessageNotificationsSubject;
}

export function receiveNotificationsObservable() {
    return receiveNotificationsSubject;
}

export function updateChatMessageReadObservable() {
    return updateChatMessageReadSubject;
}

export function updateNotificationReadObservable() {
    return updateNotificationReadSubject;
}