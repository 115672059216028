import React, { Component, useState, useEffect } from 'react';
import { Col, Row, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { schoolUserGetOtp, schoolUserVerifyOtp } from '../../../../services/http.service';


const OTPVerification = () => {

    const [validated, setValidated] = useState(false);
    const [mobile, setMobile] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // Otp
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState(null);
    // Submit
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const navigate = useNavigate();
	

    // Get OTP for verification
    const getOtp = () => {
        // const { mobile } = this.state;
        setIsOtpSent(false);
        setIsOtpLoading(true);
        setOtpErrorMsg(null);
        
        if (!mobile || mobile.trim().length === 0) {
            setIsOtpSent(false);
            setIsOtpLoading(false);
            setOtpErrorMsg('Mobile number is not entered.');
            return;
        }        
        schoolUserGetOtp(mobile).then(resp => {
            console.log(`Get Otp success - ${resp}`);
            setIsOtpSent(true);
            setIsOtpLoading(false);
            setOtpErrorMsg(null);
        }).catch(err => {
            console.log(err);
            setIsOtpSent(false);
            setIsOtpLoading(false);
            setOtpErrorMsg(err);
        })
    }

    // Verify OTP
    const verifyOtp = () => {
        // First reset all flags include get otp
        setIsLoading(false);
        setIsOtpLoading(false);
        setOtpErrorMsg(null);
        setIsSaving(true);
        setShowSuccessAlert(false);
        setErrorMsg(null);
        // this.setState({ 
        //     isLoading: false,
        //     isOtpLoading: false,
        //     otpErrorMsg: null,
        //     isSaving: true,
        //     showSuccessAlert: false,
        //     errorMsg: null,
        // })
        const data = {
            mobile: mobile,
		    otp: otp,
        }
        schoolUserVerifyOtp(data).then(resp => {
            setIsSaving(false);
            setShowSuccessAlert(true);
            setErrorMsg(null);
        }).catch(err => {
            setIsSaving(false);
            setShowSuccessAlert(false);
            setErrorMsg(err);
        });
    }
    
    return ( 
        <React.Fragment>  
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h4 mb-0 text-gray-800">Verify with OTP</h1>
            </div>
            <div className="card shadow h-100 my-2">
                <div className="card-body">
                    <Form
                        noValidate
                        validated={validated}
                        >

                        <Form.Group as={Row}>
                            <Col sm={6}>
                                <Form.Label>Mobile number</Form.Label>
                                <Form.Control type="text" placeholder="Enter mobile number"
                                    onChange={(e) => {
                                        setMobile(e.target.value);
                                    }}
                                />
                            </Col>
                        </Form.Group>
                        {isOtpSent &&
                            <Form.Group as={Row}>
                                <Col sm={4}>
                                    <Form.Control type="text" placeholder="Enter OTP"
                                        onChange={(e) => {
                                            setOtp(e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Alert className="ml-3 mb-0" variant="success">
                                        Otp sent on the registered mobile number
                                        </Alert>
                                </Col>
                            </Form.Group>
                        }


                        <div className="d-flex align-items-center justify-content-left">
                            {!isOtpSent &&
                                <Button variant="primary" onClick={getOtp}> Continue </Button>
                            }
                            {isOtpLoading &&
                                <Col sm={2}>
                                    <Spinner animation="border" variant="primary" />
                                </Col>
                            }
                            {isOtpSent &&
                                <div className="d-flex align-items-center justify-content-left">
                                    <Button variant="primary" onClick={verifyOtp}> Submit </Button>
                                    <Button variant="link" onClick={getOtp}>Resend OTP</Button>
                                    {isSaving &&
                                        <Spinner animation="border" variant="primary" />
                                    }
                                    {showSuccessAlert &&
                                        <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false)}
                                            dismissible>
                                            Verified Successfully
                                                </Alert>
                                    }
                                    {errorMsg &&
                                        <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null)}
                                            dismissible>
                                            {errorMsg}
                                        </Alert>
                                    }
                                </div>
                            }
                            {otpErrorMsg &&
                                <Alert className="ml-3 mb-0" variant="danger" onClose={() => setOtpErrorMsg(null)}
                                    dismissible>
                                    {otpErrorMsg}
                                </Alert>
                            }

                            <Button variant="link" className="ml-4" onClick={() => navigate(-1)}>Back</Button>
                        </div>

                    </Form>
                </div>
            </div>
        </React.Fragment>         
    );
}

export default OTPVerification;



// class OTPVerification extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             validated: false,
//             mobile: '',

//              // Otp
//              otp: '',
//              isOtpSent: false,
//              isOtpLoading: false,
//              otpErrorMsg: null,

//              // Submit
//              isSaving: false,
//              showSuccessAlert: false,
//              errorMsg: null,
// 		};
//     }

//     // Get OTP for verification
//     getOtp = () => {
//         const { mobile } = this.state;
//         this.setState({ isOtpSent: false, isOtpLoading: true, otpErrorMsg: null });
//         if (!mobile || mobile.trim().length === 0) {
//             this.setState({ isOtpSent: false, isOtpLoading: false, otpErrorMsg: 'Mobile number is not entered.' });
//             return;
//         }        
//         schoolUserGetOtp(mobile).then(resp => {
//             console.log(`Get Otp success - ${resp}`);
//             this.setState({ isOtpSent: true, isOtpLoading: false, otpErrorMsg: null });
//         }).catch(err => {
//             console.log(err);
//             this.setState({ isOtpSent: false, isOtpLoading: false, otpErrorMsg: err });
//         })
//     }

//     // Verify OTP
//     verifyOtp = () => {
//         // First reset all flags include get otp
//         this.setState({ 
//             isLoading: false,
//             isOtpLoading: false,
//             otpErrorMsg: null,
//             isSaving: true,
//             showSuccessAlert: false,
//             errorMsg: null,
//         })
//         const data = {
//             mobile: this.state.mobile,
// 		    otp: this.state.otp,
//         }
//         schoolUserVerifyOtp(data).then(resp => {
//             this.setState({
//                 isSaving: false,
//                 showSuccessAlert: true,
//                 errorMsg: null,
//             })
//         }).catch(err => {
//             this.setState({
//                 isSaving: false,
//                 showSuccessAlert: false,
//                 errorMsg: err,
//             })
//         });
//     }
    
//     render() {
//         const { validated, isOtpSent, isOtpLoading, otpErrorMsg, isSaving, showSuccessAlert, errorMsg } = this.state;
// 		return ( 
//             <React.Fragment>  
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Verify with OTP</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         <Form
//                             noValidate
//                             validated={validated}
//                             onSubmit={e => this.handleSubmit(e)}>

//                             <Form.Group as={Row}>
//                                 <Col sm={6}>
//                                     <Form.Label>Mobile number</Form.Label>
//                                     <Form.Control type="text" placeholder="Enter mobile number"
//                                         onChange={(e) => {
//                                             this.setState({ mobile: e.target.value });
//                                         }}
//                                     />
//                                 </Col>
//                             </Form.Group>
//                             {isOtpSent &&
//                                 <Form.Group as={Row}>
//                                     <Col sm={4}>
//                                         <Form.Control type="text" placeholder="Enter OTP"
//                                             onChange={(e) => {
//                                                 this.setState({ otp: e.target.value });
//                                             }}
//                                         />
//                                     </Col>
//                                     <Col sm={4}>
//                                         <Alert className="ml-3 mb-0" variant="success">
//                                             Otp sent on the registered mobile number
//                                             </Alert>
//                                     </Col>
//                                 </Form.Group>
//                             }


//                             <div className="d-flex align-items-center justify-content-left">
//                                 {!isOtpSent &&
//                                     <Button variant="primary" onClick={() => this.getOtp()}> Continue </Button>
//                                 }
//                                 {isOtpLoading &&
//                                     <Col sm={2}>
//                                         <Spinner animation="border" variant="primary" />
//                                     </Col>
//                                 }
//                                 {isOtpSent &&
//                                     <div className="d-flex align-items-center justify-content-left">
//                                         <Button variant="primary" onClick={() => this.verifyOtp()}> Submit </Button>
//                                         <Button variant="link" onClick={() => this.getOtp()}>Resend OTP</Button>
//                                         {isSaving &&
//                                             <Spinner animation="border" variant="primary" />
//                                         }
//                                         {showSuccessAlert &&
//                                             <Alert className="ml-3 mb-0" variant="success" onClose={() => this.setState({ showSuccessAlert: false })}
//                                                 dismissible>
//                                                 Verified Successfully
//                                                     </Alert>
//                                         }
//                                         {errorMsg &&
//                                             <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({ errorMsg: null })}
//                                                 dismissible>
//                                                 {this.state.errorMsg}
//                                             </Alert>
//                                         }
//                                     </div>
//                                 }
//                                 {otpErrorMsg &&
//                                     <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({ otpErrorMsg: null })}
//                                         dismissible>
//                                         {this.state.otpErrorMsg}
//                                     </Alert>
//                                 }

//                                 <Button variant="link" className="ml-4" onClick={() => this.props.history.goBack()}>Back</Button>
//                             </div>

//                         </Form>
//                     </div>
//                 </div>
//             </React.Fragment>         
//         )
//     }
// }

// export default OTPVerification;