import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
    Button } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ImageIcon from '@mui/icons-material/Image';
import * as Yup from 'yup';
import { Role } from '../../../utils/constants'
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../common/snackbars';

import {  saveOrUpdateAdmin, getAdmin  } from '../../../services/http.service';

const UserAdminNew = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [admin, setAdmin] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const { adminId } = useParams();
    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        if (adminId) {
            fetchAdmin(adminId);
        }
    }, []);


    // Fetch admin detail for edit
    const fetchAdmin = (adminId) => {
        setIsLoading(true);
        getAdmin(adminId).then(resp => {
            console.log(`Fetch admin - ${JSON.stringify(resp)}`);
            setAdmin(resp.data.data);
            setAdminDetail(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Set Admin details into state
    const setAdminDetail = (respAdmin) => {
        // set formik field
        formik.setFieldValue("name", respAdmin.user.name);
        formik.setFieldValue("mobile", respAdmin.user.mobile);
        formik.setFieldValue("email", respAdmin.user.email);
        formik.setFieldValue("password", "******"); // 6 digit default password on edit of admin
        setAdmin({
            ...admin,
            id: respAdmin.id,
            user: respAdmin.user,
        });

        console.log(`Set admin detail - ${JSON.stringify(admin)}`);
    }

    /**
     * Handle form submission
     */
     const validationSchema = Yup.object({
        name: Yup.string().required("Name is required."),
        mobile: Yup.string().required("Mobile is required."),
        email: Yup.string().email("Invalid email."),
        password: Yup.string().required("Password is required.")
            .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long"),
        });
    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            console.log("saving admin - ", values);
            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);
            const newAdmin = {
                ...admin,
                name: values.name,
                mobile: values.mobile,
                email: values.email,
                password: values.password,
            }
            
            console.log(`Saving admin - ${JSON.stringify(newAdmin)}`);
            saveOrUpdateAdmin(newAdmin).then(resp => {
                const respData = resp.data;
                setIsSaving(false);
                setErrorMsg(null);
                setShowSuccessAlert(true);
                setAdmin({ id: respData.data });
            }).catch(err => {
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
                console.log(err);
            });
        },
        onReset: (values, { resetForm }) => resetForm(),
    });


    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Admin saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)}
                />
            }
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                    width: '100%',
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        {admin && admin.id ? "Update admin" : "Add new admin"}
                    </Typography>
                    {isLoading &&
                        <CircularProgress />
                    }
                    <Box>
                        {admin && admin.id &&
                            <>
                                <Chip
                                    onClick={() => navigate(`${baseUrl}/${admin.id}/verify`)}
                                    icon={<HowToRegIcon />}
                                    label="Verify"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ mx: 2 }}
                                />
                                <Chip
                                    onClick={() => navigate(`${baseUrl}/${admin.id}/photo`)}
                                    icon={<ImageIcon />}
                                    label="Photo"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ mx: 2 }}
                                />
                            </>
                        }
                        <Chip
                            onClick={() => navigate(`../list`)}
                            icon={<FormatListBulletedIcon />}
                            label="Admins"
                            variant="outlined"
                            color="secondary"
                            sx={{ mx: 2 }}
                        />
                    </Box>
                </Box>

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1, }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="name"
                                label="Admin name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="mobile"
                                label="Mobile"
                                value={formik.values.mobile}
                                onChange={formik.handleChange}
                                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                helperText={formik.touched.mobile && formik.errors.mobile}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="password"
                                label="Password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        {admin && admin.id ? 'Update' : 'Save'}
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );

    // return (
    //     <React.Fragment>
    //          <div className="d-flex align-items-center justify-content-between mb-4">
    //             <div className="h5 mb-0 text-gray-800">{isUpdate ? 'Update admin' : 'New admin'}</div>
    //             <Link type="button" class="btn btn-outline-info btn-sm ml-3" to="../list">Show admins</Link>
    //         </div> 
    //         <Form
    //             noValidate
    //             validated={validated}
    //             onSubmit={handleSubmit} className="mt-5"> 

    //             {isUpdate &&
    //                 <div className="mb-3">                                    
    //                     <h5>
    //                         {user.isVerified &&
    //                             <span class="badge badge-success">Verified</span>
    //                         }
    //                         {!user.isVerified &&
    //                             <span class="badge badge-danger">Unverified</span>
    //                         }
    //                     </h5>
    //                 </div>
    //             }

    //             <Form.Group as={Row}>
    //                 <Form.Label column sm="2">Full Name</Form.Label>
    //                 <Col lg="6">
    //                     <Form.Control required type="text" placeholder="Enter full name"
    //                         value={user.name}
    //                         onChange={(e) => {
    //                             setUser({
    //                                 ...user,
    //                                 name: e.target.value,
    //                             });
    //                             // user.name = e.target.value;
    //                             // this.setState({user});
    //                         }}
    //                     />
    //                 </Col>
    //             </Form.Group>
    //             <Form.Group as={Row}>
    //                 <Form.Label column sm="2">Email</Form.Label>
    //                 <Col sm="8">
    //                     <Form.Control type="email" placeholder="Enter email" 
    //                         value={user.email}
    //                         onChange={(e) => {  
    //                             setUser({
    //                                 ...user,
    //                                 email: e.target.value,
    //                             });                                                          
    //                             // user.email = e.target.value;
    //                             // this.setState({user});
    //                         }}
    //                     />
    //                 </Col>
    //             </Form.Group>                                        
    //             <Form.Group  as={Row}>
    //                 <Form.Label column sm="2">Mobile no</Form.Label>
    //                 <Col sm="4">
    //                     <Form.Control required type="number" placeholder="Enter mobile no." 
    //                         value={user.mobile}
    //                         onChange={(e) => {   
    //                             setUser({
    //                                 ...user,
    //                                 mobile: e.target.value,
    //                             });                                                         
    //                             // user.mobile = e.target.value;
    //                             // this.setState({user});
    //                         }}
    //                     />
    //                 </Col>
    //             </Form.Group>
    //             <Form.Group  as={Row}>
    //                 <Form.Label column sm="2">Password</Form.Label>
    //                 <Col sm="4">
    //                     <Form.Control required type="password" placeholder="Enter password"
    //                         value={user.password}
    //                         onChange={(e) => {  
    //                             setUser({
    //                                 ...user,
    //                                 password: e.target.value,
    //                             });                                                         
    //                             // user.password = e.target.value;
    //                             // this.setState({user});
    //                         }}
    //                     />
    //                 </Col>
    //             </Form.Group>

    //             <div className="d-flex align-items-center justify-content-center mt-5">
    //                 <Button className="btn-general" type="submit">Submit</Button>   
    //                 {isSaving &&                         
    //                     <Spinner animation="border" variant="primary" />
    //                 }
    //                 {showSuccessAlert &&
    //                     <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false) } 
    //                         dismissible>
    //                         Saved Successfully
    //                     </Alert>
    //                 }
    //                 {errorMsg &&
    //                     <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null) } 
    //                         dismissible>
    //                         {errorMsg}
    //                     </Alert>
    //                 }
    //                 {user && user.id &&
    //                     <React.Fragment>
    //                         <Link className="ml-5" to={`../${user.id}/profile-photo`}>
    //                             Profile Photo
    //                         </Link>
    //                         <Link className="ml-5" to="../otp-verification">
    //                             Verify
    //                         </Link>
    //                     </React.Fragment>
    //                 } 

    //             </div>  

    //         </Form>    
    //     </React.Fragment>
    // );
}
export default UserAdminNew;