import React, { Component, useState, useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux'
import { Box, Typography, Button, Chip, Grid, TableBody, IconButton, 
    Badge, Tab, List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, Popover } from '@mui/material';
import { yellow, red, green } from '@mui/material/colors';
import HistoryIcon from '@mui/icons-material/History';
import RefreshIcon from '@mui/icons-material/Refresh';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChatIcon from '@mui/icons-material/Chat';
import  GooogleMap  from '../../../../common/googlemap/google-map';
import { fetchSchool as fetchSchoolHttp, getTrip, getParentByStudent, 
    getTripCurrentLocation, updateTripStudentStatus } from '../../../../services/http.service';
import { getUser } from '../../../../services/auth.service';
import ChatModal from '../../../../common/chat-modal';
import { currentLocationObservable, receiveChatMessageNotificationsObservable, 
    updateChatMessageReadObservable, receiveNotificationsObservable } from '../../../../services/observable.service';
import { Role, TripStatus, convertTripStatus, TripStudentStatus } from '../../../../utils/constants';
import { useParams, useNavigate } from "react-router-dom";
import { IMAGES_URL, ModalType, convertTripStudentStatus } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';
import { CustomModal } from '../../../../common/modals';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import { validateYupSchema } from 'formik';
import LiveLocationMap from '../../../../common/googlemap/live-location-map';
import schoolIcon from '../../../../assets/images/school_icon.png';
import stopIcon from '../../../../assets/images/stop_icon.png';


const RunningTripMapView = () => {

    let [students, setStudents] = useState([]); // 'let' can be assinged later
    // const [school, setSchool] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [stopLocations, setStopLocations] = useState([]);
    const [busCurrentLocation, setBusCurrentLocation] = useState({});
    let [trip, setTrip] = useState({
        driver: {
            user: {}
        },
        bus: {}
    });
    const [chatModal, setChatModal] = useState({
        show: false,
        data: {}
    });
    const [openedChatModal, setOpenedChatModal] = useState({
        userId: null,
        show: false,
    });
    const [studentStatusModal, setStudentStatusModal] = useState({
        show: false,
        data: {}
    });
    const [chatMessageUnReadCounts, setChatMessageUnReadCounts] = useState(new Map());

    // Child component Refs
    const googleMapElement = useRef();        
    const chatModalElement = useRef(); 
    const liveLocationMapRef = useRef(null);

    const studentsRef = useRef();

    const { tripId } = useParams();
    const schoolId = useSelector(state => state.extras.schoolId);
    const school = useSelector(state => state.school);

    let currentLocationObservableRef = null;
    let receiveChatMessageNotificationsObservableRef = null;
    let receiveNotificationsObservableRef = null;

    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log("school ", school);
    // }, [school]);

    useEffect(() => {
        studentsRef.current = students;
    }, [students]);


    useEffect(() => {
        // Get Updates of Current Location
        currentLocationObservableRef = currentLocationObservable()
        .subscribe(value => {
            console.log(`Curret location obsevrvable - ${JSON.stringify(value)}`)
            if (value.tripId === trip.id) {
                // Update driver current location on map
                //googleMapElement.current.updateDriverCurrentLocation(value);
                //liveLocationMapRef.current.updateDriverCurrentLocation(value);
                setBusCurrentLocation(value);
                //this.googleMapElement.current.showStudentOnMap(this.state.students[0]);
            }
        });

        // Receive Chat Message Notification
        receiveChatMessageNotificationsObservableRef =receiveChatMessageNotificationsObservable()
        .subscribe(value => {
            console.log(`running trip chat message notification - ${JSON.stringify(value.notifications)}`);
            // process notifications
            const notifications = value.notifications;
            if (notifications) {
                let userNotifications = new Map();
                notifications.forEach(item => {
                    // Filter out on this Trip unread counts                    
                    if (item.tripId === parseInt(tripId)) {
                        console.log(`processing item - ${JSON.stringify(item.sender.userId)}`);
                        // Only set count to those which chat modal is not opened - also send update to mark isRead on server
                        if (item.sender.userId !== openedChatModal.userId) {
                            userNotifications.set(item.sender.userId, item.count);
                        }
                        else {
                            sendUpdateChatMessageReadObservable({
                                schoolId: schoolId,
                                tripId: trip.id, 
                                userId: item.sender.userId
                            });
                        }
                    }                    
                });
                //console.log(`user notificaions - ${userNotifications.get(22)}`);
                setChatMessageUnReadCounts(userNotifications);
            }
            //this.setState({ chatMessageNotifications: value.notifications });
        });

        // Receive Notifications
        const receiveNotificationsObservableRef = receiveNotificationsObservable()
            .subscribe(value => {
                console.log(`Notification received - ${JSON.stringify(value)}`);
                // set trip student data
                if (schoolId == value.schoolId 
                    && value.data && value.data.tripId && tripId == value.data.tripId) { // extra check, although receiving notifiction for this school from dispatcher
                    const newStudents = studentsRef.current.map(item => {
                        if (item.id === value.data.studentId) {
                            item.status = value.data.status;
                            if (value.data.status == TripStudentStatus.DROPPED_OFF) {
                                item.droppedoffLocation = {
                                    latitude: value.data.location.latitude,
                                    longitude: value.data.location.longitude,
                                    location: value.data.location.address,
                                }
                                
                            } else if (value.data.status == TripStudentStatus.PICKED_UP) {
                                item.pickedupLocation = {
                                    latitude: value.data.location.latitude,
                                    longitude: value.data.location.longitude,
                                    location: value.data.location.address,
                                }
                            }
                        }
                        return item;
                    });
                    setStudents(newStudents);
                }
            });
        //fetchSchool();
        fetchTrip(tripId);

        // cleanup equivalent to - componentWillUnmount
        return () => {
            console.log('call similer to componentWillUnmount')
            currentLocationObservableRef.unsubscribe();
            receiveChatMessageNotificationsObservableRef.unsubscribe();
            receiveNotificationsObservableRef.unsubscribe();
        }

    }, [school]); // when we have the school then fetch others


    // Fetch trip
    const fetchTrip = (id) => {
        setIsLoading(true);
        getTrip(id).then(resp => {
            console.log(JSON.stringify(resp.data.data));
            const respTrip = resp.data.data;   
            trip = respTrip; // set to original trip object         
            setTrip(respTrip);

            // Prepare students to input to map
            const studentsForMap =  buildStudents(trip);  
            console.log(`students for map - ${studentsForMap}`);
            setStudents(studentsForMap);
            buildStopLocationForMap(respTrip.trip_stops);

            setIsLoading(false);

            // Fetch Trip current location
            fetchTripCurrentLocation(trip.id);

        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Fetch Trip Current Location
    const fetchTripCurrentLocation = (tripId) => {
        getTripCurrentLocation(tripId).then(resp => {
            const location = resp.data.data;
            console.log(`Trip Current Location - ${JSON.stringify(location)}`);
            // googleMapElement.current.updateDriverCurrentLocation(location);
            //liveLocationMapRef.current.updateDriverCurrentLocation(location);
            setBusCurrentLocation(location);
        }).catch(err => {
            console.log(`Error in fetching trip current location :: ${JSON.stringify(err)}`);
        });
    }

    // Build stop locations for map
    const buildStopLocationForMap = (tripStops) => {
        if (school && school.latitude && school.longitude && tripStops) {
            const newStopLocations = tripStops.map(tripStop => {
                return {
                    ...tripStop.stop.location
                };
            });
            const schoolLocation = {
                latitude: school.latitude,
                longitude: school.longitude,
                location: school.address,
            }
            newStopLocations.unshift(schoolLocation); // Add at the top for origin
            setStopLocations(newStopLocations);
        }
    }

    // Prepare students for google map
    const buildStudents = (trip) => {
        const students = trip.trip_students.map(item => {
            const student = item.student;
            let location = null;
            const routeType = trip.route.type.name;            
            if (routeType === 'DROPOFF') {
                location = student.dropoff;
            } else {
                location = student.pickup;
            }
            let profilePhotoUrl = `${IMAGES_URL.USER}/default_profile.png`;
            if (student.profile_photo) {
                profilePhotoUrl = `${IMAGES_URL.USER}/${student.profile_photo}`;
            }
            return {
                id: student.id,
                name: student.name,
                profilePhoto: profilePhotoUrl,
                latitude: location?.latitude,
                longitude: location?.longitude,
                location: location?.location,
                rollNo: student.roll_no,
                sclass: student.sclass,
                section: student.section,
                order: item.order,
                parent: student.parent_student ? student.parent_student.parent : null,
                status: item.status,
                pickedupLocation: item.picked_up_location,
                droppedoffLocation: item.dropped_off_location,
            } 
        })
        //console.log(`students for google map - ${JSON.stringify(students)}`);
        return students;
    }

    // Show student info on Map - pass student data to GoogleMap Component
    const showStudentOnMap = (student) => {
        googleMapElement.current.showStudentOnMap(student);
    }

    // Opena parent chat modal
    const openParentChatModal = (student) => {        
        console.log(`Opening chat for student - ${JSON.stringify(student)}`);
        if (!student.parent) {
            return;
        }
        // Make visible chat modal
        setChatModal({
            ...chatModal,
            show: true,
        });
        // this.setState(ps => ({
        //     chatModal: {
        //         ...ps.chatModal,
        //         show: true,
        //     }
        // }));
        
        const data = {
            schoolId: schoolId,
            tripId: trip.id,               
            name: student.parent.user.name, 
            userId: student.parent.user.id,
            role: Role.PARENT,
            student: student
        }         
        // Send notification to update chat message to read for this driver user
        sendUpdateChatMessageReadObservable(data);
        // Add this chat modal opened
        setOpenedChatModal({
            ...openedChatModal,
            userId: data.userId,
            show: true,
        });
        chatModalElement.current.loadChats(data);
    }

    // Open Driver chat modal
    const openDriverChatModal = () => {
        const driver = trip.driver;
        // Make visible chat modal
        setChatModal({
            ...chatModal,
            show: true,
        });
        const data = {
            schoolId: schoolId,
            tripId: trip.id,               
            name: driver.user.name, 
            userId: driver.user.id,
            role: Role.DRIVER,
            driver: driver
        }
        console.log(`Driver chat modal data - ${JSON.stringify(data)}`);
        // Send notification to update chat message to read for this driver user
        sendUpdateChatMessageReadObservable(data);
        // Add this chat modal opened
        setOpenedChatModal({
            ...openedChatModal,
            userId: data.userId,
            show: true,
        });
        chatModalElement.current.loadChats(data);
    }

    // Send update to socket server to update chat mesage unread
    const sendUpdateChatMessageReadObservable = (data) => {
        updateChatMessageReadObservable().next({
            schoolId: data.schoolId,
            tripId: data.tripId,
            sender: {
                userId: data.userId,
                role: data.role,
            },
            receiver: {
                userId: getUser().id
            }            
        });
    }

    // Save student status if driver has marked wrong status from mobile app
    const handleStudentStatus = (data) => {
        setStudentStatusModal({
            ...studentStatusModal,
            show: true,
            data
        });
        console.log(`handleStudentStatus - ${JSON.stringify(studentStatusModal)}`);
    }

    // On student status change
    const onStudentStatusChange = (data) => {
        console.log('onStudentStatusChange ' + JSON.stringify(data));

        // Hide delete confirm modal
        setStudentStatusModal({
            ...studentStatusModal,
            show: false,
            data: {}
        });
        //Show spinner
        const body = {
            tripId: trip.id,
            studentId: data.id,
            status: data.status,
            statusChangeReason: data.value
        }
        console.log('payload to change trip student status', body);
        setIsLoading(true);
        setErrorMsg(null);
        setSuccessMsg(null);
        updateTripStudentStatus(body).then(resp => {
            setIsLoading(false);
            setSuccessMsg("Status updated successfully");
            fetchTrip(tripId);
        }).catch(err => {
            console.err(`Error i updating trip student status ${JSON.stringify(err)}`);
            setIsLoading(false);   
            setErrorMsg(err);       
        })
    }


    return (
        <>
             
             { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { successMsg &&
                <SuccessAlertTopRight
                    successMsg={successMsg}
                    onClose={() => setSuccessMsg(null)} 
                />
            }
             
             <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'center' }}>
                    <Typography component="h2" variant="header">
                        Trip - #{trip.ref_no} - {trip?.route?.type.name}
                    </Typography>
                    <Box>
                        <Chip
                            onClick={() => fetchTrip(tripId)}
                            sx={{ mr: 3 }}
                            icon={<RefreshIcon />}
                            label="Reload trip"
                            variant="outlined"
                            color="primary"
                        />
                        <Chip
                            onClick={() => navigate(`../today/list`)}
                            sx={{ mr: 3 }}
                            icon={<HistoryIcon />}
                            label="Todays History"
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>

                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item xs={12} md={6} lg={4}>
                        {trip.status === TripStatus.ON_RUN &&
                            <Chip
                                label={convertTripStatus(trip.status)}
                                color="success"
                                icon={<DirectionsBusFilledIcon />}
                            />
                        }
                        {trip.status === TripStatus.NEW &&
                            <Chip
                                label={convertTripStatus(trip.status)}
                                color="info"
                                icon={<ArrowCircleUpIcon />}
                            />
                        }
                        {trip.status === TripStatus.FINISHED &&
                            <Chip
                                label={convertTripStatus(trip.status)}
                                color="warning"
                                icon={<CheckCircleIcon />}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
                        <Typography variant='h5' component={'h5'}>
                            Driver - {trip.driver.user.name}
                        </Typography>
                        <IconButton aria-label="chat" color="success">
                            <Badge badgeContent={chatMessageUnReadCounts.get(trip.driver.user.id)} color="primary">
                                <ChatIcon onClick={openDriverChatModal} />
                            </Badge>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
                        <Typography variant='h5' component={'h5'}>
                            Bus no. - {trip.bus.number}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 1, minHeight: '500px' }}>
                    <Grid item xs={12} md={9}>
                        {students.length > 0 && school &&
                            <LiveLocationMap
                                locations={stopLocations}
                                currentLocation={busCurrentLocation}
                                otherIcon={stopIcon}
                                originIcon={schoolIcon}
                                ref={liveLocationMapRef}
                            />
                            // <GooogleMap ref={googleMapElement}
                            //     students={students}
                            //     school={school}>
                            // </GooogleMap>
                        }
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <List 
                            overflow="auto" 
                            sx={{ 
                                overflowY: "scroll",
                                maxHeight: '35rem', 
                                bgcolor: 'background.paper'
                            }}
                        >
                            {students.map((item, index) => (
                                <>
                                    <ListItem 
                                        sx={{ my: 1 }}
                                        alignItems="flex-start"
                                        secondaryAction={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    alignItems: "flex-start"
                                                }}
                                            >   
                                                <VertActionMenu
                                                    onViewLocation={() => { showStudentOnMap(item) } }
                                                    onGetOnBus={() => { 
                                                        handleStudentStatus({
                                                            id: item.id, 
                                                            status: TripStudentStatus.PICKED_UP,
                                                            content: 'Please enter the reason get student on the bus'
                                                        })
                                                     }}
                                                     onGetOffBus={() => { 
                                                        handleStudentStatus({
                                                            id: item.id, 
                                                            status: TripStudentStatus.DROPPED_OFF,
                                                            content: 'Please enter the reason for get student off the bus'
                                                        })
                                                     }}
                                                     onAbsent={() => { 
                                                        handleStudentStatus({
                                                            id: item.id, 
                                                            status: TripStudentStatus.ABSENT,
                                                            content: 'Please enter the reason for marking absent'
                                                        })
                                                     }}
                                                /> 
                                                <IconButton aria-label="chat" color="success">
                                                    <Badge badgeContent={chatMessageUnReadCounts?.get(item?.parent?.user?.id)} color="primary">
                                                        <ChatIcon onClick={() => openParentChatModal(item)} />
                                                    </Badge>
                                                </IconButton>

                                                
                                                  
                                                
                                                {/* <IconButton aria-label="chat" color="success"
                                                    onClick={() => openParentChatModal(item)}
                                                >
                                                    <ChatIcon />
                                                    <Badge variant="danger">{item.parent ? chatMessageUnReadCounts.get(item.parent.user.id):''}</Badge>
                                                </IconButton> */}
                                                
                                                {/* <IconButton aria-label="location" color="primary"
                                                    onClick={() => { showStudentOnMap(item) }}
                                                >
                                                    <LocationOnIcon />
                                                </IconButton> */}
                                            </Box>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={item.profilePhoto} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.name}
                                            secondary={
                                                <>
                                                    <StudentStatus 
                                                        student={item}
                                                    />
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </>
                            ))}
                        </List>
                    </Grid>
                </Grid>

            </Box>


            <ChatModal
                ref={chatModalElement}
                show={chatModal.show}
                onHide={() => {
                    const newChatModal = {
                        ...chatModal,
                        show: false,
                    }
                    setChatModal(newChatModal);

                    const newOpenedChatModal = {
                        ...openedChatModal,
                        userId: null,
                        show: false,
                    }
                    setOpenedChatModal(newOpenedChatModal);

                    // this.setState(ps => ({
                    //     chatModal: {
                    //         ...ps.chatModal,
                    //         show: false,
                    //     },
                    //     openedChatModal: {
                    //         ...ps.openDriverChatModal,
                    //         userId: null,
                    //         show: false,
                    //     }
                    // }));
                }}
            >
            </ChatModal>

            {/* Trip student change status modal */}
            <CustomModal
                show={studentStatusModal.show}
                data={studentStatusModal.data}
                type={ModalType.CONFIRM_FORM}
                onOkay={(data) => { onStudentStatusChange(data) }}
                onHide={ () => {setStudentStatusModal({...studentStatusModal, show: false})} }
                content={studentStatusModal.data.content}
            ></CustomModal>

        </>
    );
   
}


export default RunningTripMapView;

const StudentStatus = ({ student }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        if (status !== TripStudentStatus.ABSENT) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    }

    const { status, pickedupLocation, droppedoffLocation } = student;

    let color = null;
    if (status === TripStudentStatus.PICKED_UP) {
        color = 'primary';
    } else if (status === TripStudentStatus.DROPPED_OFF) {
        color = 'success';
    } else if (status === TripStudentStatus.ABSENT) {
        color = 'error';
    }

    if (color) {
        return (
            <>
                <Chip 
                    label={convertTripStudentStatus(status)} size="small"  color={color} 
                    onClick={handleClick}
                />
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ 
                        display: 'flex'
                    }}>
                        <Typography sx={{ p: 1, fontWeight: 'bold' }}>On the bus at:</Typography>
                        <Typography sx={{ py: 1, pr: 1, maxWidth: '20rem' }}>{pickedupLocation?.location}</Typography>
                    </Box>
                    <Box sx={{ 
                        display: 'flex'
                    }}>
                        <Typography sx={{ p: 1, fontWeight: 'bold' }} >Off the bus at:</Typography>
                        <Typography sx={{ py: 1, pr: 1, maxWidth: '20rem' }}>{droppedoffLocation?.location}</Typography>
                    </Box>
                </Popover>
            </>
        );
    } else {
        return (<></>);
    }
}
