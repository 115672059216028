import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Chip } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import CommonProfilePhoto from '../../../../common/profile-photo';
import { IMAGES_URL } from '../../../../utils/constants';
import { uploadProfilePhoto, getUserProfile } from '../../../../services/http.service';
import { updateProfilePhoto } from '../../../../services/auth.service';

const SettingProfilePhoto = () => {
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [user, setUser] = useState(null);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        if (user) {
            const url = user.profile_photo ? `${IMAGES_URL.USER}/${user.profile_photo}` : `${IMAGES_URL.USER}/default_profile.png`
            setProfilePhotoUrl(url);
        }
    }, [user]);

    // Fetch User
    const fetchUser = () => {
        setIsLoading(true);
        getUserProfile().then(resp => {
            setIsLoading(false);
            setErrorMsg(null);
            const data = resp.data.data;
            if (data) {
                setUser(data);
            }
        }).catch(err => {
            setIsLoading(false);
            setErrorMsg(err);
        });
    }

    // update photo
    const upload = (image) => {
        console.log(`Uploading profile photo - ${JSON.stringify(image)}`);

        // Show error if image is not selected
        if (!image) {
            setIsSaving(false);
            setErrorMsg("Please select image to upload");
            setShowSuccessAlert(false);
            return;
        }
        // Reset flags
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        uploadProfilePhoto(image).then(resp => {
            console.log(`Uploaded successfully`);
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const newUser = {
                ...user,
                profile_photo: resp.data.filename
            }
            console.log("new user - ", newUser);
            updateProfilePhoto(resp.data.filename);
            setUser(newUser);
        }).catch(err => {
            console.error(err);
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    return (
        <>
           { errorMsg && 
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert && 
                <SuccessAlertTopRight
                    successMsg={'Uploaded successfully'}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
            { isSaving && 
                <CircularProgress />
            }
            <Box  
                sx={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "start",
                    alignItems: "start"
                }}
            >
                <Chip
                    onClick={() => navigate(-1)}
                    icon={<ArrowBackIosNewIcon />}
                    label="Edit profile"
                    variant="outlined"
                    color="secondary"
                    sx={{ mb: 2 }}
                />
                {user &&
                    <CommonProfilePhoto
                        profilePhotoUrl={profilePhotoUrl}
                        altName={user.name}
                        onSubmit={upload}
                    />
                }
            </Box>
        </>
    );


}

export default SettingProfilePhoto;