import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';


const CustomPaginationActions = (props) =>  {

    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };


    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );





//     const { currentPage, totalCount, limit } = props;

//     return ( 
//         <div className="d-flex justify-content-between align-items-center">
//             <div className="d-flex">
//                 <button type="button" class="btn btn-link" onClick={() => props.onFirst()}>
//                     <i className="fas fa-angle-double-left fa-lg" title="First"></i>
//                 </button>
//                 <button type="button" class="btn btn-link" onClick={() => props.onPrevious()}>
//                     <i className="fas fa-angle-left fa-lg" title="Previous"></i>
//                 </button>
//             </div>

//             <div className="text-muted">
//                 {getRecordCountText(currentPage, totalCount, limit)}
//             </div>

//             <div className="d-flex">
//                 <button type="button" class="btn btn-link" onClick={() => props.onNext()}>
//                     <i className="fas fa-angle-right fa-lg" title="Next"></i>                    
//                 </button>
//                 <button type="button" class="btn btn-link" onClick={() => props.onLast()}>
//                     <i className="fas fa-angle-double-right fa-lg" title="Last"></i>
//                 </button>
//             </div>
//         </div>
//     )
// }

// // Get record count text
// const getRecordCountText = (currentPage, totalCount, limit) => {    
//     let count = currentPage * limit;
//     if (count > totalCount) {
//         count = totalCount;
//     }
//     return (
//         <div>{count} of {totalCount}</div>
//     )
// }
}


export default (CustomPaginationActions);