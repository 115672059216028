import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Col, Form, Button, Spinner, Modal } from 'react-bootstrap';
//import { withRouter } from "react-router";

import { ModalTypes } from './utils/constants'
import { closeModal } from './actions/modals';
import { useNavigate } from "react-router-dom";

const ModalComponent = (props) => {
    let history = useNavigate();
    return (
        <React.Fragment>
            {props.modals.modalType === ModalTypes.ERROR &&
                <Modal show={props.modals.isOpen} onHide={() => props.onCancel()}>
                    <Modal.Header closeButton color="danger">
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{props.modals.content}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => {
                            props.onCancel();
                            //props.history.push('/login');
                            history.push('/login');
                        }}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </React.Fragment>
    )
    
}

const mapDispatchToProps = dispatch => {
    return {
        onCancel: (type) => {
            console.log("onCancel clicked");
            dispatch(closeModal(ModalTypes.ERROR));
        }
        
    }
  }
  
  const mapStateToProps = state => ({
    modals: state.modals
  })
  
//   export default connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(withRouter(ModalComponent));

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalComponent);  // withRouter is replaced with userHistory above