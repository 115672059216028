import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';

import { Box } from '@mui/material';

import Notification from './notification';
import ChatNotification from './chat-notification';

const NotificationSection = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                ml: 2,
                mr: 3,
                [theme.breakpoints.down('md')]: {
                    mr: 2
                }
            }}
        >
            <Notification />
            <ChatNotification />
        </Box>
    )
}

export default NotificationSection;