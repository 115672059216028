import React, { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationBasic = ({ totalCount, limit, onChange }) => {

    const [totalPages, setTotalPages] = useState();

    useEffect(() => {
        setTotalPages(Math.ceil(totalCount/limit));
    }, [totalCount]);

    const onPageChange = (event, page) => {
        console.log("page - ", page);
        onChange(page)
    }

    return (
        <Stack spacing={2}>
            <Pagination 
                count={totalPages} 
                variant="outlined"
                onChange={onPageChange}
            />
        </Stack>
    );
}

export default PaginationBasic;