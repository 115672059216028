import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Form, Button, Spinner, Alert } from 'react-bootstrap';

import { editUserProfile, getUserProfile } from '../../services/http.service';
import { useNavigate } from "react-router-dom";


const EditProfile = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        fetchUser();
    }, []);

    // Fetch User
    const fetchUser = () => {
        setIsLoading(true);
        getUserProfile().then(resp => {
            setIsLoading(false);
            if (resp.data.data) {
                setName(resp.data.data.name);
                setEmail(resp.data.data.email);
            }
        }).catch(err => {
            setIsLoading(false);
            setErrorMsg(err);
        });
    }

    /**
     * Handle form submission
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log(`not valid`);
            return
        } 

        // Reset flags
        setIsSaving(true);
        setShowSuccessAlert(false);
        setErrorMsg(null);
        // Prepare data
        const data = {
            name: form[0].value,
            email: form[1].value,
        }
        editUserProfile(data).then(resp => {
            setIsSaving(false);
            setShowSuccessAlert(true);
            setErrorMsg(null);
        }).catch(err => {
            console.log(err);
            setIsSaving(false);
            setShowSuccessAlert(false);
            setErrorMsg(err);
        })

    }


    return (            
        <React.Fragment>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h4 mb-0 text-gray-800">Edit Profile</h1>
            </div>
            <div className="card shadow h-100 my-2">
                <div className="card-body">
                    {isLoading &&
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col md={6}>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control required type="text" placeholder="Enter name"
                                            value={name}
                                            onChange={(e) => {       
                                                setName(e.target.value);
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control required type="email" placeholder="Enter email"
                                            value={email}
                                            onChange={(e) => {     
                                                setEmail(e.target.value);
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter valid email.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <div className="d-flex align-items-center justify-content-left">
                                        <Button className="btn btn-primary mr-4" type="submit">Update</Button>
                                        <Button variant="link" onClick={() => navigate(-1)}>Back</Button>
                                        {isSaving &&                         
                                            <Spinner animation="border" variant="primary" />
                                        }
                                        {showSuccessAlert &&
                                            <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false) } 
                                                dismissible>
                                                Saved Successfully
                                            </Alert>
                                        }
                                        {errorMsg &&
                                            <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null) } 
                                                dismissible>
                                                {errorMsg}
                                            </Alert>
                                        }
                                    </div>  
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>  
        </React.Fragment>     
    );
}

export default EditProfile


// class EditProfile extends Component {
//     constructor(props) {
// 		super(props)
// 		this.state = {
//             isLoading: false,
//             validated: false,
//             name: '',
//             email: '',
//         }
//     }

//     componentDidMount() {
//         this.fetchUser();
//     }

//     // Fetch User
//     fetchUser = () => {
//         this.setState({ isLoading: true });
//         getUserProfile().then(resp => {
//             this.setState({ isLoading: false });
//             if (resp.data.data) {
//                 this.setState({ 
//                     name: resp.data.data.name,
//                     email: resp.data.data.email
//                 })
//             }
//         }).catch(err => {
//             this.setState({ isLoading: false, errorMsg: err });
//         });
//     }

//     /**
//      * Handle form submission
//      */
//     handleSubmit = (event) => {
//         event.preventDefault();
//         event.stopPropagation();
//         this.setState({ validated: true });
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             console.log(`not valid`);
//             return
//         } 

//         // Reset flags
//         this.setState({ isSaving: true, showSuccessAlert: false, errorMsg: null});
//         // Prepare data
//         const data = {
//             name: form[0].value,
//             email: form[1].value,
//         }
//         editUserProfile(data).then(resp => {
//             this.setState({ isSaving: false, showSuccessAlert: true, errorMsg: null});
//         }).catch(err => {
//             console.log(err);
//             this.setState({ isSaving: false, showSuccessAlert: false, errorMsg: err});
//         })

//     }

//     render() {

//         const { isLoading, validated, isSaving, showSuccessAlert, errorMsg } = this.state;

//         return (            
//             <React.Fragment>
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Edit Profile</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         {isLoading &&
//                             <Row>
//                                 <Col className="d-flex justify-content-center">
//                                     <Spinner animation="border" variant="primary" />
//                                 </Col>
//                             </Row>
//                         }
//                         <Row>
//                             <Col md={6}>
//                                 <Form
//                                     noValidate
//                                     validated={validated}
//                                     onSubmit={e => this.handleSubmit(e)}>
//                                         <Form.Group>
//                                             <Form.Label>Name</Form.Label>
//                                             <Form.Control required type="text" placeholder="Enter name"
//                                                 value={this.state.name}
//                                                 onChange={(e) => {                                                        
//                                                     this.setState({ name: e.target.value });
//                                                 }}
//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please enter name.
//                                             </Form.Control.Feedback>
//                                         </Form.Group>
//                                         <Form.Group>
//                                             <Form.Label>Email</Form.Label>
//                                             <Form.Control required type="email" placeholder="Enter email"
//                                                 value={this.state.email}
//                                                 onChange={(e) => {                                                        
//                                                     this.setState({ email: e.target.value });
//                                                 }}
//                                             />
//                                             <Form.Control.Feedback type="invalid">
//                                                 Please enter valid email.
//                                             </Form.Control.Feedback>
//                                         </Form.Group>
//                                         <div className="d-flex align-items-center justify-content-left">
//                                             <Button className="btn btn-primary mr-4" type="submit">Update</Button>
//                                             <Button variant="link" onClick={() => this.props.history.goBack()}>Back</Button>
//                                             {isSaving &&                         
//                                                 <Spinner animation="border" variant="primary" />
//                                             }
//                                             {showSuccessAlert &&
//                                                 <Alert className="ml-3 mb-0" variant="success" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                                     dismissible>
//                                                     Saved Successfully
//                                                 </Alert>
//                                             }
//                                             {errorMsg &&
//                                                 <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({errorMsg: null}) } 
//                                                     dismissible>
//                                                     {this.state.errorMsg}
//                                                 </Alert>
//                                             }
//                                         </div>  
//                                 </Form>
//                             </Col>
//                         </Row>
//                     </div>
//                 </div>  
//             </React.Fragment>     
//         )
//     }

// }

// export default connect()(EditProfile)