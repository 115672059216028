import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    IconButton, Divider } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { saveOrUpdateRouteBus, deleteRouteBus } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import { CustomModal } from '../../../../common/modals';
import { ModalType } from '../../../../utils/constants';

const AssignRouteBus = ({ availableBuses, route, onNext, onBack, onSubmit }) => {

    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [selectedBus, setSelectedBus] = useState(null);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    useEffect(() => {
        console.log("availableBuses - ", availableBuses);
        setSelectedBus(route.bus);
    }, [route]);

    // Save assinged bus
    const handleSubmit = () => {
        console.log("Selected Bus - ", selectedBus);
        if (!selectedBus) {
            return;
        }
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        const data = {
            id: route.id,
            type: route.type,
            bus_id: selectedBus.id                
        }

        saveOrUpdateRouteBus(data).then(resp => { 
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const respData = resp.data.data;
            if (respData) {
                const newRoute = {
                    ...route,
                    bus: selectedBus,
                    id: respData.route_id, // set back id to update for the same in next section
                }
                onSubmit(newRoute);
            }
        }).catch(err => {
            console.log(err);
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    // Delete bus from route
    const deleteBus = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
    }
    // On Delete of Route bus
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsSaving(true);
        deleteRouteBus(data.id).then(resp => {
            setIsSaving(false);
            onSubmit(route);
        }).catch(err => {
            console.log(`Error in deleting bus from route :: ${JSON.stringify(err)}`);
            setIsSaving(false);          
        })
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Route students saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)}
                />
            }

            <Box>
                <Box sx={{ mt: 1 }}>
                    <Autocomplete
                        id="available-buses"
                        options={availableBuses}
                        sx={{ width: '20rem' }}
                        value={selectedBus}
                        isOptionEqualToValue={(option, value) => {
                            if (value) {
                                return option.id === value.id;
                            }
                            return false;
                        }}
                        getOptionLabel={(option) => option.number}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSelectedBus(newValue);
                            }
                        }}
                        renderInput={(params) => <TextField {...params} label="Assign Bus" />}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}
            >
                {isSaving &&
                    <CircularProgress />
                }
                {!isSaving &&
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ my: 2 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ mx: 2}}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ mx: 2 }}
                                startIcon={<ArrowBackIosNewIcon />}
                                onClick={onBack}
                            >
                                Back
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{ mx: 2 }}
                                startIcon={<ArrowForwardIosIcon />}
                                onClick={onNext}
                            >
                                Next
                            </Button>
                        </Box>
                        { selectedBus &&
                            <Box sx={{ my: 1 }}>
                                <Button 
                                    size="small" 
                                    color="error"
                                    onClick={() => deleteBus(route.id)}>
                                    Remove Bus
                                </Button>
                            </Box>
                        }
                    </Box>
                }
            </Box>
            {/* For delete confirmation */}
            {deleteJson.show &&
                <CustomModal 
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        onDelete(deleteJson.data);
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                    onHide={() => {
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                >
                </CustomModal>
            }
        </>
    );

}

export default AssignRouteBus;