import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton, FormControl, InputLabel, Select, MenuItem, Tabs,
    Tab } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getRoutesWithDistinctRef, getTodayTrips, deleteTrip } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { TripStatus } from '../../../../utils/constants';
import Loader from '../../../../common/loader';

import ListTab from './list-tab';



const TodayTripList = () => {

    const [tabValue, setTabValue] = React.useState(0);
    const [routes, setRoutes] = useState([]);
    const [trips, setTrips] = useState([]);
    const [routeLoading, setRouteLoading] = useState(false);
    const [tabKey, setTabKey] = useState('runningTrips');
    const [filter, setFilter] = useState({
        status: 'ON_RUN'
    });
    const [isLoading, setIsLoading] = useState(false);

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        fetchRoutes(); 
        fetchTrips();
    }, []);

    // Fetch Routes with only Ref and type
    const fetchRoutes = () => {
        getRoutesWithDistinctRef().then(resp => {
            const respRoutes = resp.data.data;
            const routes = respRoutes;
            setRoutes(routes);            
        }).catch(err => {
            console.log(err);
        })
    }

    // Select route from dropdown box
    const selectRoute = (e) => {
        const value = e.target.value;
        if (value) {
            setFilter({...filter, route_ref: value});
            fetchTrips();
        } 
    }

    // On select of tab action
    const onSelectTab = (key) => {
        filter.status = null;
        if (key === 'runningTrips') { 
            filter.status = TripStatus.ON_RUN;            
        } else if (key === 'finishedTrips') {
            filter.status = TripStatus.FINISHED; 
        }
        setFilter(filter);
        fetchTrips();
    }


    // Fetch routes
    const fetchTrips = () => {
        setIsLoading(true);
        getTodayTrips(filter).then(resp => {
            setTrips(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Handle tabs
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        filter.status = null;
        if (newValue === 0) { // running trips
            filter.status = TripStatus.ON_RUN;            
        } else if (newValue === 2) { // finished trips
            filter.status = TripStatus.FINISHED; 
        }
        setFilter(filter);
        fetchTrips();
    };

    // Delete trip
    const onDelete = (tripId) => {
        // Show spinner
        setIsLoading(true);
        deleteTrip(tripId).then(resp => {
            setIsLoading(false);
            fetchTrips();
        }).catch(err => {
            console.log(`Error in fetching Trips :: ${JSON.stringify(err)}`);
            setIsLoading(false);         
        })
    }


    return (
        <>
             <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Today's Trips
                    </Typography>
                    <Box>
                        <Chip
                            onClick={() => navigate(`../history/list`)}
                            sx={{ mr: 3 }}
                            icon={<HistoryIcon />}
                            label="History"
                            variant="outlined"
                            color="secondary"
                        />
                        <Chip
                            onClick={() => navigate(`../metadata/list`)}
                            icon={<SettingsIcon />}
                            label="Metadata"
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>

                { isLoading &&
                    <Loader />
                }

                <Box sx={{ mt: 3, width: '15rem' }}>
                    <FormControl fullWidth>
                        <InputLabel id="route-select-label">Filter with route</InputLabel>
                        <Select
                            labelId="route-select-label"
                            id="route-select"
                            label="Filter with route "
                            onChange={selectRoute}
                        >
                            {routes.map((item, index) => (
                                <MenuItem value={item.ref_no}>{item.number} ({item.ref_no})</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Running" />
                        <Tab label="Trips" />
                        <Tab label="Finished Trips" />
                    </Tabs>
                </Box>

                <ListTab 
                    trips={trips} 
                    baseUrl={baseUrl}
                    onTripDelete={onDelete}
                >
                </ListTab>

            </Box>
        
        </>
    );

    // return (
    //     <React.Fragment> 
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <div className="h5 mb-0 text-gray-800">Today's Trips</div>
    //         </div>
    //         <div class="card shadow h-100 py-2 my-2">
    //             <div class="card-body">
    //                 <Form>                        
    //                     <Form.Group as={Row}>
    //                         <Form.Label column sm={3}>Filter with Route
    //                             {routeLoading &&
    //                                 <Spinner className="ml-2" animation="border" size="sm" variant="primary" /> 
    //                             }
    //                         </Form.Label>
    //                         <Col sm={6}>
    //                             <Form.Control as="select" onChange={selectRoute}>
    //                                 <option value="">Select</option>
    //                                 {routes.map((item, index) => ( 
    //                                     <option key={index} value={JSON.stringify(item)}
    //                                     selected={false}>{item.ref_no}</option>
    //                                 ))}                                                                      
    //                             </Form.Control>
    //                         </Col>
    //                     </Form.Group> 
    //                 </Form>   


    //                 {isLoading &&  
    //                     <div className="d-flex justify-content-center">             
    //                     <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />  
    //                     </div>                          
    //                 }
    //                 <Tabs onSelect={k => onSelectTab(k)}>
    //                     <Tab eventKey="runningTrips" title="Running">
    //                         <ListTab trips={trips} baseUrl={baseUrl}></ListTab>
    //                     </Tab>
    //                     <Tab eventKey="trips" title="Trips">
    //                         <ListTab trips={trips} baseUrl={baseUrl}></ListTab>
    //                     </Tab>
    //                     <Tab eventKey="finishedTrips" title="Finished Trips">
    //                         <ListTab trips={trips} baseUrl={baseUrl}></ListTab>
    //                     </Tab>
    //                 </Tabs>
    //             </div>
    //         </div>


    //     </React.Fragment> 
    // );
    
}


export default TodayTripList;

// const mapDispatchToProps = dispatch => { 
//     return {}
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(TodayTripList);


// -----------------------------------------------------------


// class TodayTripList extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             routes: [], 
//             trips: [],
//             routeLoading: false,
//             tabKey: 'runningTrips',
//             filter: {
//                 status: 'ON_RUN'
//             }
// 		};
//     }

//     componentDidMount() {
//         this.fetchRoutes(); 
//         this.fetchTrips();
//     }

//      // Fetch Routes with only Ref and type
//      fetchRoutes = () => {
//         getRoutesWithDistinctRef().then(resp => {
//             const respRoutes = resp.data.data;
//             const routes = respRoutes;
//             this.setState({routes});            
//         }).catch(err => {
//             console.log(err);
//         })
//     }

//     // Select route from dropdown box
//     selectRoute = (e) => {
//         const value = e.target.value;
//         const filter = this.state.filter;
//         if (value && value !== "") {
//             const route = JSON.parse(value);            
//             filter.route_ref = route.ref_no;            
//         } else {
//             filter.route_ref = null; 
//         }
//         this.setState({filter});
//     }

//     // On select of tab action
//     onSelectTab = (key) => {
//         const filter = this.state.filter;
//         filter.status = null;
//         if (key === 'runningTrips') { 
//             filter.status = TripStatus.ON_RUN;            
//         } else if (key === 'finishedTrips') {
//             filter.status = TripStatus.FINISHED; 
//         }
//         this.setState({filter});
//         this.fetchTrips();
//     }


//     // Fetch routes
//     fetchTrips = () => {
//         this.setState({isLoading: true});
//         getTodayTrips(this.state.filter).then(resp => {
//             this.setState({trips: resp.data.data});
//             this.setState({isLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isLoading: false});
//         })
//     }

    

//     render() {

//         const { routes, trips, isLoading, routeLoading } = this.state;

// 		return (
//             <React.Fragment> 
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Today's Trips</h1>
//                 </div>
//                 <div class="card shadow h-100 py-2 my-2">
//                     <div class="card-body">
//                         <Form>                        
//                             <Form.Group as={Row}>
//                                 <Form.Label column sm={3}>Filter with Route
//                                     {routeLoading &&
//                                         <Spinner className="ml-2" animation="border" size="sm" variant="primary" /> 
//                                     }
//                                 </Form.Label>
//                                 <Col sm={6}>
//                                     <Form.Control as="select" onChange={(e) => {this.selectRoute(e)}}>
//                                         <option value="">Select</option>
//                                         {routes.map((item, index) => ( 
//                                             <option key={index} value={JSON.stringify(item)}
//                                             selected={false}>{item.ref_no}</option>
//                                         ))}                                                                      
//                                     </Form.Control>
//                                 </Col>
//                             </Form.Group> 
//                         </Form>   


//                         {isLoading &&  
//                             <div className="d-flex justify-content-center">             
//                             <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />  
//                             </div>                          
//                         }
//                         <Tabs onSelect={k => this.onSelectTab(k)}>
//                             <Tab eventKey="runningTrips" title="Running">
//                                 <ListTab trips={trips} baseUrl={this.props.baseUrl}></ListTab>
//                             </Tab>
//                             <Tab eventKey="trips" title="Trips">
//                                 <ListTab trips={trips} baseUrl={this.props.baseUrl}></ListTab>
//                             </Tab>
//                             <Tab eventKey="finishedTrips" title="Finished Trips">
//                                 <ListTab trips={trips} baseUrl={this.props.baseUrl}></ListTab>
//                             </Tab>
//                         </Tabs>
//                     </div>
//                 </div>


//             </React.Fragment> 
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {}
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(TodayTripList);