import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Chip,
    Box,
    Paper,
    Grid,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { AlertSnackbar } from '../../../../common/snackbars';
import schoolIcon from '../../../../assets/images/school_icon.png';
import stopIcon from '../../../../assets/images/stop_icon.png';
import RoutableMap from '../../../../common/googlemap/routable-map';
import TripStopsTable from './trip-stops-table';

const UpdateTripStops = ({ trip, onNext, onSubmit }) => {
    const [sortedTripStops, setSortedTripStops] = useState([]);
    const [stopLocationsForMap, setStopLocationsForMap] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    const routableMapRef = useRef(null);
    const school = useSelector(state => state.school);

    useEffect(() => {
        if (trip.stops && trip.stops.length > 0) {
            const sortedStops = [...trip.stops].sort((a, b) => a.order - b.order);
            console.log("sortedStops - ", sortedStops);
            setSortedTripStops(sortedStops);
            // Extract stop locations for map
            const stopLocations = sortedStops.filter(stop => stop.isActive).map(tripStop => tripStop.stop.location);
            console.log("stop locations for map ", JSON.stringify(stopLocations));
            const schoolLocation = {
                latitude: school.latitude,
                longitude: school.longitude,
                location: school.address,
            }
            stopLocations.unshift(schoolLocation);
            setStopLocationsForMap(stopLocations);
        }
    }, [trip.stops]);

      // Handle show location on map
      const handleViewLocationOnMap = (routeStop) => {
        if (routeStop) {
            console.log("handleViewLocationOnMap ", routeStop);
            if (routableMapRef.current) {
                routableMapRef.current.showInfoWindow(routeStop.stop.location);
            }
        }
    }

    return (
        <>
            {errorMsg || successMsg &&
                <AlertSnackbar 
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    onClose={() => {
                        setErrorMsg(null);
                        setSuccessMsg(null);
                    }}
                />
            }
            <Grid container spacing={2} sx={{ padding: 2, mt: 2 }} component={Paper}>
                <Grid item xs={12} md={6}>
                    <TripStopsTable
                        tripId={trip.id}
                        tripStops={sortedTripStops}
                        onViewLocationOnMap={handleViewLocationOnMap}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ minHeight: '500px'}}>
                    {stopLocationsForMap.length > 0 &&
                        <RoutableMap
                            locations={stopLocationsForMap}
                            originIcon={schoolIcon}
                            otherIcon={stopIcon}
                            ref={routableMapRef}
                        />
                    }
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ my: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowForwardIosIcon />}
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default UpdateTripStops;