import React, { useState, useEffect, useRef } from 'react';

import {
    Button,
    Chip,
    Box,
    Paper,
    Grid,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { AlertSnackbar } from '../../../../common/snackbars';
import TripStudentsTable from './trip-students-table';

const UpdateTripStudents = ({ trip, onNext, onBack }) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    return (
        <>
            {errorMsg || successMsg &&
                <AlertSnackbar 
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    onClose={() => {
                        setErrorMsg(null);
                        setSuccessMsg(null);
                    }}
                />
            }
            <TripStudentsTable
                tripId={trip.id}
                tripStudents={trip.students}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ my: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={onBack}
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowForwardIosIcon />}
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default UpdateTripStudents;