import React, { Component, useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux'
import { Box, Typography, Grid, TextField, CircularProgress, Button } from '@mui/material';
import GoogleMapReact from 'google-map-react';

import { saveSchool, saveOrUpdateSchool, fetchSchool } from '../../../services/http.service';
import { openModal } from '../../../actions/modals';
import { ModalTypes, DEFAULT_MAP_LOCATION } from '../../../utils/constants';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../common/snackbars';
import { createMapOptions } from '../../../utils/google-map-helper';

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    address: Yup.string().required("Address is required."),
    zipcode: Yup.string().required("Zipcode is required."),
    phone: Yup.string().required("Phone is required."),
});


const NewSchool = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [initialCenter, setInitialCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
    const [center, setCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
    const [zoom, setZoom] = useState(14);
    let [school, setSchool] = useState({
        id: null,
        name: '',
        address: '',
        zipcode: '',
        phone: '',
        website: '',
        latitude: 0,
        longitude: 0
    });
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [validated, setValidated] = useState(false);

    // useRef for school as callback functions do not get updated object done by set states
    const schoolRef = useRef(school);

    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    
    let mapCache = null; // store for later use
    let mapsCache = null; // store for later use
    let markerCache = null;

    // run on compnonent init
    useEffect(() => {
        console.log("useEffect init");
        setSchool({
            ...school,
            id: id,
        })
    }, []); // runs once similar to componentDidMount
   

    /**
     * Handle Submit of form
     */
     const formik = useFormik({
        // enableReinitialize: true,
        // initialValues: {
        //     name: schoolRef.current.name,
        //     address: schoolRef.current.address,
        //     phone: schoolRef.current.phone,
        //     website: schoolRef.current.website,
        // },
        //initialValues: {schoolRef.current},
        initialValues: {
            name: '',
            address: '',
            phone: '',
            website: '',
            zipcode: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const updatedSchool = {...school,
                name: values.name,
                //address: values.address, // do not set address as it doesnt get from google suggestion
                phone: values.phone,
                website: values.website,
                zipcode: values.zipcode,
            }
            setIsSaving(true);
            saveOrUpdateSchool(updatedSchool).then(resp => {
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
                
                // set school id if its new
                if (!school.id) {
                    setSchool({...school, id: resp.data.data.school_id});
                }

                // Navigate to adding School Logo screen
                // if (resp.data.data) {
                //     navigate(`/school/${resp.data.data.school_id}/logo`);
                // }
                
            }).catch(err => {
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
                //dispatch(openModal(ModalTypes.ERROR, err, null, null));
            });
        },
    });

    // Load school
    const loadSchool = () => {
        const id = school.id;
        if (!id) {
            return;
        }
        setIsLoading(true);
        fetchSchool(id).then(resp => {
            const school = resp.data.school;
            setSchool(school);
            schoolRef.current = school; // update ref to be used in other function
            formik.setValues({ ...school }); // get copy of school
            setIsLoading(false);
            // Set Initial center
            setCenter({lat: school.latitude, lng: school.longitude});
            // Remove previous marker
            if (markerCache !== null) {
                markerCache.setMap(null);
                //setMarker(null);
                markerCache = null;
            }
            // Add new Marker    
            const postion =  {lat: school.latitude, lng: school.longitude};       
            const schoolMarker = new mapsCache.Marker({
                draggable: true,
                position: postion,
                map: mapCache,
                title: school.name
            });
            mapCache.setCenter(postion);
            
            console.log(`marker in loadSchool - ${mapCache}`)
            //setMarker(marker);
            markerCache = schoolMarker;
            // On Marker Drag get location
            setOnMarkerDrag();
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
    }



    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setValidated(true);
    //     const form = e.currentTarget;
    //     if (form.checkValidity() === false) {
    //         console.log(`not valid`);
    //         return
    //     }
    //     school.name = form[0].value;
    //     school.address = form[1].value;
    //     school.phone = form[2].value;
    //     school.website = form[3].value;
    //     console.log(`School - ${JSON.stringify(school)}`);
    //     setIsSaving(true);
    //     saveOrUpdateSchool(school).then(resp => {
    //         setIsSaving(false);
    //         setShowSuccessAlert(true);
    //         setErrorMsg(null);

    //         // Navigate to adding School Logo screen
    //         if (resp.data.data) {
    //             navigate(`/school/${resp.data.data.school_id}/logo`);
    //         }
            
    //     }).catch(err => {
    //         setIsSaving(false);
    //         setErrorMsg(err);
    //         setShowSuccessAlert(false);
    //         dispatch(openModal(ModalTypes.ERROR, err, null, null));
    //     });
    // }


    // ------- Google map apis ----------------

    const handleApiLoaded = (map, maps) => {
        console.log("On google map ready");
        mapCache = map; // set for later use
        mapsCache = maps; // set for later use
        //this.setState({google: this.props});
        
        // Load school for Edit
        loadSchool();
        
        // Add default marker
        const defaultMarker = new maps.Marker({
            draggable: true,
            position: initialCenter,
            map,
            title: 'School Address'
        });
        //setMarker(defaultMarker);
        markerCache = defaultMarker; // for later use

        // Add Autocomplete input       
		const autocomplete = new maps.places.Autocomplete(document.getElementById('address'));
        autocomplete.bindTo('bounds', map);
        autocomplete.addListener('place_changed', () => {
            var place = autocomplete.getPlace();
            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);  // Why 17? Because it looks good.
            }
            //setSchool({...school, name: "fdfdd"});


            // Set address into school
            const updatedSchool = schoolRef.current; // get from school ref
            console.log(JSON.stringify(schoolRef.current));
            setSchool({
                ...updatedSchool,
                address: place.formatted_address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
            });
            schoolRef.current = updatedSchool; // update it back
            console.log(JSON.stringify(schoolRef.current));
            
            // Remove previous marker
            if (markerCache !== null) {
                markerCache.setMap(null);
                //setMarker(null);
                markerCache = null;
            }
            // Add new Marker            
            const newMarker = new maps.Marker({
                draggable: true,
                position: place.geometry.location,
                map: map,
                title: 'School Address'
            });
            //setMarker(newMarker);
            markerCache = newMarker;
            
            // On Marker Drag get location
            setOnMarkerDrag();

        });
    };

    // Set OnMarker Drag event
    const setOnMarkerDrag = () => {
        const geocoder = new mapsCache.Geocoder();
        
        mapsCache.event.addListener(markerCache, 'dragend', (marker) => {
            console.log("Marker drag new Location :: " + JSON.stringify(marker.latLng));
            geocoder.geocode({'latLng': marker.latLng}, (results, status) => {
                if (status === mapsCache.GeocoderStatus.OK) {
                    console.log("Marker drag Geocode Address :: " + results[0].formatted_address);
                    // Update school object
                    const updatedSchool = schoolRef.current;
                    setSchool({
                        ...updatedSchool,
                        address: results[0].formatted_address,
                        latitude: marker.latLng.lat(),
                        longitude: marker.latLng.lng()
                    });
                }
            });
        });
    }

    // ------- End google map api -----------

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Student saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
            <Typography component="h2" variant="header">
                Create new school
            </Typography>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                {isLoading &&
                    <Grid container>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="name"
                            label="School name"
                            value={formik.values.name}
                            onChange={(e) => {
                                const val = e.target.value;
                                setSchool({...school, name: val});
                                schoolRef.current.name = val; // update it to be used in functions
                                formik.handleChange(e);
                            }}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            id="address" // to be used for address suggestions
                            name="address"
                            label="Address"
                            value={school.address}
                            onChange={(e) => {
                                setSchool({...school, address: e.target.value});
                                formik.handleChange(e);
                            }}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                        />
                         <TextField
                            margin="normal"
                            required
                            name="zipcode"
                            label="Zipcode (Pincode)"
                            value={school.zipcode}
                            onChange={(e) => {
                                setSchool({...school, zipcode: e.target.value});
                                formik.handleChange(e);
                            }}
                            error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                            helperText={formik.touched.zipcode && formik.errors.zipcode}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Phone"
                            name="phone"
                            required
                            value={formik.values.phone}
                            onChange={(e) => {
                                console.log(JSON.stringify(school));
                                const val = e.target.value;
                                setSchool({...school, name: val});
                                schoolRef.current.name = val; // update it to be used in functions
                                formik.handleChange(e);
                            }}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Website"
                            name="website"
                            value={formik.values.website}
                            onChange={(e) => {
                                console.log(JSON.stringify(school));
                                const val = e.target.value;
                                setSchool({...school, name: val});
                                schoolRef.current.name = val; // update it to be used in functions
                                formik.handleChange(e);
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {isSaving &&
                                <CircularProgress />
                            }
                            {!isSaving &&
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {school.id ? 'Update':'Continue'}
                                </Button>
                            }
                        </Box>
                        {school.id &&
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: "space-between" }}>
                                <Link to={`/school/${school.id}/logo`}>Update Logo</Link>
                                {/* <Link to={`/school/${school.id}/subscription`}>Change Subscription</Link> */}
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ height: "500px" }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyCdw-yRVWwWV4Hfpje_ykGgiCILFp01TDs', libraries: ['places', 'geometry'], }}
                            defaultCenter={center}
                            defaultZoom={zoom}
                            options={createMapOptions}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        ></GoogleMapReact>
                    </Box>
                </Grid>
            </Grid>
        </>
    );


    // return (   
    //     <React.Fragment>
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <h1 className="h4 mb-0 text-gray-800">Create new school</h1>
    //         </div>
    //         <div className="row">
    //             <div className="col-md-6">
    //                 <div class="card border-left-info shadow h-100 py-2 my-2">
    //                     <div class="card-body">
    //                         <Form
    //                             noValidate
    //                             validated={validated}
    //                             onSubmit={handleSubmit}>
    //                             <Form.Group controlId="formName">
    //                                 <Form.Label className="small">School Name</Form.Label>
    //                                 <Form.Control required type="text" placeholder="Enter school name"
    //                                     className="form-control-user"
    //                                     value={school.name}
    //                                     onChange={(e) => {
    //                                         console.log(JSON.stringify(school));
    //                                         const val = e.target.value;
    //                                         setSchool({...school, name: val});
    //                                         schoolRef.current.name = val; // update it to be used in functions
    //                                     }}
    //                                 />
    //                             </Form.Group>
    //                             <Form.Group controlId="formName">
    //                                 <Form.Label className="small">Address</Form.Label>
    //                                 <Form.Control required type="text" id="address" placeholder="Enter address"
    //                                     className="form-control-user"
    //                                     value={school.address}
    //                                     onChange={(e) => {
    //                                         setSchool({...school, address: e.target.value});
    //                                     }}
    //                                 />
    //                             </Form.Group>
    //                             <Form.Group controlId="formName">
    //                                 <Form.Label className="small">Phone</Form.Label>
    //                                 <Form.Control required type="text" placeholder="Enter phone"
    //                                     className="form-control-user"
    //                                     value={school.phone}
    //                                     onChange={(e) => {
    //                                         setSchool({...school, phone: e.target.value});
    //                                     }}
    //                                 />
    //                             </Form.Group>
    //                             <Form.Group controlId="formName">
    //                                 <Form.Label className="small">Website</Form.Label>
    //                                 <Form.Control type="text" placeholder="Enter website"
    //                                     className="form-control-user"
    //                                     value={school.website}
    //                                     onChange={(e) => {
    //                                         setSchool({...school, website: e.target.value});
    //                                     }}
    //                                 />
    //                             </Form.Group>
    //                             <div className="d-flex flex-column align-items-center justify-content-center">
    //                                 <Button className="btn btn-primary btn-user btn-block btn-user" type="submit">
    //                                     {school.id ? 'Update':'Continue'}</Button>
    //                                 {isSaving &&
    //                                     <Spinner className="my-2" animation="border" variant="primary" />
    //                                 }
    //                                 {showSuccessAlert &&
    //                                     <Alert className="ml-3 my-2" variant="success" onClose={() => setShowSuccessAlert(false)}
    //                                         dismissible>
    //                                         Saved Successfully
    //                                 </Alert>
    //                                 }
    //                                 {errorMsg &&
    //                                     <Alert className="ml-3 my-2" variant="danger" onClose={() => setErrorMsg(null)}
    //                                         dismissible>
    //                                         {errorMsg}
    //                                     </Alert>
    //                                 }
    //                             </div>
    //                             {school.id &&
    //                                 <div className="d-flex align-items-center justify-content-between mt-3">
    //                                     <Link to={`/school/${school.id}/logo`}>Update Logo</Link>
    //                                     <Link to={`/school/${school.id}/subscription`}>Change Subscription</Link>
    //                                 </div>
    //                             }
    //                         </Form>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-md-6">
    //                 {/* <Map google={props.google}
    //                     initialCenter={initialCenter}
    //                     center={center}
    //                     zoom={zoom}
    //                     onReady={onMapReady}
    //                     style={{height: '100%', width: '95%'}}>
    //                 </Map> */}
    //                  <GoogleMapReact
    //                     bootstrapURLKeys={{ key: 'AIzaSyCdw-yRVWwWV4Hfpje_ykGgiCILFp01TDs', libraries: ['places', 'geometry'], }}
    //                     defaultCenter={center}
    //                     defaultZoom={zoom}
    //                     yesIWantToUseGoogleMapApiInternals
    //                     onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    //                 ></GoogleMapReact>
    //             </div>
    //         </div>
    //     </React.Fragment>
    // );
}

// const mapDispatchToProps = dispatch => { 
//     return {
//         onError: (err) => {
//             console.log("onError..");
//             dispatch(openModal(ModalTypes.ERROR, err, null, null));
//         }
//     }
// }

// const mapStateToProps = state => {
//     return {
//         schools: state.schools
//     }
// }

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(NewSchool);
export default (NewSchool);


// ----------------- below is old version ----




// class NewSchool extends Component {
//     constructor(props) {
// 		super(props)
// 		this.state = {
//             isLoading: false,
//             initialCenter: {lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude}, 
//             center: {lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude},
//             zoom: 14,
//             marker: null,
//             map: null,
//             school: {
//                 id: null,
//                 name: '',
//                 address: '',
//                 phone: '',
//                 website: '',
//                 latitude: 0,
//                 longitude: 0
//             },
//             isSaving: false,
//             errorMsg: null,
//             showSuccessAlert: false
//         };
//         this.onMapReady = this.onMapReady.bind(this);
//     }

//     componentDidMount() {
//         const id = this.props.match.params.id;
//         this.setState(() => ({
//             school: {
//                 ...this.state.school,
//                 id: id
//             }
//         }))
//         console.log(`school id - ${id}`);
//     }

//     // Load school
//     loadSchool = () => {
//         const id = this.state.school.id;
//         if (!id) {
//             return;
//         }
//         this.setState({ isLoading: true });
//         fetchSchool(this.state.school.id).then(resp => {
//             const school = resp.data.school;
//             const map = this.state.map;
//             this.setState({school: school});
//             this.setState({ isLoading: false });
//             // Set Initial center
//             this.setState({center: {lat: school.latitude, lng: school.longitude}})
//             // Remove previous marker
//             if (this.state.marker !== null) {
//                 this.state.marker.setMap(null);
//                 this.setState({marker: null});
//             }
//             // Add new Marker            
//             const marker = new this.props.google.maps.Marker({
//                 draggable: true,
//                 position: {lat: school.latitude, lng: school.longitude},
//                 map: map,
//                 title: school.name
//             });
            
//             console.log(`marker in loadSchool - ${this.state.map}`)
//             this.setState({marker: marker});
//             // On Marker Drag get location
//             this.setOnMarkerDrag();
//         }).catch(err => {
//             console.log(err);
//             this.setState({ isLoading: false });
//         });
//     }


//     // On Google map ready
//     onMapReady(mapProps, map) {
//         console.log("On google map ready");
//         this.setState({map: map});
//         this.setState({google: this.props});
        
//         // Load school for Edit
//         this.loadSchool();
//         const { google } = this.props;        
        
        
//         // Add default marker
//         const marker = new google.maps.Marker({
//             draggable: true,
//             position: this.state.initialCenter,
//             map: map,
//             title: 'School Address'
//         });
//         this.setState({marker: marker});
        
//         // Add Autocomplete input       
// 		const autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'));
//         autocomplete.bindTo('bounds', map);
//         autocomplete.addListener('place_changed', () => {
//             var place = autocomplete.getPlace();
//             if (!place.geometry) {
//                 // User entered the name of a Place that was not suggested and
//                 // pressed the Enter key, or the Place Details request failed.
//                 window.alert("No details available for input: '" + place.name + "'");
//                 return;
//             }
//             if (place.geometry.viewport) {
//                 map.fitBounds(place.geometry.viewport);
//             } else {
//                 map.setCenter(place.geometry.location);
//                 map.setZoom(17);  // Why 17? Because it looks good.
//             }
//             // Set address into school
//             let school = this.state.school;
//             school.address = place.formatted_address;
//             school.latitude = place.geometry.location.lat();
//             school.longitude = place.geometry.location.lng();
//             this.setState({school});
            
//             // Remove previous marker
//             if (this.state.marker !== null) {
//                 this.state.marker.setMap(null);
//                 this.setState({marker: null});
//             }
//             // Add new Marker            
//             const marker = new google.maps.Marker({
//                 draggable: true,
//                 position: place.geometry.location,
//                 map: map,
//                 title: 'School Address'
//             });
//             this.setState({marker: marker});
            
//             // On Marker Drag get location
//             this.setOnMarkerDrag();

//         });
//     }
    
//     // Set OnMarker Drag event
//     setOnMarkerDrag() {
//         const { google } = this.props;
//         const geocoder = new google.maps.Geocoder();
        
//         google.maps.event.addListener(this.state.marker, 'dragend', (marker) => {
//             console.log("Marker drag new Location :: " + JSON.stringify(marker.latLng));
//             geocoder.geocode({'latLng': marker.latLng}, (results, status) => {
//                 if (status === google.maps.GeocoderStatus.OK) {
//                     console.log("Marker drag Geocode Address :: " + results[0].formatted_address);
//                     // Update Shop object
//                     const school = this.state.school;
//                     school.address = results[0].formatted_address;
//                     school.latitude = marker.latLng.lat();
//                     school.longitude = marker.latLng.lng();
//                     this.setState({school: school});
//                 }
//             });
//         });
//     }

//     /**
//      * Handle Submit of form
//      */
//     handleSubmit = (e) => {
//         e.preventDefault();
//         e.stopPropagation();
//         this.setState({validated: true});
//         const form = e.currentTarget;
//         if (form.checkValidity() === false) {
//             console.log(`not valid`);
//             return
//         }

//         const school = this.state.school;
//         school.name = form[0].value;
//         school.address = form[1].value;
//         school.phone = form[2].value;
//         school.website = form[3].value;
//         console.log(`School - ${JSON.stringify(school)}`);
//         this.setState({isSaving: true});
//         saveOrUpdateSchool(school).then(resp => {
//             this.setState({isSaving: false});
//             this.setState({showSuccessAlert: true});
//             this.setState({errorMsg: null});

//             // Navigate to adding School Logo screen
//             if (resp.data.data) {
//                 this.props.history.push(`/school/${resp.data.data.school_id}/logo`);
//             }
            
//         }).catch(err => {
//             this.setState({isSaving: false});
//             this.setState({errorMsg: err});
//             this.setState({showSuccessAlert: false});
//             this.props.onError(err);
//         });
//     }

//     render() {
//         const { isLoading, initialCenter, center, zoom, isSaving, showSuccessAlert, errorMsg } = this.state;
//         return (   
//             <React.Fragment>
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Create new school</h1>
//                 </div>
//                 <div className="row">
//                     <div className="col-md-6">
//                         <div class="card border-left-info shadow h-100 py-2 my-2">
//                             <div class="card-body">
//                                 <Form
//                                     noValidate
//                                     validated={this.state.validated}
//                                     onSubmit={e => this.handleSubmit(e)}>
//                                     <Form.Group controlId="formName">
//                                         <Form.Label className="small">School Name</Form.Label>
//                                         <Form.Control required type="text" placeholder="Enter school name"
//                                             className="form-control-user"
//                                             value={this.state.school.name}
//                                             onChange={(e) => {
//                                                 const school = this.state.school;
//                                                 school.name = e.target.value;
//                                                 this.setState({ school });
//                                             }}
//                                         />
//                                     </Form.Group>
//                                     <Form.Group controlId="formName">
//                                         <Form.Label className="small">Address</Form.Label>
//                                         <Form.Control required type="text" id="address" placeholder="Enter address"
//                                             className="form-control-user"
//                                             value={this.state.school.address}
//                                             onChange={(e) => {
//                                                 const school = this.state.school;
//                                                 school.address = e.target.value;
//                                                 this.setState({ school });
//                                             }}
//                                         />
//                                     </Form.Group>
//                                     <Form.Group controlId="formName">
//                                         <Form.Label className="small">Phone</Form.Label>
//                                         <Form.Control required type="text" placeholder="Enter phone"
//                                             className="form-control-user"
//                                             value={this.state.school.phone}
//                                             onChange={(e) => {
//                                                 const school = this.state.school;
//                                                 school.phone = e.target.value;
//                                                 this.setState({ school });
//                                             }}
//                                         />
//                                     </Form.Group>
//                                     <Form.Group controlId="formName">
//                                         <Form.Label className="small">Website</Form.Label>
//                                         <Form.Control type="text" placeholder="Enter website"
//                                             className="form-control-user"
//                                             value={this.state.school.website}
//                                             onChange={(e) => {
//                                                 const school = this.state.school;
//                                                 school.website = e.target.value;
//                                                 this.setState({ school });
//                                             }}
//                                         />
//                                     </Form.Group>
//                                     <div className="d-flex flex-column align-items-center justify-content-center">
//                                         <Button className="btn btn-primary btn-user btn-block btn-user" type="submit">
//                                             {this.state.school.id ? 'Update':'Continue'}</Button>
//                                         {isSaving &&
//                                             <Spinner className="my-2" animation="border" variant="primary" />
//                                         }
//                                         {showSuccessAlert &&
//                                             <Alert className="ml-3 my-2" variant="success" onClose={() => this.setState({ showSuccessAlert: false })}
//                                                 dismissible>
//                                                 Saved Successfully
//                                         </Alert>
//                                         }
//                                         {errorMsg &&
//                                             <Alert className="ml-3 my-2" variant="danger" onClose={() => this.setState({ errorMsg: null })}
//                                                 dismissible>
//                                                 {this.state.errorMsg}
//                                             </Alert>
//                                         }
//                                     </div>
//                                     {this.state.school.id &&
//                                         <div className="d-flex align-items-center justify-content-between mt-3">
//                                             <Link to={`/school/${this.state.school.id}/logo`}>Update Logo</Link>
//                                             <Link to={`/school/${this.state.school.id}/subscription`}>Change Subscription</Link>
//                                         </div>
//                                     }
//                                 </Form>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-6">
//                         <Map google={this.props.google}
//                             initialCenter={initialCenter}
//                             center={center}
//                             zoom={zoom}
//                             onReady={this.onMapReady}
//                             style={{height: '100%', width: '95%'}}>
//                         </Map>
//                     </div>
//                 </div>
//             </React.Fragment>
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {
//         onError: (err) => {
//             console.log("onError..");
//             dispatch(openModal(ModalTypes.ERROR, err, null, null));
//         }
//     }
// }

// const mapStateToProps = state => {
//     return {
//         schools: state.schools
//     }
// }

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(NewSchool);