import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import { useSelector } from 'react-redux'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";

import { getTripMetadataList, deleteTripMetadata, disableEnableTripMetadata } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { TripStatus } from '../../../../utils/constants';
import { ModalType } from '../../../../utils/constants';
import Loader from '../../../../common/loader';
import RouteDetailDialog from '../../../../common/route-detail-dialog';
import { VertActionMenu } from '../../../../common/action-menu';



const TripMetadataList = () => {

    const [tripMetadataList, setTripMetadataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [disableJson, setDisableJson] = useState({
        show: false,
        data: {}
    });
    const [selectedRouteRef, setSelectedRouteRef] = useState(null);
    const [selectedRouteType, setSelectedRouteType] = useState(null);
    const [isRouteDetailDialogOpen, setIsRouteDetailDialogOpen] = useState(false);

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        fetchTripMetadataList();
    }, []);

    // Fetch Trip metadata list from server
    const fetchTripMetadataList = () => {
        setIsLoading(true);
        getTripMetadataList().then(resp => {
            setIsLoading(false);
            setTripMetadataList(resp.data.data);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteTripMetadata(data.id).then(resp => {
            setIsLoading(false);
            fetchTripMetadataList();
        }).catch(err => {
            setIsLoading(false);          
        })
    }

    // Handle Disable modal   
    const handleDisable = (id, isDisabled) => {
        setDisableJson({
            ...disableJson,
            show: true,
            data: {
                id: id,
                isDisabled: isDisabled
            }
        });
        console.log(`disable - ${JSON.stringify(disableJson)}`);
    }

    // On Disable of route
    const onDisable = (data) => {
        console.log('on disable data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDisableJson({
            ...disableJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        disableEnableTripMetadata(data.id, data.isDisabled).then(resp => {
            setIsLoading(false);
            fetchTripMetadataList();
        }).catch(err => {
            console.log(`Error in disabling/enabling trip metadata id - ${data.id} - ${err}`);
            setIsLoading(false); 
        })
    }

    return (
        <>
             <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Trip metadata list
                    </Typography>
                    <Chip
                        onClick={() => navigate(`../new`)}
                        icon={<AddCircleOutlineIcon />}
                        label="New metadata"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                {isLoading &&
                    <Loader />
                }
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Route ref</TableCell>
                                        <TableCell>Route number</TableCell>
                                        <TableCell>Route types</TableCell>
                                        <TableCell>Frequencies</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tripMetadataList.map((item, index) => (
                                        <TableRow
                                            key={item.route_ref}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.route_ref}
                                                {!item.is_active &&
                                                    <Chip
                                                        sx={{ ml: 1 }}
                                                        label="Disabled"
                                                        size="small" color="error" variant="outlined" />
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row">{item.route_number}</TableCell>
                                            <TableCell component="th" scope="row">
                                                { item.route_types.map((type) => (
                                                    <Button size="small"
                                                        onClick={() => {
                                                            setSelectedRouteRef(item.route_ref);
                                                            setSelectedRouteType(type);
                                                            setIsRouteDetailDialogOpen(true);
                                                        }}
                                                    >
                                                        {type}
                                                    </Button>
                                                ))}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.frequencies.join(', ')}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onEdit={() => navigate(`${baseUrl}/trip/metadata/edit/${item.id}`)}
                                                    onDisable={() => handleDisable(item.id, true)}
                                                    onEnable={() => handleDisable(item.id, false)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            {/* For delete confirmation */}
            {deleteJson.show &&
                <CustomModal
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => { onDelete(data) }}
                    onHide={() => { setDeleteJson({ ...deleteJson, show: false }) }}
                />
            }
            {/* Disable modal */}
            {disableJson.show &&
                <CustomModal
                    show={disableJson.show}
                    data={disableJson.data}
                    type={ModalType.CONFIRM_DISABLE}
                    onOkay={(data) => { onDisable(data) }}
                    onHide={() => { setDisableJson({ ...disableJson, show: false }) }}
                ></CustomModal>
            }

            {/* Show route detail dialog */}
            <RouteDetailDialog
                open={isRouteDetailDialogOpen}
                onClose={() => setIsRouteDetailDialogOpen(false)}
                routeRef={selectedRouteRef}
                routeType={selectedRouteType}
            />
        </>
    );


    // return (
    //     <React.Fragment>
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <div className="h5 mb-0 text-gray-800">Metadata List</div>
    //             <Link type="button" class="btn btn-outline-info btn-sm ml-3" to="../new">Add new</Link>
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 {isLoading &&                   
    //                     <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
    //                 }

    //                 <table className="table">
    //                     <thead>
    //                         <tr className="row">
    //                             <th className="col-lg-2">Route Ref</th>
    //                             <th className="col-lg-3">Types</th>
    //                             <th className="col-lg-5">Frequencies</th>     
    //                             <th className="col-lg-2"></th>                         
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {tripMetadataList.map((item, index) => (
    //                             <tr key={index} className="row">
    //                                 <td className="col-lg-2">{item.route_ref}</td>
    //                                 <td className="col-lg-3">{item.route_types.join()}</td>
    //                                 <td className="col-lg-5">{item.frequencies.join()}</td>
    //                                 <td className="col-lg-2">
    //                                     <Link to={`${baseUrl}/trip/metadata/edit/${item.id}`}>
    //                                         <i className="fas fa-edit fa-lg text-primary mr-5"></i>
    //                                     </Link>
    //                                     <Button variant="link" onClick={() => handleDelete(item.id)}>
    //                                         <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                                     </Button>
    //                                 </td>
    //                             </tr>
    //                         ))}
    //                     </tbody>
    //                 </table>
    //             </div>
    //         </div>

    //         {/* For delete confirmation */}
    //         {deleteJson.show &&
    //             <CustomModal 
    //                 show={deleteJson.show}
    //                 data={deleteJson.data}
    //                 type={ModalType.CONFIRM_DELETE}
    //                 onOkay={(data) => {
    //                     onDelete(deleteJson.data);
    //                     setDeleteJson({
    //                         ...deleteJson,
    //                         show: false,
    //                     });
    //                 }}
    //                 onHide={() => {
    //                     setDeleteJson({
    //                         ...deleteJson,
    //                         show: false,
    //                     });
    //                 }}
    //             >
    //             </CustomModal>
    //         }
    //     </React.Fragment>
    // );
}

export default TripMetadataList;



// class TripMetadataList extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             tripMetadataList: [],
//             isLoading: false,
//             delete: {
//                 show: false,
//                 data: {}
//             },
// 		};
//     }

//     componentDidMount() {
//         this.fetchTripMetadataList();
//     }

//     // Fetch Trip metadata list from server
//     fetchTripMetadataList = () => {
//         this.setState({ isLoading: true });
//         getTripMetadataList().then(resp => {
//             this.setState({ isLoading: false });
//             this.setState({ tripMetadataList: resp.data.data });
//         }).catch(err => {
//             this.setState({ isLoading: false });
//         })
//     }

//     // Handle Delete modal   
//     handleDelete = (id) => {
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));
//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of School
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));
//         // Show spinner
//         this.setState({isLoading: true});
//         // deleteRoute(data.id).then(resp => {
//         //     this.setState({isLoading: false});
//         //     this.fetchRoutes();
//         // }).catch(err => {
//         //     this.setState({isLoading: false});          
//         // })
//     }

//     render() {

//         const { isLoading, tripMetadataList } = this.state;

//         return (
//             <React.Fragment>
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Metadata List</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         {isLoading &&                   
//                             <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
//                         }

//                         <table className="table">
//                             <thead>
//                                 <tr className="row">
//                                     <th className="col-lg-2">Route Ref</th>
//                                     <th className="col-lg-3">Types</th>
//                                     <th className="col-lg-5">Frequencies</th>     
//                                     <th className="col-lg-2"></th>                         
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {tripMetadataList.map((item, index) => (
//                                     <tr key={index} className="row">
//                                         <td className="col-lg-2">{item.route_ref}</td>
//                                         <td className="col-lg-3">{item.route_types.join()}</td>
//                                         <td className="col-lg-5">{item.frequencies.join()}</td>
//                                         <td className="col-lg-2">
//                                             <Link to={`${this.props.baseUrl}/trip/metadata/edit/${item.id}`}>
//                                                 <i className="fas fa-edit fa-lg text-primary mr-5"></i>
//                                             </Link>
//                                             <Button variant="link" onClick={() => this.handleDelete(item.id)}>
//                                                 <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                             </Button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>

//                 {/* For delete confirmation */}
//                 {this.state.delete.show &&
//                     <CustomModal 
//                         show={this.state.delete.show}
//                         data={this.state.delete.data}
//                         type={ModalType.CONFIRM_DELETE}
//                         onOkay={(data) => {
//                             this.onDelete(this.state.delete.data);
//                             this.setState(ps => ({
//                                 delete: {
//                                     ...ps.delete,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                         onHide={() => {
//                             this.setState(ps => ({
//                                 delete: {
//                                     ...ps.delete,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                     >
//                     </CustomModal>
//                 }
//             </React.Fragment>
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {}
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(TripMetadataList);