import React, { useState, useEffect, useRef } from 'react';

import {
    Button,
    Chip,
    Box,
    Paper,
    Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


import RouteStopDialog from '../../../../common/route-stop-dialog';
import RoutableMap from '../../../../common/googlemap/routable-map';
import { DEFAULT_MAP_LOCATION } from '../../../../utils/constants';
import RouteStopsTable from './route-stops-table';
import { deleteRouteStop, enableDisableRouteStop } from '../../../../services/http.service';
import { AlertSnackbar } from '../../../../common/snackbars';
import { ModalType } from '../../../../utils/constants';
import { CustomModal } from '../../../../common/modals';
import schoolIcon from '../../../../assets/images/school_icon.png';
import stopIcon from '../../../../assets/images/stop_icon.png';

const AddRouteStops = ({ route, onNext, onBack, onSubmit, onUpdateRoute }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [isRouteStopDialogOpen, setRouteStopDialogOpen] = useState(false);
    const [initialCenter, setInitialCenter] = useState({lat: DEFAULT_MAP_LOCATION.latitude, lng: DEFAULT_MAP_LOCATION.longitude});
    const [stopForEdit, setStopForEdit] = useState(null);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [disableJson, setDisableJson] = useState({
        show: false,
        data: {}
    });
    const [sortedRouteStops, setSortedRouteStops] = useState([]);
    const [stopLocationsForMap, setStopLocationsForMap] = useState([]);

    const routableMapRef = useRef(null);

    const school = useSelector(state => state.school);

    const openRouteStopDialog = () => {
        setStopForEdit(null); // reset else adding new stop will consider it as update
        setRouteStopDialogOpen(true);
    }

    const closeRouteStopDialog = () => {
        setRouteStopDialogOpen(false);
    }

    const onSaveRouteStop = (data) => {
        console.log("onSaveRouteStop ", data);
        // Set route it if it's new route
        const updatedRoute = {
            ...route,
            id: data.route_id
        }
        onSubmit(updatedRoute);
    }

    const onUpdateRouteStop = (data) => {
        console.log("onUpdateRouteStop ", data);
        // Set route it if it's new route
        const updatedRoute = {
            ...route,
            id: data.routeId
        }
        // TODO - instead of fetching complete route - only update the single stop in the route
        onSubmit(updatedRoute);
    }

    const onStopOrderChange = (stops) => {
        console.log("AddRouteStops ", JSON.stringify(stops));
        console.log("current route stops", JSON.stringify(route.stops));

        // Create a map for quick access to new orders by stop id
        const newOrdersMap = stops.reduce((map, item) => {
            map[item.id] = item.order;
            return map;
        }, {});

        // Update the order in the current route stops based on the new orders
        const updatedRouteStops = route.stops.map(stop => {
            if (newOrdersMap.hasOwnProperty(stop.id)) {
                return {
                    ...stop,
                    order: newOrdersMap[stop.id]
                };
            }
            return stop;
        });
        console.log("updatedRouteStops ", updatedRouteStops);

        const newRoute = {
            ...route,
            stops: updatedRouteStops
        }
        onUpdateRoute(newRoute);
    }

    // Handle delete of route stop
    const handleDelete = (routeStop) => {
        console.log("handleDelete ", routeStop);
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: routeStop.stop.id
            }
        });
    }
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false
        });
        deleteRouteStop(data.id).then(resp => {
            setSuccessMsg("Deleted successfully");
            setErrorMsg(null);
            onSubmit(route); // Trigger fetch route to refresh
        }).catch(err => {
            setErrorMsg(err);
            setSuccessMsg(null);
        });
    }


    // Handle disable of route stop
    const handleDisable = (routeStop, isDisabled) => {
        console.log("handleDisable ", routeStop);
        setDisableJson({
            ...disableJson,
            show: true,
            data: {
                id: routeStop.stop.id,
                isDisabled: isDisabled
            }
        });
    }
    const onDisable = (data) => {
        console.log('on disable data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDisableJson({
            ...disableJson,
            show: false,
        });
        enableDisableRouteStop(data.id, data.isDisabled).then(resp => {
            setSuccessMsg(`${data.isDisabled ? 'Disabled':'Enabled'} successfully`);
            setErrorMsg(null);
            onSubmit(route); // Trigger fetch route to refresh
        }).catch(err => {
            setErrorMsg(err);
            setSuccessMsg(null);
        })
    }


    // Handle edit of route stop
    const handleEdit = (routeStop) => {
        console.log("handleEdit ", routeStop);
        setStopForEdit(routeStop.stop);
        setRouteStopDialogOpen(true);
    }

    // Handle show location on map
    const handleViewLocationOnMap = (routeStop) => {
        if (routeStop) {
            console.log("handleViewLocationOnMap ", routeStop);
            if (routableMapRef.current) {
                routableMapRef.current.showInfoWindow(routeStop.stop.location);
            }
        }
    }

    useEffect(() => {
        if (route.stops && route.stops.length > 0) {
            const sortedStops = [...route.stops].sort((a, b) => a.order - b.order);
            console.log("sortedStops - ", sortedStops);
            setSortedRouteStops(sortedStops);
            // Extract stop locations for map
            const stopLocations = sortedStops.filter(stop => stop.isActive).map(routeStop => routeStop.stop.location);
            console.log("stop locations for map ", JSON.stringify(stopLocations));
            const schoolLocation = {
                latitude: school.latitude,
                longitude: school.longitude,
                location: school.address,
            }
            stopLocations.unshift(schoolLocation); // Add at the top for origin
            setStopLocationsForMap(stopLocations);
        }
    }, [route.stops]);

    return (
        <>
            {errorMsg || successMsg &&
                <AlertSnackbar 
                    errorMsg={errorMsg}
                    successMsg={successMsg}
                    onClose={() => {
                        setErrorMsg(null);
                        setSuccessMsg(null);
                    }}
                />
            }

            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => { onDelete(data) }}
                onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
            />
            {/* Disable modal */}
            <CustomModal
                show={disableJson.show}
                data={disableJson.data}
                type={ModalType.CONFIRM_DISABLE}
                onOkay={(data) => { onDisable(data) }}
                onHide={ () => {setDisableJson({...disableJson, show: false})} }
            />
            
             
             {/* Show route detail dialog */}
             <RouteStopDialog
                open={isRouteStopDialogOpen}
                onClose={closeRouteStopDialog}
                routeId={route.id}
                routeType={route.type}
                onSubmit={onSaveRouteStop}
                onUpdate={onUpdateRouteStop}
                stopForEdit={stopForEdit}
            />

            <Grid container spacing={2} sx={{ padding: 2, mt: 2 }} component={Paper}>
                <Grid item xs={12} md={6}>
                    <RouteStopsTable
                        routeId={route.id}
                        routeStops={sortedRouteStops}
                        handleStopOrderChange={onStopOrderChange}
                        onDelete={handleDelete}
                        onDisable={handleDisable}
                        onEdit={handleEdit}
                        onViewLocationOnMap={handleViewLocationOnMap}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ minHeight: '500px'}}>
                    {stopLocationsForMap.length > 0 &&
                        <RoutableMap
                            locations={stopLocationsForMap}
                            originIcon={schoolIcon}
                            otherIcon={stopIcon}
                            ref={routableMapRef}
                        />
                    }
                </Grid>
            </Grid>

            {/* Add new stop */}
            <Chip
                onClick={openRouteStopDialog}
                icon={<AddCircleIcon />}
                label="Add Stop"
                variant="outlined"
                color="secondary"
                sx={{ mt: 1 }}
            />

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ my: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={onBack}
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ mx: 2 }}
                        startIcon={<ArrowForwardIosIcon />}
                        onClick={onNext}
                    >
                        Next
                    </Button>
                </Box>
            </Box>
            
        </>
    );
}

export default AddRouteStops;