import React, { Component, useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid, Link as MuiLink, Chip, TextField,
    Button, Autocomplete, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell,
    TableBody, Paper, IconButton } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { saveOrUpdateClass, getSections, getClass } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { ModalType } from '../../../../utils/constants';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
});



const NewClass = () => {

    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sectionsLoading, setSectionsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    const [sections, setSections] = useState([]);
    const [sclass, setSclass] = useState({
        id: null,
        name: '',
        sections: []
    });
    const [selectedSection, setSelectedSection] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const { classId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchSections();
        if (classId) {
            setSclass({
                ...sclass,
                id: classId
            });
            fetchClass(classId);
        }   
    }, []);

    // Fetch class by id from server
    const fetchClass = (id) => {
        setIsLoading(true);
        getClass(id).then(resp => {
            const sclass = resp.data.data;
            setIsLoading(false);
            setSclass(sclass);
            formik.setValues({ ...sclass }); // get copy of school
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Fetch sections of schools for dropdown
    const fetchSections = () => {
        setSectionsLoading(true);
        getSections().then(resp => {
            setSections(resp.data.data);
            setSectionsLoading(false);
        }).catch(err => {
            console.log(err);
            setSectionsLoading(false);
        })
    }
    
    // Add section to class
    const addSectionToClass = () => {
        console.log('adding section')
        // const sclass = this.state.sclass;
        // let selectedSection = this.state.selectedSection;
        console.log(`selected section - ${selectedSection}`);
        if (!selectedSection) {
            console.log('section not selected');
            return;
        }

        // let selectedSectionJson = JSON.parse(selectedSection);

        // console.log(`after parse - ${JSON.stringify(selectedSectionJson)}`);

        // Check if section already added
        const arr = sclass.sections.filter(item => item.name === selectedSection.name);
        if (arr && arr.length > 0) {
            console.log(`Section already exists`);
            return;
        }

        //sclass.sections.push(selectedSectionJson);
        setSclass({
            ...sclass,
            sections: [...sclass.sections, selectedSection]
        })
        console.log(sclass);
        //setSclass(sclass);
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });

        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false
        });
        // const sclass = this.state.sclass;
        const sections = sclass.sections.filter(item => item.id !== data.id);
        // sclass.sections = sections;
        // this.setState({sclass});
        setSclass({
            ...sclass,
            sections: sections
        });
    }

    // Formik handle submit
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const sectionsIds = sclass.sections.map(item => item.id);
            const data = {
                id: sclass.id,
                name: values.name,
                sections: sectionsIds
            };

            setIsSaving(true);
            setShowSuccessAlert(false);
            saveOrUpdateClass(data).then(resp => {
                setSelectedSection('');
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
                // if for new then do reset
                if (!sclass.id) {
                    setSclass({
                        ...sclass,
                        id: null,
                        name: '',
                        sections: [],
                    });
                }

            }).catch(err => {
                console.log(err);
                //this.setState({isSaving: false, errorMsg: err, showSuccessAlert: false}); 
                setIsSaving(false);
                setShowSuccessAlert(false);
                setErrorMsg(err);
            })

        }
    });


    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                {errorMsg &&
                    <ErrorAlertTopCenter
                        errorMsg={errorMsg}
                        onClose={() => setErrorMsg(null)}
                    />
                }
                {showSuccessAlert &&
                    <SuccessAlertTopRight
                        successMsg={"School saved successfully!!"}
                        onClose={() => setShowSuccessAlert(false)}
                    />
                }
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        {sclass && sclass.id ? "Update class" : "Create new class"}
                    </Typography>
                    <Box>
                        {sclass && sclass.id &&
                            <Chip
                                onClick={() => navigate("../new")}
                                icon={<AddCircleOutlineIcon />}
                                label="New class"
                                variant="outlined"
                                color="secondary"
                                sx={{ mr: 3 }}
                            />
                        }
                        <Chip
                            onClick={() => navigate(`../list`)}
                            icon={<FormatListBulletedIcon />}
                            label="Classes"
                            variant="outlined"
                            color="secondary"
                        />
                    </Box>
                </Box>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    {isLoading &&
                        <Grid container>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                required
                                name="name"
                                label="Class name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    mt: 3
                                }}
                            >
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={sections}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Select section" />}
                                    onChange={(event, newValue) => {
                                        setSelectedSection(newValue);
                                    }}
                                />
                                {sectionsLoading &&
                                    <CircularProgress size="30" />
                                }
                                <Chip
                                    onClick={addSectionToClass}
                                    icon={<AddCircleOutlineIcon />}
                                    label="Add"
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ ml: 3 }}
                                />
                            </Box>

                            {sclass.sections && sclass.sections.length > 0 &&
                                <TableContainer component={Paper}
                                    sx={{ mt: 3 }}
                                >
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sclass.sections.map((item, index) => (
                                                <TableRow
                                                    key={item.name}
                                                >
                                                    <TableCell align="left">{index + 1}</TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {item.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IconButton aria-label="delete" color="error"
                                                            onClick={() => handleDelete(item.id)}
                                                        >
                                                            <DeleteIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSaving &&
                                    <CircularProgress />
                                }
                                {!isSaving &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Save
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            {deleteJson.show &&
                <CustomModal
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        onDelete(deleteJson.data);
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                    onHide={() => {
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                >
                </CustomModal>
            }

        </>

    );

 
    // return (
    //     <React.Fragment> 
    //             <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //                 <h1 className="h4 mb-0 text-gray-800">New Class</h1>
    //             </div>
    //             <div className="card shadow h-100 my-2">
    //                 <div className="card-body">
    //                     <Form
    //                         noValidate
    //                         validated={validated}
    //                         onSubmit={handleSubmit}>
                                                            
    //                         <Form.Group as={Row}>
    //                             <Form.Label column sm="2">Class Name</Form.Label>
    //                             <Col sm="4">
    //                                 <Form.Control type="text" required placeholder="Enter class name" 
    //                                     value={sclass.name}
    //                                     onChange={(e) => {
    //                                         // const sclass = this.state.sclass;                                                           
    //                                         // sclass.name = e.target.value;
    //                                         // this.setState({sclass});
    //                                         setSclass({
    //                                             ...sclass,
    //                                             name: e.target.value
    //                                         });
    //                                     }}
    //                                 />
    //                             </Col>
    //                         </Form.Group>  

    //                         <Form.Group as={Row}>
    //                             <Form.Label column sm="2">Add Section
    //                                 {sectionsLoading &&
    //                                     <Spinner className="ml-2" animation="border" size="sm" variant="primary" /> 
    //                                 }
    //                             </Form.Label>                                    
    //                             <Col sm="4">
    //                                 <Form.Control as="select" lg="5" 
    //                                     onChange={(e) => { 
    //                                         console.log(e.target.value)
    //                                         setSelectedSection(e.target.value);
    //                                     }}>
    //                                     <option value="">Select</option>
    //                                     {sections.map((item, index) => (
    //                                         <option key={index} value={JSON.stringify(item)}>{item.name}</option>
    //                                     ))}
    //                                 </Form.Control>
    //                             </Col>
    //                             <Col sm="4">
    //                                 <Button lg="2" onClick={addSectionToClass}>Add</Button>
    //                             </Col>                                    
    //                         </Form.Group> 
                        
    //                         <table className="table" ble striped bordered hover>
    //                             <thead>
    //                                 <tr className="row">
    //                                     <th className="col-md-1">#</th>
    //                                     <th className="col-md-10">Section Name</th>
    //                                     <th className="col-md-1"></th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {sclass.sections.map((item, index) => (
    //                                     <tr key={index} className="row">
    //                                         <td className="col-md-1">{index + 1}</td>
    //                                         <td className="col-md-10">{item.name}</td>
    //                                         <td className="col-md-1">
    //                                             <Button variant="link" onClick={() => handleDelete(item.id)}>
    //                                                 <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                                             </Button>
    //                                         </td>
    //                                     </tr>
    //                                 ))}
    //                             </tbody>
    //                         </table>
    //                         <div className="d-flex align-items-center justify-content-left">
    //                             <Button className="btn-general" type="submit">Submit</Button>
    //                             {isSaving &&
    //                                 <Spinner animation="border" variant="primary" />
    //                             }
    //                             {showSuccessAlert &&
    //                                 <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false)}
    //                                     dismissible>
    //                                     Saved Successfully
    //                                         </Alert>
    //                             }
    //                             {errorMsg &&
    //                                 <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null)}
    //                                     dismissible>
    //                                     {errorMsg}
    //                                 </Alert>
    //                             }
    //                         </div> 
    //                     </Form>
    //                 </div>
    //             </div>
    //             {deleteJson.show &&
    //             <CustomModal 
    //                 show={deleteJson.show}
    //                 data={deleteJson.data}
    //                 type={ModalType.CONFIRM_DELETE}
    //                 onOkay={(data) => {
    //                     onDelete(deleteJson.data);
    //                     setDeleteJson({
    //                         ...deleteJson,
    //                         show: false,
    //                     });
    //                 }}
    //                 onHide={() => {
    //                     setDeleteJson({
    //                         ...deleteJson,
    //                         show: false,
    //                     });
    //                 }}
    //             >
    //             </CustomModal>
    //         }
    //     </React.Fragment> 
    // );
}

export default NewClass;


// class NewClass extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             validated: false,
//             isLoading: false,
//             sectionsLoading: false,
//             delete: {
//                 show: false,
//                 data: {}
//             },
//             sections: [],
//             sclass: {
//                 id: null,
//                 name: '',
//                 sections: []
//             },
//             selectedSection: '',

//             isSaving: false,
//             errorMsg: null,
//             showSuccessAlert: false
// 		};
//     }

//     componentDidMount() {
//         this.fetchSections();
//         const id = this.props.match.params.id;
//         if (id) {
//             // const sclass = this.state.sclass;
//             // sclass.id = id;
//             // this.setState({sclass});

//             this.setState(() => ({
//                 ...this.state.sclass,
//                 id: id
//             }));

//             this.fetchClass(id);
//         }        
//     }

//     // Fetch class by id from server
//     fetchClass = (id) => {
//         this.setState({isLoading: true});
//         getClass(id).then(resp => {
//             this.setState({isLoading: false});
//             this.setState({sclass: resp.data.data});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isLoading: false});
//         })
//     }

//     // Fetch sections of schools for dropdown
//     fetchSections = () => {
//         this.setState({sectionsLoading: true});
//         getSections().then(resp => {
//             this.setState({sections: resp.data.data});
//             this.setState({sectionsLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({sectionsLoading: false});
//         })
//     }
    
//     // Add section to class
//     addSectionToClass = () => {
//         console.log('adding section')
//         const sclass = this.state.sclass;
//         let selectedSection = this.state.selectedSection;
//         console.log(`selected section - ${selectedSection}`);
//         if (!selectedSection || selectedSection.trim().length === 0) {
//             console.log('section not selected');
//             return;
//         }

//         selectedSection = JSON.parse(selectedSection);

//         console.log(`after parse - ${JSON.stringify(selectedSection)}`);

//         // Check if section already added
//         const arr = sclass.sections.filter(item => item.name === selectedSection.name);
//         if (arr && arr.length > 0) {
//             console.log(`Section already exists`);
//             return;
//         }

//         sclass.sections.push(selectedSection);
//         console.log(sclass);
//         this.setState({sclass});
//     }

//     // Handle Delete modal   
//     handleDelete = (id) => {
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));

//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of School
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));
//         const sclass = this.state.sclass;
//         const sections = sclass.sections.filter(item => item.id !== data.id);
//         sclass.sections = sections;
//         this.setState({sclass});
//     }


//     // Handle Submit
//     handleSubmit(event) {
//         event.preventDefault();
//         event.stopPropagation();
//         this.setState({ validated: true });
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             return false;
//         }      

//         // Prepare body of request - Extract ids from class sections
//         const sclass = this.state.sclass;
//         const sectionsIds = this.state.sclass.sections.map(item => item.id);
//         const data = {
//             id: sclass.id,
//             name: form[0].value,
//             sections: sectionsIds
//         };

//         this.setState({isSaving: true});  
//         this.setState({showSuccessAlert: false});
//         saveOrUpdateClass(data).then(resp => {
//             this.setState(ps => ({
//                 selectedSection: '',
//                 isSaving: false,
//                 showSuccessAlert: true,
//                 errorMsg: null,
//                 validated: false,
//             }));
//             // if for new then do reset
//             if (!sclass.id) {
//                 this.setState(ps => ({
//                     sclass: {
//                         ...ps.sclass,
//                         id: null,
//                         name: '',  
//                         sections: [],                  
//                     },
//                 }));
//             }

//         }).catch(err => {
//             console.log(err);
//             this.setState({isSaving: false, errorMsg: err, showSuccessAlert: false}); 
//         })
//     }

//     render() {
//         const { validated, isSaving, errorMsg, showSuccessAlert, sectionsLoading, sections, sclass } = this.state;
// 		return (
//             <React.Fragment> 
//                     <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                         <h1 className="h4 mb-0 text-gray-800">New Class</h1>
//                     </div>
//                     <div className="card shadow h-100 my-2">
//                         <div className="card-body">
//                             <Form
//                                 noValidate
//                                 validated={validated}
//                                 onSubmit={e => this.handleSubmit(e)}>
                                                               
//                                 <Form.Group as={Row}>
//                                     <Form.Label column sm="2">Class Name</Form.Label>
//                                     <Col sm="4">
//                                         <Form.Control type="text" required placeholder="Enter class name" 
//                                             value={this.state.sclass.name}
//                                             onChange={(e) => {
//                                                 const sclass = this.state.sclass;                                                           
//                                                 sclass.name = e.target.value;
//                                                 this.setState({sclass});
//                                             }}
//                                         />
//                                     </Col>
//                                 </Form.Group>  

//                                 <Form.Group as={Row}>
//                                     <Form.Label column sm="2">Add Section
//                                         {sectionsLoading &&
//                                             <Spinner className="ml-2" animation="border" size="sm" variant="primary" /> 
//                                         }
//                                     </Form.Label>                                    
//                                     <Col sm="4">
//                                         <Form.Control as="select" lg="5" 
//                                             onChange={(e) => { 
//                                                 console.log(e.target.value)
//                                                 this.setState({selectedSection: e.target.value});
//                                             }}>
//                                             <option value="">Select</option>
//                                             {sections.map((item, index) => (
//                                                 <option key={index} value={JSON.stringify(item)}>{item.name}</option>
//                                             ))}
//                                         </Form.Control>
//                                     </Col>
//                                     <Col sm="4">
//                                         <Button lg="2" onClick={() => {this.addSectionToClass()}}>Add</Button>
//                                     </Col>                                    
//                                 </Form.Group> 
                            
//                                 <table className="table" ble striped bordered hover>
//                                     <thead>
//                                         <tr className="row">
//                                             <th className="col-md-1">#</th>
//                                             <th className="col-md-10">Section Name</th>
//                                             <th className="col-md-1"></th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {sclass.sections.map((item, index) => (
//                                             <tr key={index} className="row">
//                                                 <td className="col-md-1">{index + 1}</td>
//                                                 <td className="col-md-10">{item.name}</td>
//                                                 <td className="col-md-1">
//                                                     <Button variant="link" onClick={() => this.handleDelete(item.id)}>
//                                                         <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                                     </Button>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                                 <div className="d-flex align-items-center justify-content-left">
//                                     <Button className="btn-general" type="submit">Submit</Button>
//                                     {isSaving &&
//                                         <Spinner animation="border" variant="primary" />
//                                     }
//                                     {showSuccessAlert &&
//                                         <Alert className="ml-3 mb-0" variant="success" onClose={() => this.setState({ showSuccessAlert: false })}
//                                             dismissible>
//                                             Saved Successfully
//                                                 </Alert>
//                                     }
//                                     {errorMsg &&
//                                         <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({ errorMsg: null })}
//                                             dismissible>
//                                             {this.state.errorMsg}
//                                         </Alert>
//                                     }
//                                 </div> 
//                             </Form>
//                         </div>
//                     </div>
//                     {this.state.delete.show &&
//                     <CustomModal 
//                         show={this.state.delete.show}
//                         data={this.state.delete.data}
//                         type={ModalType.CONFIRM_DELETE}
//                         onOkay={(data) => {
//                             this.onDelete(this.state.delete.data);
//                             this.setState(ps => ({
//                                 delete: {
//                                     ...ps.delete,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                         onHide={() => {
//                             this.setState(ps => ({
//                                 delete: {
//                                     ...ps.delete,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                     >
//                     </CustomModal>
//                 }
//             </React.Fragment> 
//         )
//     }
// }

// export default NewClass;