import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Row, Form, Col, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { fetchSchoolUsers as fetchSchoolUsersHttp, 
    deleteUser } from '../../../../services/http.service';
import SearchBox from '../../../../common/search-box';
import { Role } from '../../../../utils/constants';

import CustomPagination from '../../../../utils/pagination';

const UserStudentList = () => {

    const [schoolUsers, setSchoolUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    let [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    let studentSearchKey = null;

    useEffect(() => {
        fetchSchoolUsers();
    }, []);

     // Fetch school users
     const fetchSchoolUsers = () => {
        setSchoolUsers([]);        
        setIsLoading(true);
        const filter = {
            'searchKey': studentSearchKey
        }
        fetchSchoolUsersHttp(Role.STUDENT, filter, page, limit).then(resp => {
            const respData = resp.data.data;
            setSchoolUsers(respData.school_users);
            setTotalCount(respData.total_count);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        });
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id,
                roleName: Role.STUDENT
            }
        });

        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteUser(data.roleName, data.id).then(resp => {
            setIsLoading(false);
            fetchSchoolUsers();
        }).catch(err => {
            setIsLoading(false);            
        })
    }

    // on search stduents
    const onStudentSearch = (val) => {
        console.log(`on student search ${val}`);
        studentSearchKey = val;
        page = 0;
        setPage(0);
        fetchSchoolUsers();
    }

    // ****************************** Pagination ******************************
    const onFirst = () => {
        console.log(`onFirst`);
        page = 0;
        setPage(page);
        fetchSchoolUsers();  
    }

    const onPrevious = () => {
        console.log(`onPrevious`);
        if (page > 0) {
            page -= 1;
            setPage(page);
            fetchSchoolUsers();
        }
    }

    const onNext = () => {
        console.log(`onNext`);
        console.log(`cal count - ${(page * limit)}`)
        // check if records on next page is less than the total record count i.e (page + 1)
        if (((page + 1) * limit) < totalCount) {
            page += 1;
            setPage(page);
            fetchSchoolUsers();
        }
    }

    const onLast = () => {
        console.log(`onLast`);
        // Calculate last page number
        // const { page, totalCount, limit } = this.state;
        const newPage = Math.floor(totalCount/limit);
        console.log(`new page - ${newPage}`);  
        page = newPage;
        setPage(page);   
        fetchSchoolUsers();  
    }

    // ************************** End Pagination ******************************

    return (
        <React.Fragment>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div className="h5 mb-0 text-gray-800">Students</div>
                <SearchBox onChangeText={onStudentSearch} />
                <Link type="button" class="btn btn-outline-info btn-sm ml-3" to="../new">Add new</Link>
            </div> 
            <div>
                {isLoading &&                   
                    <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
                }
                {schoolUsers.length === 0 &&                        
                    <h4 className="text-muted text-center mb-3">Oops..No students found!!</h4>                           
                }
                {schoolUsers.length > 0 &&
                    <div>
                        <table className="table" ble striped bordered hover>
                            <thead className="container">
                                <tr className="row">
                                    <th className="col-lg-11">Name</th>
                                    <th className="col-lg-1"></th>
                                </tr>
                            </thead>
                            <tbody className="container">
                                {schoolUsers.map((item, index) => (
                                    <tr key={index} className="row">
                                        <td className="col-lg-11">{item.user.name}</td>
                                        <td className="col-lg-1 d-flex justify-content-between">
                                            <Link to={"../edit/" + item.user.id}>
                                                <i className="fas fa-edit fa-lg text-primary mr-2"></i>
                                            </Link>
                                            <a href="#" onClick={() => handleDelete(item.user.id)}>
                                                <i className="fas fa-trash-alt fa-lg text-danger"></i>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* Custom Pagination rendering */}
                        <div className="mt-3">
                            <CustomPagination 
                                currentPage={page+1}
                                totalCount={totalCount}
                                limit={limit}
                                onFirst={onFirst}
                                onPrevious={onPrevious}
                                onNext={onNext}
                                onLast={onLast}
                            />
                        </div>
                    </div>
                } 
            </div>
        </React.Fragment>
    );
}
export default UserStudentList;