import React, { useState, useEffect, useRef, useCallback } from 'react';
import GoogleMapCustom from './google-map-custom'; // Import your GoogleMapCustom component

const SingleMarkerMap = ({ 
    center, 
    zoom,
    isDraggable = false,
    onMapReady,
    onMarkerDragged,
}) => {
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [marker, setMarker] = useState(null);
    //const markerRef = useRef(null);

    const handleMapReady = (map, maps) => {
        setMap(map);
        setMaps(maps);
        onMapReady(map, maps);
    }

    useEffect(() => {
        // Remove old markers if any
        if (marker) {
            marker.setMap(null);
        }
        if (center && map) {
            // Add new marker
            const newMarker = new window.google.maps.Marker({
                position: center,
                map,
                draggable: isDraggable,
                title: 'Selected Location',
            });
            setMarker(newMarker);
        }
    }, [center, map, maps]);

    useEffect(() => {
        // Handle dragend event
        if (maps) {
            maps.event.addListener(marker, 'dragend', (event) => {
                console.log("SingleMarkerMap ", event.latLng);
                if (onMarkerDragged) {
                    onMarkerDragged(event.latLng);
                }
            });
        }
    }, [marker]);


    return (
        <GoogleMapCustom
            center={center}
            zoom={zoom}
            onMapReady={handleMapReady}
        />
    );
};

export default SingleMarkerMap;
