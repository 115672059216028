import axios from 'axios';
import { getAccessToken, getRefreshToken, setAccessToken } from './auth.service';
import { removeAuth } from './auth.service';

import { URL, SCHOOL, OTPVerificationType, Role, GOOGLE_MAP_API_KEY } from '../utils/constants';

// Save School
export async function saveSchool(school) {
	console.log("API :: Saving School :: " + JSON.stringify(school));
	const url = `${URL.BASE_URL}/schools`;
	return await axios.post(url, school);
}

// Save Or Update School
export async function saveOrUpdateSchool(school) {
	console.log("API :: Saving School :: " + JSON.stringify(school));
	if (school.id && school.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/schools/${school.id}`, school);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/schools`, school);
	}
}

// Delete School
export async function deleteSchool(schoolId) {
	console.log("API :: Deleting School :: " + schoolId);
	const url = `${URL.BASE_URL}/schools/${schoolId}`;
	return await axios.delete(url);
}

// Register
export async function register(user) {
	console.log("API :: Register :: " + JSON.stringify(user));
	const url = `${URL.BASE_URL}/register`;
	return await axios.post(url, user);
}

// Forgot Password
export async function forgotPassword(mobile) {
	console.log("API :: ForgotPassword :: " + JSON.stringify(mobile));
	const body = {
		mobile: mobile
	}
	const url = `${URL.BASE_URL}/forgot-password`;
	return await axios.post(url, body);
}

// Login
export async function login(mobile, password) {
	const url = `${URL.BASE_URL}/login`;
	const body = {
		mobile: mobile,
		password: password
	}
	return await axios.post(url, body);
}

// Admin Login
export async function adminLogin(data) {
	const url = `${URL.BASE_URL}/admin-login`;
	const body = {
		school_id: data.schoolId,
		mobile: data.mobile,
		password: data.password
	}
	return await axios.post(url, body);
}

// Logout
export async function logout() {
	const url = `${URL.BASE_URL}/logout`;
	return await axios.post(url);
}

// OTP verification
export async function verifyOTP(userId, otp, type) {
	const body = {
		user_id: userId,
		otp: otp
	}
	let url = `${URL.BASE_URL}/verify-otp`;
	if (type === OTPVerificationType.FORGOT_PASSWORD) {
		url = `${URL.BASE_URL}/forgot-password/verify-otp`;
	}
	return await axios.post(url, body);
}

// Reset OTP
export async function resendOTP(userId, type) {
	const body = {
		user_id: userId
	}
	let url = `${URL.BASE_URL}/resend-otp`;
	if (type === OTPVerificationType.FORGOT_PASSWORD) {
		url = `${URL.BASE_URL}/forgot-password/resend-otp`;
	}
	return await axios.post(url, body);
}

// Reset Password
export async function resetPassword(userId, data) {	
	const body = {
		user_id: userId,
		password: data.password,
	}
	let url = `${URL.BASE_URL}/forgot-password/reset`;	
	return await axios.post(url, body);
}

// Get all subscription
export async function getSubscriptions() {
	const url = `${URL.BASE_URL}/subscriptions`;
	return await axios.get(url);
}

// Get OTP expiry timing
export async function getOtpExpiryTime() {
	const url = `${URL.BASE_URL}/otp-expiry-time`;
	return await axios.get(url);
}

// Choose Subscription
export async function chooseSubscription(data) {
	const body = {
		school_id: data.schoolId,
		subscription_id: data.subscriptionId
	}
	const url = `${URL.BASE_URL}/subscriptions/choose`;
	return await axios.post(url, body);
}

// School list
export async function schools() {
	const url = `${URL.BASE_URL}/schools`;
	return await axios.get(url);
}

// Get School
export async function fetchSchool(id) {
	const url = `${URL.BASE_URL}/schools/${id}`;
	return await axios.get(url);
}

// Get School by unique number
export async function getSchoolByUniqueNumber(uniqueNumber) {
	const url = `${URL.BASE_URL}/schools/unique-number/${uniqueNumber}`;
	return await axios.get(url);
}

// Fetch roles except - SCHOOL_OWNER role
export async function fetchRoles() {
	const url = `${URL.BASE_URL}/roles`;
	return await axios.get(url);
}

// Fetch User by Id
export async function getUser(userId) {
	const url = `${URL.BASE_URL}/users/${userId}`;
	return await axios.get(url);
}

// Get Logged in user profile
export async function getUserProfile() {
	const url = `${URL.BASE_URL}/users/profile`;
	return await axios.get(url);
}

// Edit user profile
export async function editUserProfile(data) {
	const body = {
		name: data.name,
		email: data.email,
	}
	const url = `${URL.BASE_URL}/users/profile`;
	return await axios.put(url, body);
}

// Change password Otp
export async function changePasswordOtp() {
	const url = `${URL.BASE_URL}/change-password/get-otp`;
	return await axios.post(url);
}

// School user get OTP
export async function schoolUserGetOtp(mobile) {
	const body = {
		mobile: mobile
	}
	const url = `${URL.BASE_URL}/school-users/get-otp`;
	return await axios.post(url, body);
}

// School user verify OTP
export async function schoolUserVerifyOtp(data) {
	const body = {
		mobile: data.mobile,
		otp: data.otp,
	}
	const url = `${URL.BASE_URL}/school-users/verify-otp`;
	return await axios.post(url, body);
}


// Change password
export async function changePassword(data) {
	const body = {
		old_password: data.oldPassword,
		new_password: data.password,
		otp: data.otp,
	}
	const url = `${URL.BASE_URL}/change-password`;
	return await axios.put(url, body);
}

// Save School User
export async function saveOrUpdateSchoolUser(schoolUser, isUpdate) {
	const body = {
		role_name: schoolUser.roleName,
		school_id: schoolUser.schoolId,
		id: schoolUser.id,
		name: schoolUser.name,
		email: schoolUser.email,
		mobile: schoolUser.mobile,
		password: schoolUser.password
	}
	console.log(`school users - ${JSON.stringify(body)}`)
	
	if (isUpdate) {
		const url = `${URL.BASE_URL}/school-users/${body.role_name}/${body.id}`
		return await axios.put(url, body);
	}
	else {
		const url = `${URL.BASE_URL}/school-users`;
		return await axios.post(url, body);
	}
	
}

// Fetch school users by role
export async function fetchSchoolUsers(roleName, filter, page, limit) {
	const body = {
		search_key: filter.searchKey,
	}
	const url = `${URL.BASE_URL}/school-users/${roleName}/page/${page}/limit/${limit}`;
	return await axios.post(url, body);
}

// Delete User from school
export async function deleteUser(roleName, userId) {
	const url = `${URL.BASE_URL}/school-users/${roleName}/${userId}`;
	return await axios.delete(url);
}

// Fetch school user by userid
export async function fetchSchoolUser(roleName, userId) {
	const url = `${URL.BASE_URL}/school-users/${roleName}/${userId}`;
	return await axios.get(url);
}

// Save or update section
export async function saveOrUpdateSection(section) {
	if (section.id && section.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/sections/${section.id}`, section);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/sections`, section);
	}
}

// Get all sections of school
export async function getSections() {
	const url = `${URL.BASE_URL}/sections`;
	return await axios.get(url);
}

// Get a section of school
export async function getSection(id) {
	const url = `${URL.BASE_URL}/sections/${id}`;
	return await axios.get(url);
}

// Delete Section
export async function deleteSection(sectionId) {
	const url = `${URL.BASE_URL}/sections/${sectionId}`;
	return await axios.delete(url);
}

// Get all sections of school
export async function getClasses() {
	const url = `${URL.BASE_URL}/classes`;
	return await axios.get(url);
}

// Save or update class
export async function saveOrUpdateClass(sclass) {
	console.log(`saving class - ${JSON.stringify(sclass)}`);
	if (sclass.id && sclass.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/classes/${sclass.id}`, sclass);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/classes`, sclass);
	}
}

// Delete Class
export async function deleteClass(classId) {
	const url = `${URL.BASE_URL}/classes/${classId}`;
	return await axios.delete(url);
}

// Get a class of school
export async function getClass(id) {
	const url = `${URL.BASE_URL}/classes/${id}`;
	return await axios.get(url);
}


// Save or Update student basic detail
export async function saveOrUpdateStudentBasic(student) {
	
	const body = {
		id: student.id,
		name: student.name,
		roll_no: student.rollNo,
		sclass: {
			id: student.sclass.id
		}
	}
	if (student.section) {
		body.section = {
			id: student.section.id
		}
	}

	console.log(`Saving Student basic detail - ${JSON.stringify(body)}`);
	
	if (body.id && body.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/students/${body.id}/basic`, body);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/students/basic`, body);
	}	
}

// Save or Update student address detail
export async function saveOrUpdateStudentAddress(student) {	
	const body = {
		id: student.id,
		user: {
			id: student.user.id
		},
		address: {
			id: student.address.id,
			address: student.address.address,
			city: student.address.city,
			state: student.address.state,
			country: student.address.country,
			postal_code: student.address.postalCode
		}
	}
	
	if (body.address.id && body.address.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/students/${body.id}/address`, body);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/students/address`, body);
	}	
}

// Save or Update student pickup address detail
export async function saveOrUpdateStudentPickup(student) {	
	const body = {
		id: student.id,
		pickup: {
			id: student.pickup.id,
			latitude: student.pickup.latitude,
			longitude: student.pickup.longitude,
			location: student.pickup.location
		}
	}
	
	if (body.pickup.id && body.pickup.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/students/${body.id}/pickup`, body);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/students/pickup`, body);
	}	
}

// Save or Update student dropoff detail
export async function saveOrUpdateStudentDropoff(student) {	
	const body = {
		id: student.id,
		dropoff: {
			id: student.dropoff.id,
			latitude: student.dropoff.latitude,
			longitude: student.dropoff.longitude,
			location: student.dropoff.location
		},
		is_dropoff_same_as_pickup: student.isDropoffSameAsPickup === null ? false : student.isDropoffSameAsPickup,
	}
	
	if (body.dropoff.id && body.dropoff.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/students/${body.id}/dropoff`, body);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/students/dropoff`, body);
	}	
}

// Get a student by user id
export async function getStudentByUserId(userId) {
	const url = `${URL.BASE_URL}/students/user/${userId}`;
	return await axios.get(url);
}

// Get a driver by user id
export async function getDriverByUserId(userId) {
	const url = `${URL.BASE_URL}/drivers/user/${userId}`;
	return await axios.get(url);
}


// Get all students of school
export async function getStudents(filter, page, limit) {
	const body = {
		search_key: filter.searchKey,
	}
	const url = `${URL.BASE_URL}/students/page/${page}/limit/${limit}`;
	return await axios.post(url, body);
}

// Get a student of school
export async function getStudent(studentId) {
	const url = `${URL.BASE_URL}/students/${studentId}`;
	return await axios.get(url);
}

// Delete a student
export async function deleteStudent(studentId) {
	const url = `${URL.BASE_URL}/students/${studentId}`;
	return await axios.delete(url);
}

// Search students by name
export async function searchStudent(value) {
	const url = `${URL.BASE_URL}/students/search/${value}`;
	return await axios.get(url);
}

// Save or Update driver
export async function saveOrUpdateDriver(driver) {	
	const body = {
		id: driver.id,
		name: driver.name,
		mobile: driver.mobile,
		email: driver.email,
		password: driver.password,
		license_number: driver.licenseNumber
	}
	
	if (body.id && body.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/drivers/${body.id}`, body);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/drivers`, body);
	}	
}


// Save or Update admin
export async function saveOrUpdateAdmin(admin) {	
	const body = {
		id: admin.id,
		name: admin.name,
		mobile: admin.mobile,
		email: admin.email,
		password: admin.password
	}
	
	if (body.id && body.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/admins/${body.id}`, body);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/admins`, body);
	}	
}

// Upload school driver profile photo
export async function uploadDriverProfilePhoto(driverId, file) {
	console.log(`Uploading driver profile photo --- file - ${file}`);
	const data = new FormData()
   	data.append('file', file, 'profile.jpg');
	return await axios.put(`${URL.BASE_URL}/drivers/${driverId}/profile-photo`, data);
}

// Upload school admin profile photo
export async function uploadAdminProfilePhoto(adminId, file) {
	console.log(`Uploading admin profile photo --- file - ${file}`);
	const data = new FormData()
   	data.append('file', file, 'profile.jpg');
	return await axios.put(`${URL.BASE_URL}/admins/${adminId}/profile-photo`, data);
}

// Get all drivers of school
export async function getDrivers() {
	const url = `${URL.BASE_URL}/drivers`;
	return await axios.get(url);
}

// Get all admins of school
export async function getAdmins() {
	const url = `${URL.BASE_URL}/admins`;
	return await axios.get(url);
}

// Delete a driver
export async function deleteDriver(driverId) {
	const url = `${URL.BASE_URL}/drivers/${driverId}`;
	return await axios.delete(url);
}

// Delete a admin
export async function deleteAdmin(adminId) {
	const url = `${URL.BASE_URL}/admins/${adminId}`;
	return await axios.delete(url);
}

// Get a driver of school
export async function getDriver(driverId) {
	const url = `${URL.BASE_URL}/drivers/${driverId}`;
	return await axios.get(url);
}

// Get a admin of school
export async function getAdmin(adminId) {
	const url = `${URL.BASE_URL}/admins/${adminId}`;
	return await axios.get(url);
}



// Save or Update bus
export async function saveOrUpdateBus(bus) {	
	const body = {
		id: bus.id,
		brand: bus.brand,
		manufacturer: bus.manufacturer,
		model: bus.model,
		number: bus.number
	}
	
	if (body.id && body.id > 0) { // Update
		return await axios.put(`${URL.BASE_URL}/buses/${body.id}`, body);
	}
	else {
		return await axios.post(`${URL.BASE_URL}/buses`, body);
	}	
}

// Get all buses of school
export async function getBuses() {
	const url = `${URL.BASE_URL}/buses`;
	return await axios.get(url);
}

// Delete a bus
export async function deleteBus(busId) {
	const url = `${URL.BASE_URL}/buses/${busId}`;
	return await axios.delete(url);
}

// Get a bus of school
export async function getBus(busId) {
	const url = `${URL.BASE_URL}/buses/${busId}`;
	return await axios.get(url);
}

// Get Route types
export async function getRouteTypes(busId) {
	const url = `${URL.BASE_URL}/route-types`;
	return await axios.get(url);
}

// Save route students
export async function saveOrUpdateRouteStudents(route) {
	const body = {
		type: route.type,
		students: route.students,
		estimated_distance: route.estimatedDistance,
		estimated_duration: route.estimatedDuration
	}
	if (route.id) {
		const url = `${URL.BASE_URL}/routes/${route.id}/students`;
		return await axios.put(url, body);
	} else {
		const url = `${URL.BASE_URL}/routes/students`;
		return await axios.post(url, body);
	}
}

// Get avilable buses
export async function getAvailableBuses(routeRefNo) {
	const url = `${URL.BASE_URL}/buses/available?route_ref_no=${routeRefNo}`;
	return await axios.get(url);
}

// Save route bus
export async function saveOrUpdateRouteBus(route) {
	if (route.id) {
		const url = `${URL.BASE_URL}/routes/${route.id}/bus`;
		return await axios.put(url, route);
	} else {
		const url = `${URL.BASE_URL}/routes/bus`;
		return await axios.post(url, route);
	}
}

// Delete route bus
export async function deleteRouteBus(routeId) {
	const url = `${URL.BASE_URL}/routes/${routeId}/bus`;
	return await axios.delete(url);
}

// Get avilable drivers
export async function getAvailableDrivers(routeRefNo) {
	const url = `${URL.BASE_URL}/drivers/available?route_ref_no=${routeRefNo}`;
	return await axios.get(url);
}

// Save route driver
export async function saveOrUpdateRouteDriver(route) {
	if (route.id) {
		const url = `${URL.BASE_URL}/routes/${route.id}/driver`;
		return await axios.put(url, route);
	} else {
		const url = `${URL.BASE_URL}/routes/driver`;
		return await axios.post(url, route);
	}
}

// Save route number
export async function saveOrUpdateRouteNumber(route) {
	if (route.id) {
		const url = `${URL.BASE_URL}/routes/${route.id}/number`;
		return await axios.put(url, route);
	} else {
		const url = `${URL.BASE_URL}/routes/number`;
		return await axios.post(url, route);
	}
}

// Delete route number
export async function deleteRouteNumber(routeId) {
	const url = `${URL.BASE_URL}/routes/${routeId}/number`;
	return await axios.delete(url);
}


// Delete route driver
export async function deleteRouteDriver(routeId) {
	const url = `${URL.BASE_URL}/routes/${routeId}/driver`;
	return await axios.delete(url);
}

// Final submission 
export async function finalSubmitRoute(route) {
	const students = route.students.map(student => {
		return {
			id: student.id,
			order: student.order
		}
	});
	const body = {
		id: route.id,
		type: route.type,
		students: students,
		bus_id: route.bus.id,
		driver_id: route.driver.id
	}

	if (route.id) {
		const url = `${URL.BASE_URL}/routes/${route.id}/final-submit`;
		return await axios.put(url, body);
	} else {
		const url = `${URL.BASE_URL}/routes/final-submit`;
		return await axios.post(url, body);
	}
}

// Create Dropoff route
export async function createDropOffRoute(pickupRouteId) {
	const body = {
		pickup_route_id: pickupRouteId
	}
	const url = `${URL.BASE_URL}/routes/dropoff`;
	return await axios.post(url, body);
}

// Get avilable drivers
export async function getRoutes() {
	const url = `${URL.BASE_URL}/routes`;
	return await axios.get(url);
}

// Delete a route
export async function deleteRoute(routeId) {
	const url = `${URL.BASE_URL}/routes/${routeId}`;
	return await axios.delete(url);
}

// Disable/Enable a route
export async function disableEnableRoute(routeId, isDisabled) {
	let url = `${URL.BASE_URL}/routes/${routeId}`;
	if (isDisabled) {
		url = `${url}/disable`;
	} else {
		url = `${url}/enable`;
	}
	return await axios.post(url);
}



// Get route
export async function getRoute(routeId) {
	const url = `${URL.BASE_URL}/routes/${routeId}`;
	return await axios.get(url);
}

// Get pickup/dropoff route by refno
export async function getRouteByRefAndType(refNo, routeType) {
	const url = `${URL.BASE_URL}/routes/ref/${refNo}/type/${routeType}`;
	return await axios.get(url);
}

// Get route
export async function getRoutesWithDistinctRef(routeId) {
	const url = `${URL.BASE_URL}/routes/with-ref-no`;
	return await axios.get(url);
}

// Save trip
export async function saveOrUpdateTrip(trip) {	
	if (trip.id) {
		const url = `${URL.BASE_URL}/trips/${trip.id}`;
		return await axios.put(url, trip);
	} else {
		const url = `${URL.BASE_URL}/trips`;
		return await axios.post(url, trip);
	}
}

// Get trip frequency types
export async function getTripFrequencyTypes() {
	const url = `${URL.BASE_URL}/trip-frequency-types`;
	return await axios.get(url);
}

// Get trip frequency types
export async function getTripFrequencies() {
	const url = `${URL.BASE_URL}/trip-frequencies`;
	return await axios.get(url);
}

// Get trip metadata list
export async function getTripMetadataList() {
	const url = `${URL.BASE_URL}/trips/metadata`;
	return await axios.get(url);
}

// Get trip metadata by id
export async function getTripMetadata(metadataId) {
	const url = `${URL.BASE_URL}/trips/metadata/${metadataId}`;
	return await axios.get(url);
}

// Delete metadata by id
export async function deleteTripMetadata(metadataId) {
	const url = `${URL.BASE_URL}/trips/metadata/${metadataId}`;
	return await axios.delete(url);
}

// Disable/enable metadata by id
export async function disableEnableTripMetadata(metadataId, isDisabled) {
	let url = `${URL.BASE_URL}/trips/metadata/${metadataId}`;
	if (isDisabled) {
		url = `${url}/disable`;
	} else {
		url = `${url}/enable`;
	}
	return await axios.post(url);

}

// Save/Update Trip metadata
export async function saveOrUpdateTripMetadata(tripMetadata) {
	
	const body = {
		id: tripMetadata.id,
		route_ref: tripMetadata.routeRef,
		route_types: tripMetadata.routeTypes, 
		frequency_type: tripMetadata.frequencyType, 
		frequencies: tripMetadata.frequencies,
		
		pickup_start_time: tripMetadata.pickupStartTime,
		pickup_end_time: tripMetadata.pickupEndTime,
		dropoff_start_time: tripMetadata.dropoffStartTime,
		dropoff_end_time: tripMetadata.dropoffEndTime,
		timezone: tripMetadata.timezone,

		// pickup_start_time: tripMetadata.pickupStartTime? tripMetadata.pickupStartTime.getTime():null,
		// pickup_end_time: tripMetadata.pickupEndTime? tripMetadata.pickupEndTime.getTime():null,
		// dropoff_start_time: tripMetadata.dropoffStartTime? tripMetadata.dropoffStartTime.getTime():null,
		// dropoff_end_time: tripMetadata.dropoffEndTime? tripMetadata.dropoffEndTime.getTime():null
	}

	if (body.id) {
		console.log(`updating new trip meta data - ${JSON.stringify(body)}`);
		const url = `${URL.BASE_URL}/trips/metadata/${body.id}`;
		return await axios.put(url, body);
	} else {
		console.log(`saving trip meta data - ${JSON.stringify(body)}`);
		const url = `${URL.BASE_URL}/trips/metadata`;
		return await axios.post(url, body);
	}
}

// Get today trips by status and other filters
export async function getTodayTrips(filter) {
	console.log(`Get trips filter - ${JSON.stringify(filter)}`);
	const url = `${URL.BASE_URL}/trips/today`;
	return await axios.get(url, {params: filter});
}

// Get trip history by filter
export async function getHistoryTrips(filter, page, limit) {
	console.log(`Get history trips filter - ${JSON.stringify(filter)}`);
	const body = {
		date: filter.date ? filter.date.getTime(): null,
		route_ref: filter.routeRef ? filter.routeRef : null,
		trip_status: filter.tripStatus ? filter.tripStatus : null,
		bus_id: filter.busId ? filter.busId : null,
		driver_id: filter.driverId ? filter.driverId : null,
	}
	const url = `${URL.BASE_URL}/trips/history/list/page/${page}/limit/${limit}`;
	return await axios.post(url, body);
}

// Get trip 
export async function getTrip(tripId) {
	const url = `${URL.BASE_URL}/trips/${tripId}`;
	return await axios.get(url);
}

// Delete trip
export async function deleteTrip(tripId) {
	const url = `${URL.BASE_URL}/trips/${tripId}`;
	return await axios.delete(url);
}

// Get trip 
export async function getTripRefsByIds(tripIds) {
	const body = {
		trip_ids: tripIds,
	}
	const url = `${URL.BASE_URL}/trips/with-ref-no`;
	return await axios.post(url, body);
}

// Get trip locations
export async function getTripLocations(tripId, page, limit) {
	const url = `${URL.BASE_URL}/trips/${tripId}/locations/page/${page}/limit/${limit}`;
	return await axios.get(url);
}

// Update trip students
export async function updateTripStudents(data) {	
	const body = {
		students: data.students,
	}
	const url = `${URL.BASE_URL}/trips/${data.tripId}/students`;
	return await axios.put(url, body);
}

// Update trip student status
export async function updateTripStudentStatus(data) {	
	const body = {
		status: data.status,
		status_change_reason: data.statusChangeReason
	}
	const url = `${URL.BASE_URL}/trips/${data.tripId}/students/${data.studentId}/status`;
	return await axios.put(url, body);
}

// Delete Trip Student
export async function deleteTripStudent(tripId, studentId) {
	const url = `${URL.BASE_URL}/trips/${tripId}/students/${studentId}`;
	return await axios.delete(url);
}

// Get avilable drivers of trip
export async function getAvailableTripDrivers(routeRefNo) {
	const url = `${URL.BASE_URL}/drivers/trip/available?route_ref_no=${routeRefNo}`;
	return await axios.get(url);
}

// Get avilable buses of trip
export async function getAvailableTripBuses(routeRefNo) {
	const url = `${URL.BASE_URL}/buses/trip/available?route_ref_no=${routeRefNo}`;
	return await axios.get(url);
}

// Update Trip bus
export async function updateTripBus(data) {
	return await axios.put(`${URL.BASE_URL}/trips/${data.tripId}/bus/${data.busId}`, data);
}

// Update Trip driver
export async function updateTripDriver(data) {
	return await axios.put(`${URL.BASE_URL}/trips/${data.tripId}/driver/${data.driverId}`, data);
}

// Get parent by student
export async function getParentByStudent(studentId) {
	const url = `${URL.BASE_URL}/parents/student/${studentId}`;
	return await axios.get(url);
}

// Upload school logo
export async function uploadSchoolLogo(schoolId, logoFile) {
	const data = new FormData()
   	data.append('logo', logoFile)
	return await axios.put(`${URL.BASE_URL}/schools/${schoolId}/logo`, data);
}

// Upload user profile photo
export async function uploadProfilePhoto(file) {
	console.log(`Uploading profile photo --- file - ${file}`);
	const data = new FormData()
   	data.append('file', file, 'profile.jpg');
	return await axios.put(`${URL.BASE_URL}/users/profile/photo`, data);
}

// Upload school user profile photo
export async function uploadSchoolUserProfilePhoto(userId, file) {
	console.log(`Uploading profile photo --- file - ${file}`);
	const data = new FormData()
   	data.append('file', file, 'profile.jpg');
	return await axios.put(`${URL.BASE_URL}/school-users/${userId}/profile-photo`, data);
}

// Upload school student profile photo
export async function uploadStudentProfilePhoto(studentId, file) {
	console.log(`Uploading student profile photo --- file - ${file}`);
	const data = new FormData()
   	data.append('file', file, 'profile.jpg');
	return await axios.put(`${URL.BASE_URL}/students/${studentId}/profile-photo`, data);
}

// Delete school logo
export async function deleteSchoolLogo(schoolId) {
	return await axios.delete(`${URL.BASE_URL}/schools/${schoolId}/logo`);
}

  // Get chat with Admin
export async function getChatWithAdmin(userId, tripId, role, page, limit) {
	console.log(`Fetching Chat with ${role} - page - ${page} - limit - ${limit}`);
	let url = '';
	if (role === Role.DRIVER) {
		url = `${URL.BASE_URL}/drivers/${userId}/trip/${tripId}/chats-with-admin/page/${page}/limit/${limit}`;
	}
	else if (role === Role.PARENT) {
		url = `${URL.BASE_URL}/parents/${userId}/chats-with-admin/page/${page}/limit/${limit}?trip_id=${tripId}`;
	}
	return await axios.get(url);
}

// get Notifications
export async function getNotifications(page, limit) {
	return await axios.get(`${URL.BASE_URL}/notifications/page/${page}/limit/${limit}`);
}

// Get Trip current location
export async function getTripCurrentLocation(tripId) {
	return await axios.get(`${URL.BASE_URL}/trips/${tripId}/current-location`);
}

export async function saveRouteStop(stop) {
	console.log("API :: Saving route stop :: " + JSON.stringify(stop));
	const data = {
		name: stop.name,
		address: stop.address,
		latitude: stop.lat,
		longitude: stop.lng,
		pickup_time: stop.pickupTime,
		dropoff_time: stop.dropoffTime,
	}
	if (stop.id) { // For update
		const body = data;
		return await axios.put(`${URL.BASE_URL}/routes/${stop.routeId}/stops/${stop.id}`, body);
	} else { // For new
		const body = {
			...data,
			route_id: stop.routeId,
			route_type: stop.routeType,
		}
		return await axios.post(`${URL.BASE_URL}/routes/stops`, body);
	}
}

export async function changeRouteStopOrder(routeId, stopId, order) {
	console.log("API :: route stop order :: ");
	const body = {
		order: order,
	}
	return await axios.put(`${URL.BASE_URL}/routes/${routeId}/stops/${stopId}/change-order`, body);
}

export async function deleteRouteStop(stopId) {
	console.log(`API :: Delete route stop :: ${stopId}`);
	return await axios.delete(`${URL.BASE_URL}/routes/stops/${stopId}`);
}

export async function deleteRouteStudent(routeId, studentId) {
	console.log(`API :: Delete route student :: ${routeId} - ${studentId}`);
	return await axios.delete(`${URL.BASE_URL}/routes/${routeId}/students/${studentId}`);
}

export async function enableDisableRouteStop(stopId, isDisabled) {
	console.log(`API :: Disable route stop :: ${stopId} - ${isDisabled}`);
	if (isDisabled) {
		return await axios.put(`${URL.BASE_URL}/routes/stops/${stopId}/disable`);
	} else {
		return await axios.put(`${URL.BASE_URL}/routes/stops/${stopId}/enable`);
	}
}

export async function enableDisableRouteStudent(routeId, studentId, isDisabled) {
	console.log(`API :: Disable route stop :: ${routeId} - ${studentId} - ${isDisabled}`);
	if (isDisabled) {
		return await axios.put(`${URL.BASE_URL}/routes/${routeId}/students/${studentId}/disable`);
	} else {
		return await axios.put(`${URL.BASE_URL}/routes/${routeId}/students/${studentId}/enable`);
	}
}

// Save or update student route stop
export async function saveRouteStudentStop(routeId, routeType, stopId, studentId) {
	console.log(`API :: Save student route stop :: ${studentId}, ${routeId}, ${stopId}`);
	const body = {
		"route_type": routeType,
		"route_id": routeId,
		"stop_id": stopId,
		"student_id": studentId,
	}
	return await axios.post(`${URL.BASE_URL}/routes/students`, body);
}


// Google Map Distance API call
export async function getDistanceAndDuration(origin, destinations) {
	let url = `https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${origin}&destinations=${destinations}&key=${GOOGLE_MAP_API_KEY}`;
	console.log(`Google map distance API - ${url}`);
	return await axios.get(`${url}`);
}

// Google API to get address from lat/lng
export async function reverseGeocode(lat, lng) {
	const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${lat},${lng}&key=${GOOGLE_MAP_API_KEY}`;
	console.log(`Reverse Geocode url - ${url}`);
	return await axios.get(url);
}

// Google API to get lat/lng of road from custom lat/lng
export async function snapToRoads(path) {
	const url = `https://roads.googleapis.com/v1/snapToRoads?path=${path}&interpolate=true&key=${GOOGLE_MAP_API_KEY}`;
	console.log(`snapToRoads url - ${url}`);
	return await axios.get(url);
}


// ************************** Response Interceptors **********************************

// Handle error in reponse
// Handle refresh tokens
let isRefreshing = false;
let refreshSubscribers = [];

// Add a auth token to request
axios.interceptors.request.use(function (config) {
	console.log("Interceptor..");
	const exclude_urls = ['login', 'register', 'otp-verification', 'verify-otp', 'resend-otp', 'refresh', 'maps.googleapis.com', 'roads.googleapis.com'];
	const exceptional_include_urls = ['school-users/verify-otp'];

	const matches = exclude_urls.filter(url => config.url.indexOf(url) > -1);
	console.log(`Matched urls - ${matches}`);

	const exceptional_include_matches = exceptional_include_urls.filter(url => config.url.indexOf(url) > -1);

	if (matches.length === 0 || exceptional_include_matches.length > 0) {
		config.headers.Authorization = `Bearer ${getAccessToken()}`;
	}	
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

// Add schoolid header
axios.interceptors.request.use(function (config) {

	const exclude_urls = ['login', 'register', 'otp-verification', 'verify-otp', 'resend-otp', 'refresh', 'maps.googleapis.com', 'roads.googleapis.com'];

	const matches = exclude_urls.filter(url => config.url.indexOf(url) > -1);
	console.log(`add schoolid - Matched urls - ${matches}`);

	const explicit_include_urls = ['school-users/verify-otp']

	if (SCHOOL.id && (matches.length === 0 || explicit_include_urls.length)) {
		config.headers.schoolId = SCHOOL.id;
	}
	
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

// Handle error in reponse
axios.interceptors.response.use(
	response => response,
	err => {
		console.log('response interceptor - ' + JSON.stringify(err));
		if (!err.response) {
			return Promise.reject("An error occured");
		}
		else if (err.response.status === 401) {
			// Clear auth details
			// removeAuth();
			// return Promise.reject(err.response.data.description); // will check later

			const originalRequest = err.config;
			const exclude_urls = ['logout', 'refresh']; // if found these urls then remove auth and logout - do need to get new access token

			const matches = exclude_urls.filter(url => originalRequest.url.indexOf(url) > -1);

			console.log(`401 Matches exclude urls - ${JSON.stringify(matches)}`);

			if (matches.length > 0) {
				removeAuth();
				return Promise.reject('Unauthroized!! Please logout and login again');
			}

			if (!isRefreshing) {
				isRefreshing = true;
				// Fetch new token
				newAccessToken().then(async resp => {
					console.log(`Access token from Refresh token - ${JSON.stringify(resp.data)}`);
					isRefreshing = false;
					const newToken = resp.data.access_token;
					// Update new Access token into storage
					setAccessToken(newToken);
					onRrefreshed(newToken);
				}).catch(e => {
					return Promise.reject(e);
				});
			}
			const retryOrigReq = new Promise((resolve, reject) => {
				subscribeTokenRefresh(token => {
					// replace the expired token and retry
					originalRequest.headers.Authorization = `Bearer ${token}`;
					resolve(axios(originalRequest));
				});
			});
			return retryOrigReq;

		}
		else if (err.response.status === 403) {
			//alert("Access Denied!");
			console.log(`Access denied - ${err.response.data.message}`);
			// TODO return status and message exmample - {statue: 403, message: 'access denied'}
			return Promise.reject(err.response.data.message);
			//return Promise.reject(err);
		}
		else if (err.response.status === 400) {
			// TODO Handle required field error
			console.log(`bad request - ${err.response.data.message}`);
			return Promise.reject(err.response.data.message); // will check later
		}
		else {
			return Promise.reject(err.response.data.message);
		}
	}
);


// Fetch access token using refresh token from server
const newAccessToken = async () => {
	const refreshToken = getRefreshToken();
	if (!refreshToken) {
		Promise.reject('An error occurred');
	}
	let url = `${URL.BASE_URL}/refresh`;
	return await axios.post(url, {}, { headers: {'Authorization': `Bearer ${refreshToken}`} });
}

const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
}

const onRrefreshed = (token) => {
	refreshSubscribers.map(cb => cb(token));
}