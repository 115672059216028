import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Chip } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import CommonProfilePhoto from '../../../../common/profile-photo';
import { IMAGES_URL } from '../../../../utils/constants';
import { uploadDriverProfilePhoto } from '../../../../services/http.service';

import { getDriver } from '../../../../services/http.service';

const DriverPhoto = () => {

    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [driver, setDriver] = useState(null);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
    
    const { driverId } = useParams();
    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDriver(driverId);
    }, []);

    useEffect(() => {
        if (driver) {
            const url = driver.user?.profile_photo ? `${IMAGES_URL.USER}/${driver.user.profile_photo}` : `${IMAGES_URL.USER}/default_profile.png`
            setProfilePhotoUrl(url);
        }
    }, [driver]);

    // Fetch driver detail for edit
    const fetchDriver = (driverId) => {
        setIsLoading(true);
        getDriver(driverId).then(resp => {
            setIsLoading(false);
            setErrorMsg(null);
            console.log(`Fetch driver - ${JSON.stringify(resp)}`);
            setDriver(resp.data.data);
            //setDriverDetail(resp.data.data);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
            setErrorMsg(err);
        })
    }

    // update photo
    const upload = (image) => {
        console.log(`Uploading profile photo - ${JSON.stringify(image)}`);

        // Show error if image is not selected
        if (!image) {
            setIsSaving(false);
            setErrorMsg("Please select image to upload");
            setShowSuccessAlert(false);
            return;
        }
        // Reset flags
        setIsSaving(true);
        setErrorMsg(null);
        setShowSuccessAlert(false);

        uploadDriverProfilePhoto(driver.id, image).then(resp => {
            console.log(`Uploaded successfully`);
            setIsSaving(false);
            setErrorMsg(null);
            setShowSuccessAlert(true);
            const newDriver = {
                ...driver,
                user: {
                    ...driver.user,
                    profile_photo: resp.data.filename
                }
            }
            console.log("new driver - ", newDriver);
            //setProfilePhotoUrl(`${IMAGES_URL.USER}/${resp.data.filename}`);
            setDriver(newDriver);
        }).catch(err => {
            console.error(err);
            setIsSaving(false);
            setErrorMsg(err);
            setShowSuccessAlert(false);
        });
    }

    return (
        <>
           { errorMsg && 
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert && 
                <SuccessAlertTopRight
                    successMsg={'Uploaded successfully'}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }
             <Chip 
                icon={<ArrowBackIosIcon />} 
                label="Back" 
                variant="outlined" 
                size="small"
                onClick={() => navigate(-1)}
            />
            { isSaving && 
                <CircularProgress />
            }
            <Box  
                sx={{ 
                    mt: 4, 
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "start",
                    alignItems: "start"
                }}
            >
                {driver &&
                    <CommonProfilePhoto
                        profilePhotoUrl={profilePhotoUrl}
                        altName={driver.name}
                        onSubmit={upload}
                    />
                }
            </Box>
        </>
    );
}


export default DriverPhoto;