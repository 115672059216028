import React, { Component, useState, useEffect, useRef } from 'react';
import { Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useParams, useNavigate } from 'react-router';

import { uploadSchoolUserProfilePhoto } from '../../../../services/http.service'
import { getUser } from '../../../../services/http.service';
import { IMAGES_URL, ImageSourceType } from '../../../../utils/constants';




const ProfilePhoto = () => {

    const [validated, setValidated] = useState(false);
    const [src, setSrc] = useState(null);
    const [user, setUser] = useState({});
    const [userLoading, setUserLoading] = useState({
        isLoading: false,
        errorMsg: null,
    });
    const [profilePhoto, setProfilePhoto] = useState({
        url: null,
        type: null,
    });
    const [uploadStatus, setUploadStatus] = useState({
        isSaving: false,
        errorMsg: null,
        showSuccessAlert: false
    });
    const [crop, setCrop] = useState({
        unit: "%",
        width: 50,
        height: 50,
        aspect: 16 / 16             
    });
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [image, setImage] = useState(null);

    const imageRef = useRef();
    const navigate = useNavigate();
    let fileUrl = null;
	
    const { userId } = useParams();

	useEffect(() => {
        fetchUser(userId);
    }, []);


    // Fetch user from server
    const fetchUser = (userId) => {
        // Reset flags
        setUserLoading({
            ...userLoading,
            isLoading: true,
            errorMsg: null,
        });
        getUser(userId).then(resp => {
            console.log(`User response - ${JSON.stringify(resp.data)}`);
            const user = resp.data.data;
            setUser(user);
            setUserLoading({
                ...userLoading,
                isLoading: false,
                errorMsg: null,
            });
            setProfilePhoto({
                ...profilePhoto,
                url: `${IMAGES_URL.USER}/${user.profile_photo}`,
                type: ImageSourceType.REMOTE,
            });
        }).catch(err => {
            console.error(err);
            setUserLoading({
                ...userLoading,
                isLoading: false,
                errorMsg: err,
            });
        })
    }


     // ************** Profile Photo & Crop *****************/ 

     const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                //console.log(`Selected profile photo - ${reader.result}`);
                //this.setState({ src: reader.result });
                // this.setState((prevState) => ({
                //     cropProfilePhoto: {
                //         ...prevState.cropProfilePhoto,
                //         src: reader.result
                //     }
                // }));
                console.log(`Selected file Src - ${reader.result}`);
                setSrc(reader.result);
            });            
            reader.readAsDataURL(e.target.files[0]);
            // Do not show image - already loaded from server if user wants to select new one
            setProfilePhoto({
                ...profilePhoto,
                url: null,
                type: ImageSourceType.LOCAL,
            });
            setUploadStatus({
                ...uploadStatus,
                isSaving: false,
                errorMsg: null,
                showSuccessAlert: false,
            });
        }
    };
    // If you setState the crop in here you should return false.
    const onImageLoaded = image => {
        console.log(`onImageLoaded - image - ${image}`);
        imageRef.current = image;
    };

    const onCropComplete = crop => {
        console.log(`onCropComplete - crop - ${JSON.stringify(crop)}`);
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        console.log(`onCropChange`);
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageRef.current,
                crop,
                "newFile.jpeg"
            );
            console.log(`Cropped image url - ${croppedImageUrl}`);
            setCroppedImageUrl(croppedImageUrl);
        }
    }

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                console.log(`setting file image to upload - ${JSON.stringify(blob)}`);
                setImage(blob);
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, "image/jpeg");
        });
    }
    // ************** End Profile Photo & Crop *****************/ 


    // Handle Cancel
    const handleCancel = () => {
        setProfilePhoto({
            ...profilePhoto,
            url: user.profile_photo && user.profile_photo.trim().length > 0 ? `${IMAGES_URL.USER}/${user.profile_photo}` : null,
            type: user.profile_photo && user.profile_photo.trim().length > 0 ?  ImageSourceType.REMOTE : null,
        });
        setImage(null);
        setCroppedImageUrl(null);
        setSrc(null);
    }

    const upload = (e) => {
        console.log(`Uploading profile photo - ${JSON.stringify(image)}`);

        // Show error if image is not selected
        if (!image) {
            setUploadStatus({
                ...uploadStatus,
                isSaving: false,
                errorMsg: "Please select image to upload",
                showSuccessAlert: false,
            });
            return;
        }
        // Reset flags
        setUploadStatus({
            ...uploadStatus,
            isSaving: true,
            errorMsg: null,
            showSuccessAlert: false,
        });

        uploadSchoolUserProfilePhoto(user.id, image).then(resp => {
            console.log(`Uploaded successfully`);
            setUploadStatus({
                ...uploadStatus,
                isSaving: false,
                errorMsg: null,
                showSuccessAlert: true,
            });
            setUser({
                ...user,
                profile_photo: resp.data.filename
            });
            setProfilePhoto({
                ...profilePhoto,
                url: `${IMAGES_URL.USER}/${resp.data.filename}`,
                type: ImageSourceType.REMOTE,
            });
            setImage(null);
            setCroppedImageUrl(null);
            setSrc(null);
        }).catch(err => {
            console.error(err);
            setUploadStatus({
                ...uploadStatus,
                isSaving: false,
                errorMsg: err,
                showSuccessAlert: false,
            });
        });
    }

    let image_url = `${IMAGES_URL.USER}/default_profile.png`;
    if (user.profile_photo) {
        image_url = `${IMAGES_URL.USER}/${user.profile_photo}`;
    }

  

    return (
        <React.Fragment> 
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <div className="h5 mb-0 text-gray-800">Upload Profile Photo</div>
            </div>
            <div className="card shadow h-100 my-2">
                <div className="card-body">
                    <button className="btn btn-light mb-4" onClick={() => navigate(-1)}>
                        <i class="fas fa-chevron-left"></i>
                    </button> 
                    <div className="row">
                        <div className="col-md-4">
                            <Form> 
                                <Form.Group>
                                    <Form.Label>Select Profile Photo</Form.Label>
                                    <Form.Control required type="file" 
                                        onChange={onSelectFile} 
                                    />
                                </Form.Group> 
                            </Form>
                        </div>
                        <div className="col-md-6">
                            {uploadStatus.isSaving &&
                                <Spinner animation="border" variant="primary" />
                            }
                            {uploadStatus.showSuccessAlert &&
                                <Alert className="ml-3 mb-0" variant="success">
                                    Saved Successfully
                                    </Alert>
                            }
                            {uploadStatus.errorMsg &&
                                <Alert className="ml-3 mb-0" variant="danger">
                                    {uploadStatus.errorMsg}
                                </Alert>
                            }
                        </div>
                        {profilePhoto.type === ImageSourceType.LOCAL &&
                            <div className="col-md-2">
                                <Button className="btn btn-primary" onClick={upload}>Upload</Button>
                            </div>
                        }
                    </div>  
                    <div>
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={onImageLoaded}
                                onComplete={onCropComplete}
                                onChange={onCropChange}
                                style={{ maxWidth: "100%", height: "100%"}}
                            />
                        )}
                        {croppedImageUrl && (
                            <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
                        )}
                    </div>

                    <div className="mt-3 d-flex align-items-center justify-content-left">
                        {profilePhoto.type === ImageSourceType.LOCAL &&                                    
                            <Button size="sm" variant="light" onClick={() => handleCancel()}>Cancel</Button>                                  
                        }                           
                    </div>  

                    {profilePhoto.type === ImageSourceType.REMOTE &&
                        <div className="mt-3">
                            <img src={image_url} alt={user.name}></img>
                        </div> 
                    }                           
                </div>
            </div>                
        </React.Fragment>
    );
}

export default ProfilePhoto;



// class ProfilePhoto extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             validated: false,
//             // Crop profile photo
//             src: null,
//             crop: {
//                 unit: "%",
//                 width: 50,
//                 height: 50,
//                 aspect: 16 / 16             
//             },
//             user: {},

//             // User loading status
//             userLoading: {
//                 isLoading: false,
//                 errorMsg: null,
//             },

//             // to show profile photo for save/edit
//             profilePhoto: {
//                 url: null,
//                 type: null,
//             },

//             // Uploading image status
//             uploadStatus: {
//                 isSaving: false,
//                 errorMsg: null,
//                 showSuccessAlert: false
//             }
// 		};
//     }

//     componentDidMount() {
//         const userId = this.props.match.params.id;
//         console.log(`User id - ${userId}`);
//         this.fetchUser(userId);
//     }


//     // Fetch user from server
//     fetchUser = (userId) => {
//         // Reset flags
//         this.setState(ps => ({
//             userLoading: {
//                 ...ps.userLoading,
//                 isLoading: true,
//                 errorMsg: null,
//             }
//         }));
//         getUser(userId).then(resp => {
//             console.log(`User response - ${JSON.stringify(resp.data)}`);
//             const user = resp.data.data;
//             this.setState({ user })
//             this.setState(ps => ({
//                 userLoading: {
//                     ...ps.userLoading,
//                     isLoading: false,
//                     errorMsg: null,
//                 },
//                 profilePhoto: {
//                     ...ps.profilePhoto,
//                     url: `${IMAGES_URL.USER}/${user.profile_photo}`,
//                     type: ImageSourceType.REMOTE,
//                 }
//             }));
//         }).catch(err => {
//             console.error(err);
//             this.setState(ps => ({
//                 userLoading: {
//                     ...ps.userLoading,
//                     isLoading: false,
//                     errorMsg: err,
//                 }
//             }));
//         })
//     }


//      // ************** Profile Photo & Crop *****************/ 

//      onSelectFile = e => {
//         if (e.target.files && e.target.files.length > 0) {
//             const reader = new FileReader();
//             reader.addEventListener("load", () => {
//                 //console.log(`Selected profile photo - ${reader.result}`);
//                 //this.setState({ src: reader.result });
//                 // this.setState((prevState) => ({
//                 //     cropProfilePhoto: {
//                 //         ...prevState.cropProfilePhoto,
//                 //         src: reader.result
//                 //     }
//                 // }));
//                 console.log(`Selected file Src - ${reader.result}`);
//                 this.setState({ src: reader.result })
//             });            
//             reader.readAsDataURL(e.target.files[0]);
//             // Do not show image - already loaded from server if user wants to select new one
//             this.setState(ps => ({                
//                 profilePhoto: {
//                     ...ps.profilePhoto,
//                     url: null,
//                     type: ImageSourceType.LOCAL,
//                 },
//                 uploadStatus: { // reset flags also
//                     ...ps.uploadStatus,
//                     isSaving: false,
//                     errorMsg: null,
//                     showSuccessAlert: false,
//                 }
//             }));
//         }
//     };
//     // If you setState the crop in here you should return false.
//     onImageLoaded = image => {
//         console.log(`onImageLoaded - image - ${image}`);
//         this.imageRef = image;
//     };

//     onCropComplete = crop => {
//         console.log(`onCropComplete - crop - ${JSON.stringify(crop)}`);
//         this.makeClientCrop(crop);
//     };

//     onCropChange = (crop, percentCrop) => {
//         console.log(`onCropChange`);
//         // You could also use percentCrop:
//         // this.setState({ crop: percentCrop });
//         this.setState({ crop });
//     };

//     async makeClientCrop(crop) {
//         if (this.imageRef && crop.width && crop.height) {
//             const croppedImageUrl = await this.getCroppedImg(
//                 this.imageRef,
//                 crop,
//                 "newFile.jpeg"
//             );
//             console.log(`Cropped image url - ${croppedImageUrl}`);
//             this.setState({ croppedImageUrl });
//         }
//     }

//     getCroppedImg(image, crop, fileName) {
//         const canvas = document.createElement("canvas");
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;
//         canvas.width = crop.width;
//         canvas.height = crop.height;
//         const ctx = canvas.getContext("2d");

//         ctx.drawImage(
//             image,
//             crop.x * scaleX,
//             crop.y * scaleY,
//             crop.width * scaleX,
//             crop.height * scaleY,
//             0,
//             0,
//             crop.width,
//             crop.height
//         );

//         return new Promise((resolve, reject) => {
//             canvas.toBlob(blob => {
//                 if (!blob) {
//                     //reject(new Error('Canvas is empty'));
//                     console.error("Canvas is empty");
//                     return;
//                 }
//                 blob.name = fileName;
//                 console.log(`setting file image to upload - ${JSON.stringify(blob)}`);
//                 this.setState({ image: blob});
//                 window.URL.revokeObjectURL(this.fileUrl);
//                 this.fileUrl = window.URL.createObjectURL(blob);
//                 resolve(this.fileUrl);
//             }, "image/jpeg");
//         });
//     }
//     // ************** End Profile Photo & Crop *****************/ 


//     // Handle Cancel
//     handleCancel = () => {
//         const { user } = this.state;               
//         this.setState(ps => ({
//             profilePhoto: {
//                 ...ps.profilePhoto,
//                 url: user.profile_photo && user.profile_photo.trim().length > 0 ? `${IMAGES_URL.USER}/${user.profile_photo}` : null,
//                 type: user.profile_photo && user.profile_photo.trim().length > 0 ?  ImageSourceType.REMOTE : null,
//             },
//             image: null,
//             croppedImageUrl: null,
//             src: null,
//         }));       
//     }

//     upload = (e) => {
//         const { image } = this.state;
//         console.log(`Uploading profile photo - ${JSON.stringify(image)}`);

//         // Show error if image is not selected
//         if (!image) {
//             this.setState(ps => ({
//                 uploadStatus: {
//                     ...ps.uploadStatus,
//                     isSaving: false,
//                     errorMsg: "Please select image to upload",
//                     showSuccessAlert: false,
//                 }
//             }));
//             return;
//         }
//         // Reset flags
//         this.setState(ps => ({
//             uploadStatus: {
//                 ...ps.uploadStatus,
//                 isSaving: true,
//                 errorMsg: null,
//                 showSuccessAlert: false,
//             }
//         }));


//         uploadSchoolUserProfilePhoto(this.state.user.id, image).then(resp => {
//             console.log(`Uploaded successfully`);
//             this.setState(ps => ({
//                 uploadStatus: {
//                     ...ps.uploadStatus,
//                     isSaving: false,
//                     errorMsg: null,
//                     showSuccessAlert: true,
//                 },
//                 user: {
//                     ...ps.user,
//                     profile_photo: resp.data.filename
//                 },
//                 profilePhoto: {
//                     ...ps.profilePhoto,
//                     url: `${IMAGES_URL.USER}/${resp.data.filename}`,
//                     type: ImageSourceType.REMOTE,
//                 },
//                 image: null,
//                 croppedImageUrl: null,
//                 src: null,
//             }));

//         }).catch(err => {
//             console.error(err);
//             this.setState(ps => ({
//                 uploadStatus: {
//                     ...ps.uploadStatus,
//                     isSaving: false,
//                     errorMsg: err,
//                     showSuccessAlert: false,
//                 }
//             }));
//         });
//     }

    
//     render() {

//         const { crop, croppedImageUrl, profilePhoto, src, user, userLoading, uploadStatus } = this.state;

//         let image_url = `${IMAGES_URL.USER}/default_profile.png`;
//         if (user.profile_photo) {
//             image_url = `${IMAGES_URL.USER}/${user.profile_photo}`;
//         }

//         return (
//             <React.Fragment> 
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Upload Profile Photo</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         <button className="btn btn-light mb-4" onClick={() => this.props.history.goBack()}>
//                             <i class="fas fa-chevron-left"></i>
//                         </button> 
//                         <div className="row">
//                             <div className="col-md-4">
//                                 <Form> 
//                                     <Form.Group>
//                                         <Form.Label>Select Profile Photo</Form.Label>
//                                         <Form.Control required type="file" 
//                                             onChange={this.onSelectFile} 
//                                         />
//                                     </Form.Group> 
//                                 </Form>
//                             </div>
//                             <div className="col-md-6">
//                                 {uploadStatus.isSaving &&
//                                     <Spinner animation="border" variant="primary" />
//                                 }
//                                 {uploadStatus.showSuccessAlert &&
//                                     <Alert className="ml-3 mb-0" variant="success">
//                                         Saved Successfully
//                                         </Alert>
//                                 }
//                                 {uploadStatus.errorMsg &&
//                                     <Alert className="ml-3 mb-0" variant="danger">
//                                         {this.state.uploadStatus.errorMsg}
//                                     </Alert>
//                                 }
//                             </div>
//                             {profilePhoto.type === ImageSourceType.LOCAL &&
//                                 <div className="col-md-2">
//                                     <Button className="btn btn-primary" onClick={this.upload}>Upload</Button>
//                                 </div>
//                             }
//                         </div>  
//                         <div>
//                             {src && (
//                                 <ReactCrop
//                                     src={src}
//                                     crop={crop}
//                                     onImageLoaded={this.onImageLoaded}
//                                     onComplete={this.onCropComplete}
//                                     onChange={this.onCropChange}
//                                     style={{ maxWidth: "100%", height: "100%"}}
//                                 />
//                             )}
//                             {croppedImageUrl && (
//                                 <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
//                             )}
//                         </div>

//                         <div className="mt-3 d-flex align-items-center justify-content-left">
//                             {profilePhoto.type === ImageSourceType.LOCAL &&                                    
//                                 <Button size="sm" variant="light" onClick={() => this.handleCancel()}>Cancel</Button>                                  
//                             }                           
//                         </div>  

//                         {profilePhoto.type === ImageSourceType.REMOTE &&
//                             <div className="mt-3">
//                                 <img src={image_url} alt={user.name}></img>
//                             </div> 
//                         }                           
//                     </div>
//                 </div>                
//             </React.Fragment>
//         )
//     }
// }

// export default ProfilePhoto;