import { CircularProgress, Box } from '@mui/material';

const Loader = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <CircularProgress  color="primary"/>
        </Box>
    );
}

export default Loader;