import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Container, Button, Box, Avatar, Typography, TextField, Grid, Paper, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { ErrorAlertTopCenter } from '../../common/snackbars';

import { register } from '../../services/http.service';
import UnAuthLayout from '../../layouts/unauth';

import '../../styles/register.css';

// Yup from validation
const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    mobile: Yup.string().required("Mobile is required."),
    email: Yup.string().required("Email is required.").email("Invalid email."),
    password: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long"),
    confirmPassword: Yup.string().required("Password is required.")
        .min(6, "Password must be 6 chars long").max(32, "Password must be more that 32 chars long")
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    
});




const Register = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    let navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsSubmitting(true);
            console.log(`submit values - ${JSON.stringify(values)}`);
            setErrorMsg(null);
            register(values).then(resp => {
                setIsSubmitting(false);
                navigate('/otp-verification/' + resp.data.user_id);
            }).catch(err => {
                setIsSubmitting(false);
                setErrorMsg(err);
            });
        },
    });

    return (
        <>
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            <Container component="main" maxWidth="sm">
                <Paper elevation={10}>
                    <Box
                        padding={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign Up
                        </Typography>
                        <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="name"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Name"
                                        autoFocus
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="mobile"
                                        label="Mobile"
                                        name="mobile"
                                        autoComplete="family-name"
                                        value={formik.values.mobile}
                                        onChange={formik.handleChange}
                                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                        helperText={formik.touched.mobile && formik.errors.mobile}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        autoComplete="new-password"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    />
                                </Grid>
                            </Grid>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {isSubmitting &&
                                    <CircularProgress />
                                }
                                {!isSubmitting &&
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign Up
                                    </Button>
                                }
                            </Box>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link to="/login" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );

    // return (
    //     <div id="layoutAuthentication" class="bg-primary">
    //         <div id="layoutAuthentication_content">
    //             <main>
    //                 <div class="container">
    //                     <div class="row justify-content-center">
    //                         <div class="col-lg-7">
    //                             <div class="card shadow-lg border-0 rounded-lg mt-5">
    //                                 <div class="card-header"><h3 class="text-center font-weight-light my-4">Create Account</h3></div>
    //                                 <div class="card-body">
    //                                     <Formik
    //                                         validationSchema={schema}
    //                                         onSubmit={( values, { setSubmitting }) => {
    //                                             setIsSubmitting(true);
    //                                             console.log(`submit values - ${JSON.stringify(values)}`);
    //                                             setShowErrorAlert(false);
    //                                             setErrorMsg(null);
    //                                             register(values).then(resp => {
    //                                                 setIsSubmitting(false);
    //                                                 //this.setState({showSuccessAlert: true});
    //                                                 //this.setState({showErrorAlert: false});
    //                                                 navigate('/otp-verification/' + resp.data.user_id);
    //                                             }).catch(err => {
    //                                                 setIsSubmitting(false);
    //                                                 setShowErrorAlert(true);
    //                                                 setErrorMsg(err);
    //                                             });  
    //                                         }}
    //                                         initialValues={{
    //                                             name: '',
    //                                             mobile: '',
    //                                             email: '',
    //                                             password: '',
    //                                             confirmPassword: '',
    //                                         }}
    //                                     >   
    //                                         <Form>
    //                                             <div class="form-row">
    //                                                 <div class="col-md-6">
    //                                                     <div className="form-group">
    //                                                         <label className="small mb-1">Name</label>
    //                                                         <Field
    //                                                             className="form-control form-control-user py-4"
    //                                                             placeholder="Enter name"
    //                                                             name="name"
    //                                                             type="text"
    //                                                         />
    //                                                         <div className="App-form-error">
    //                                                             <ErrorMessage name="name" />
    //                                                         </div>
                                                            
    //                                                     </div>
    //                                                 </div>
    //                                                 <div class="col-md-6">
    //                                                     <div className="form-group">
    //                                                         <label className="small mb-1">Mobile</label>
    //                                                         <Field
    //                                                             className="form-control form-control-user py-4"
    //                                                             placeholder="Enter mobile"
    //                                                             name="mobile"
    //                                                             type="text"
    //                                                         />
    //                                                         <div className="App-form-error">
    //                                                             <ErrorMessage name="mobile" />
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                             <div className="form-group">
    //                                                 <label className="small mb-1">Email</label>
    //                                                 <Field
    //                                                     className="form-control form-control-user py-4"
    //                                                     placeholder="Enter email"
    //                                                     name="email"
    //                                                     type="text"
    //                                                 />
    //                                                 <div className="App-form-error">
    //                                                     <ErrorMessage name="email" />
    //                                                 </div>
    //                                             </div>

    //                                             <div class="form-row">
    //                                                 <div class="col-md-6">
    //                                                     <div className="form-group">
    //                                                         <label className="small mb-1">Password</label>
    //                                                         <Field
    //                                                             className="form-control form-control-user py-4"
    //                                                             placeholder="Enter password"
    //                                                             name="password"
    //                                                             type="password"
    //                                                         />
    //                                                         <div className="App-form-error">
    //                                                             <ErrorMessage name="password" />
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                                 <div class="col-md-6">
    //                                                     <div className="form-group">
    //                                                         <label className="small mb-1">Confirm Password</label>
    //                                                         <Field
    //                                                             className="form-control form-control-user py-4"
    //                                                             placeholder="Enter confirm password"
    //                                                             name="confirmPassword"
    //                                                             type="password"
    //                                                         />
    //                                                         <div className="App-form-error">
    //                                                             <ErrorMessage name="confirmPassword" />
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                             <div class="form-group mt-4 mb-0">
    //                                                 <div className="d-flex flex-column align-items-center justify-content-center">
    //                                                     <Button className="btn btn-primary btn-user btn-block btn-user" type="submit">Create Account</Button>                            
    //                                                     {isSubmitting &&                         
    //                                                         <Spinner className="mt-3" animation="border" variant="primary" />
    //                                                     }
    //                                                     {showSuccessAlert &&
    //                                                         <Alert className="ml-3 mb-0 mt-3" variant="success" onClose={() => setShowSuccessAlert(false) } 
    //                                                             dismissible>
    //                                                             Registered Successfully
    //                                                         </Alert>
    //                                                     }
    //                                                     {errorMsg &&
    //                                                         <Alert className="ml-3 mb-0 mt-3" variant="danger" onClose={() => setShowSuccessAlert(false) } 
    //                                                             dismissible>
    //                                                             {errorMsg}
    //                                                         </Alert>
    //                                                     }
    //                                                 </div>
    //                                             </div>
    //                                         </Form>
                                            
                                        
    //                                     </Formik>

    //                                 </div>
    //                                 <div class="card-footer text-center">
    //                                     <div class="small"><a href="/login">Have an account? Go to login</a></div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </main>
    //         </div>
    //     </div>
    // );
}

export default Register;



// -------------



// class Register extends Component {
//     constructor(props) {
// 		super(props)
// 		this.state = {
//             showSuccessAlert: false,
//             errorMsg: null,
// 		};
//     }

   
    
//     render() {
        
//         const { showSuccessAlert, errorMsg } = this.state;
        
//         return (
//             <div id="layoutAuthentication" class="bg-primary">
//                 <div id="layoutAuthentication_content">
//                     <main>
//                         <div class="container">
//                             <div class="row justify-content-center">
//                                 <div class="col-lg-7">
//                                     <div class="card shadow-lg border-0 rounded-lg mt-5">
//                                         <div class="card-header"><h3 class="text-center font-weight-light my-4">Create Account</h3></div>
//                                         <div class="card-body">
//                                             <Formik
//                                                 validationSchema={schema}
//                                                 onSubmit={( values, { setSubmitting }) => {
//                                                     //setSubmitting(false);
//                                                     console.log(`submit values - ${JSON.stringify(values)}`);
//                                                     this.setState({showErrorAlert: false});
//                                                     this.setState({errorMsg: null})
//                                                     register(values).then(resp => {
//                                                         setSubmitting(false);
//                                                         //this.setState({showSuccessAlert: true});
//                                                         //this.setState({showErrorAlert: false});
//                                                         this.props.history.push('/otp-verification/' + resp.data.user_id);
//                                                     }).catch(err => {
//                                                         setSubmitting(false);
//                                                         this.setState({showErrorAlert: true});
//                                                         this.setState({errorMsg: err});
//                                                     });  
//                                                 }}
//                                                 initialValues={{
//                                                     name: '',
//                                                     mobile: '',
//                                                     email: '',
//                                                     password: '',
//                                                     confirmPassword: '',
//                                                 }}
//                                             >
//                                                 {({
//                                                     handleSubmit,
//                                                     handleChange,
//                                                     handleBlur,
//                                                     values,
//                                                     touched,
//                                                     isValid,
//                                                     errors,
//                                                     isSubmitting,
//                                                 }) => (
//                                                     <Form noValidate onSubmit={handleSubmit}>
//                                                         <div class="form-row">
//                                                             <div class="col-md-6">
//                                                                 <Form.Group className="form-group">
//                                                                     <Form.Label className="small mb-1">Name</Form.Label>
//                                                                     <Form.Control
//                                                                         className="form-control form-control-user py-4"
//                                                                         type="text"
//                                                                         placeholder="Enter name"
//                                                                         name="name"
//                                                                         value={values.name}
//                                                                         onChange={handleChange}
//                                                                         isInvalid={!!errors.name} />
//                                                                     <Form.Control.Feedback type="invalid">
//                                                                         {errors.name}
//                                                                     </Form.Control.Feedback>
//                                                                 </Form.Group>
//                                                             </div>
//                                                             <div class="col-md-6">
//                                                                 <Form.Group className="form-group">
//                                                                     <Form.Label className="small mb-1">Mobile</Form.Label>
//                                                                     <Form.Control
//                                                                         className="form-control form-control-user py-4"
//                                                                         type="text"
//                                                                         placeholder="Enter mobile"
//                                                                         name="mobile"
//                                                                         value={values.mobile}
//                                                                         onChange={handleChange}
//                                                                         isInvalid={!!errors.mobile} />
//                                                                     <Form.Control.Feedback type="invalid">
//                                                                         {errors.mobile}
//                                                                     </Form.Control.Feedback>
//                                                                 </Form.Group>
//                                                             </div>
//                                                         </div>

//                                                         <Form.Group className="form-group">
//                                                             <Form.Label className="small mb-1">Email</Form.Label>
//                                                             <Form.Control
//                                                                 className="form-control form-control-user py-4"
//                                                                 type="email"
//                                                                 placeholder="Enter email"
//                                                                 name="email"
//                                                                 value={values.email}
//                                                                 onChange={handleChange}
//                                                                 isInvalid={!!errors.email} />
//                                                             <Form.Control.Feedback type="invalid">
//                                                                 {errors.email}
//                                                             </Form.Control.Feedback>
//                                                         </Form.Group>

//                                                         <div class="form-row">
//                                                             <div class="col-md-6">
//                                                                 <Form.Group className="form-group">
//                                                                     <Form.Label className="small mb-1">Password</Form.Label>
//                                                                     <Form.Control
//                                                                         className="form-control form-control-user py-4"
//                                                                         type="password"
//                                                                         placeholder="Enter password"
//                                                                         name="password"
//                                                                         value={values.password}
//                                                                         onChange={handleChange}
//                                                                         isInvalid={!!errors.password} />
//                                                                     <Form.Control.Feedback type="invalid">
//                                                                         {errors.password}
//                                                                     </Form.Control.Feedback>
//                                                                 </Form.Group>
//                                                             </div>
//                                                             <div class="col-md-6">
//                                                                 <Form.Group className="form-group">
//                                                                     <Form.Label className="small mb-1">Confirm Password</Form.Label>
//                                                                     <Form.Control
//                                                                         className="form-control form-control-user py-4"
//                                                                         type="password"
//                                                                         placeholder="Enter confirm password"
//                                                                         name="confirmPassword"
//                                                                         value={values.confirmPassword}
//                                                                         onChange={handleChange}
//                                                                         isInvalid={!!errors.confirmPassword} />
//                                                                     <Form.Control.Feedback type="invalid">
//                                                                         {errors.confirmPassword}
//                                                                     </Form.Control.Feedback>
//                                                                 </Form.Group>
//                                                             </div>
//                                                         </div>
//                                                         <div class="form-group mt-4 mb-0">
//                                                             <div className="d-flex flex-column align-items-center justify-content-center">
//                                                                 <Button className="btn btn-primary btn-user btn-block btn-user" type="submit">Create Account</Button>                            
//                                                                 {isSubmitting &&                         
//                                                                     <Spinner className="mt-3" animation="border" variant="primary" />
//                                                                 }
//                                                                 {showSuccessAlert &&
//                                                                     <Alert className="ml-3 mb-0 mt-3" variant="success" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                                                         dismissible>
//                                                                         Registered Successfully
//                                                                     </Alert>
//                                                                 }
//                                                                 {errorMsg &&
//                                                                     <Alert className="ml-3 mb-0 mt-3" variant="danger" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                                                         dismissible>
//                                                                         {this.state.errorMsg}
//                                                                     </Alert>
//                                                                 }
//                                                             </div>
//                                                         </div>
//                                                     </Form>
//                                                 )
//                                             }
//                                             </Formik>

//                                         </div>
//                                         <div class="card-footer text-center">
//                                             <div class="small"><a href="/login">Have an account? Go to login</a></div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </main>
//                 </div>
//             </div>


//             // <Row className="h-100 justify-content-center align-items-center register-bg" style={{overflowY: 'scroll'}}>
//             //     <div id="register-bg-overlay"> </div>
//             //     <Col lg={4} id="register-container" className="shadow-lg rounded">
//             //         <div className="text-center text-muted">
//             //             <h4>Registration</h4>
//             //         </div>

//             //         <Formik
//             //             validationSchema={schema}
//             //             onSubmit={( values, { setSubmitting }) => {
//             //                 //setSubmitting(false);
//             //                 console.log(`submit values - ${JSON.stringify(values)}`);
//             //                 this.setState({showErrorAlert: false});
//             //                 this.setState({errorMsg: null})
//             //                 register(values).then(resp => {
//             //                     setSubmitting(false);
//             //                     //this.setState({showSuccessAlert: true});
//             //                     //this.setState({showErrorAlert: false});
//             //                     this.props.history.push('/otp-verification/' + resp.data.user_id);
//             //                 }).catch(err => {
//             //                     setSubmitting(false);
//             //                     this.setState({showErrorAlert: true});
//             //                     this.setState({errorMsg: err});
//             //                 });  
//             //             }}
//             //             initialValues={{
//             //                 name: '',
//             //                 mobile: '',
//             //                 email: '',
//             //                 password: '',
//             //                 confirmPassword: '',
//             //             }}
//             //             >
//             //                 {({
//             //                     handleSubmit,
//             //                     handleChange,
//             //                     handleBlur,
//             //                     values,
//             //                     touched,
//             //                     isValid,
//             //                     errors,
//             //                     isSubmitting,
//             //                 }) => (
//             //                     <Form noValidate onSubmit={handleSubmit}>
//             //                         <Form.Group>
//             //                             <Form.Label>Name</Form.Label>
//             //                             <Form.Control
//             //                                 type="text"
//             //                                 placeholder="Enter name"
//             //                                 name="name"
//             //                                 value={values.name}
//             //                                 onChange={handleChange}
//             //                                 isInvalid={!!errors.name} />
//             //                             <Form.Control.Feedback type="invalid">
//             //                                 {errors.name}
//             //                             </Form.Control.Feedback>
//             //                         </Form.Group>
//             //                         <Form.Group>
//             //                             <Form.Label>Mobile</Form.Label>
//             //                             <Form.Control
//             //                                 type="text"
//             //                                 placeholder="Enter mobile"
//             //                                 name="mobile"
//             //                                 value={values.mobile}
//             //                                 onChange={handleChange}
//             //                                 isInvalid={!!errors.mobile} />
//             //                             <Form.Control.Feedback type="invalid">
//             //                                 {errors.mobile}
//             //                             </Form.Control.Feedback>
//             //                         </Form.Group>
//             //                         <Form.Group>
//             //                             <Form.Label>Email</Form.Label>
//             //                             <Form.Control
//             //                                 type="email"
//             //                                 placeholder="Enter email"
//             //                                 name="email"
//             //                                 value={values.email}
//             //                                 onChange={handleChange}
//             //                                 isInvalid={!!errors.email} />
//             //                             <Form.Control.Feedback type="invalid">
//             //                                 {errors.email}
//             //                             </Form.Control.Feedback>
//             //                         </Form.Group>
//             //                         <Form.Group>
//             //                             <Form.Label>Password</Form.Label>
//             //                             <Form.Control
//             //                                 type="password"
//             //                                 placeholder="Enter password"
//             //                                 name="password"
//             //                                 value={values.password}
//             //                                 onChange={handleChange}
//             //                                 isInvalid={!!errors.password} />
//             //                             <Form.Control.Feedback type="invalid">
//             //                                 {errors.password}
//             //                             </Form.Control.Feedback>
//             //                         </Form.Group>
//             //                         <Form.Group>
//             //                             <Form.Label>Confirm Password</Form.Label>
//             //                             <Form.Control
//             //                                 type="password"
//             //                                 placeholder="Enter confirm password"
//             //                                 name="confirmPassword"
//             //                                 value={values.confirmPassword}
//             //                                 onChange={handleChange}
//             //                                 isInvalid={!!errors.confirmPassword} />
//             //                             <Form.Control.Feedback type="invalid">
//             //                                 {errors.confirmPassword}
//             //                             </Form.Control.Feedback>
//             //                         </Form.Group>
//             //                         <div className="d-flex align-items-center justify-content-left">
//             //                             <Button className="btn-general" type="submit">Register</Button>                            
//             //                             {isSubmitting &&                         
//             //                                 <Spinner animation="border" variant="primary" />
//             //                             }
//             //                             {showSuccessAlert &&
//             //                                 <Alert className="ml-3 mb-0" variant="success" onClose={() => this.setState({showSuccessAlert: false}) } 
//             //                                     dismissible>
//             //                                     Registered Successfully
//             //                                 </Alert>
//             //                             }
//             //                             {errorMsg &&
//             //                                 <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({showSuccessAlert: false}) } 
//             //                                     dismissible>
//             //                                     {this.state.errorMsg}
//             //                                 </Alert>
//             //                             }
//             //                         </div>
//             //                     </Form>
//             //                 )}

//             //         </Formik>
//             //         <div className="d-flex align-items-center justify-content-between text-muted mt-2">
//             //             <a href="/login" className="btn btn-link">Login</a>
//             //         </div>
//             //     </Col>
//             // </Row>
//         )
//     }
// }

//export default Register;