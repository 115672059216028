import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Chip, Paper, Grid,
    TextField, Autocomplete, CircularProgress, List, ListItem, ListItemText, ListItemAvatar, Avatar,
    IconButton, Divider } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { saveOrUpdateRouteNumber, deleteRouteNumber } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import { CustomModal } from '../../../../common/modals';
import { ModalType } from '../../../../utils/constants';

const AddRouteNumber = ({ route, onNext, onBack, onSubmit }) => {

    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    useEffect(() => {
        const formValues = {};
        if (route.number) {
            formValues.number = route.number;
        } else {
            formValues.number = '';
        }
        formik.setValues(formValues);
    }, [route]);

     // Formik handle submit
    const validationSchema = Yup.object({
        number: Yup.string().required("Route number is required."),
    });
    const formik = useFormik({
        initialValues: {
            number: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(values);
            if (!values || values.number.trim().length === 0) {
                return;
            }
            setIsSaving(true);
            setErrorMsg(null);
            setShowSuccessAlert(false);
    
            const data = {
                id: route.id,
                type: route.type,
                number: values.number
            }
            saveOrUpdateRouteNumber(data).then(resp => { 
                setIsSaving(false);
                setErrorMsg(null);
                setShowSuccessAlert(true);
                const respData = resp.data.data;
                if (respData) {
                    const newRoute = {
                        ...route,
                        number: values,
                        id: respData.route_id, // set back id to update for the same in next section
                    }
                    onSubmit(newRoute);
                }
            }).catch(err => {
                console.log(err);
                setIsSaving(false);
                setErrorMsg(err);
                setShowSuccessAlert(false);
            });
        }   
    });

    // Delete number from route
    const deleteNumber = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
    }
    // On Delete of Route bus
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));
        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsSaving(true);
        deleteRouteNumber(data.id).then(resp => {
            setIsSaving(false);
            onSubmit(route);
        }).catch(err => {
            console.log(`Error in deleting number of route :: ${JSON.stringify(err)}`);
            setIsSaving(false);          
        })
    }

    return (
        <>
            {errorMsg &&
                <ErrorAlertTopCenter
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)}
                />
            }
            {showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Route number saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)}
                />
            }

            
            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    name="number"
                    label="Route number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    error={formik.touched.number && Boolean(formik.errors.number)}
                    helperText={formik.touched.number && formik.errors.number}
                />

                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center'
                    }}
                >
                    {isSaving &&
                        <CircularProgress />
                    }
                    {!isSaving &&
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ my: 2 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mx: 2 }}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{ mx: 2 }}
                                    startIcon={<ArrowBackIosNewIcon />}
                                    onClick={onBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{ mx: 2 }}
                                    startIcon={<ArrowForwardIosIcon />}
                                    onClick={onNext}
                                >
                                    Next
                                </Button>
                            </Box>
                            { route.number &&
                                <Box sx={{ my: 1 }}>
                                    <Button 
                                        size="small" 
                                        color="error"
                                        onClick={() => deleteNumber(route.id)}>
                                        Remove Number
                                    </Button>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
            </Box>
            {/* For delete confirmation */}
            {deleteJson.show &&
                <CustomModal 
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        onDelete(deleteJson.data);
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                    onHide={() => {
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                >
                </CustomModal>
            }
        </>
    );

}

export default AddRouteNumber;