import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom'

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';

import NavigationScroll from './navigation-scroll'

import logo from './logo.svg';
import './App.css';

import AppRoute from './routes/index';



// defaultTheme
import themes from './themes';

function App() {
  const customization = useSelector((state) => state.customization);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <AppRoute />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}






// used in v5
// function PrivateRoute({ component: Component, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         isAuthenticated() ? (
//           <Component {...props} />
//         ) : (
//           <Navigate
//             to={{
//               pathname: "/login",
//               state: { from: props.location }
//             }}
//           />
//         )
//       }
//     />
//   )
// }


// used in v5
// function PublicRoute({ component: Component, ...rest }) {

//   const authenticated = isAuthenticated();
//   console.log(`isAuthenticated - ${authenticated}`);
//   const user = getUser();

//   let url = '/school/list';
//   if (authenticated) {
//     console.log(`auth user - ${JSON.stringify(user)}`);
//     // Check if user is parent/admin - then Navigate to accordingly
//     const roleOwner = user.roles.find(item => item.name === Role.SCHOOL_OWNER);
//     console.log(`User Owner role - ${JSON.stringify(roleOwner)}`);
//     const roleAdmin = user.roles.find(item => item.name === Role.ADMIN);
//     console.log(`User Admin role - ${JSON.stringify(roleAdmin)}`);
//     if (roleOwner) {
//       url = '/school/list';
//     } else if (roleAdmin) {
//       url = `/school/${user.school.id}/admin/trip/today/list`;
//     }    
//   }


//   return (
//     <Route
//       {...rest}
//       render={props =>
//         !authenticated ? (
//           <Component {...props} />
//         ) : (
//           <Navigate
//             to={{
//               pathname: url,
//               state: { from: props.location }
//             }}
//           />
//         )
//       }
//     />
//   )
// }

export default App;
