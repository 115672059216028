import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box, Typography } from '@mui/material';

import { DEFAULT_MAP_LOCATION, IMAGES_URL, GOOGLE_MAP_API_KEY, convertMetersToKMsText, convertSecsToHms } from '../../utils/constants';
import { createMapOptions, drawRoute, getDistanceAndDuration } from '../../utils/google-map-helper';

const StudentLocationMap = ({ student, school }) => {
    const [zoom, setZoom] = useState(14);
    const [studentLocation, setStudentLocation] = useState({lat: null, lng: null});
    const [schoolLocation, setSchoolLocation] = useState(null);
    const [schoolDistanceTime, setSchoolDistanceTime] = useState(null);

    useEffect(() => {
        if (student?.location?.latitude && student?.location?.longitude) {
            setStudentLocation({
                lat: student?.location?.latitude,
                lng: student?.location?.longitude
            })
        }
        if (school) {
            setSchoolLocation({
                lat: school.latitude,
                lng: school.longitude
            })
        }
    }, [student, school]);

    const onMapReady = (map, maps) => {
        console.log("studentLocation", studentLocation);
        let bounds = new maps.LatLngBounds();
        // Student Marker
        const studentMarker = new maps.Marker({
            draggable: false,
            position: studentLocation,
            map: map,
            title: `${student.location.address}`,
            //icon: "http://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_purple.png"
        });
        bounds.extend(studentLocation);

        if (schoolLocation) {
            const schoolMarker = new maps.Marker({
                draggable: false,
                position: schoolLocation,
                map: map,
                title: `${school.address}`
            });
            bounds.extend(schoolLocation);

            // Draw route between student and school
            const latLngs = [studentLocation, schoolLocation];
            drawRoute(map, maps, latLngs);

            // Get distance from school
            const origins = [new maps.LatLng(schoolLocation.lat, schoolLocation.lng)];
            const destinations = [new maps.LatLng(studentLocation.lat, studentLocation.lng)];

            getDistanceAndDuration(maps, origins, destinations)
            .then(resp => {
                console.log("studentschooldistance", resp);
                if (resp) {
                    setSchoolDistanceTime(`${convertMetersToKMsText(resp.distance)} (${convertSecsToHms(resp.duration)})`)
                }
            });
            
        }
        // Fit/Zoom all the markers
        map.fitBounds(bounds);
    };
    
    return (
        <>
            <Box sx={{ height: "500px", mt: 1 }}>
                <Typography component="h5" variant="h5" sx={{ mb: 1 }}>
                    {student.location?.address}
                    {schoolDistanceTime}
                </Typography>
                <Typography component="h6" variant="h6" sx={{ mb: 1 }}>
                    {schoolDistanceTime}
                </Typography>
                { studentLocation.lat && studentLocation.lng &&
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'], }}
                        defaultCenter={studentLocation}
                        defaultZoom={zoom}
                        options={createMapOptions}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => onMapReady(map, maps)}
                    ></GoogleMapReact>
                }
            </Box>
        </>
    )
}

export default StudentLocationMap;