import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import GoogleMapCustom from './google-map-custom';

const RoutableMap = forwardRef(({ 
    locations, 
    polylineColor = '#5257ff',
    zoom = 8,
    originIcon,
    otherIcon,
    onMapReady,
}, ref) => {
    const [routeKey, setRouteKey] = useState(0); // State to force re-render
    const directionsService = useRef(null);
    const directionsRenderer = useRef(null);
    const mapRef = useRef(null);
    const markersRef = useRef([]);
    const infoWindowRef = useRef(null);

    const handleMapReady = (map, maps) => {
        if (onMapReady) {
            onMapReady(map, maps);
        }
        mapRef.current = map;

        if (!infoWindowRef.current) {
            infoWindowRef.current = new maps.InfoWindow();
        }

        if (!locations || locations.length < 2) {
            console.warn('Insufficient locations to draw a route.');
            return;
        }

        directionsService.current = new maps.DirectionsService();
        directionsRenderer.current = new maps.DirectionsRenderer({
            map,
            suppressMarkers: true, // Suppress default markers
        });

        drawRoute();
        addMarkers();
    };

    const drawRoute = () => {
        const waypoints = locations.slice(1, -1).map(location => ({
            location: new window.google.maps.LatLng(location.latitude, location.longitude),
            stopover: true,
        }));

        const origin = new window.google.maps.LatLng(locations[0].latitude, locations[0].longitude);
        const destination = new window.google.maps.LatLng(locations[locations.length - 1].latitude, locations[locations.length - 1].longitude);

        const request = {
            origin,
            destination,
            waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
        };

        directionsService.current.route(request, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                // Clear existing routes
                if (directionsRenderer.current) {
                    directionsRenderer.current.setMap(null);
                }
                
                // Create a new DirectionsRenderer with custom polyline
                const renderer = new window.google.maps.DirectionsRenderer({
                    map: mapRef.current,
                    polylineOptions: {
                        strokeColor: polylineColor,
                        strokeOpacity: 1.0,
                        strokeWeight: 3,
                    },
                    suppressMarkers: true, // Suppress default markers
                });

                renderer.setDirections(result);
            } else {
                console.error(`Error fetching directions ${result}`);
            }
        });
    };

    const addMarkers = () => {
        if (markersRef.current.length > 0) {
            // Clear existing markers
            markersRef.current.forEach(marker => marker.setMap(null));
            markersRef.current = [];
        }

        if (locations.length > 0) {
            // Add origin marker with custom icon
            const originLocation = new window.google.maps.LatLng(locations[0].latitude, locations[0].longitude);
            const originMarker = new window.google.maps.Marker({
                position: originLocation,
                map: mapRef.current,
                icon: {
                    url: originIcon,
                    scaledSize: new window.google.maps.Size(40, 40), // Customize size here
                },
                title: 'School',
            });
            markersRef.current.push(originMarker);
            // Add InfoWindow for the origin marker
            originMarker.addListener('click', () => {
                infoWindowRef.current.setContent(locations[0].location);
                infoWindowRef.current.open(mapRef.current, originMarker);
            });
        }

        // Add other markers if needed
        locations.slice(1).forEach(location => {
            const markerLocation = new window.google.maps.LatLng(location.latitude, location.longitude);
            const marker = new window.google.maps.Marker({
                position: markerLocation,
                map: mapRef.current,
                icon: {
                    url: otherIcon,
                    scaledSize: new window.google.maps.Size(30, 30), // Customize size here
                },
                title: location.location, // Assuming `location` has a `location` property for the title
            });
            markersRef.current.push(marker);
            // Add InfoWindow for each marker
            marker.addListener('click', () => {
                infoWindowRef.current.setContent(location.location);
                infoWindowRef.current.open(mapRef.current, marker);
            });
        });
    };

    

    // ************ External Calls ******************
    useImperativeHandle(ref, () => ({
        showInfoWindow,
    }));

    // Handler to show InfoWindow for a specific marker by latitude and longitude
    const showInfoWindow = (location) => {
        const { latitude, longitude } = location;
        const marker = markersRef.current.find(marker =>
            marker.getPosition().lat() === latitude && marker.getPosition().lng() === longitude
        );
        if (marker) {
            const location = locations.find(loc => loc.latitude === latitude && loc.longitude === longitude);
            if (location) {
                infoWindowRef.current.setContent(location.location);
                infoWindowRef.current.open(mapRef.current, marker);
            }
        } else {
            console.warn('Marker not found for the given latitude and longitude');
        }
    };

    // ************ End // External Calls ******************



    useEffect(() => {
        if (directionsService.current && directionsRenderer.current && locations && locations.length > 1) {
            drawRoute();
        }
    }, [locations]);

    useEffect(() => {
        setRouteKey(prevKey => prevKey + 1); // Force re-render by changing state
    }, [locations]);

    return (
        <GoogleMapCustom
            key={routeKey} // Ensure the component re-renders when the key changes
            onMapReady={handleMapReady}
            center={locations && locations.length > 0 ? { latitude: locations[0].latitude, longitude: locations[0].longitude } : { latitude: 0, longitude: 0 }}
            zoom={8}
        />
    );
});

export default RoutableMap;
