import React, { Component, useState, useEffect, useRef } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton, TableFooter, TablePagination, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getStudents, deleteStudent } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { VertActionMenu } from '../../../../common/action-menu';
import { ModalType } from '../../../../utils/constants';
import Loader from '../../../../common/loader';
import CustomPaginationActions from '../../../../utils/pagination';
import StudentDetailDialog from '../../../../common/student-detail-dialog';


const StudentList = () =>  {

    const [students, setStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });
    let [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [isStudentDetailDialogOpen, setIsStudentDetailDialogOpen] = useState(false);

    const didMount = useRef(true);

    let studentSearchKey = null;
    
    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();


    useEffect(() => {
        console.log('fetch students');
        fetchStudents();
    }, []);

    // Fetch students
    const fetchStudents = () => {
        setIsLoading(true);
        const filter = {
            'searchKey': studentSearchKey
        }
        console.log(`new page ${page} --- row per page - ${rowsPerPage}`);
        getStudents(filter, page, rowsPerPage).then(resp => {
            const respData = resp.data.data;
            setStudents(respData.students);
            setTotalCount(respData.total_count);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        // Show spinner
        setIsLoading(true);
        deleteStudent(data.id).then(resp => {
            setIsLoading(false);
            fetchStudents();
        }).catch(err => {
            setIsLoading(false);        
        })
    }

     // on search stduents
     const onStudentSearch = (event) => {
         const val = event.target.value;
        console.log(`on student search ${val}`);
        studentSearchKey = val;
        setPage(0); 
        // page is not changed then it doesnt trigger fetch students in useEffect so call it here
        if (page === 0) {
            fetchStudents();
        }
    }

    // ****************************** Pagination ******************************

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        // avoid first render
        if (didMount.current) {
            didMount.current = false;
            return;
        }
        fetchStudents();
    }, [page, rowsPerPage]);


    // const onFirst = () => {
    //     console.log(`onFirst`);
    //     page = 0;
    //     setPage(page);
    //     fetchStudents();  
    // }

    // const onPrevious = () => {
    //     console.log(`onPrevious`);
    //     if (page > 0) {
    //         page -= 1;
    //         setPage(page);
    //         fetchStudents();
    //     }
    // }

    // const onNext = () => {
    //     console.log(`onNext`);
    //     console.log(`cal count - ${(page * limit)}`)
    //     // check if records on next page is less than the total record count i.e (page + 1)
    //     if (((page + 1) * limit) < totalCount) {
    //         page += 1;
    //         setPage(page);
    //         fetchStudents();
    //     }
    // }

    // const onLast = () => {
    //     console.log(`onLast`);
    //     // Calculate last page number
    //     // const { page, totalCount, limit } = this.state;
    //     const newPage = Math.floor(totalCount/limit);
    //     console.log(`new page - ${newPage}`);  
    //     page = newPage;
    //     setPage(page);   
    //     fetchStudents();  
    // }

    // ************************** End Pagination ******************************
    

    return (
        <>
             <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Students
                    </Typography>
                    <Chip
                        onClick={() => navigate(`../new`)}
                        icon={<AddCircleOutlineIcon />}
                        label="Add student"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TextField 
                            id="outlined-search" 
                            label="Search students" 
                            type="search" 
                            sx={{ mb: 3, width: '20rem' }}
                            onChange={onStudentSearch}
                        />
                        { isLoading &&
                            <Box sx={{ my: 2 }}><Loader /></Box>
                        }
                        <TableContainer component={Paper}>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell sx={{ width: '40%' }}>Name</TableCell>
                                        <TableCell sx={{ width: '20%' }}>Roll no.</TableCell>
                                        <TableCell sx={{ width: '10%' }}>Class</TableCell>
                                        <TableCell sx={{ width: '10%' }}>Section</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {students.map((item, index) => (
                                        <TableRow
                                            key={item.rollNo}
                                        >
                                            <TableCell align="left">{page * rowsPerPage + (index + 1)}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.roll_no}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.sclass ? item.sclass.name : ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.section ? item.section.name : ''}
                                            </TableCell>
                                            <TableCell align="right">
                                                {/* <Link to={`${baseUrl + "/student/" + item.id + "/detail-view"}`} target="_blank">
                                                    <IconButton aria-label="preview" color="primary">
                                                        <PreviewIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Link>

                                                <IconButton aria-label="edit" color="secondary"
                                                    onClick={() => navigate(`${baseUrl + "/student/edit/" + item.id}`)}
                                                >
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                                <IconButton aria-label="delete" color="error"
                                                    onClick={() => handleDelete(item.id)}
                                                >
                                                    <DeleteIcon fontSize="inherit" />
                                                </IconButton> */}

                                                <VertActionMenu 
                                                    onView={() => {
                                                        setSelectedStudentId(item.id);
                                                        setIsStudentDetailDialogOpen(true);
                                                    }}
                                                    onEdit={() => navigate(`${baseUrl + "/student/edit/" + item.id}`)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />

                                               


                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={3}
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={CustomPaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            {deleteJson.show &&
                <CustomModal 
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => {
                        onDelete(deleteJson.data);
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                    onHide={() => {
                        setDeleteJson({
                            ...deleteJson,
                            show: false,
                        });
                    }}
                >
                </CustomModal>
            }

            <StudentDetailDialog 
                open={isStudentDetailDialogOpen} 
                onClose={() => setIsStudentDetailDialogOpen(false)}
                studentId={selectedStudentId}
            />

        </>
    );

    // return (
    //     <React.Fragment> 
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <div className="h5 mb-0 text-gray-800">Student List</div>
    //             <SearchBox onChangeText={onStudentSearch} />
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 {isLoading &&                   
    //                     <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
    //                 }
    //                 <table className="table" striped bordered hover>
    //                     <thead>
    //                             <tr className="row">
    //                                 <th className="col-lg-1">#</th>
    //                                 <th className="col-lg-3">Name</th>
    //                                 <th className="col-lg-2">Roll Number</th>
    //                                 <th className="col-lg-2">Section</th>
    //                                 <th className="col-lg-2">Class</th>
    //                                 <th className="col-lg-2">Action</th>
    //                             </tr>
    //                         </thead>
    //                         <tbody>
    //                             {students.map((item, index) => (
    //                                 <tr key={index} className="row">
    //                                     <td className="col-lg-1">{(page * limit) + index + 1}</td>
    //                                     <td className="col-lg-3">{item.user.name}</td>
    //                                     <td className="col-lg-2">{item.roll_no ? item.roll_no:''}</td>
    //                                     <td className="col-lg-2">{item.section ? item.section.name:''}</td>
    //                                     <td className="col-lg-2">{item.sclass ? item.sclass.name:''}</td>
    //                                     <td className="col-lg-2">
    //                                         <Link to={baseUrl + "/student/edit/" + item.id}>
    //                                             <i className="fas fa-edit fa-lg text-primary mr-5"></i>
    //                                         </Link>
    //                                         <Button variant="link" onClick={() => handleDelete(item.id)}>
    //                                             <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                                         </Button>
    //                                     </td>
    //                                 </tr>
    //                             ))}
    //                     </tbody>
    //                 </table>
    //                 {/* Custom Pagination rendering */}
    //                 <div className="mt-3">
    //                     <CustomPagination 
    //                         currentPage={page+1}
    //                         totalCount={totalCount}
    //                         limit={limit}
    //                         onFirst={onFirst}
    //                         onPrevious={onPrevious}
    //                         onNext={onNext}
    //                         onLast={onLast}
    //                     />
    //                 </div>
    //             </div>
    //         </div>                 
    //         {deleteJson.show &&
    //             <CustomModal 
    //                 show={deleteJson.show}
    //                 data={deleteJson.data}
    //                 type={ModalType.CONFIRM_DELETE}
    //                 onOkay={(data) => {
    //                     onDelete(deleteJson.data);
    //                     setDeleteJson({
    //                         ...deleteJson,
    //                         show: false,
    //                     });
    //                 }}
    //                 onHide={() => {
    //                     setDeleteJson({
    //                         ...deleteJson,
    //                         show: false,
    //                     });
    //                 }}
    //             >
    //             </CustomModal>
    //         }
    //     </React.Fragment> 
    // );
}

export default StudentList;





// class StudentList extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             students: [],
//             isLoading: false,
//             delete: {
//                 show: false,
//                 data: {}
//             }
// 		};
//     }

//     componentDidMount() {
//         this.fetchStudents();
//     }

//     // Fetch students
//     fetchStudents = () => {
//         this.setState({isLoading: true});
//         getStudents().then(resp => {
//             this.setState({students: resp.data.data});
//             this.setState({isLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isLoading: false});
//         })
//     }

//     // Handle Delete modal   
//     handleDelete = (id) => {
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));
//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of School
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));
//         // Show spinner
//         this.setState({isLoading: true});
//         deleteStudent(data.id).then(resp => {
//             this.setState({isLoading: false});
//             this.fetchStudents();
//         }).catch(err => {
//             this.setState({isLoading: false});          
//         })
//     }
    
//     render() {

//         const { students, isLoading } = this.state;

// 		return (
//             <React.Fragment> 
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Student List</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         {isLoading &&                   
//                             <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
//                         }
//                         <table className="table" striped bordered hover>
//                             <thead>
//                                     <tr className="row">
//                                         <th className="col-lg-1">#</th>
//                                         <th className="col-lg-3">Name</th>
//                                         <th className="col-lg-2">Roll Number</th>
//                                         <th className="col-lg-2">Section</th>
//                                         <th className="col-lg-2">Class</th>
//                                         <th className="col-lg-2">Action</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {students.map((item, index) => (
//                                         <tr key={index} className="row">
//                                             <td className="col-lg-1">{index + 1}</td>
//                                             <td className="col-lg-3">{item.user.name}</td>
//                                             <td className="col-lg-2">{item.roll_no ? item.roll_no:''}</td>
//                                             <td className="col-lg-2">{item.section ? item.section.name:''}</td>
//                                             <td className="col-lg-2">{item.sclass ? item.sclass.name:''}</td>
//                                             <td className="col-lg-2">
//                                                 <Link to={this.props.baseUrl + "/student/edit/" + item.id}>
//                                                     <i className="fas fa-edit fa-lg text-primary mr-5"></i>
//                                                 </Link>
//                                                 <Button variant="link" onClick={() => this.handleDelete(item.id)}>
//                                                     <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                                 </Button>
//                                             </td>
//                                         </tr>
//                                     ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>                 
//                 {this.state.delete.show &&
//                     <CustomModal 
//                         show={this.state.delete.show}
//                         data={this.state.delete.data}
//                         type={ModalType.CONFIRM_DELETE}
//                         onOkay={(data) => {
//                             this.onDelete(this.state.delete.data);
//                             this.setState(ps => ({
//                                 delete: {
//                                     ...ps.delete,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                         onHide={() => {
//                             this.setState(ps => ({
//                                 delete: {
//                                     ...ps.delete,
//                                     show: false,
//                                 }
//                             }));
//                         }}
//                     >
//                     </CustomModal>
//                 }
//             </React.Fragment> 
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {
        
//     }
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(StudentList);