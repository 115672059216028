import { combineReducers } from 'redux'
import modals from './modals';
import extras from './extras'
import leftNav from './left-nav';
import school from './school';
import customization from './customization';


export default combineReducers({
    modals: modals,
    extras: extras,
    leftNav: leftNav,
    school: school,
    customization: customization,
})