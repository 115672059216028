import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { getBuses, deleteBus } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import Loader from '../../../../common/loader';
import { ModalType } from '../../../../utils/constants';
import { VertActionMenu } from '../../../../common/action-menu';


const BusList = () => {

    const [buses, setBuses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBuses();
    }, []);

    // Fetch buses
    const fetchBuses = () => {
        setIsLoading(true);
        getBuses().then(resp => {
            setBuses(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });
        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of Bus
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false
        });
        // Show spinner
        setIsLoading(true);
        deleteBus(data.id).then(resp => {
            setIsLoading(false);
            fetchBuses();
        }).catch(err => {
            setIsLoading(false);         
        })
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Buses
                    </Typography>
                    <Chip
                        onClick={() => navigate(`../new`)}
                        icon={<AddCircleOutlineIcon />}
                        label="New bus"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>
                { isLoading &&
                    <Loader />
                }

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Number</TableCell>
                                        <TableCell>Manufacturer</TableCell>
                                        <TableCell>Model</TableCell>
                                        <TableCell>Brand</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {buses.map((item, index) => (
                                        <TableRow
                                            key={item.id}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.number}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.manufacturer}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.model}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.brand}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onEdit={() => navigate(`${baseUrl + "/bus/edit/" + item.id}`)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {/* Delete modal */}
                <CustomModal
                    show={deleteJson.show}
                    data={deleteJson.data}
                    type={ModalType.CONFIRM_DELETE}
                    onOkay={(data) => { onDelete(data) }}
                    onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
                >
                </CustomModal>
            </Box>
        </>
    );
 

    // return (
    //     <React.Fragment> 
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <div className="h5 mb-0 text-gray-800">Bus List</div>
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 {isLoading &&                   
    //                     <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
    //                 }
    //                 <table className="table">
    //                     <thead>
    //                         <tr className="row">
    //                             <th className="col-lg-1">#</th>
    //                             <th className="col-lg-2">Number</th>
    //                             <th className="col-lg-2">Model</th>
    //                             <th className="col-lg-2">Manufacturer</th>
    //                             <th className="col-lg-2">Brand</th>
    //                             <th className="col-lg-2">Action</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {buses.map((item, index) => (
    //                             <tr key={index} className="row">
    //                                 <td className="col-lg-1">{index + 1}</td>
    //                                 <td className="col-lg-2">{item.number}</td>
    //                                 <td className="col-lg-2">{item.model}</td>
    //                                 <td className="col-lg-2">{item.manufacturer}</td>
    //                                 <td className="col-lg-2">{item.brand}</td>
    //                                 <td className="col-lg-3">
    //                                     <Link to={baseUrl + "/bus/edit/" + item.id}>
    //                                         <i className="fas fa-edit fa-lg text-primary mr-5"></i>
    //                                     </Link>
    //                                     <Button variant="link" onClick={() => handleDelete(item.id)}>
    //                                         <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                                     </Button>
    //                                 </td>
    //                             </tr>
    //                         ))}
    //                     </tbody>
    //                 </table>
    //             </div>
    //         </div>
                
    //         <CustomModal 
    //             show={deleteJson.show}
    //             data={deleteJson.data}
    //             onOkay={(data) => {onDelete(data)}}>
    //         </CustomModal>
    //     </React.Fragment> 
    // );
}

export default BusList;

// class BusList extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             buses: [],
//             isLoading: false,
//             delete: {
//                 show: false,
//                 data: {}
//             }
// 		};
//     }

//     componentDidMount() {
//         this.fetchBuses();
//     }

//     // Fetch buses
//     fetchBuses = () => {
//         this.setState({isLoading: true});
//         getBuses().then(resp => {
//             this.setState({buses: resp.data.data});
//             this.setState({isLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isLoading: false});
//         })
//     }

//     // Handle Delete modal   
//     handleDelete = (id) => {
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));
//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of Bus
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));
//         // Show spinner
//         this.setState({isLoading: true});
//         deleteBus(data.id).then(resp => {
//             this.setState({isLoading: false});
//             this.fetchBuses();
//         }).catch(err => {
//             this.setState({isLoading: false});          
//         })
//     }
    
//     render() {

//         const { buses, isLoading } = this.state;

// 		return (
//             <React.Fragment> 
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Bus List</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         {isLoading &&                   
//                             <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
//                         }
//                         <table className="table">
//                             <thead>
//                                 <tr className="row">
//                                     <th className="col-lg-1">#</th>
//                                     <th className="col-lg-2">Number</th>
//                                     <th className="col-lg-2">Model</th>
//                                     <th className="col-lg-2">Manufacturer</th>
//                                     <th className="col-lg-2">Brand</th>
//                                     <th className="col-lg-2">Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {buses.map((item, index) => (
//                                     <tr key={index} className="row">
//                                         <td className="col-lg-1">{index + 1}</td>
//                                         <td className="col-lg-2">{item.number}</td>
//                                         <td className="col-lg-2">{item.model}</td>
//                                         <td className="col-lg-2">{item.manufacturer}</td>
//                                         <td className="col-lg-2">{item.brand}</td>
//                                         <td className="col-lg-3">
//                                             <Link to={this.props.baseUrl + "/bus/edit/" + item.id}>
//                                                 <i className="fas fa-edit fa-lg text-primary mr-5"></i>
//                                             </Link>
//                                             <Button variant="link" onClick={() => this.handleDelete(item.id)}>
//                                                 <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                             </Button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
                 
//                  <CustomModal 
//                     show={this.state.delete.show}
//                     data={this.state.delete.data}
//                     onOkay={(data) => {this.onDelete(data)}}>
//                 </CustomModal>
//             </React.Fragment> 
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {}
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(BusList);