import React, { Component } from 'react';
import { Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { login, getAccessToken, logout } from './services/auth.service';

import './styles/login.css';

const SchoolAccessDenied = () => {

    const navigate = useNavigate();

    const onLogout = () => {
        console.log('loggin out..');
        logout().then(resp => {
            navigate('/login');
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <div class="card shadow-lg border-0 rounded-lg mt-5">
                        <div class="card-header"><h3 class="text-center font-weight-light my-4">Access Denied</h3></div>
                        <div class="card-body">
                            <h6>
                                <p>You cannot access your school as your school is not activated yet.</p>
                                <a href="/school/list">Got to your school list</a>
                                <button class="btn btn-link" onClick={onLogout}>Logout</button>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SchoolAccessDenied;