import React, { Component, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell,
    Button, Chip, Paper, Grid, TableBody, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";


import { getSections, deleteSection } from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { ModalType } from '../../../../utils/constants';
import Loader from '../../../../common/loader';
import { VertActionMenu } from '../../../../common/action-menu';


const SectionList = () => {

    const [sections, setSections] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteJson, setDeleteJson] = useState({
        show: false,
        data: {}
    });

    const baseUrl = useSelector(state => state.extras.baseUrl);
    const navigate = useNavigate();

    useEffect(() => {
        fetchSections();
    }, []);

    // Fetch sections from server
    const fetchSections = () => {
        setIsLoading(true);
        getSections().then(resp => {
            setSections(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Handle Delete modal   
    const handleDelete = (id) => {
        setDeleteJson({
            ...deleteJson,
            show: true,
            data: {
                id: id
            }
        });

        console.log(`delelte - ${JSON.stringify(deleteJson)}`);
    }

    // On Delete of School
    const onDelete = (data) => {
        console.log('on delete data ' + JSON.stringify(data));

        // Hide delete confirm modal
        setDeleteJson({
            ...deleteJson,
            show: false,
        });
        
        // Show spinner
        setIsLoading(true);
        deleteSection(data.id).then(resp => {
            setIsLoading(false);
            fetchSections();
        }).catch(err => {
            setIsLoading(false);          
        })
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    alignItems: "start",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        Sections
                    </Typography>
                    <Chip
                        onClick={() => navigate(`../new`)}
                        icon={<AddCircleOutlineIcon />}
                        label="New section"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                { isLoading &&
                    <Loader />
                }

                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item xs={12} md={8}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '.8rem' }}>#</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sections.map((item, index) => (
                                        <TableRow
                                            key={item.name}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {item.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <VertActionMenu
                                                    onEdit={() => navigate(`${baseUrl + "/section/edit/" + item.id}`)}
                                                    onDelete={() => handleDelete(item.id)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            {/* Delete modal */}
            <CustomModal
                show={deleteJson.show}
                data={deleteJson.data}
                type={ModalType.CONFIRM_DELETE}
                onOkay={(data) => { onDelete(data) }}
                onHide={ () => {setDeleteJson({...deleteJson, show: false})} }
            >
            </CustomModal>
        </>
    );

    // return (
    //     <React.Fragment> 
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <h1 className="h4 mb-0 text-gray-800">Section List</h1>
    //         </div>
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 {isLoading &&                   
    //                     <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
    //                 }
    //                 <table className="table">
    //                     <thead>
    //                         <tr className="row">
    //                             <th className="col-lg-1">#</th>
    //                             <th className="col-lg-9">Name</th>
    //                             <th className="col-lg-2">Action</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                     {sections.map((item, index) => (
    //                         <tr key={index} className="row">
    //                             <td className="col-lg-1">{index + 1}</td>
    //                             <td className="col-lg-9">{item.name}</td>
    //                             <td className="col-lg-2">
    //                                 <Link to={baseUrl + "/section/edit/" + item.id}>
    //                                     <i className="fas fa-edit fa-lg text-primary mr-5"></i>
    //                                 </Link>
    //                                 <Button variant="link" onClick={() => handleDelete(item.id)}>
    //                                     <i className="fas fa-trash-alt fa-lg text-danger"></i>
    //                                 </Button>
    //                             </td>
    //                         </tr>
    //                     ))}
    //                     </tbody>
    //                 </table>
    //             </div>
    //         </div>
                
    //             {/* Delete modal */}
    //         <CustomModal 
    //             show={deleteJson.show}
    //             data={deleteJson.data}
    //             onOkay={(data) => {onDelete(data)}}>
    //         </CustomModal>
    //     </React.Fragment> 
    // );
}

export default SectionList;



// class SectionList extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             sections: [],
//             isLoading: false,
//             delete: {
//                 show: false,
//                 data: {}
//             }
// 		};
//     }

//     componentDidMount() {
//         this.fetchSections();
//     }

//     // Fetch sections from server
//     fetchSections = () => {
//         this.setState({isLoading: true});
//         getSections().then(resp => {
//             this.setState({sections: resp.data.data});
//             this.setState({isLoading: false});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isLoading: false});
//         })
//     }

//     // Handle Delete modal   
//     handleDelete = (id) => {
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: true,
//                 data: {
//                     id: id
//                 }
//             }
//         }));

//         console.log(`delelte - ${JSON.stringify(this.state.delete)}`);
//     }

//     // On Delete of School
//     onDelete = (data) => {
//         console.log('on delete data ' + JSON.stringify(data));

//         // Hide delete confirm modal
//         this.setState(() => ({
//             delete: {
//                 ...this.state.delete,
//                 show: false
//             }
//         }));
//         // Show spinner
//         this.setState({isLoading: true});
//         deleteSection(data.id).then(resp => {
//             this.setState({isLoading: false});
//             this.fetchSections();
//         }).catch(err => {
//             this.setState({isLoading: false});          
//         })
//     }
    
//     render() {

//         const { sections, isLoading } = this.state;

// 		return (
//             <React.Fragment> 
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">Section List</h1>
//                 </div>
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         {isLoading &&                   
//                             <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
//                         }
//                         <table className="table">
//                             <thead>
//                                 <tr className="row">
//                                     <th className="col-lg-1">#</th>
//                                     <th className="col-lg-9">Name</th>
//                                     <th className="col-lg-2">Action</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                             {sections.map((item, index) => (
//                                 <tr key={index} className="row">
//                                     <td className="col-lg-1">{index + 1}</td>
//                                     <td className="col-lg-9">{item.name}</td>
//                                     <td className="col-lg-2">
//                                         <Link to={this.props.baseUrl + "/section/edit/" + item.id}>
//                                             <i className="fas fa-edit fa-lg text-primary mr-5"></i>
//                                         </Link>
//                                         <Button variant="link" onClick={() => this.handleDelete(item.id)}>
//                                             <i className="fas fa-trash-alt fa-lg text-danger"></i>
//                                         </Button>
//                                     </td>
//                                 </tr>
//                             ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
                 
//                  {/* Delete modal */}
//                 <CustomModal 
//                     show={this.state.delete.show}
//                     data={this.state.delete.data}
//                     onOkay={(data) => {this.onDelete(data)}}>
//                 </CustomModal>
//             </React.Fragment> 
//         )
//     }
// }

// const mapDispatchToProps = dispatch => { 
//     return {
        
//     }
// }

// const mapStateToProps = state => {
//     return {
//         schoolId: state.extras.schoolId,
//         baseUrl: state.extras.baseUrl
//     }
// }
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(SectionList);