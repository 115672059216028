import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Box, AppBar, CssBaseline, Toolbar, useMediaQuery, Avatar, ButtonBase } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { IconChevronRight, IconMenu2 } from '@tabler/icons';


import { leftNav as leftNavAction } from '../actions';
import { LeftNavViews, Role, drawerWidth } from '../utils/constants';
import { getUser } from '../services/auth.service';
import Header from './header';
import LeftNav from './left-nav';
import { SET_MENU } from '../actions/action-types';

// ======= Template used > https://mui.com/store/items/berry-react-material-admin-free/



// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

let School = () =>  {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);
 
     

    axios.interceptors.response.use(
        response => response,
        err => {
            //console.log(`Access denied - ${err} - status - ${err.response.status}`);
            if (err.response && err.response.status === 403) {
                navigate('/school/access-denied');
                return Promise.reject(err.response.data.message);
            }
            else {
                return Promise.reject(err);
            }
        });
    
    // Send default role SCHOOL_OWNER after owner loggedin - this is to show left nav menus according to roles
    console.log(`User in the Auth - ${JSON.stringify(getUser())}`);
    
    const user = getUser();
    dispatch(leftNavAction({
        view: LeftNavViews.SCHOOL,
        userRoles: user.roles.map(role => role.name),
        // baseUrl: match.url, check later to pass it
        user: user,
    }));

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle}></Header>
                </Toolbar>
            </AppBar>
            {/* drawer */}
            <LeftNav drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                <Outlet />
            </Main>
        </Box>
    );
};
  
export default School;

