import { Outlet } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EditIcon from '@mui/icons-material/Edit';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LockResetIcon from '@mui/icons-material/LockReset';

import AuthLayout from '../index';

const SettingsLayout = () =>  {

    const settings = {
        id: 'settings',
        title: 'Settings',
        type: 'group',
        children: [
            {
                id: 'profile',
                title: 'Profile',
                type: 'collapse',
                icon: AccountBoxIcon,
                breadcrumbs: false,
                children: [
                    {
                        id: 'edit',
                        title: `Edit`,
                        type: 'item',
                        url: `profile/edit`,
                        icon: EditIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'photo',
                        title: `Photo`,
                        type: 'item',
                        url: `profile/photo`,
                        icon: InsertPhotoIcon,
                        breadcrumbs: false,
                    },
                    {
                        id: 'change-password',
                        title: `Change Password`,
                        type: 'item',
                        url: `profile/change-password`,
                        icon: LockResetIcon,
                        breadcrumbs: false,
                    },
                ]
            },
        ]
    };

    const menuItems = [settings];

    return (
        <AuthLayout menuItems={menuItems}>
            <Outlet />
        </AuthLayout>
    );
};
  
export default SettingsLayout;