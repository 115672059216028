import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Col, Form, Button, Spinner, ButtonToolbar ,Alert, Row } from 'react-bootstrap';
import { Role } from '../../../../utils/constants'

import { saveOrUpdateSchoolUser,
    fetchSchoolUser as fetchSchoolUserHttp, getDriverByUserId } from '../../../../services/http.service';

const UserDriverNew = () => {
    const [validated, setValidated] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [driver, setDriver] = useState({});


    const { userId } = useParams();
    const schoolId = useSelector(state => state.extras.schoolId);

    useEffect(() => {
        if (userId) {
            setIsUpdate(true);
            fetchDriverSchoolUser(userId);
        } 
    }, []);

      // Fetch driver school user from server
      const fetchDriverSchoolUser = (userId) => {
        setIsLoading(true);
        getDriverByUserId(userId).then(resp => {
            setIsLoading(false);
            const driver = resp.data.data;
            if (driver) {
                setDriver(driver);
                const user = {
                    id: driver.user.id,
                    name: driver.user.name,
                    email: driver.user.email,
                    mobile: driver.user.mobile,
                    isVerified: driver.user.is_verified,
                    password: '******'  // Default password
                }
                setUser(user);
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Handle Submit
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            return false;
        }   
        setIsSaving(true); 
        user.name = form[0].value;
        console.log(`user - ${JSON.stringify(user)}`);
        user.roleName = Role.DRIVER;
        user.schoolId = schoolId;
        setShowSuccessAlert(false);
        saveOrUpdateSchoolUser(user, isUpdate).then(resp => {
            setIsSaving(false); 
            setShowSuccessAlert(true);
            setErrorMsg(null);
            const respData = resp.data.data;
            setDriver({...driver, id: respData.driver_id});
            // Set back user id - to make available profile photo link and other
            if (!user.id) { // If its not Edit user
                setUser({
                    ...user,
                    id: resp.data.data.user_id,
                });
            }
        }).catch(err => {
            console.log(err);
            setIsSaving(false); 
            setShowSuccessAlert(false);
            setErrorMsg(err);
        })
    }

    return (
        <React.Fragment>
             <div className="d-flex align-items-center justify-content-between mb-4">
                <div className="h5 mb-0 text-gray-800">{isUpdate ? 'Update driver' : 'New driver'}</div>
                <Link type="button" class="btn btn-outline-info btn-sm ml-3" to="../list">Show drivers</Link>
            </div> 
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit} className="mt-5"> 

                {isUpdate &&
                    <div className="mb-3">                                    
                        <h5>
                            {user.isVerified &&
                                <span class="badge badge-success">Verified</span>
                            }
                            {!user.isVerified &&
                                <span class="badge badge-danger">Unverified</span>
                            }
                        </h5>
                    </div>
                }

                <Form.Group as={Row}>
                    <Form.Label column sm="2">Full Name</Form.Label>
                    <Col lg="6">
                        <Form.Control required type="text" placeholder="Enter full name"
                            value={user.name}
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    name: e.target.value,
                                });
                                // user.name = e.target.value;
                                // this.setState({user});
                            }}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="2">Email</Form.Label>
                    <Col sm="8">
                        <Form.Control type="email" placeholder="Enter email" 
                            value={user.email}
                            onChange={(e) => {  
                                setUser({
                                    ...user,
                                    email: e.target.value,
                                });                                                          
                                // user.email = e.target.value;
                                // this.setState({user});
                            }}
                        />
                    </Col>
                </Form.Group>                                        
                <Form.Group  as={Row}>
                    <Form.Label column sm="2">Mobile no</Form.Label>
                    <Col sm="4">
                        <Form.Control required type="number" placeholder="Enter mobile no." 
                            value={user.mobile}
                            onChange={(e) => {   
                                setUser({
                                    ...user,
                                    mobile: e.target.value,
                                });                                                         
                                // user.mobile = e.target.value;
                                // this.setState({user});
                            }}
                        />
                    </Col>
                </Form.Group>
                <Form.Group  as={Row}>
                    <Form.Label column sm="2">Password</Form.Label>
                    <Col sm="4">
                        <Form.Control required type="password" placeholder="Enter password"
                            value={user.password}
                            onChange={(e) => {  
                                setUser({
                                    ...user,
                                    password: e.target.value,
                                });                                                         
                                // user.password = e.target.value;
                                // this.setState({user});
                            }}
                        />
                    </Col>
                </Form.Group>

                <div className="d-flex align-items-center justify-content-center mt-5">
                    <Button className="btn-general" type="submit">Submit</Button>   
                    {isSaving &&                         
                        <Spinner animation="border" variant="primary" />
                    }
                    {showSuccessAlert &&
                        <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false) } 
                            dismissible>
                            Saved Successfully
                        </Alert>
                    }
                    {errorMsg &&
                        <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null) } 
                            dismissible>
                            {errorMsg}
                        </Alert>
                    }
                    {user && user.id &&
                        <React.Fragment>
                            <Link className="ml-5" to={`../../../driver/edit/${driver.id}`}>
                                Add detail
                            </Link>
                            <Link className="ml-5" to={`../${user.id}/profile-photo`}>
                                Profile Photo
                            </Link>
                            <Link className="ml-5" to="../otp-verification">
                                Verify
                            </Link>
                        </React.Fragment>
                    } 

                </div>  

            </Form>    
        </React.Fragment>
    );
}
export default UserDriverNew;