import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoogleMapReact from 'google-map-react';

import { DEFAULT_MAP_LOCATION, IMAGES_URL } from '../utils/constants';
import { getStudent, fetchSchool as fetchSchoolHttp } from '../services/http.service';
import Loader from './loader';
import StudentLocationMap from './googlemap/student-location-map';


const StudentDetailDialog = ({ open, onClose, studentId }) => {
    const [student, setStudent] = useState({
        id: null,
        name: '',
        rollNo: '',
        sclass: {},
        section: {},
        address: {
            address: '',
            city: '',
            state: '',
            country: '',
            postalCode: ''
        },
        pickup: {
            latitude: null,
            longitude: null,
            location: '',
            distanceAndDuration: null, // from school location
        },
        dropoff: {
            latitude: null,
            longitude: null,
            location: '',
            distanceAndDuration: null, // from school location
        }, 
        isDropoffSameAsPickup: false,               
    });
    const [isLoading, setIsLoading] = useState(false);
    const [profilePhotoUrl, setProfilePhotoUrl] = useState(null);
    
    const schoolId = useSelector(state => state.extras.schoolId);
    const school = useSelector(state => state.school);

    useEffect(() => {
        if (open) {
            fetchStudent(studentId);
        }
    }, [open]);

    useEffect(() => {
        const url = student.profilePhoto ? `${IMAGES_URL.STUDENT}/${student.profilePhoto}` : `${IMAGES_URL.STUDENT}/default_profile.png`
        setProfilePhotoUrl(url);
    }, [student]);

    // Fetch student detail for edit
    const fetchStudent = (studentId) => {
        setIsLoading(true);
        getStudent(studentId).then(resp => {
            console.log(`Fetch student - ${JSON.stringify(resp)}`);
            setIsLoading(false)
            setStudentDetail(resp.data.data);
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    // set student detail in the state from server
    const setStudentDetail = (respStudent) => {
        let pickup = student.pickup;
        let dropoff = student.dropoff;

        // Update pickup details
        if (respStudent.pickup && respStudent.pickup.id) {
            // do not use setStudent
            pickup = {
                id: respStudent.pickup.id,
                location: respStudent.pickup.location,
                latitude: respStudent.pickup.latitude,
                longitude: respStudent.pickup.longitude
            }
            console.log(pickup);
        }
        // Update dropoff details
        if (respStudent.dropoff && respStudent.dropoff.id) {
            // do not use state setters
            dropoff = {
                id: respStudent.dropoff.id,
                location: respStudent.dropoff.location,
                latitude: respStudent.dropoff.latitude,
                longitude: respStudent.dropoff.longitude
            }
        }
        setStudent((prevSchool) => ({
            ...prevSchool,
            id: respStudent.id,
            name: respStudent.name,
            rollNo: respStudent.roll_no,
            sclass: respStudent.sclass,
            section: respStudent.section,
            uniqueNumber: respStudent.unique_no,
            isDropoffSameAsPickup: respStudent.is_dropoff_same_as_pickup,
            profilePhoto: respStudent.profile_photo,
            pickup,
            dropoff,
        }));
        console.log(`Student in state from server - ${JSON.stringify(student)}`);
    }



    const handleClickOpen = () => {
       
    };

    const handleClose = () => {
        onClose(false);
    };

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            size="large"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                { isLoading &&
                    <Box sx={{ my: 2 }}><Loader /></Box>
                }
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mt: 1 }}
                >
                    <Grid item xs={12} md={4} >
                        <Card sx={{ maxWidth: 345, ml: 1 }}>
                            <CardMedia
                                component="img"
                                image={profilePhotoUrl}
                                alt={student?.name}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container rowSpacing={2}
                            sx={{ mt: 1, ml: 2 }}
                        >
                            <Grid item xs={12} md={3}>
                                <Typography component="h5" variant="h5">
                                    Name:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4">
                                    {student?.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={2}
                            sx={{ mt: 1, ml: 2 }}
                        >
                            <Grid item xs={12} md={3}>
                                <Typography component="h5" variant="h5">
                                    Roll Number:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4">
                                    {student?.rollNo}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={2}
                            sx={{ mt: 1, ml: 2 }}
                        >
                            <Grid item xs={12} md={3}>
                                <Typography component="h5" variant="h5">
                                    Section:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4">
                                    {student?.section?.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={2}
                            sx={{ mt: 1, ml: 2 }}
                        >
                            <Grid item xs={12} md={3}>
                                <Typography component="h5" variant="h5">
                                    Class:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component="h4" variant="h4">
                                    {student?.sclass?.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography component="h4" variant="h4">Pickup Address</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StudentLocationMap 
                            student={{
                                name: student.name,
                                location: {
                                    latitude: student?.pickup?.latitude,
                                    longitude: student?.pickup?.longitude,
                                    address: student?.pickup?.location
                                }
                            }}
                            school={school}
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography component="h4" variant="h4">Drop-off Address</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <StudentLocationMap 
                            student={{
                                name: student.name,
                                location: {
                                    latitude: student?.dropoff?.latitude,
                                    longitude: student?.dropoff?.longitude,
                                    address: student?.dropoff?.location
                                }
                            }}
                            school={school}
                        />
                    </AccordionDetails>
                </Accordion>


            </Dialog>
        </>
    )
}

export default StudentDetailDialog;