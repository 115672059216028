import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const TopBar = () => {
    return (
        <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
        >
            <Toolbar>
                {/* <Header handleLeftDrawerToggle={handleLeftDrawerToggle} /> */}
            </Toolbar>
        </AppBar>
    )
}
export default TopBar;