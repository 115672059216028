import React, { Component, useState, useEffect, useRef } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, Chip, StepContent, Paper, Grid,
    TextField, Autocomplete, CircularProgress } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';
import { useSelector } from 'react-redux';

import {
    getRouteTypes, searchStudent, fetchSchool as fetchSchoolHttp, saveOrUpdateRouteStudents,
    getAvailableBuses, saveOrUpdateRouteBus, getAvailableDrivers, saveOrUpdateRouteDriver,
    finalSubmitRoute, getRoute, createDropOffRoute, getDistanceAndDuration, getRouteByRefAndType
} from '../../../../services/http.service';
import { CustomModal } from '../../../../common/modals';
import { convertMetersToKMsText, convertSecsToHms, RouteType, ModalType, 
    GOOGLE_MAP_API_KEY } from '../../../../utils/constants';
import { Link, useParams, useNavigate } from 'react-router-dom';


import AddStudents from './add-students';
import AssignRouteBus from './assign-bus';
import AssignRouteDriver from './assign-driver';
import CreateDropOffRoute from './create-drop-off-route';
import AddRouteNumber from './add-number';
import AddRouteStops from './add-stops';


const steps = [
    // {
    //     label: 'Add students',
    //     description: `Search student from the dropdown and pont to exact student's location on google map on the right.`,
    // },
    {
        label: 'Add route number',
        description: `Add number to the route.`,
    },
    {
        label: 'Add stops',
        description: `Add stops to the route.`,
    },
    {
        label: 'Add students',
        description: `Add students to the route.`,
    },
    {
        label: 'Assign bus',
        description: `Assign the available bus to the route.`,
    },
    {
        label: 'Assign driver',
        description:`Assign the available driver to the route`,
    },
    {
        label: 'Create/update drop-off route',
        description:`Create drop-off route based on pick-up route. After creating drop-off route you can update details`,
    },
];


const NewRoute = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [errorMsg, setErrorMsg] = useState(null);
    const [route, setRoute] = useState({
        id: null,
        type: RouteType.PICKUP,
    });
    const [school, setSchool] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [routeTypesLoading, setRouteTypesLoading] = useState(false);
    const [routeTypes, setRouteTypes] = useState([]);
    const [availableBuses, setAvailableBuses] = useState([]);
    const [availableDrivers, setAvailableDrivers] = useState([]);

    const navigate = useNavigate();
    const { routeId } = useParams();

    const schoolId = useSelector(state => state.extras.schoolId);
    const baseUrl = useSelector(state => state.extras.baseUrl);

    useEffect(() => {
        fetchRouteTypes();
        if (routeId) {
            fetchRoute(routeId);
        }
        else {
            // Load available buses and drivers for new route
            fetchAvailableBuses();
            fetchAvailableDrivers();
        }
    }, [routeId]); // trigger when routeId changes - [] donot trigger if only id changes


    // Fetch route types
    const fetchRouteTypes = () => {
        setRouteTypesLoading(true);
        getRouteTypes().then(resp => {
            setRouteTypes(resp.data.data);
            console.log(`fetched route types = ${JSON.stringify(resp.data.data)}`);
            setRouteTypesLoading(false);
        }).catch(err => {
            console.log(err);
            setRouteTypesLoading(false);
        })
    }

    // Fetch route
    const fetchRoute = (routeId) => {
        setIsLoading(true);
        getRoute(routeId).then(resp => {
            const respRoute = resp.data.data;
            console.log("response route - ", respRoute);
            setIsLoading(false);
            if (respRoute) {
                const newRoute = {
                    id: respRoute.id,
                    refNo: respRoute.ref_no,
                    number: respRoute.number,
                    type: respRoute.type.name,
                    students: respRoute.route_students.map(item => {
                        return {
                            id: item.id,
                            isActive: item.is_active,
                            stop: item.stop,
                            student: {
                                id: item.student.id,
                                name: item.student.name,
                                rollNo: item.student.roll_no,
                                profilePhoto: item.student.profile_photo,
                                class: item.student.sclass,
                                section: item.student.section,
                            }
                        }
                    }),
                    stops: respRoute.route_stops.map(item => {
                        return {
                            id: item.id,
                            routeId: item.route_id,
                            order: item.order,
                            isActive: item.is_active,
                            stop: {
                                id: item.stop.id,
                                name: item.stop.name,
                                pickupTime: item.stop.pickup_time,
                                dropoffTime: item.stop.dropoff_time,
                                location: item.stop.location,
                            }
                        }
                    }),
                    bus: respRoute.bus,
                    driver: respRoute.driver
                }
                setRoute(newRoute);
                // Load available buses and drivers here for edit route, because it needs route refno to filter out them 
                fetchAvailableBuses(newRoute.refNo);
                fetchAvailableDrivers(newRoute.refNo);
            }
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
    }

       // Fetch available buses
       const fetchAvailableBuses = (routeRefNo) => {        
        getAvailableBuses(routeRefNo).then(resp => {
            setAvailableBuses(resp.data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    // Fetch available drivers
    const fetchAvailableDrivers = (routeRefNo) => {
        getAvailableDrivers(routeRefNo).then(resp => {
            setAvailableDrivers(resp.data.data);
        }).catch(err => {
            console.log(err);
        });
    }


    // on update of route from child stepper component
    const handleSubmit = (route) => {
        //setRoute(route);
        fetchRoute(route.id); // to updates latest change
    }

    // On update route - only set the state route
    const handleUpdateRoute = (route) => {
        setRoute(route);
    }

    // Update the url with new routeId
    useEffect(() => {
        if (route && route.id) {
            navigate(`${baseUrl}/route/edit/${route.id}`);
        }
    }, [route]);


    // ############ Stepper config ########################
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    // ############ End // Stepper config ########################

    // Fetch route by ref no and its type - to Navigate to pickup of this route 
    const NavigateToPickup = (refNo) => {
        getRouteByRefAndType(refNo, RouteType.PICKUP).then(resp => {
            const pickupRoute = resp.data.data;
            console.log(`Show pickup route - ${JSON.stringify(pickupRoute)}`);
            if (pickupRoute && pickupRoute.id) {
                const url = `${baseUrl}/route/edit/${pickupRoute.id}`;
                console.log(`pickup route - ${url}`);
                // this.props.history.push('/temp'); // to trigger pickup reload
                // this.props.history.push(url);
                //navigate('/temp');
                navigate(url);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const NavigateToDropOff = (refNo) => {
        getRouteByRefAndType(refNo, RouteType.DROPOFF).then(resp => {
            const dropoffRoute = resp.data.data;
            console.log(`Show drop off route - ${JSON.stringify(dropoffRoute)}`);
            if (dropoffRoute && dropoffRoute.id) {
                const url = `${baseUrl}/route/edit/${dropoffRoute.id}`;
                console.log(`pickup route - ${url}`);
                // this.props.history.push('/temp'); // to trigger pickup reload
                // this.props.history.push(url);
                //navigate('/temp');
                navigate(url);
            }
        }).catch(err => {
            console.log(err);
        })
    }



    
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                }}
            > 
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <Typography component="h2" variant="header">
                        {route.id ? 'Update route' : 'New route'}
                    </Typography>
                    <Chip
                        onClick={() => navigate(`../list`)}
                        icon={<FormatListBulletedIcon />}
                        label="Routes"
                        variant="outlined"
                        color="secondary"
                    />
                </Box>

                {route && route.id &&
                    <Box sx={{ mt: 2, display: "flex", justifyContent: "start", width: "100%", alignItems: "center" }}>
                        <Typography component="h5" variant="h5">
                            Route Type: <strong>{route.type}</strong>
                        </Typography>
                        <Typography component="h5" variant="h5" sx={{ ml: 4 }}>
                            Ref No: <strong>{route.refNo}</strong>
                        </Typography>
                        {route.type === RouteType.DROPOFF &&
                            <Button variant="link" sx={{ ml: 3 }} onClick={() => NavigateToPickup(route.refNo)}>Go to pickup route</Button>
                        }
                        {route.type === RouteType.PICKUP &&
                            <Button variant="link" sx={{ ml: 3 }} onClick={() => NavigateToDropOff(route.refNo)}>Go to drop-off route</Button>
                        }
                    </Box>
                }

                <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: "100%", mt: 2 }}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                    {/* Add students on route with map */}
                                    {/* {index === 0 &&
                                        <AddStudents
                                            route={route}
                                            onNext={handleNext} 
                                            onSubmit={(route) => handleSubmit(route)}
                                        />
                                    } */}
                                    {/* Add number to the route */}
                                    {index === 0 &&
                                        <AddRouteNumber
                                            route={route}
                                            onNext={handleNext} 
                                            onBack={handleBack}
                                            onSubmit={(route) => handleSubmit(route)}
                                        />
                                    }
                                    {/* Add stops to the route */}
                                    {index === 1 &&
                                        <AddRouteStops
                                            route={route}
                                            onNext={handleNext} 
                                            onBack={handleBack}
                                            onSubmit={(route) => handleSubmit(route)}
                                            onUpdateRoute={handleUpdateRoute}
                                        />
                                    }
                                    {index === 2 &&
                                        <AddStudents
                                            route={route}
                                            onNext={handleNext} 
                                            onBack={handleBack}
                                            onSubmit={(route) => handleSubmit(route)}
                                            onUpdateRoute={handleUpdateRoute}
                                        />
                                    }
                                    {/* Assign bus to the route */}
                                    {index === 3 &&
                                        <AssignRouteBus
                                            route={route}
                                            availableBuses={availableBuses}
                                            onNext={handleNext} 
                                            onSubmit={(route) => handleSubmit(route)}
                                            onBack={handleBack}
                                        />
                                    }
                                    {/* Assign driver to the route */}
                                    {index === 4 &&
                                        <AssignRouteDriver
                                            route={route}
                                            availableDrivers={availableDrivers}
                                            onNext={handleNext} 
                                            onSubmit={(route) => handleSubmit(route)}
                                            onBack={handleBack}
                                        />
                                    }
                                    {index === 5 &&
                                        <CreateDropOffRoute
                                            route={route}
                                            onSubmit={(route) => handleSubmit(route)}
                                            onBack={handleBack}
                                        />
                                    }
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </>
    )
    
}

export default NewRoute;