import { LEFT_NAV_VIEW } from '../actions/action-types'

const leftNav = (state = {}, action) => {
    console.log("LeftNav reducer :: action type " + action.type + " -- payload -- : " + JSON.stringify(action.payload));
    switch (action.type) {
      case LEFT_NAV_VIEW:
          return action.payload   
      default:
        return state
      }
  }

  export default leftNav