import React, { Component, useState } from 'react';
import { Container, Button, Box, Avatar, Typography, TextField, FormControlLabel, 
  Checkbox, Grid, Link, Paper, Snackbar, Alert, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

import { getSchoolByUniqueNumber, adminLogin } from '../../services/http.service';
import { saveAuth } from '../../services/auth.service';
import { ErrorAlertTopCenter } from '../../common/snackbars';
import UnAuthLayout from '../../layouts/unauth';


const AdminLogin = () => {
  const [schoolUniqueNumber, setSchoolUniqueNumber] = useState('');
  const [validated, setValidated] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [school, setSchool] = useState(null);

  let navigate = useNavigate();

  // Submit School Uniquer number and get school detail
  const fetchSchoolByUniqueNumber = () => {
    // Reset flags
    setIsSaving(true);
    setErrorMsg(null);
    // If unique number field is blank
    if (!schoolUniqueNumber || schoolUniqueNumber === "") {
      setIsSaving(false);
      setErrorMsg("Please provide School Identification Number");
      return;
    }

    getSchoolByUniqueNumber(schoolUniqueNumber).then(resp => {
      setIsSaving(false);
      setErrorMsg(null);
      setSchool(resp.data.data );
    }).catch(err => {
      setIsSaving(false);
      setErrorMsg(err);
    });
  }

    
  // Handle Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    // Reset flags
    setIsSaving(true);
    setErrorMsg(null);
    const data = {
      schoolId: school.id,
      mobile: form.get('mobile'),
      password: form.get('password')
    }
    adminLogin(data).then(resp => {
      setIsSaving(false);
      setErrorMsg(null);
      // Save auth and refirect to school Admin screen
      const auth = resp.data.data
      auth.user.school = school;
      console.log(`User login user - ${JSON.stringify(auth)}`);
      saveAuth(auth);
      // Navigate to school Admin
      navigate(`/school/${school.id}/admin/trip/today/list`);

    }).catch(err => {
      console.log(JSON.stringify(err));
      setIsSaving(false);
      setErrorMsg(err);
    })
  }


  return (
    <>
      {errorMsg &&
        <ErrorAlertTopCenter
          errorMsg={errorMsg}
          onClose={() => setErrorMsg(null)}
        />
      }
      <Container component="main" maxWidth="xs">
        <Paper elevation={10}>
          <Box
            padding={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Admin Sign In
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, px:2, width: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="schoolUniqueNumber"
                label="School Identification Number"
                name="schoolUniqueNumber"
                autoComplete="schoolUniqueNumber"
                autoFocus
                onChange = {(e) => {
                  setSchoolUniqueNumber(e.target.value);
                }}
              />
              {school && school.id &&
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Mobile/Email"
                    name="mobile"
                    autoComplete="mobile"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </>
              }

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {isSaving &&
                  <CircularProgress />
                }
                { !isSaving &&
                  <>
                    {!(school && school.id) &&
                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={fetchSchoolByUniqueNumber}
                      >
                        Continue
                      </Button>
                    }
                    {school && school.id &&
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Sign In
                      </Button>
                    }
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );


}

// class AdminLogin extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       schoolUniqueNumber: '',
//       validated: false,
//       errorMsg: null,
//       isSaving: false,
//       mobile: '',
//       password: '',
//       school: null,
//     };
//   }

//   // Submit School Uniquer number and get school detail
//   fetchSchoolByUniqueNumber = () => {
//     // Reset flags
//     this.setState({
//       isSaving: true,
//       errorMsg: null
//     })
//     // If unique number field is blank
//     if (!this.state.schoolUniqueNumber || this.state.schoolUniqueNumber === "") {
//       this.setState({
//         isSaving: false,
//         errorMsg: "Please provide School Identification Number"
//       })
//       return;
//     }

//     getSchoolByUniqueNumber(this.state.schoolUniqueNumber).then(resp => {
//       this.setState({
//         isSaving: false,
//         errorMsg: null
//       })
//       this.setState({ school: resp.data.data});
//     }).catch(err => {
//       this.setState({
//         isSaving: false,
//         errorMsg: err
//       })
//     });
//   }

//   // Submit form
//   // Handle Submit
//   handleSubmit(event) {
//     event.preventDefault();
//     event.stopPropagation();
//     this.setState({ validated: true });
//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//         return;
//     }
//     // Reset flags
//     this.setState({
//       isSaving: true,
//       errorMsg: null
//     })
//     const data = {
//       schoolId: this.state.school.id,
//       mobile: form[1].value,
//       password: form[2].value
//     }
//     adminLogin(data).then(resp => {
//       this.setState({
//         isSaving: false,
//         errorMsg: null
//       })
//       // Save auth and refirect to school Admin screen
//       const auth = resp.data.data
//       auth.user.school = this.state.school;
//       console.log(`User login user - ${JSON.stringify(auth)}`);
//       saveAuth(auth);
//       // Navigate to school Admin
//       this.props.history.push(`/school/${this.state.school.id}/admin/trip/today/list`);

//     }).catch(err => {
//       console.log(JSON.stringify(err));
//       this.setState({
//         isSaving: false,
//         errorMsg: err
//       })
//     })
//   }

//   render() {
//     const { school, validated, isSaving, errorMsg } = this.state;
//     return (
//       <div id="layoutAuthentication" class="bg-primary">
//         <div id="layoutAuthentication_content">
//           <main>
//             <div class="container">
//                 <div class="row justify-content-center">
//                     <div class="col-lg-5">
//                         <div class="card shadow-lg border-0 rounded-lg mt-5">
//                             <div class="card-header"><h3 class="text-center font-weight-light my-4">Admin Login</h3></div>
//                             <div class="card-body">
//                               {this.state.errorMsg &&
//                                 <Alert variant="danger" onClose={() => this.setState({ errorMsg: null })} dismissible>
//                                   <strong>{errorMsg}</strong>
//                                 </Alert>
//                               }
//                               <Form className="mt-3 text-muted"
//                                 noValidate
//                                 validated={validated}
//                                 onSubmit={e => this.handleSubmit(e)}>

//                                   <Form.Group controlId="formMobile" className="form-group">
//                                     <Form.Label className="small mb-1">School Identification Number</Form.Label>
//                                     <Form.Control
//                                       className="form-control py-4"
//                                       required
//                                       type="text"
//                                       placeholder="Enter Identification Number"
//                                       value={this.state.schoolUniqueNumber}
//                                       onChange={(e) => {
//                                         this.setState({ schoolUniqueNumber: e.target.value });
//                                       }} />
//                                     </Form.Group>

//                                     {school && school.id &&
//                                       <React.Fragment>
//                                         <Form.Group className="form-group">
//                                           <Form.Label className="small mb-1">Mobile/Email</Form.Label>
//                                           <Form.Control
//                                             className="form-control py-4"
//                                             required
//                                             type="text"
//                                             placeholder="Enter mobile/email" />
//                                         </Form.Group>
//                                         <Form.Group className="form-group">
//                                           <Form.Label className="small mb-1">Password</Form.Label>
//                                           <Form.Control
//                                             className="form-control py-4"
//                                             required
//                                             type="password"
//                                             placeholder="Enter password" />
//                                         </Form.Group>
//                                       </React.Fragment>
//                                     }

//                                     <div className="d-flex flex-column align-items-center justify-content-center">
//                                       {!(school && school.id) &&
//                                         <Button className="btn btn-primary btn-user btn-block btn-user mb-3" type="button"
//                                           onClick={() => this.fetchSchoolByUniqueNumber()}
//                                         >
//                                           Submit
//                                         </Button>
//                                       }
//                                       {school && school.id &&
//                                         <Button className="btn btn-primary btn-user btn-block btn-user mb-3" type="submit">Login</Button>
//                                       }
//                                       {isSaving &&
//                                         <Spinner animation="border" variant="primary" />
//                                       }
//                                     </div>
//                                   </Form>
//                             </div>
//                             <div class="card-footer text-center">
//                                 <div class="small d-flex align-items-center justify-content-between">
//                                     <a href="/login">Login</a>
//                                     <a href="/forgot-password">Forgot Password?</a>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//           </main>
//         </div>
//       </div>
//     )
//   }
// }

export default AdminLogin;