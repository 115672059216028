import React, { Component, useState, useEffect } from 'react';
import { Box, Typography, Grid, Link as MuiLink, Chip, CircularProgress, TextField,
    Button } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { saveOrUpdateSection, getSection } from '../../../../services/http.service';
import { ErrorAlertTopCenter, SuccessAlertTopRight } from '../../../../common/snackbars';

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
});


const NewSection = () => {

    const [validated, setValidated] = useState(false);
    const [section, setSection] = useState({
        id: null,
        name: ''
    });
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const { sectionId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (sectionId) {
            fetchSection(sectionId);
        }
    }, []);


    // Fetch section by id from server
    const fetchSection = (id) => {
        setIsLoading(true);
        getSection(id).then(resp => {
            const section = resp.data.data;
            setIsLoading(false);
            setSection(section);
            formik.setValues({ ...section }); // get copy of section
            console.log(section);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    // Formik handle submit
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const newSection = {
                id: section?.id, // in case of update
                name: values.name
            }
            setIsSaving(true);  
            setShowSuccessAlert(false);
            saveOrUpdateSection(newSection).then(resp => {
                setIsSaving(false);
                setShowSuccessAlert(true);
                setErrorMsg(null);
                // do not clear in case of update
                if (!section?.id) {
                    formik.resetForm();
                }
            }).catch(err => {
                console.log(err);
                setIsSaving(false);
                setShowSuccessAlert(false);
                setErrorMsg(err);
            });
        }
    });

    // Handle Submit
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     setValidated(true);
    //     const form = event.currentTarget;
    //     if (form.checkValidity() === false) {
    //         return false;
    //     }   

    //     // const section = this.state.section;
    //     //section.name = form[0].value;
    //     setSection({
    //         name: form[0].value
    //     });
    //     console.log(section);

    //     setIsSaving(true);  
    //     setShowSuccessAlert(false);
    //     saveOrUpdateSection(section).then(resp => {
    //         setIsSaving(false);
    //         setShowSuccessAlert(true);
    //         setErrorMsg(null);
    //         // fetch newly added section

    //     }).catch(err => {
    //         console.log(err);
    //         setIsSaving(false);
    //         setShowSuccessAlert(false);
    //         setErrorMsg(err);
    //     })
    // }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "start",
            }}
        >
            { errorMsg &&
                <ErrorAlertTopCenter 
                    errorMsg={errorMsg}
                    onClose={() => setErrorMsg(null)} 
                />
            }
            { showSuccessAlert &&
                <SuccessAlertTopRight
                    successMsg={"Section saved successfully!!"}
                    onClose={() => setShowSuccessAlert(false)} 
                />
            }

            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <Typography component="h2" variant="header">
                    {section && section.id ? "Update new section" : "Create new section"}
                </Typography>
                <Chip
                    onClick={() => navigate(`../list`)}
                    icon={<FormatListBulletedIcon />}
                    label="Sections"
                    variant="outlined"
                    color="secondary"
                />
            </Box>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                {isLoading &&
                    <Grid container>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} md={6}>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            required
                            name="name"
                            label="Section name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {isSaving &&
                                <CircularProgress />
                            }
                            {!isSaving &&
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Submit
                                </Button>
                            }
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
  
    // return (
    //     <React.Fragment>
    //         <div className="d-sm-flex align-items-center justify-content-between mb-4">
    //             <div className="h5 mb-0 text-gray-800">New Section</div>
    //         </div>                
    //         <div className="card shadow h-100 my-2">
    //             <div className="card-body">
    //                 {isLoading &&                   
    //                     <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
    //                 }
    //                 <Form
    //                     noValidate
    //                     validated={validated}
    //                     onSubmit={handleSubmit}>
                                                        
    //                     <Form.Group as={Row}>
    //                         <Form.Label column sm="2" >Setion Name</Form.Label>
    //                         <Col sm="4">
    //                             <Form.Control type="text" placeholder="Enter section name" 
    //                                 value={section.name}
    //                                 onChange={(e) => {
    //                                     // const section = this.state.section;                                                           
    //                                     // section.name = e.target.value;
    //                                     // this.setState({section});
    //                                     setSection({
    //                                         ...section,
    //                                         name: e.target.value
    //                                     });
    //                                 }}
    //                             />
    //                         </Col>
    //                     </Form.Group>                                
                                    
    //                     <div className="d-flex align-items-center justify-content-left">
    //                         <Button className="btn btn-primary" type="submit">Submit</Button>   
    //                         {isSaving &&                         
    //                             <Spinner animation="border" variant="primary" />
    //                         }
    //                         {showSuccessAlert &&
    //                             <Alert className="ml-3 mb-0" variant="success" onClose={() => setShowSuccessAlert(false) } 
    //                                 dismissible>
    //                                 Saved Successfully
    //                             </Alert>
    //                         }
    //                         {errorMsg &&
    //                             <Alert className="ml-3 mb-0" variant="danger" onClose={() => setErrorMsg(null) } 
    //                                 dismissible>
    //                                 {errorMsg}
    //                             </Alert>
    //                         }
    //                     </div> 

    //                 </Form>
    //             </div>
    //         </div>                 
    //     </React.Fragment> 
    // );
}

export default NewSection;


// class NewSection extends Component {
	
// 	constructor(props) {
// 		super(props)
// 		this.state = {
//             validated: false,
//             section: {
//                 id: null,
//                 name: ''
//             },

//             isSaving: false,
//             errorMsg: null,
//             showSuccessAlert: false
// 		};
//     }

//     componentDidMount() {
//         const id = this.props.match.params.id;
//         this.fetchSection(id);
//     }

//     // Fetch section by id from server
//     fetchSection = (id) => {
//         this.setState({isLoading: true});
//         getSection(id).then(resp => {
//             this.setState({isLoading: false});
//             this.setState({section: resp.data.data});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isLoading: false});
//         })
//     }

//     // Handle Submit
//     handleSubmit(event) {
//         event.preventDefault();
//         event.stopPropagation();
//         this.setState({ validated: true });
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             return false;
//         }   

//         const section = this.state.section;
//         section.name = form[0].value;

//         this.setState({isSaving: true});  
//         this.setState({showSuccessAlert: false});
//         saveOrUpdateSection(section).then(resp => {
//             this.setState({isSaving: false}); 
//             this.setState({showSuccessAlert: true});
//             this.setState({errorMsg: null});
//         }).catch(err => {
//             console.log(err);
//             this.setState({isSaving: false}); 
//             this.setState({errorMsg: err}); 
//             this.setState({showSuccessAlert: false});
//         })
//     }
    
//     render() {
//         const { validated, isSaving, errorMsg, showSuccessAlert, isLoading } = this.state;
// 		return (
//             <React.Fragment>
//                 <div className="d-sm-flex align-items-center justify-content-between mb-4">
//                     <h1 className="h4 mb-0 text-gray-800">New Section</h1>
//                 </div>                
//                 <div className="card shadow h-100 my-2">
//                     <div className="card-body">
//                         {isLoading &&                   
//                             <Spinner className="text-muted text-center mb-3" animation="border" variant="primary" />                            
//                         }
//                         <Form
//                             noValidate
//                             validated={validated}
//                             onSubmit={e => this.handleSubmit(e)}>
                                                            
//                             <Form.Group as={Row}>
//                                 <Form.Label column sm="2" >Setion Name</Form.Label>
//                                 <Col sm="4">
//                                     <Form.Control type="text" placeholder="Enter section name" 
//                                         value={this.state.section.name}
//                                         onChange={(e) => {
//                                             const section = this.state.section;                                                           
//                                             section.name = e.target.value;
//                                             this.setState({section});
//                                         }}
//                                     />
//                                 </Col>
//                             </Form.Group>                                
                                        
//                             <div className="d-flex align-items-center justify-content-left">
//                                 <Button className="btn btn-primary" type="submit">Submit</Button>   
//                                 {isSaving &&                         
//                                     <Spinner animation="border" variant="primary" />
//                                 }
//                                 {showSuccessAlert &&
//                                     <Alert className="ml-3 mb-0" variant="success" onClose={() => this.setState({showSuccessAlert: false}) } 
//                                         dismissible>
//                                         Saved Successfully
//                                     </Alert>
//                                 }
//                                 {errorMsg &&
//                                     <Alert className="ml-3 mb-0" variant="danger" onClose={() => this.setState({errorMsg: null}) } 
//                                         dismissible>
//                                         {this.state.errorMsg}
//                                     </Alert>
//                                 }
//                             </div> 

//                         </Form>
//                     </div>
//                 </div>                 
//             </React.Fragment> 
//         )
//     }
// }

// export default NewSection;