import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Chip, Box } from '@mui/material';

import CommonUserOtpVerification from '../../../../common/user-otp-verification';
import { getDriver } from '../../../../services/http.service';

const DriverVerify = () => {

    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [driver, setDriver] = useState(null);

    const { driverId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchDriver(driverId);
    }, []);

    // Fetch driver detail for edit
    const fetchDriver = (driverId) => {
        setIsLoading(true);
        getDriver(driverId).then(resp => {
            console.log(`Fetch driver - ${JSON.stringify(resp)}`);
            setDriver(resp.data.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }

    return (
        <>
             <Chip 
                icon={<ArrowBackIosIcon />} 
                label="Back" 
                variant="outlined" 
                size="small"
                onClick={() => navigate(-1)}
            />
            { driver &&
                <Box sx={{ width: '50%'}}>
                    <CommonUserOtpVerification user={driver.user}  />
                </Box>
            }
        </>
    )
}

export default DriverVerify