import React, { useState, useEffect } from 'react';
import { Outlet } from "react-router-dom";

const TripMetadata = () => {
    return (
        <React.Fragment>
            <Outlet />   
        </React.Fragment>
    );
}
export default TripMetadata;