import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ModalType } from '../utils/constants';


export const CustomModal = ({ type, show, onHide, onOkay, data, content }) => {

    const [confirmFormError, setConfirmFormError] = useState(false);

    const onConfirmFormSubmit = (data) => {
        if (!data.value || data.value.trim().length === 0) {
            setConfirmFormError(true);
        } else {
            setConfirmFormError(false);
            onOkay(data);
        }
    }

    useEffect(() => {
        setConfirmFormError(false);
    }, [show]);

    return (
        <>
            {type === ModalType.CONFIRM_DELETE &&
                <Dialog
                    open={show}
                    onClose={onHide}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    <Typography component="h3" variant="h3" color="error.light">
                        {'Delete'}
                    </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'Are you sure you want to delete?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onHide}>No</Button>
                        <Button onClick={() => onOkay(data)} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {type === ModalType.CONFIRM_FORM &&
                <Dialog
                    open={show}
                    onClose={onHide}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="sm"
                    fullWidth="sm"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography component="h3" variant="h3" color="error.light">
                            {'Confirm'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {content}
                        </DialogContentText>
                        <TextField
                            error={confirmFormError}
                            helperText={confirmFormError ? 'Required' : ''}
                            autoFocus
                            margin="dense"
                            id="reason"
                            label="Type the reason"
                            type="text"
                            fullWidth
                            multiline
                            maxRows={4}
                            onChange={(event) => {
                                const value = event.target.value;
                                data.value = event.target.value;
                                if (value) {
                                    setConfirmFormError(false);
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onHide}>Cancel</Button>
                        <Button onClick={() => onConfirmFormSubmit(data)} autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {type === ModalType.CONFIRM_DISABLE &&
                <Dialog
                    open={show}
                    onClose={onHide}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    <Typography component="h3" variant="h3" color="error.light">
                        {data.isDisabled ? 'Disable' : 'Enable'}
                    </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {`Are you sure you want to ${data.isDisabled ? 'disable' : 'enable'}?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onHide}>No</Button>
                        <Button onClick={() => onOkay(data)} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
	

    // return (   
    //     <React.Fragment>
    //         {console.log('CustomModal render...')}
    //         {props.type === ModalType.CONFIRM_DELETE &&
    //             <Modal show={props.show} onHide={() => {props.onHide()}}>
    //                 <Modal.Header closeButton>
    //                     <Modal.Title>{'Delete'}</Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>{'Are you sure you want to delete?'}</Modal.Body>
    //                 <Modal.Footer>
    //                     <Button variant="secondary" onClick={() => {props.onHide()}}>
    //                         Cancel
    //                     </Button>
    //                     <Button variant="primary" onClick={() => props.onOkay(props.data)}>
    //                         Yes
    //                     </Button>
    //                 </Modal.Footer>
    //             </Modal>
    //         }
    //         {props.type === ModalType.ALERT &&
    //             <Modal show={props.show} onHide={() => {props.onHide()}}>
    //                 <Modal.Header closeButton>
    //                     <Modal.Title>Alert</Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>{props.body}</Modal.Body>
    //                 <Modal.Footer>
    //                     <Button variant="primary" onClick={() => props.onHide()}>
    //                         Ok
    //                     </Button>
    //                 </Modal.Footer>
    //             </Modal>
    //         }
    //     </React.Fragment>
    // )  
}