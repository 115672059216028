import React from 'react';
import { Outlet, Link } from "react-router-dom";

const User = () => {
    return (
        <React.Fragment>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h4 mb-0 text-gray-800">School users</h1>
                <div class="mx-3">
                    <Link type="button" class="btn btn-info btn-sm ml-3" to="student/list">Students</Link>
                    <Link type="button" class="btn btn-info btn-sm ml-3" to="driver/list">Drivers</Link>
                    {/* TODO Do not show this for Admin user */}
                    <Link type="button" class="btn btn-info btn-sm ml-3" to="admin/list">Admins</Link>
                </div>
            </div>
            <div className="card shadow h-100 my-2">
                <div className="card-body">
                    <Outlet />
                </div>
            </div>
        </React.Fragment>
    );
}

export default User;